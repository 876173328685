import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import DataTableLayout from "layouts/DatatableLayout";
import KitchenCategoryModal from "./KitchenCategoryModal";
import ProductCategoryApi from "services/branch/kitchen/ProductCategoryApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";

const KitchenCategoryList = () => {
  const { branchID, kitchenID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();
  const [modalcategorylistShow, setModalcategorylistShow] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [categorydata, setCategorydata] = useState([]);
  const [subcategory, setSubcategory] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.categoryNo,
      cell: (row, index, column, id) => (
        <div className="ps-4">{row.categoryNo}</div>
      ),
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.category_name,
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.sub_category_name,
    },
    {
      name: "จำนวนสินค้า",
      selector: (row) => row.count,
      center: "true",
    },
    // {
    //   name: "จัดการ",
    //   selector: (row) => row.category_id,
    //   center: "true",
    //   cell: (row, index, column, id) => (
    //     <div>
    //       <Button
    //         type="button"
    //         className="btn-delete-st-3"
    //         onClick={() => handleDelete(row)}
    //       ></Button>
    //     </div>
    //   ),
    // },
  ];

  const filteredItems = categorydata.filter(
    (item) =>
      item.category_name &&
      item.category_name.toLowerCase().includes(filterText.toLowerCase()),
    (item) =>
      item.sub_category_name &&
      item.sub_category_name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    if (user.businessId && token && branchID && kitchenID) {
      getCategoryData();
    }
  }, [user.businessId, token, branchID, kitchenID]);

  const getCategoryData = async () => {
    ProductCategoryApi.GetAllProductCategories(
      branchID,
      kitchenID,
      config
    ).then((res) => {
      // push no to data
      res.data.map((item, index) => {
        item.categoryNo = index + 1;
        return item;
      });
      setCategorydata(res.data);
      getSubCategoryData(res.data);
    });
  };

  const getSubCategoryData = async (catData) => {
    ProductCategoryApi.GetAllSubCategories(config).then((res) => {
      // remove data from res.data if product_category_id equal categorydata category_id exist 
      const filteredData = res.data.filter((item) => {
        return !catData.some((item2) => {
          return item.id == item2.sub_category_id;
        });
      });

      setSubcategory(filteredData);
    });
  };

  const AddCategory = (category) => {
    console.log(category);
    setModalcategorylistShow(false);
  };

  const handleDelete = (item) => {
    console.log(item);
  };

  return (
    <div>
      {kitchenID && (
        <div className="card card-st-2 mb-4">
          <div className="card-body px-0 py-4">
            <div className="d-flex align-items-center px-4 mb-4">
              <div className="tc-7 fs-20 fw-6   me-auto">หมวดหมู่</div>
              <div className="me-3">
                <input
                  type="text"
                  className="form-control form-st-4"
                  placeholder="ค้นหาชื่อหมวดหมู่"
                  onKeyUp={(e) => setFilterText(e.target.value)}
                />
              </div>
              <Button
                disabled={true}
                type="button"
                className="btn btn btn-st-7 d-none"
                onClick={() => setModalcategorylistShow(true)}
              >
                เพิ่มหมวดหมู่
              </Button>
            </div>

            <DataTableLayout
              columns={columns}
              item={filteredItems}
              isSelectRows={false}
            />
          </div>
        </div>
      )}

      {subcategory && subcategory.length > 0 && (
        <KitchenCategoryModal
          show={modalcategorylistShow}
          onHide={() => setModalcategorylistShow(false)}
          handleSubmit={(category) => AddCategory(category)}
          subcategory={subcategory}
        />
      )}
    </div>
  );
};

export default KitchenCategoryList;
