
import React, { useState} from 'react';
import ReactPaginate from 'react-paginate';

export default function PaginatedItems(props) {

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + props.itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = props.items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(props.items.length / props.itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * props.itemsPerPage) % props.items.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <div>
            <Items currentItems={currentItems} />
        </div>
        
        <div className='d-flex justify-content-center'>
            <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            />
        </div>
      </>
    );
}
function Items({ currentItems }) {
    return (
      <div className='py-4 d-flex flex-wrap'>
        {currentItems &&
          currentItems.map((item,index) => (
            <div className="card card-food me-3 mb-3" key={index}>
                  <div className='position-relative'>
                    <img src={item.productImage} className="food-img" />
                    <div className='position-absolute' style={{top:'10px',right:'10px'}}>
                    {
                        item.buffet == 1 ? <div className='tag-img buffet'>เป็นแพคเกจบุฟเฟต์</div> : ''
                    }
                    {
                        item.saleperweight == 1 ? <div className='tag-img saleperweight'>ขายตามน้ำหนัก</div> : ''
                    }
                    {
                        item.productSet == 1 ? <div className='tag-img productset'>สินค้าจัดชุด</div> : ''
                    }
    
                    </div>
                  </div>
                  <div className="card-body pt-1 px-3 ">
                    <div className="card-name ">{item.productName}</div>
                    <div className="card-price mb-1">฿{item.productPrice}.-</div>
                    <div className="card-barcode mb-2">฿{item.productCode}.-</div>
                    <div className='d-flex flex-wrap'>
                        <div className='tag-category me-2'>{item.productCategory}</div>
                        <div className='tag-display me-2'></div>
                        <div className='tag-vat me-2'>Vat</div>
                    </div>
                  </div>
            </div>
          ))}
      </div>
    );
}
