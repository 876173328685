import React, { useState,useEffect  } from 'react';
import {Link,useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export function  Workingtime() {
    
    const [formdata, setFormdata] = useState({})
    const schema = yup.object().shape();

    const [value, onChange] = useState("00:00");

    const [allday, setAllDay] = useState(
        {
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
    )

    const [day, setDay] = useState([
        {
            "id":1,
            "day":"monday",
            "dayTh":"วันจันทร์",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":2,
            "day":"tuesday",
            "dayTh":"วันอังคาร",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":3,
            "day":"wednesday",
            "dayTh":"วันพุธ",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":4,
            "day":"thursday",
            "dayTh":"วันพฤหัสบดี",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":5,
            "day":"friday",
            "dayTh":"วันศุกร์",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":6,
            "day":"saturday",
            "dayTh":"วันเสาร์",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
        {
            "id":7,
            "day":"sunday",
            "dayTh":"วันอาทิตย์",
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        }
    ])

    const [breaktime, setBreaktime] = useState(
        {
            "timeStart":"00:00",
            "timeEnd":"00:00",
            "status":0
        },
    )

    const handleCheckday = (e,id) => {
        let check = e.target.checked === true ? 1 : 0;
        setDay((prev) =>
        prev.map((el) =>
            el.id === id
            ? {
                ...el,
                status: el.status = check
                }
            : el
        )
        );
        setAllDay((prev) => ({  ...prev,status:0,timeStart:"00:00",timeEnd:"00:00" }) );
    }

    const handlestartTime = (e,id) => {
        setDay((prev) =>
        prev.map((el) =>
            el.id === id
            ? {
                ...el,
                timeStart: el.timeStart = e
                }
            : el
        )
        );
    }
    const handleendTime = (e,id) => {
        setDay((prev) =>
        prev.map((el) =>
            el.id === id
            ? {
                ...el,
                timeEnd: el.timeEnd = e
                }
            : el
        )
        );
    }

    return (
        <div>
            <div className='px-4 py-3 fw-bold' style={{color:'#565DFF',background:'#FFF',borderBottom:'1px solid rgba(76, 78, 100, 0.12)' }}>ตั้งค่าเวลา</div>
            <div className='card border-0 mx-auto mt-5 mb-5' style={{width: '513px',borderRadius: '8px'}}> 
                <div className='card-body p-4'>
                    <div className="fw-bold mb-4" style={{color:'#565DFF'}}>ตั้งค่าทั้วไป</div>
                    <Formik
                    enableReinitialize
                    initialValues={ formdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        console.log(values)
        
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={(e) => { 
                            e.preventDefault();
                            setFieldValue("allday",allday)
                            setFieldValue("day",day)
                            setFieldValue("breaktime",breaktime)

                            handleSubmit();
                        } }>

                    
                    <div>
                        <div className='row align-items-center mb-2 pb-1'>
                            <div className='col-6'>
                                <Form.Check 
                                    type='checkbox'
                                    id="alldays"
                                    label="เปิดทำการทุกวัน"
                                    className='form-check-st-1 tc-7'
                                    checked={allday.status === 1} 
                                    onChange={(e) => {
                                        if(e.target.checked === true){
                                            setAllDay((prev) => ({  ...prev,status:1 }) );
                                            setDay((prev) =>
                                                prev.map((el) =>
                                                    ({
                                                        ...el
                                                        ,timeStart:"00:00"
                                                        ,status : 0
                                                        ,timeEnd: "00:00"
                                                    })
                                                )
                                            );
                                        }else{
                                            setAllDay((prev) => ({  ...prev,status:0 }) );
                                        } 
                                    }}

                                />
                            </div>
                            <div className='col-3'>
                                <TimePicker 
                                     
                                    value={allday.timeStart}
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={allday.status === 0} 
                                    onChange={(e) => {
                                        setAllDay((prev) => ({  ...prev,timeStart:e }) );
                                    }}
                                    
                                />                                   
                            </div>
                            <div className='col-3'>
                                <TimePicker 
                                    
                                    value={allday.timeEnd}
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={allday.status === 0} 
                                    onChange={(e) => {
                                        setAllDay((prev) => ({  ...prev,timeEnd:e }) );
                                    }}
                                />
                            </div>
                        </div>

                        
                        { day.map((item,index) =>
                                <div className='row align-items-center mb-2 pb-1' key={item.id}>
                                <div className='col-6 ps-5'>
                                    <Form.Check 
                                        type='checkbox'
                                        id={"day"+item.id}
                                        label={item.dayTh}
                                        className='form-check-st-1 tc-7'
                                        checked={item.status === 1} 
                                        onChange={(e) => {
                                            handleCheckday(e,item.id)
                                        }}
                                        
                                    />
                                </div>
                                <div className='col-3'>
                                    <TimePicker 
                                        onChange={(e) => {
                                            handlestartTime(e,item.id)
                                        }}
                                        value={item.timeStart} 
                                        clockIcon={null}
                                        clearIcon={null}
                                        format="HH:mm"
                                        className="form-time"
                                        disabled={item.status === 0} 
                                    />                                   
                                </div>
                                <div className='col-3'>
                                    <TimePicker 
                                        onChange={(e) => {
                                            handleendTime(e,item.id)
                                        }}
                                        value={item.timeEnd} 
                                        clockIcon={null}
                                        clearIcon={null}
                                        format="HH:mm"
                                        className="form-time"
                                        disabled={item.status === 0} 
                                    />
                                </div>
                                </div>
                            ) 
                        }



                        <div className='row align-items-center mb-3'>
                            <div className='col-6'>
                                <Form.Check 
                                    type='checkbox'
                                    id="btime"
                                    label="เวลาพักเบก"
                                    className='form-check-st-1 tc-7'
                                    checked={breaktime.status === 1} 
                                    onChange={(e) => {
                                        if(e.target.checked === true){
                                            setBreaktime((prev) => ({  ...prev,status:1 }) );
                                        }else{
                                            setBreaktime((prev) => ({  ...prev,status:0 }) );
                                        } 
                                    }}
                                    
                                />
                            </div>
                            <div className='col-3'>
                                <TimePicker 
                                    onChange={(e) => {
                                        setBreaktime((prev) => ({  ...prev,timeStart:e }) );
                                    }}
                                    value={breaktime.timeStart} 
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={breaktime.status === 0} 
                                    
                                />                                   
                            </div>
                            <div className='col-3'>
                                <TimePicker 
                                    onChange={(e) => {
                                        setBreaktime((prev) => ({  ...prev,timeEnd:e }) );
                                    }} 
                                    value={breaktime.timeEnd} 
                                    clockIcon={null}
                                    clearIcon={null}
                                    format="HH:mm"
                                    className="form-time"
                                    disabled={breaktime.status === 0} 
                                />
                            </div>
                        </div>

                            <div className="text-end pt-3 btn-group-control" >
                                <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                            </div>
                        
                    </div>
                    </Form>
                    )}
                    </Formik>
                </div>
                
            </div>
            <div className='py-4'></div>
        </div>
    )
}