import CustomBtn from 'components/fragments/CustomBtn'
import CustomInput from 'components/fragments/CustomInput'
import CustomInputPassword from 'components/fragments/CustomInputPassword'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authState, useAuthLogin, useAuthTokenCheck, useClientCheck } from '../../state/auth'
import { posState } from 'state/pos'
import { appState } from 'state/app'
import Logo3 from 'img/logo-3.png'

const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string().required('อีเมลหรือเบอร์โทรศัพท์ is required'),
    password: Yup.string().required('รหัสผ่าน is required'),
})

export default function LoginPage () {
    const navigate = useNavigate()
    const { isLoggedIn, authErrorStatus, authErrorDisplayText } = useRecoilValue(authState)
    const { loginBusiness } = useRecoilValue(appState)
    const authUser = useAuthLogin()
    const authTokenCheck = useAuthTokenCheck()
    const clientCheck = useClientCheck()

    const formik = useFormik({
        initialValues: {
            emailOrPhone: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await authUser(values)
        },
    })

    // useEffect(() => {
    //   authTokenCheck();
    // },[]);

    useEffect(() => {
        // console.log("isLoggedIn: ", isLoggedIn)
        // if(isLoggedIn) {
        //   navigate("/");
        // }
        clientCheck()
        authTokenCheck()
    }, [isLoggedIn])

    return (
        <div
            className="login-bg w-full min-h-screen flex justify-center items-center sarabun text-[var(--color-black)] p-[1vw]">
            <div
                className="bg-white p-[min(2.92vw,30px)] flex flex-col items-center rounded-[min(2.44vw,25px)] w-[min(39.16vw,401px)]">
                <div className="text-24 font-[700] flex flex-col items-center">
                    <div className="w-[min(4.83vw,49.5px)] aspect-square">
                        {/* <img className="w-full h-full object-contain" src="img/logo-3.png" alt="" /> */}
                        <img className="w-full h-full object-contain" src={Logo3} alt=""/>
                    </div>
                    <div className="comfortaa">
                        <span className="text-[--color-grey]">Allder</span>{' '}
                        <span className="text-[--color-green]">Cafe’</span>
                    </div>
                </div>
                {/* <div className="mt-[min(1.95vw,20px)] font-[700] text-20 text-[--color-blue] uppercase">
          POS - อินเตอร์ คอฟฟี่ เทรด
        </div> */}
                <div className="mt-[min(1.95vw,20px)] font-[700] text-20 text-[--color-orange] uppercase">
                    {loginBusiness}
                </div>
                {authErrorStatus &&
                    <div className="mt-[min(1.95vw,12px)] font-[500] text-16 text-[--color-red] uppercase">
                        {authErrorDisplayText}
                    </div>
                }
                <form className="w-full" onSubmit={formik.handleSubmit}>
                    <CustomInput
                        className="mt-[min(1.95vw,20px)] w-full"
                        inputprops={{
                            className: 'rounded-[min(0.78vw,8px)] border-[min(0.09vw,8px)] border-[#b6bbc6] py-[min(1.66vw,17px)] px-[min(1.95vw,20px)] pr-[min(4.2vw,42px)]',
                            placeholder: 'อีเมลหรือเบอร์โทรศัพท์',
                            name: 'emailOrPhone',
                            onChange: formik.handleChange,
                            onBlur: formik.handleBlur,
                            value: formik.values.emailOrPhone,
                            error:
                                formik.touched.emailOrPhone &&
                                formik.errors.emailOrPhone ? 'error' : undefined,
                        }}
                    />
                    <CustomInputPassword
                        className="mt-[min(1.95vw,20px)] w-full"
                        inputprops={{
                            className: 'rounded-[min(0.78vw,8px)] border-[min(0.09vw,8px)] border-[#b6bbc6] py-[min(1.66vw,17px)] px-[min(1.95vw,20px)] pr-[min(4.2vw,42px)]',
                            placeholder: 'รหัสผ่าน',
                            name: 'password',
                            onChange: formik.handleChange,
                            onBlur: formik.handleBlur,
                            value: formik.values.password,
                            error:
                                formik.touched.password &&
                                formik.errors.password ? 'error' : undefined,
                        }}
                    />
                    <CustomBtn
                        type="submit"
                        className="btn-main-active text-18 mt-[min(1.95vw,20px)] w-full rounded-[min(0.78vw,8px)] py-[min(1.66vw,17px)]"
                        btntxt={'เข้าสู่ระบบ'}
                    />
                </form>

                <div className="mt-[min(3.22vw,30px)] text-16">
                    ลืมรหัสผ่าน ?{' '}
                    <a href="#" className="underline text-[var(--color-blue)]">
                        รีเซ็ตรหัสผ่าน
                    </a>
                </div>
                <div className="mt-[min(3.22vw,30px)] text-16 flex gap-[min(0.87vw,9px)]">
                    <div>Power By</div>
                    <div className="aspect-square w-[min(2.05vw,21px)]">
                        <img
                            className="w-full h-full object-contain"
                            src="/img/logo_allder_2-01.png"
                            alt=""
                        />
                    </div>
                    <div>Allder</div>
                </div>
            </div>
        </div>
    )
}
