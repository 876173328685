import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// console.log('api url', API_URL);

const authToken = window.localStorage.getItem("allDerCafeToken");

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        // Authorization: `Bearer ${authToken}`
        Authorization: `Bearer FxuRkpUMnl4Wc0TeYr939JMCHZhDnEG0rzpGeJkcsg7fyTUiBI78PqJPUPDEFwEUZyFOLNoqkfBZNj3i0myeUGtPQJ9lyjKzSJ9tL7qtItLP4ozGQineoYxiEErtZxaY`
    }
});

// POST method config
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

// PATCH method config
axiosInstance.defaults.headers.patch['Content-Type'] = 'application/json';

// Refresh header token
axiosInstance.interceptors.request.use(function (config) {
    let token = window.localStorage.getItem("allDerCafeToken");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default axiosInstance;