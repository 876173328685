import Global from "./Global";

const UploadFile = async (path, file) => {
    const api = `${process.env.REACT_APP_URL_UPLOAD_FILE}`;
    const uuid = Global.uuid();
    const extension = file.name.split('.').pop();
    const allowedExtensions = ['png', 'jpg', 'jpeg'];

    if (!allowedExtensions.includes(extension)) {
        return;
    }

    const key = `${path}/${uuid}.${extension}`;

    const formData = new FormData();

    formData.append('key', `${key}`);
    formData.append('file', file);

    try {
        const response = await fetch(api, {
            method: 'POST',
            body: formData,
        });

        // const data = await response.text();
        // console.log("🚀 ~ UploadFile ~ data:", key)

        if (response.ok) {
            return `${process.env.REACT_APP_URL_UPLOAD_FILE_PATH}${key}`;
        }
    } catch (error) {
        console.log(error);
    }
}

export default UploadFile;