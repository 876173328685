import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";

export function NavBar({ selectbranchID }) {
  const [slide, setSlide] = useState(1);
  //   const { slug } = useRecoilValue(appState);
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  //hidden menu
  const [hidden, setHidden] = useState("hidden");

  function handleSlide(index) {
    var slide = document.querySelectorAll(".slide");
    for (let i = 0; i < slide.length; i++) {
      slide[i].classList.remove("is-expanded");
    }
    slide[index].classList.add("is-expanded");
  }

  const handleEnterHoverSidebar = () => {
    if(document.body.classList.contains('sidenav-toggled')) {
      document.body.classList.add("sidenav-toggled-open")
    }
  }

  const handleLeaveHoverSidebar = () => {
    if(document.body.classList.contains('sidenav-toggled')) {
      document.body.classList.remove("sidenav-toggled-open")
    } 
  }
  // 
  useEffect(() => {
    handleSlide(slide);
  }, [slide]);

  // if(slug){
  //   return (
  //     <>
  //       Loading...
  //     </>
  //   )
  // }

  return (
    <div>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar sidebar-scroll" 
        onMouseEnter={() => handleEnterHoverSidebar() }
        onMouseLeave={() => handleLeaveHoverSidebar() }
      >
        <div className="main-sidebar-header px-3 py-0 active">
          <a className="desktop-logo active" href={`/${slug}`}>
            <img src="/images/logo-2.png" className="main-logo" alt="logo" />
          </a>
          <a className="logo-icon mobile-logo icon-light active" href="/">
            <img
              src="/images/logo.png"
              className="logo-icon mx-auto"
              alt="logo"
            />
          </a>
        </div>
        <div className="main-sidemenu px-2 ">
          <ul className="side-menu">
            <li className={`slide ${hidden}`}>
              <a className="side-menu__item py-2 " href="#">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-home"></div>
                </div>
                <span className="side-menu__label"> หน้าหลัก</span>
                <span className="tag tagnew">NEW</span>
              </a>
            </li>
            <li>
              <div className="break-menu fs-12 tc-19">เมนู</div>
            </li>

            <li
              className="slide"
              onClick={(e) => {
                handleSlide(1);
              }}
            >
              <a className="side-menu__item py-2" data-toggle="slide" href="#">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-business"></div>
                </div>
                <span className="side-menu__label">รายงาน</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink
                    to={`report/sales/${user.businessId}`}
                    className="slide-item"
                  >
                    ยอดขาย
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={`report/monthly/sales/${user.businessId}`}
                    className="slide-item"
                  >
                    ยอดขายรายเดือน
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`report/product/sales/${user.businessId}`}
                    className="slide-item"
                  >
                    ยอดขายรายสินค้า
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`report/category/sales/${user.businessId}`}
                    className="slide-item"
                  >
                    ยอดขายตามหมวดหมู่
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`report/bill/sales/${user.businessId}`}
                    className="slide-item"
                  >
                    ยอดขายรายบิล
                  </NavLink>
                </li> */}
                
              </ul>
            </li>

            <li
              id="disabledItem"
              className="slide"
              onClick={(e) => {
                handleSlide(2);
              }}
            >
              <a className="side-menu__item py-2" href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-note"></div>
                </div>
                <span className="side-menu__label">สินค้า/บริการ</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to={`products`} className="slide-item">
                    จัดการข้อมูลสินค้า
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`product/category`} className="slide-item">
                    จัดการหมวดหมู่สินค้า
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`product/options/standard`}
                    className="slide-item"
                  >
                    จัดการตัวเลือกมาตราฐาน
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`product/options/note`} className={`slide-item `}>
                    จัดการตัวเลือกหมายเหตุ
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`product/unit`} className={`slide-item ${hidden}`}>
                    จัดการหน่วยนับสินค้า
                  </NavLink>
                </li>
              </ul>
            </li>

            <li
              className={`slide ${hidden}`}
              onClick={(e) => {
                handleSlide(3);
              }}
            >
              <a className="side-menu__item py-2 " href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-user"></div>
                </div>
                <span className="side-menu__label">สมาชิก/ลูกค้า</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to="/customer" className="slide-item">
                    รายชื่อลูกค้า
                  </NavLink>
                </li>
              </ul>
            </li>
            <li
              className={`slide ${hidden}`}
              onClick={(e) => {
                handleSlide(4);
              }}
            >
              <a className="side-menu__item py-2 " href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-box"></div>
                </div>
                <span className="side-menu__label">คลังสินค้า</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to="/warehouse" className="slide-item">
                    จัดการคลังสินค้า
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ingredients" className="slide-item">
                    รายการวัตถุดิบ
                  </NavLink>
                </li>
                <li>
                  <a className="slide-item" href="x">
                    ประเภทวัตถุดิบ
                  </a>
                </li>
                <li>
                  <a className="slide-item" href="x">
                    งานคลังสินค้า
                  </a>
                </li>
              </ul>
            </li>
            <li
              className="slide"
              onClick={(e) => {
                handleSlide(5);
              }}
            >
              <a
                className={`side-menu__item py-2`}
                href="#"
                data-toggle="slide"
              >
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-shop"></div>
                </div>
                <span className="side-menu__label">จัดการสาขา</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to={`branch`} className="slide-item">
                    รายชื่อสาขา
                  </NavLink>
                </li>
                {/*<li>*/}
                {/*    <NavLink to={`${slug}/branch/setting/general/${selectbranchID}`} className="slide-item">*/}
                {/*        จัดการสาขา*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
              </ul>
            </li>
            <li
              className={`slide`}
              onClick={(e) => {
                handleSlide(6);
              }}
            >
              <a className="side-menu__item py-2 " href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-profile"></div>
                </div>
                <span className="side-menu__label">พนักงาน</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to="emps" className="slide-item">
                    รายชื่อพนักงาน
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/emp/department" className="slide-item">
                    แผนกงาน
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/emp/position" className="slide-item">
                    ตำแหน่งงาน
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/emp/business" className="slide-item">
                    จัดการผู้ใช้งานธุรกิจ
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/emp/permission" className="slide-item">
                    ตั้งค่าสิทธิ์การใช้งาน
                  </NavLink>
                </li>
              </ul>
            </li>
            <li
              className={`slide ${hidden}`}
              onClick={(e) => {
                handleSlide(7);
              }}
            >
              <a className="side-menu__item py-2 " href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-business2"></div>
                </div>
                <span className="side-menu__label">เครื่องมือการตลาด</span>
              </a>
              <ul className="slide-menu">
                <li>
                  <NavLink to="/marketing/crm" className="slide-item">
                    CRM
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/marketing/promotion" className="slide-item">
                    จัดการโปรโมชั่น
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/marketing/coupon" className="slide-item">
                    จัดการคูปอง
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/marketing/discount" className="slide-item">
                    จัดการส่วนลด
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/marketing/point" className="slide-item">
                    จัดการคะแนน
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li className="">
              <div className="break-menu fs-12 tc-19">ตั้งค่า</div>
            </li> */}
            <li
              className="slide"
              onClick={(e) => {
                handleSlide(8);
              }}
            >
              <a className="side-menu__item  py-2" href="#" data-toggle="slide">
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-setting"></div>
                </div>
                <span className="side-menu__label">ตั้งค่า</span>
              </a>
              <ul className="slide-menu">
                <li className="ps-4 fs-16 tc-6 py-1">ตั้งค่าธุรกิจ</li>
                <li>
                  <NavLink to={`setting/business`} className="slide-item">
                    ข้อมูลธุรกิจ
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`setting/uselink`} className="slide-item ">
                    ลิงค์ใช้งาน
                  </NavLink>
                </li>
                <li className={`ps-4 fs-16 tc-6 py-1 ${hidden}`}>
                  ตั้งค่าคลังสินค้า
                </li>
                <li>
                  <NavLink
                    to={`setting/product/unit`}
                    className={`slide-item ${hidden} `}
                  >
                    หน่วยนับ
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
}
