import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  NavLink,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { appState } from "../state/app";

export function BranchSettingMenu({ selectbranchID, setSelectBranchID }) {
  const { branchID } = useParams();
  const [breadcrumb, setBreadcrumb] = useState("ข้อมูลสาขา");
  const { slug } = useRecoilValue(appState);

  const navigate = useNavigate();

  // useEffect(() => {
  //     navigate("/branch/setting/general/"+selectbranchID);
  // }, [selectbranchID]);

  return (
    <>
      <nav aria-label="breadcrumb" className="mt-1">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7 text-decoration-none">
              ตั้งค่า
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {breadcrumb}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">{breadcrumb}</div>
      <div
        className="d-flex mb-4 overflow-hidden"
        style={{
          boxShadow: " 0px 2px 10px 0px rgba(76, 78, 100, 0.22)",
          borderRadius: "6px",
        }}
      >
        <div
          className="py-4"
          style={{
            flex: "0 0 200px",
            borderRadius: "10px 0px 0px 10px",
            background: "#FFF",
          }}
        >
          <div
            className="mb-4 px-3"
            style={{
              color: "rgba(76, 78, 100, 0.87)",
              letterSpacing: "0.15px",
            }}
          >
            จัดการสาขา
          </div>
          <ul className="side-menu side-menu-branch">
            <li className="slide">
              <NavLink
                to={`/${slug}/branch/setting/general/${branchID}`}
                className="side-menu__item py-2 "
                onClick={() => setBreadcrumb("ข้อมูลสาขา")}
              >
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b1"></div>
                </div>
                <span className="side-menu__label"> ข้อมูลสาขา</span>
              </NavLink>
            </li>
            <li className="slide">
              <NavLink
                to={`/${slug}/branch/setting/product/${branchID}`}
                className="side-menu__item py-2 "
                onClick={() => setBreadcrumb("จัดการเมนูขาย")}
              >
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b2"></div>
                </div>
                <span className="side-menu__label"> จัดการเมนูขาย</span>
              </NavLink>
            </li>

            <li className="slide">
              <NavLink
                to={`/${slug}/branch/setting/kitchen/${branchID}`}
                className="side-menu__item py-2"
                onClick={() => setBreadcrumb("จัดการครัว")}
              >
                <div className="side-menu_icon">
                  <div className="side-menu__icon icon-b8"></div>
                </div>
                <span className="side-menu__label"> จัดการครัว</span>
              </NavLink>
            </li>
            <li className="slide">
               <NavLink 
               to={`/${slug}/branch/setting/emp/${branchID}`}
               className="side-menu__item py-2"
                onClick={() => setBreadcrumb("จัดการพนักงาน") }
               >
                   <div className="side-menu_icon"><div className="side-menu__icon icon-b9"></div></div>
                   <span className="side-menu__label"> จัดการพนักงาน</span>
               </NavLink>
            </li>
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/workingtime/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่าทั่วไป") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b3"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่าทั่วไป</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}

            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/salesmodel/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("รูปแบบการขาย") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b4"></div></div>*/}
            {/*        <span className="side-menu__label"> รูปแบบการขาย</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/table/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("จัดการโซน/โต๊ะ") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b5"></div></div>*/}
            {/*        <span className="side-menu__label"> จัดการโซน/โต๊ะ</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/payment/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่าการชำระเงิน") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b6"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่าการชำระเงิน</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/delivery/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่า Delivery") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b7"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่า Delivery</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/kitchen/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("จัดการครัว") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b8"></div></div>*/}
            {/*        <span className="side-menu__label"> จัดการครัว</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/emp/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("จัดการพนักงาน") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b9"></div></div>*/}
            {/*        <span className="side-menu__label"> จัดการพนักงาน</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/document/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่าเอกสาร") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b10"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่าเอกสาร</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/printer/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่าเครื่องพิมพ์") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b11"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่าเครื่องพิมพ์</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/notification/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งแจ้งเตือน") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b12"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งแจ้งเตือน</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*<li className="slide"  >*/}
            {/*    <NavLink to={"/branch/setting/pos/"+branchID} className="side-menu__item py-2 " */}
            {/*    onClick={() => setBreadcrumb("ตั้งค่า POS") }*/}
            {/*    >*/}
            {/*        <div className="side-menu_icon"><div className="side-menu__icon icon-b13"></div></div>*/}
            {/*        <span className="side-menu__label"> ตั้งค่า POS</span>*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
          </ul>
        </div>
        <div
          className="flex-fill"
          style={{
            borderRadius: "0px 10px 10px 0px",
            maxWidth: "calc(100% - 190px)",
          }}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}
