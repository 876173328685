import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams,NavLink, Link } from "react-router-dom";
import DataTable from "@jcognard-actinvision/react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MonthPicker from "simple-react-month-picker";
import { MultiSelect } from "react-multi-select-component";

import CheckboxTable from "fragments/CheckboxTable";
import  DownloadExcel from 'utils/DownloadExcel';

import Button from "react-bootstrap/Button";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { appState } from "../../state/app";
import { authState } from "../../state/auth";
import pos2 from "../../img/pos2.png";
import { MoonLoader } from "react-spinners";
import {
  getCurrentDate,
  convertDateFormat,
  getCurrentDateM,
  formatDateToYMD,
  subtractDays,
  getCurrentDateforApi,
} from "utils/dateFormat";
import Select from "react-select";
import { default as ReactSelect, components, InputAction } from "react-select";
import DailyApi from "services/report/DailyApi";

import ReactPaginate from 'react-paginate';
import CurrencyFormat from 'react-currency-format';

import DefaultProductImg from 'img/product/default.png';
import Collapse from 'react-bootstrap/Collapse';
import { numberWithTwoDecimals } from "utils/numberFormat";
import { BillPaperToPrint } from "components/fragments/BillPaper";


import { useReactToPrint } from 'react-to-print';
export function ReportBillDetail() {
    const { token, setToken } = useToken();
    let { businessId } = useParams();
    const navigate = useNavigate();
    const { slug } = useRecoilValue(appState);
    const { user } = useRecoilValue(authState);
    const BASE_URL =
      process.env.REACT_APP_API_URL + "/back-office-info-dashboard/total";
    const BASE_URL_POS =
      process.env.REACT_APP_API_URL + "/back-office-info-dashboard";

    const [modalPinOpen, setModalPinOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
    const [modalCancleBillOpen, setModalCancleBillOpen] = useState(false);
    const [collapseShow, setCollapseShow] = useState(false);
    const [orderBill, setOrderBill] = useState(null)
  
    const [billdatalist, setBilldatalist] = useState([]);
  //   console.log("🚀 ~ billdatalist:", billdatalist)
    const [billdata, setBilldata] = useState(false);
  //   console.log("🚀 ~ billdata:", billdata)
    const [customer, setCustomer] = useState(false);
    const [billcancledata, setBillcancledata] = useState({
      "billID":"",
      "reason":"",
      "note":""
    });
    const { currentPOS } = useState([]);

    const {  branch } = useRecoilValue(authState);

    const getBillData = () => {
        fetch("/json/bill2.json")
          .then((response) => response.json())
          .then((result) => setBilldata(result))
          .catch((error) => console.log("error", error));
    };

    const billRef = useRef();
    const handlePrintBill = useReactToPrint({
      content: () => billRef.current,
      onAfterPrint: async () => {

      }
    });

    const billInvoiceRef = useRef();
    const handlePrintBillInvoice = useReactToPrint({
      content: () => billInvoiceRef.current,
      onAfterPrint: async () => {

      }
    });
    

    const onPrintBillBtnHandler = async () => {

    }


    useEffect(() => {
        getBillData()
        // getData()
    }, [user.businessId])




    return (
        <div>
            
            <div className="p-3 position-relative" style={{borderRadius: '8px',background: '#FFF',boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)'}}>
            <Link className="btn btn-st-6 mb-3" to={`/${slug}/report/bill/sales/${user.businessId}`} >กลับสู่หน้ารายงาน</Link>
            {  billdata ? (
                <div className="row gx-3">
                    <div className="col-4">
                        <div className="ps-3 py-2 d-flex flex-column" style={{height:'calc(100vh - 175px)',borderRadius: '8px',background: '#FFF',boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)'}}>
                            <div className="fs-18 fw-500 text-[#2DC76D] mb-3">ข้อมูลสมาชิก</div>

                            <div className="pe-3" style={{ height:'calc(100vh - 270px)',overflow:'auto' }}>
                                <div className="d-flex align-items-center mb-3">
                                    <img
                                    className="aspect-square w-[min(6.13vw,62px)] rounded-full object-cover"
                                    src="/img/avatar-female.png"
                                    alt=""
                                    />
                                    <div className="ms-3 fs-14 text-[#2F4858]">
                                        {/* <div> {billdata.customerName ? billdata.customerName : '-'} </div> */}
                                        <div> {billdata.member ? billdata.member.name : '-'} </div>
                                        {/* <div> {customer ? customer.customerPhone : '-'}  </div> */}
                                        <div> {billdata.member ? billdata.member.phone_number : '-'}  </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">แต้มสะสมที่มีอยู่</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.all : '-'}</div> */}
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.all : billdata.member ? billdata.member.point : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.member ? billdata.memberPrevPoint : billdata.member?.current_point ? billdata.member.current_point : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">คะแนนสะสมที่ใช้</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.usage : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.usagePoint ? billdata.usagePoint : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ได้รับคะแนนสะสม</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.receive : '-'}</div> */}
                                    {/* <div className="text-[#2F4858]">{(customer && billdata.billStatus == 2 && orderBill?.total) ? Math.floor(orderBill.total / 25) : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.receivePoint ? billdata.receivePoint : '-'}</div>
                                    
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">แต้มสะสมที่มีอยู่</div>
                                    {/* <div className="text-[#2F4858]">{customer ?  (customer.point.all + customer.point.receive  ) -  customer.point.usage : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.memberNetPoint ? billdata.memberNetPoint : '-'}</div>
                                </div>
                                <hr />
                                <div className="fs-18 fw-500 text-[#2DC76D] mb-2 mt-2">ส่วนลดและโปรโมชั่นที่ได้รับ</div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดท้ายบิล{orderBill?.discount_percent > 0 && ` (${orderBill.discount_percent}%)`}</div>
                                    {/* <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.endBill) : '-'}</div> */}
                                    <div className="text-[#2F4858]">
                                        {  
                                            billdata.orderPrice?.discount_round ? numberWithTwoDecimals(billdata.orderPrice.discount_round) :
                                            billdata.orderPrice?.discount ? numberWithTwoDecimals(billdata.orderPrice.discount) :
                                            '-'
                                        }
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดโปรโมชั่น</div>
                                    <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.promotion) : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดการใช้คะแนน</div>
                                    <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.point) : '-'}</div>
                                </div>
                                <hr />
                                <div className="fs-18 fw-500 text-[#2DC76D] mb-2 mt-2">ค่าใช้จ่ายอื่นๆ</div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ค่าธรรมเนียมบัตรเครดิต</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.payment.credit : '-'}</div> */}
                                    <div className="text-[#2F4858]">
                                        {billdata.billStatus == 2 && billdata.payments.fee ? numberWithTwoDecimals(billdata.payments.fee) : '-'}
                                    </div>
                                </div>
                                <hr />
                                <div className={`fs-18 fw-500 text-[#2DC76D] mb-2 mt-2 ${(!billdata?.payments || billdata?.payments.length == 0) && "mb-auto"}`}>ช่องทางการชำระเงิน</div>
                                {/* { (billdata.billStatus == 2 && billdata.payments.cash && billdata.payments.cash > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินสด</div>
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 &&  billdata.payments.cash ? billdata.payments.cash : '-'}</div>
                                    </div>
                                    : null
                                } */}
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินสด</div>
                                        {/* <div className="text-[#2F4858]">{customer ? customer.payment.cash : '-'}</div> */}
                                        <div className="text-[#2F4858]">{billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total ? numberWithTwoDecimals(billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total) : '-'}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Transfer").total > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินโอน</div>
                                            {/* <div className="text-[#2F4858]">{customer ? customer.payment.tranfers : '-'}</div> */}
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.transfer ? billdata.payments.transfer : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Transfer").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.payments.promptpay && billdata.payments.promptpay > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">พร้อมเพย์</div>
                                        {/* <div className="text-[#2F4858]">{customer ? customer.payment.promptpay : '-'}</div> */}
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.promptpay ? numberWithTwoDecimals(billdata.payments.promptpay) : '-'}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Credit").total > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">บัตรเครดิต</div>
                                            {/* <div className="text-[#2F4858]">{customer ? customer.payment.credit : '-'}</div> */}
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.credit ? billdata.payments.credit : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Credit").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                                {/* { (billdata.billStatus == 2 && billdata.payments.other && billdata.payments.other > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">อื่นๆ</div>
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.other ? billdata.payments.other : '-'}</div>
                                    </div>
                                    : null
                                } */}
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").total > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">{billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").name} {billdata.delivery_remark && `(${billdata.delivery_remark})`}</div>
                                        <div className="text-[#2F4858]">{numberWithTwoDecimals(billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").total)}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").channels.length > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">{billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").name} {billdata.delivery_remark && `(${billdata.delivery_remark})`}</div>
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.other ? billdata.payments.other : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="mb-auto"></div>
                            
                        </div>              
                    </div>
                    <div className="col-4">
                        <div className="p-3 position-relative" style={{height:'calc(100vh - 175px)',borderRadius: '8px',background: '#FFF',boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)'}}>
                            <div className="d-flex align-items-center">
                            <div className="me-auto ">
                                <span className="text-14 text-[#2DC76D] me-2">Order No.</span> <span className="text-14 text-[#2F4858]">
                                { billdata?.orderNo && billdata?.orderNo != "NA" ? billdata.orderNo : "-"}
                                </span>
                            </div>
                            { billdata ? 
                                billdata.billStatus == 1 ?  <div className="bill-status-s2">เปิดบิลอยู่</div> : 
                                billdata.billStatus == 4 ?  <div className="bill-status-s1">พักบิล</div> : 
                                billdata.billStatus == 2 ?  <div className="bill-status-s3">สำเร็จ</div>: 
                                billdata.billStatus == 3 ?  <div className="bill-status-s4">ยกเลิก</div> : ''
                            : "-"
                            }
                            </div>

        

                            <div>
                            <span className="text-14 text-[#2DC76D] me-2">Receipt No.</span>
                            <span className="text-[#2F4858] text-14">{ billdata ? billdata.receiptNo : "-"} </span>
                            </div>
                            <div className="row g-0">
                            <div className="col">
                                <div>
                                <span className="text-12 text-[#2DC76D] me-2">วันที่ :</span>
                                <span className="text-12 text-[#2F4858] me-2">{ billdata ? billdata.orderDate : "-"} </span>
                                <span className="text-12 text-[#2DC76D] me-2">จุดขาย :</span>
                                <span className="text-12 text-[#2F4858]">{ billdata ? billdata.pos : "-"}</span>
                                </div>
                                <div>
                                <span className="text-12 text-[#2DC76D] me-2">พนักงาน :</span>
                                <span className="text-12 text-[#2F4858]">{ billdata ? billdata.staff : "-"}</span>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="text-14 text-[#2F4858] text-end mb-1">Total</div>
                                <div className="text-16 fw-500 text-[#2DC76D]">{ billdata && billdata.orderPrice && billdata.orderPrice.net ? billdata.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</div>
                            </div>
                            </div>
                            <div className="my-2">
                                {
                                billdata.delivery == 1 ? ( <div className="dl1">ทานที่ร้าน</div> ) : ''
                                }
                                {
                                billdata.delivery == 2 ? ( <div className="dl2">กลับบ้าน</div> ) : ''
                                }
                                {
                                billdata.delivery == 3 ? ( <div className="dl3">เดลิเวอรี่</div> ) : ''
                                }
                            </div>
                            <div>
                            <div className="">
                                <div className="row gx-2 tc-17 fs-12">
                                <div className="col-7">รายการ</div>
                                <div className="col-2 text-center">จำนวน</div>
                                <div className="col-3 text-end">ราคา</div>
                                </div>
                            </div>
                            <hr className="mt-1 mb-0" />
                            <div className="bx-main-order-list " style={{ height:'calc(100vh - 440px)' }}>
                                <ul className="p-0 m-0  w-100 ">
                                    {
                                    billdata.product && billdata.product.map((item, index) =>
                                        (billdata.billStatus != 3 && item.productStatus == 3) ? null :
                                        <li className="px-2" key={item.id}  >
                                        <div className="row gx-2 align-items-center">
                                            <div className="col-7 d-flex align-items-center">
                                                <img 
                                                    src={item.productImage} 
                                                    className="foodimg" 
                                                    onError={(e) => e.target.src = DefaultProductImg}
                                                />
                                                <div className="fs-12">{item.productName}</div>
                                            </div>
                                            <div className="col-2 fs-14 text-center foodQtyli relative">
                                                <p className={`${item.productCancel > 0 ? 'text-[var(--color-red)]' : ''} ${billdata.billStatus == 3 ? 'line-through' : ''}`}>{billdata.billStatus != 3 ? item.productRemain : item.productQty}</p>
                                                { (item.productCancel > 0 && billdata.billStatus != 3) &&
                                                    <span className="absolute line-through top-[calc(min(0.78vw,8px)*-1.5)] right-4">{item.productAmount}</span>
                                                }
                                            </div>
                                            <div className="col-3 fs-14 text-end "><span className="foodPriceli">{item.productSumPrice.toLocaleString()}</span>.-</div>
                                        </div>
                                        <div className="d-flex flex-wrap bx-Foption ">
                                            {item.optionstandard && item.optionstandard.map((item2, index) => 
                                            <div className="Foption mt-2" key={item2.listID}>{item2.listName} {item2.optionQty > 1 && `x ${item2.optionQty}`}</div>
                                            )}
                                            {item.optionproduct && item.optionproduct.map((item2, index) => 
                                            <div className="Foption mt-2" key={item2.listID}>{item2.listName} {item2.optionQty > 1 && `x ${item2.optionQty}`}</div>
                                            )}
                                        </div>
                    
                                        { item.optionnote && item.optionnote.length > 0 || item.note != "" ?
                                            (
                                            <div className="fNote ">
                                            {item.optionnote && item.optionnote.map((item2, index) => 
                                                <span key={item2.listID}>
                                                {item2.listName}{index != item.optionnote.length - 1 && ", "}
                                                </span>
                                            )}
                    
                                            { item.note }
                                            </div>
                                            ) : ''
                                        }
                                        </li>
                                    )
                                    }
                                </ul>
                            </div>
                            </div>

                            <div className="absolute p-3 bottom-[0%] w-100 left-[0%]">
                                <Collapse in={collapseShow}>
                                    <div id="collapse1">
                                        <div className="card card-body border-0 bg-c1 px-3 py-2" >
                                            <div className="d-flex fs-14 tc-10 px-1">
                                            <div className="me-auto">ยอดรวม</div>
                                            <div className="" id="txsum">
                                                { billdata.orderPrice.sumprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                            </div>
                                            </div>
                                            <div className="d-flex fs-12 tc-10 px-1">
                                            <div className="me-auto">ส่วนลด</div>
                                            <div id="txdiscount">
                                                { 
                                                    billdata?.orderPrice?.discount_round > 0 ? 
                                                        billdata.orderPrice.discount_round.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                                        billdata.orderPrice.discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                }฿
                                            </div>
                                            </div>
                                            <div className="d-flex fs-12 tc-10 px-1">
                                            <div className="me-auto">ภาษี</div>
                                            <div id="txtax">{ billdata.orderPrice.vat.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }฿</div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                                <button
                                    className="btn collapse-total collapsed "
                                    type="button"
                                    onClick={() => setCollapseShow(!collapseShow)}
                                    aria-controls="collapse1"
                                    aria-expanded={collapseShow}
                                    style={{borderRadius: '8px'}}
                                    >
                                    <div className="d-flex align-items-center  ">
                                        <div className="me-auto">ยอดรวมทั้งหมด </div>
                                        <div id="txnet">{ billdata && billdata.orderPrice.net && billdata.orderPrice.net ? billdata.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00" }฿</div>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                    
                    <div className="col-4">
                        <div className="pe-3" style={{ height:'calc(100vh - 235px)',overflow:'auto' }}>
                            {/* { orderBill && */}
                                <BillPaperToPrint 
                                    key={'print-receipt'} 
                                    ref={billRef} 
                                    order={billdata}
                                    user={user}
                                    bill={orderBill}
                                    pos={currentPOS}
                                    branch={branch}
                                    type="receipt"
                                    copy={true}
                                />
                            {/* } */}
                        </div>
                        <div className="pt-4 pb-2 d-flex justify-content-center">
                            <button 
                                className="btn btn-x1 btn-x1-print text-[#FFF] "
                                // disabled={ !customer }
                                disabled={ billdata.billStatus === 4 }
                                onClick={async () => { await onPrintBillBtnHandler(); }}
                            >
                                พิมพ์
                            </button>
                        </div>
                    </div>

                    
                    
                </div>

            ) : (

                    <div className="d-flex align-items-center justify-content-center" style={{ height:'calc(100vh - 150px)' } }>
                        <div>ไม่พบรายการ</div>
                    </div>
                )
            }
            </div>

        </div>
    )
}