import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { ModalAllder } from "layouts/ModalAllder";
import { useRecoilValue } from "recoil";
import { authState } from "../state/auth";
import useToken from "../utils/UseToken";

export function EditOptionNote(props) {
  const [optionnote, setOptionnote] = useState([]);
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();

  const getoptionData = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/business-product/remark-options-binding/${id}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setOptionnote(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getoptionData(props.values.id);
  }, [props.values.id, token]);

  const handleDeleteoptionList = async (optionID) => {
    // props.setListOptionnote(prev => prev.filter(item => item.id !== optionID))
    // console.log('remark',props)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/business-product/product-options-binding/${props.values.id}/${optionID}`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "business-id": user.businessId,
          }),
        }
      );
      const result = await res.text();

      // Update the state to remove the deleted option
      props.setListOptionnote((prev) =>
        prev.filter((item) => item.id !== optionID)
      );

      getoptionData(props.values.id);

      return result;
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const [modaledit, setModaledit] = useState({
    open: false,
    x: 0,
    y: 0,
    width: 560,
    height: 340,
    pin: false,
    mode: "normal",
    modalname: "modal_edit_option_product",
  });

  return (
    <div className="px-5 py-4">
      <div className="row">
        <div className="col-xl-6 col">
          <div className="mb-2 mt-4">
            <button
              type="button"
              className="btn btn-st-3 mb-4"
              onClick={() => {
                setModaledit((prev) => ({ ...prev, open: true }));
              }}
            >
              เพิ่มตัวเลือกหมายเหตุ
            </button>
          </div>
        </div>
      </div>
      <div className=" mt-3 ">
        <div className="d-flex flex-wrap">
          {props.listoptionnote.map((item, index) => (
            <div className="row gx-4 align-items-center mb-5" key={index}>
              <div className="col-xl-8 col-6">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">ตัวเลือก</Form.Label>
                  <Form.Control
                    type="text"
                    name="note"
                    className="form-control"
                    placeholder="ตัวเลือก"
                    value={item.name}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-xl-4 col-6">
                <button
                  type="button"
                  className="btn  btn-st-3"
                  onClick={() => handleDeleteoptionList(item.id)}
                  style={{ background: "#F95057", borderColor: "#F95057" }}
                >
                  ลบตัวเลือก
                </button>
              </div>

              {item.product_option_detail.map((item2, index2) => (
                <div
                  className="col-xl-3 row gx-4 align-items-center"
                  key={index2}
                >
                  <div className="col">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">รายการ</Form.Label>
                      <Form.Control
                        type="text"
                        name="listName"
                        className="form-control"
                        placeholder="รายการ"
                        value={item2.name}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <ModalEditOption
        modal={modaledit}
        setModal={setModaledit}
        optionnote={optionnote}
        setOptionnote={setOptionnote}
        setListOptionnote={props.setListOptionnote}
        values={props.values.id}
      />
    </div>
  );
}

const ModalEditOption = ({
  modal,
  setModal,
  optionnote,
  setOptionnote,
  setListOptionnote,
  values,
  ...props
}) => {
  const [optionselected, setOptionselected] = useState([]);
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();

  const handleChangeOptionNote = (options) => {
    setOptionselected(options);
  };

  const getoptionData = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/business-product/remark-options-binding/${id}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setOptionnote(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleCreateoption = async (optionselected) => {
    const product_id = values;
    const option_id = optionselected.id;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/business-product/product-options-binding/${product_id}/${option_id}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "business-id": user.businessId,
          }),
        }
      );
      const result = await res.text();

      getoptionData(product_id);
      return result;
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"เพิ่มตัวเลือกมาตราฐาน"}
      hbtn={true}
    >
      <div className="px-4">
        <div className="text-end pb-4 pt-5 mt-5 pe-3">
          <Form.Group className="mb-2 form-st-3 pb-1">
            <Form.Label className="mb-1">
              ตัวเลือก <span className="text-danger">*</span>
            </Form.Label>
            <Select
              as="select"
              name="storeH"
              onChange={(e) => {
                handleChangeOptionNote(e);
              }}
              className="w-100 select-st-1 text-start"
              options={optionnote}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="ตัวเลือก"
              value={optionnote.filter((i) => optionselected.id == i.id)}
            />
          </Form.Group>
        </div>
        <div className="text-end pb-4 pt-4 pe-3">
          <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
            <Button
              className="btn btn-st-6 me-3 bg-transparent"
              onClick={() => setModal((prev) => ({ ...prev, open: false }))}
            >
              ยกเลิก
            </Button>
            <Button
              type="button"
              className="btn-st-3 "
              onClick={() => {
                setListOptionnote((prev) => [...prev, optionselected]);
                handleCreateoption(optionselected);
                setModal((prev) => ({ ...prev, open: false }));
              }}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </div>
    </ModalAllder>
  );
};
