import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAllEmpByBussiness = (config) =>
  axiosClient.get(`/branch-employee`, config);

export default {
    GetAllEmpByBussiness,
};