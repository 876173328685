import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'

import { ModalAllder } from 'layouts/ModalAllder';
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'
import { appState } from '../state/app'

export function EditOptionStandard (props){
    const branchProductOptionURL = `${process.env.REACT_APP_API_URL}/branch-product-option`;

    const { productID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [modalStockShow, setModalStockShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)

    const deleteBranchProduct = async (productID, optionID) => {
        console.log(`Delete branch optionID: ${optionID}`)
        try {
            const res = await fetch(`${branchProductOptionURL}/${productID}/${optionID}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            return { status: res.status }
        } catch (error) {
            console.log('Failed to patch:', error)
        }
    }

    const handleDeleteoptionList = async (optionID) => {
        const removeBranchProductOption = await deleteBranchProduct(productID, optionID)
        if (removeBranchProductOption.status === 200) {
            props.onSuccess()
        }
    }

    const handleChangeSelecttype = (e, optionID) => {
        let status = e.target.checked === true ? 1 : 0
        props.setListOptionstandard((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        selecttype: el.selecttype = status,
                    }
                    : el,
            ),
        )
    }

    const [modaledit, setModaledit] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:360,
        pin:false,
        mode:'normal',
        modalname:"modal_edit_option_product"
    });

    const [productOptionDetail, setProductOptionDetail] = useState({
        "id":null,
        "optionDetail":null
    })

    return (
        <div className="px-5 py-4">
            {/* <div className="row">
                <div className="col-xl-6 col">

                    <div className="mb-2 mt-4">
                        <button type="button" className="btn btn-st-3 mb-4" 
                        onClick={()=>{
                            setModaledit( (prev) => ({...prev,open:true}) )
                        }}
                        >
                            เพิ่มตัวเลือกมาตราฐาน
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="ms-xl-4 mt-2 ps-2">
                {props.listoptionstandard.map((item, index) => (
                    <div className="row gx-4 align-items-center mb-5" key={index}>
                        <div className="col-xl-6 col-6">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">ตัวเลือกมาตราฐาน</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="ตัวเลือกมาตราฐาน"
                                    value={item.name}
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-xl-4 col-6">
                            <Form.Check
                                type="switch"
                                id={`custom-switch-stardard${index}`}
                                label="เลือกได้มากกว่า 1 รายการ"
                                name={`selecttype[${index}]`}
                                className="custom-switch-st-1 tc-10  s1 mb-1"
                                defaultChecked={item.selecttype}
                                onChange={(e) => handleChangeSelecttype(e, item.id)}
                                disabled={true}

                            />
                        </div>
                        <div className="col-xl-2 col-6">
                            <button
                                type="button"
                                className="btn  btn-st-3"
                                onClick={() => handleDeleteoptionList(item.id)}
                                style={{ background: '#F95057', borderColor: '#F95057' }}
                            >
                                ลบตัวเลือกสินค้า
                            </button>
                        </div>
                        {item.product_option_detail.map((item2, index2) => (
                            <div className="col-xl-6 row gx-4 align-items-center" key={index2}>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="listName"
                                            className="form-control"
                                            placeholder="รายการ"
                                            value={item2.name}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ราคา</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="note"
                                            className="form-control"
                                            placeholder="ราคา"
                                            value={item2.price}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-auto ">
                                    <div className='d-flex align-items-center' style={{width:'200px'}}>
                                        <button
                                            type='button'
                                            className="btn d-block btn-edit-st-1 me-0  "
                                            onClick={() => {
                                                setProductOptionDetail({
                                                    optionDetail: item2
                                                })
                                                setModaledit((prev) => ({
                                                    ...prev,
                                                    open:true
                                                }))
                                            }}
                                        />
                                        <button
                                            type='button'
                                            className={`btn d-block btn-eye-st-1 ms-2 ${item2.is_active && "active"}`}
                                            style={{cursor:'auto'}}
                                        />
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="btn btn-setting-st-2 ms-2 me-3 hidden"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        setModalStockShow(true)*/}
                                        {/*        setProductOptionDetail( (prev) => ({*/}
                                        {/*            ...prev,id:item.id,optionDetail:item2*/}
                                        {/*        }))*/}
                                        {/*        if (!item2.stock) {*/}
                                        {/*            setSettingStock({*/}
                                        {/*                "storeHouse": null,*/}
                                        {/*                "productID":null,*/}
                                        {/*                "qty": 1,*/}
                                        {/*                "unit": "ลัง"*/}
                                        {/*            })*/}
                                        {/*        } else {*/}
                                        {/*            setSettingStock(item2.stock)*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*{item2.stock ? (*/}
                                        {/*    <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>*/}
                                        {/*) : (*/}
                                        {/*    <span*/}
                                        {/*        className="fs-16"*/}
                                        {/*        style={{ color: '#B6BBC6', lineHeight: '24px' }}*/}
                                        {/*    >*/}
                                        {/*        ยังไม่ได้ตั้งค่า*/}
                                        {/*    </span>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <StockModal
                show={modalStockShow}
                onHide={() => setModalStockShow(false)}
                settingStock={settingStock}
                deletestock={() => {
                    // values.optionList[stockindex].stock = null
                    setModalStockShow(false)
                }}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                optionproduct={props.listoptionstandard}
                setOptionproduct={props.setListOptionstandard}
            />
            <ModalEditOptionProduct
                modal={modaledit}
                setModal={setModaledit}
                setListOptionstandard={props.setListOptionstandard}
                listoptionstandard={props.listoptionstandard}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                onSuccess={() => props.onSuccess()}
            />
        </div>
    )
}

const ModalEditOptionProduct = ({
    modal,
    setModal,
    listoptionstandard,
    setListOptionstandard,
    productOptionDetail,
    setProductOptionDetail,
    onSuccess,
    ...props
}) => {
    const branchProductOptionURL = `${process.env.REACT_APP_API_URL}/branch-product-option`;

    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [branchProductOption, setBranchProductOption] = useState({
        id: undefined,
        is_active: undefined,
        name: '',
        price: undefined,
    })

    const patchBranchProductOption = async (values) => {
        console.log(`Patch branch product option data to productID ${values.id}:`, values)
        try {
            const res = await fetch(`${branchProductOptionURL}/${values.id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values)
            })
            return { status: res.status }
        } catch (error) {
            console.log('Failed to patch:', error)
        }
    }

    const handleSubmit = async () => {
        const updateBranchProductOption = await patchBranchProductOption({
            ...branchProductOption,
            price: parseInt(branchProductOption.price),
        });
        if (updateBranchProductOption.status === 200) {
            setModal((prev) => ({ ...prev, open:false }))
            onSuccess()
        }
    }

    useEffect(() => {
        if (productOptionDetail) {
            setBranchProductOption({
                id: productOptionDetail?.optionDetail?.id,
                is_active: productOptionDetail?.optionDetail?.is_active,
                name: productOptionDetail?.optionDetail?.name,
                price: productOptionDetail?.optionDetail?.price,
            })
        }
    }, [productOptionDetail])

    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"แก้ไขตัวเลือกมาตรฐาน"} 
                hbtn={true}
            >
            {productOptionDetail.optionDetail &&
                <>
                    <fieldset >
                        <div className="row ">
                            <div className="col-12">
                                <Form.Group className="mb-4 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={`listName`}
                                        className="form-control"
                                        placeholder="กรอกชื่อรายการ"
                                        value={branchProductOption.name}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-12">
                                <Form.Group className="mb-4 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name={`listPrice`}
                                        className="form-control"
                                        placeholder="กรอกราคา"
                                        value={branchProductOption.price}
                                        onChange={(e) => {
                                            setBranchProductOption({
                                                ...branchProductOption,
                                                price: e.target.value,
                                            })
                                        }}
                                        disabled={false}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="mb-2 me-3">
                            <Form.Check
                                type="switch"
                                id="custom-switch3"
                                label="แสดงในเมนู"
                                name="displayMenu"
                                className="custom-switch-st-1 tc-10 s2 mb-1"
                                defaultChecked={branchProductOption.is_active}
                                checked={branchProductOption.is_active}
                                onChange={(e) => {
                                    setBranchProductOption({
                                        ...branchProductOption,
                                        is_active: e.target.checked,
                                    })
                                }}
                            />
                        </div>
                    </fieldset>
                    <div className="text-end pb-4 pt-4 pe-3">
                        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                            <Button
                                className="btn btn-st-6 me-3 bg-transparent"
                                onClick={() => setModal( (prev) => ({...prev,open:false}))}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                type="button"
                                className="btn-st-3 "
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </>
            }
        </ModalAllder>
    )
}


function StockModal (props) {

    const { settingStock, deletestock,productOptionDetail,setProductOptionDetail,optionproduct,setOptionproduct, ...rest } = props


   
    const schema = yup.object().shape({

    })



    const optionsProduct = [
        { value: 1, label: 'ไข่ไก่สด' },
        { value: 2, label: 'ไก่สด' },
    ]

    const optionsStoreHouse = [
        { value: 1, label: 'คลังสินค้าวัตถุดิบ' },
        { value: 2, label: 'คลังสินค้าวัตถุดิบ' },
    ]
    

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">ตั้งค่าเชื่อมต่อสต๊อกสินค้า</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        initialValues={props.settingStock}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            // props.setFieldValue('stock', values)
                            // props.setFieldValue(`optionList[${props.stockindex}].stock`, values)

                            props.setOptionproduct((prev) =>
                                prev.map((el) =>
                                    el.id === productOptionDetail.id
                                        ? {
                                            ...el,
                                            optionList: el.optionList.map((el2) =>
                                                el2.listID == productOptionDetail.optionDetail.listID
                                                    ? {
                                                        ...el2,
                                                        stock: values,
                                                    } : el2,
                                            ),
                                        }
                                        : el,
                                ),
                            )

                            props.onHide()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="listName"
                                        className="form-control"
                                        placeholder="ชื่อรายการ"
                                        disabled={true}
                                        value={productOptionDetail.optionDetail ? productOptionDetail.optionDetail.name : ''}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ประเภทคลังสินค้าที่ตัดสต๊อก </Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsStoreHouse.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsStoreHouse}
                                            placeholder="ประเภทคลังสินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">สินค้าที่ตัดสต๊อก</Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsProduct.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsProduct}
                                            placeholder="สินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">จำนวน </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="qty"
                                        className="form-control"
                                        placeholder="จำนวน"
                                        value={values.qty}
                                        onChange={handleChange}
                                        isInvalid={!!errors.qty}


                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">หน่วยวัตถุดิบ / UMS </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="unit"
                                        className="form-control"
                                        placeholder="หน่วยวัตถุดิบ / UMS"
                                        value={values.unit}
                                        disabled={true}

                                    />
                                </Form.Group>

                                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                                    {
                                        productOptionDetail.optionDetail.stock ? (
                                            <Button type="button" className="btn-st-5 me-auto "
                                                    style={{ width: '80px' }} onClick={props.deletestock}>ลบ</Button>
                                        ) : (
                                            <div className="me-auto"></div>
                                        )
                                    }

                                    <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}
                                            onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className="btn-st-3  "
                                            style={{ width: '100px' }}>บันทึก</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}

