import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import { utils, writeFileXLSX } from 'xlsx'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import 'react-datepicker/dist/react-datepicker.css'
import DataTable from 'react-data-table-component'
import { nanoid } from 'nanoid'
import { useRecoilValue } from 'recoil'
import { authState } from '../../state/auth'
import useToken from '../../utils/UseToken'
import { appState } from '../../state/app'
import { getCurrentDate } from '../../utils/dateFormat'

import { ModalAllder } from 'layouts/ModalAllder'

export function OptionStandard () {
    const OPTION_STANDARD_URL = `${process.env.REACT_APP_API_URL}/business-product-standard-options`

    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const { slug } = useRecoilValue(appState)
    const [optiondata, setOptiondata] = useState([])
    const [optionStandardData, setOptionStandardData] = useState([])
    const [isShowModalDelete, setIsShowModalDelete] = useState(false)
    const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
    const [selectedOption, setSelectedOption] = useState(undefined)
    const [onSuccess, setOnSuccess] = useState(false)

    const getData = () => {
        fetch('/json/optionstandard.json')
            .then((response) => response.json())
            .then((result) => {
                setOptiondata(result)
            })
            .catch((error) => console.log('error', error))
    }

    const getOptionStandardData = async () => {
        if (user.businessId) {
            try {
                const res = await fetch(OPTION_STANDARD_URL, {
                    headers: new Headers({
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'business-id': user.businessId,
                    }),
                })
                const data = await res.json()
                setOptionStandardData(data)
                setOnSuccess(false)
            } catch (error) {
                console.log('Failed to get data:', error)
            }
        } else {
            console.log('Error: User ID not found.')
            return []
        }
    }

    useEffect(() => {
        getOptionStandardData()
        // getData()
    }, [onSuccess, user.businessId])

    const columns = [
        {
            name: 'ลำดับที่',
            selector: row => row.id,
            width: '120px',
        },
        {
            name: 'ชื่อตัวเลือกเพิ่มเติม',
            selector: row => row.name,
            cell: (row, index, column, id) => (
                <div style={{ color: '#565DFF', fontSize: 16, fontWeight: 500 }}>
                    {row.name}
                </div>
            ),
            width: '20%',
        },
        {
            name: 'รายการ',
            selector: row => row.product_option_detail,
            cell: (row, index, column, id) => (
                <div>
                    {
                        row.product_option_detail !== undefined && row.product_option_detail.map((item, index) => (
                            index === 0 ? (
                                <span key={index}>
                                    {item.name}
                                </span>
                            ) : (
                                <span key={index}>
                                    <span className="px-1">,</span>
                                    {item.name}
                                </span>
                            )
                        ))
                    }
                </div>
            ),
        },
        {
            name: 'สถานะ',
            selector: row => row.is_active,
            center: 'true',
            cell: (row, index, column, id) => (
                <div className={'datalist-status status' + (row.is_active === true ? '1' : '0')}></div>
            ),
            width: '120px',
        },
        {
            name: 'จัดการ',
            selector: row => row.optionID,
            center: 'true',
            width: '80px',
            cell: (row, index, column, id) => (
                <div>
                    <Link
                        to={`/${slug}/product/options/standard/edit/${row.id}`}
                        className="btn d-block btn-edit-st-1 "
                    >
                    </Link>
                </div>
            ),
        },
        {
            name: 'ลบ',
            selector: row => row.optionID,
            center: 'true',
            width: '70px',
            cell: (row, index, column, id) => (
                <div>
                    <Button
                        type="button"
                        className="btn-st-5 me-auto px-0"
                        style={{ width: '50px' }}
                        onClick={() => {
                            setSelectedOption(row)
                            setIsShowModalDelete(true)
                        }}
                    >
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                background: ' #F5F5F7',
                paddingTop: '15px',
                paddingBottom: '15px',

            },
        },
        cells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                fontWeight: '300',
                paddingTop: '10px',
                paddingBottom: '10px',
            },
        },
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'จำนวนรายการต่อหน้า',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const [filterText, setFilterText] = useState('')
    const filteredItems = optionStandardData.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    )

    // const filteredItems = optiondata.filter(
    //     item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // )

    const downloadExcel = () => {
        console.log('Download Excel')
        const ws = utils.json_to_sheet(filteredItems)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'รายชื่อสาขา')
        writeFileXLSX(wb, 'รายชื่อสาขา.xlsx')
    }

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li className="breadcrumb-item ">
                            <a
                                href="#"
                                className="tc-7  text-decoration-none"
                            >
                                สินค้าและบริการ
                            </a>
                        </li>
                        <li className="breadcrumb-item active tc-1 " aria-current="page">จัดการตัวเลือกมาตราฐาน</li>
                    </ol>
                </nav>
                <div className="fs-24 tc-7  mb-4 lh-1">จัดการตัวเลือกมาตราฐาน</div>
            </div>
            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className="d-flex align-items-center px-4 mb-4">
                        <button className="btn btn-export-st-1 me-auto" onClick={downloadExcel}>Export</button>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control form-st-4"
                                placeholder="ค้นหาตัวเลือกมาตราฐาน"
                                onKeyUp={e => setFilterText(e.target.value)}
                            />
                        </div>
                        <Link
                            to={`/${slug}/product/options/standard/create`}
                            className="btn btn btn-st-11"
                        >
                            เพิ่มตัวเลือกมาตรฐาน
                        </Link>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>
            </div>
            <ConfirmDeleteModal
                show={isShowModalDelete}
                onHide={() => setIsShowModalDelete(false)}
                onSuccess={() => {
                    setIsShowModalSuccess(true)
                    setOnSuccess(true)
                }}
                optiondata={selectedOption}
            />
            <SuccessDeleteModal
                show={isShowModalSuccess}
                onHide={() => setIsShowModalSuccess(false)}
            />
        </div>
    )
}

export function OptionCreate () {
    const productStandardOptionsURL = `${process.env.REACT_APP_API_URL}/business-product-standard-options`

    const navigate = useNavigate()
    const newId = nanoid()
    const { slug } = useRecoilValue(appState)
    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [modalShow, setModalShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})
    const [optionCreateListData, setOptionCreateListData] = useState([])

    const [optionCreateData, setOptionCreateData] = useState({
        'name': '',
        'is_active': true,
        'is_show': true,
        'created_at': new Date(),
        'option_details': [{
            'name': '',
            'price': undefined,
        }],
        'created_by': user.accountId,
        'modified_by': user.accountId,
        'creator_name': `${user.firstNameEN} ${user.lastNameEN}`,
    })

    const schema = yup.object().shape({
        name: yup.string().required(),
        is_active: yup.boolean().required(),
        is_show: yup.boolean().required(),
        created_at: yup.string().required(),
        option_details: yup.array().of(yup.object().shape({
            name: yup.string().required(),
            price: yup.number().required(),
        })),
        created_by: yup.string().required(),
    })

    const addHandler = () => {
        setOptionCreateListData(optionlist => [...optionlist, {
            'name': '',
            'price': undefined,
            // 'stock': {
            //     'listName': '',
            //     'storeHouse': '',
            //     'productID': '',
            //     'qty': '',
            //     'unit': '',
            // },
        }])
    }

    const deleteHandeler = (removeId) => {
        setOptionCreateListData(optionlist => optionlist.filter(item => item.id !== removeId))
    }

    const postStandardOptionsData = async (values) => {
        console.log('Create submitted!:', values)

        try {
            const res = await fetch(productStandardOptionsURL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to post data:', error)
        }
    }

    useEffect(() => {
        setOptionCreateListData(optionCreateData.option_details)
    }, [optionCreateData])

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li
                            className="breadcrumb-item "
                        >
                            <a
                                href="#"
                                className="tc-7  text-decoration-none"
                            >
                                สินค้าและบริการ
                            </a>
                        </li>
                        <li className="breadcrumb-item active tc-1 " aria-current="page"> จัดการตัวเลือกมาตราฐาน</li>
                    </ol>
                </nav>
                <div className="fs-24 tc-7  mb-4 lh-1">จัดการตัวเลือกมาตราฐาน</div>
                <div className="card card-st-1 mb-4">
                    <div className="card-body p-4">
                        <div className="fs-22 fw-500 tc-7  mb-4 lh-1">สร้างตัวเลือกมาตราฐาน</div>
                        <Formik
                            initialValues={optionCreateData}
                            validationSchema={schema}
                            onSubmit={async (values) => {
                                postStandardOptionsData({
                                    ...values,
                                    created_at: `${values.created_at.toISOString()}`,
                                    option_type_id: 0,
                                    created_by: 0,
                                    modified_by: 0,
                                })
                                    .then(() => navigate(`/${slug}/product/options/standard`))
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                values,
                                setFieldValue,
                                isInvalid,
                                isValid,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset>
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">ชื่อมาตราฐาน <span
                                                        className="text-danger">*</span> </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="กรอกชื่อมาตราฐาน"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.name && touched.name)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {optionCreateListData !== undefined && optionCreateListData.map((item, index) =>
                                            <OptionCreateList
                                                item={item}
                                                key={item.id}
                                                n={index}
                                                deleteHandeler={() => {
                                                    values.option_details.splice(index, 1)
                                                    deleteHandeler(item.id)
                                                }}
                                                // settingHandeler={() => {
                                                //     setModalShow(true)
                                                //     setSettingStock(item.stock)
                                                //     setStockindex(index)
                                                // }}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                            />,
                                        )}
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <div className="mb-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-st-3 mb-4"
                                                        onClick={addHandler}
                                                    >
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                                {/* <div className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="แสดงหน้าจอลูกค้า"
                                                        name="is_show"
                                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                                        checked={values.is_show}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setFieldValue('is_show', true)
                                                            } else {
                                                                setFieldValue('is_show', false)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="เปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio1"
                                                        className="form-check-st-2 tc-7"
                                                        checked={values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', true)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="ปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio2"
                                                        className="form-check-st-2 tc-7"
                                                        checked={!values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', false)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="created_at"
                                                                className="form-control"
                                                                placeholder="วันที่สร้าง"
                                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.created_at}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="creator_name"
                                                                className="form-control"
                                                                placeholder="ชื่อผู้สร้าง"
                                                                value={values.creator_name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.creator_name}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </fieldset>
                                    <div className="text-end pb-4 pe-3">
                                        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                            <Link
                                                to={`/${slug}/product/options/standard`}
                                                className="btn btn-st-20 me-3 bg-transparent"
                                            >
                                                ยกเลิก
                                            </Link>
                                            <Button type="submit" className="btn-st-3 ">
                                                บันทึก
                                            </Button>
                                        </div>
                                        <StockModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            settingStock={settingStock}
                                            setFieldValue={setFieldValue}
                                            stockindex={stockindex}
                                            deletestock={() => {
                                                values.optionList[stockindex].stock = null
                                                setModalShow(false)
                                            }}
                                            values={values}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function OptionEdit () {
    const OPTION_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product-standard-options`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const { slug } = useRecoilValue(appState)
    const navigate = useNavigate()

    const [optiondata, setOptiondata] = useState({
        'optionName': '',
        'dateCreate': getCurrentDate('/'),
        'userCreate': 'Admin Pat',
        'optionStatus': 1,
        'displayCustomer': 0,
        'optionList': [],
    })

    const [optionEditData, setOptionEditData] = useState({
        'name': '',
        'created_at': getCurrentDate('/'),
        'whos_updated': '',
        'is_active': undefined,
        'is_show': undefined,
        'product_option_detail': [],
    })

    const [optionEditListData, setOptionEditListData] = useState([])

    const getOptionEditData = async (id, tokens, users) => {
        try {
            const res = await fetch(`${OPTION_EDIT_URL}/${optionID}`, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setOptionEditData(data)
            setOptionEditListData(data.product_option_detail)
            setOnSuccess(false)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const updateStandardOption = async (values) => {

        try {
            const res = await fetch(`${OPTION_EDIT_URL}/${optionID}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to post data:', error)
        }
    }

    useEffect(() => {
        getOptionEditData( optionID, token, user.businessId)
        // getData()
    }, [ optionID, user.businessId, token])

    const [formdisabled, setFormdisabled] = useState(false)

    const [optionlist, setOptionlist] = useState([])

    const schema = yup.object().shape({
        name: yup.string().required(),
        created_at: yup.string().required(),
        whos_updated: yup.string().required(),
        is_active: yup.boolean().required(),
        is_show: yup.boolean().required(),
        product_option_detail: yup.array().of(yup.object().shape({
            name: yup.string().required(),
            price: yup.number().required(),
        })),
    })

    const [modalShow, setModalShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})
    const [modalDeleteShow, setModalDeleteShow] = useState(false)
    const [modalsuccessShow, setModalsuccessShow] = useState(false)

    const [productOptionDetail, setProductOptionDetail] = useState({
        'id': null,
        'name': null,
        'price': null,
    })

    const editHandeler = (item) => {
        setProductOptionDetail(item)
        setModaledit((prev) => ({ ...prev, open: true }))
    }
    const [modaledit, setModaledit] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 560,
        height: 380,
        pin: false,
        mode: 'normal',
        modalname: 'modal_edit_option_standard',
    })

    const [modaladd, setModaladd] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 560,
        height: 380,
        pin: false,
        mode: 'normal',
        modalname: 'modal_add_option_standard',
    })

    const [onSuccess, setOnSuccess] = useState(false)

    useEffect(() => {
        if (onSuccess === true) {
            getOptionEditData()
        }
    }, [onSuccess])

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li className="breadcrumb-item ">
                            <a href="#" className="tc-7 text-decoration-none">
                                สินค้าและบริการ
                            </a>
                        </li>
                        <li className="breadcrumb-item active tc-1" aria-current="page">จัดการตัวเลือกมาตราฐาน</li>
                    </ol>
                </nav>
                <div className="fs-24 tc-7  mb-4 lh-1">จัดการตัวเลือกมาตราฐาน</div>
                <div className="card card-st-1 mb-4">
                    <div className="card-body p-4">
                        <div className="fs-22 fw-500 tc-7  mb-4 lh-1">แก้ไขตัวเลือกมาตราฐาน</div>
                        <Formik
                            enableReinitialize
                            initialValues={optionEditData}
                            // initialValues={optiondata}
                            validationSchema={schema}
                            onSubmit={async (values) => {
                                const updateData = await updateStandardOption({
                                    id: values.id,
                                    name: values.name,
                                    is_show: values.is_show,
                                    is_active: values.is_active
                                })
                                if (updateData.status === 200) {
                                    setFormdisabled(true)
                                    // redirect to option standard page
                                    console.log('Update success:', updateData.data)
                                    navigate(`/${slug}/product/options/standard`)
                                }
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                values,
                                setFieldValue,
                                isInvalid,
                                isValid,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset disabled={formdisabled}>
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">
                                                        ชื่อมาตราฐาน
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={values.name}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.name && touched.name)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {optionEditListData !== undefined && optionEditListData.map((item, index) =>
                                            <OptionList
                                                item={item}
                                                key={item.id}
                                                n={index}
                                                editHandeler={() => {
                                                    editHandeler(item)
                                                }}
                                                settingHandeler={() => {
                                                    setModalShow(true)
                                                    setSettingStock(item.stock)
                                                    setStockindex(index)
                                                }}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                            />,
                                        )}
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <div className="mb-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-st-3 mb-4"
                                                        onClick={() => setModaladd((prev) => ({ ...prev, open: true }))}
                                                    >
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                                {/* <div className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="แสดงหน้าจอลูกค้า"
                                                        name="is_show"
                                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                                        checked={values.is_show}
                                                        // defaultChecked={() => {
                                                        //     console.log('is_show:', values.is_show)
                                                        // }}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setFieldValue('is_show', true)
                                                            } else {
                                                                setFieldValue('is_show', false)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="เปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio1"
                                                        className="form-check-st-2 tc-7"
                                                        checked={values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', true)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="ปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio2"
                                                        className="form-check-st-2 tc-7"
                                                        checked={!values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', false)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="created_at"
                                                                className="form-control"
                                                                placeholder="วันที่สร้าง"
                                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                                onChange={handleChange}
                                                                // isInvalid={!!errors.created_at}
                                                                isInvalid={!!(errors.created_at && touched.created_at)}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="whos_updated"
                                                                className="form-control"
                                                                placeholder="ชื่อผู้สร้าง"
                                                                value={values.whos_updated}
                                                                onChange={handleChange}
                                                                // isInvalid={!!errors.whos_updated}
                                                                isInvalid={!!(errors.whos_updated && touched.whos_updated)}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="text-end pb-4 pe-3">
                                        {
                                            formdisabled === true ? (
                                                <Button
                                                    type="button"
                                                    className="btn-st-3 mx-3"
                                                    onClick={(e) => {
                                                        setFormdisabled(false)
                                                    }}
                                                >
                                                    แก้ไข
                                                </Button>
                                            ) : (
                                                <div
                                                    className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                                    <Button
                                                        type="button"
                                                        className="btn-st-5 me-auto px-4 "
                                                        style={{ width: '90px' }}
                                                        onClick={() => setModalDeleteShow(true)}
                                                    >
                                                        ลบ
                                                    </Button>
                                                    <Link
                                                        to={`/${slug}/product/options/standard`}
                                                        className="btn btn-st-20 me-3 bg-transparent"
                                                    >
                                                        ยกเลิก
                                                    </Link>
                                                    <Button type="submit" className="btn-st-3 ">
                                                        บันทึก
                                                    </Button>
                                                </div>
                                            )
                                        }
                                        <StockModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            settingStock={settingStock}
                                            setFieldValue={setFieldValue}
                                            stockindex={stockindex}
                                            deletestock={() => {
                                                values.optionList[stockindex].stock = null
                                                setModalShow(false)
                                            }}
                                            values={values}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ConfirmDeleteModal
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                onSuccess={() => setModalsuccessShow(true)}
                optiondata={optionEditData}
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => {
                    setModalsuccessShow(false)
                    navigate(`/${slug}/product/options/standard`)
                }}
            />
            <ModalEdit
                modal={modaledit}
                setModal={setModaledit}
                productOptionDetail={productOptionDetail}
                setProductOptionDetail={setProductOptionDetail}
                setOptionEditListData={setOptionEditListData}
                optionEditListData={optionEditListData}
                onSuccess={() => {
                    setOnSuccess(true)
                    // redirect to option standard page
                    // navigate(`/${slug}/product/options/standard`)
                }}
            />
            <ModalAdd
                modal={modaladd}
                setModal={setModaladd}
                setOptionEditListData={setOptionEditListData}
                optionEditListData={optionEditListData}
                onSuccess={() => {
                    setOnSuccess(true)
                    // redirect to option standard page
                    // navigate(`/${slug}/product/options/standard`)
                }}
            />
        </div>
    )
}

const ModalAdd = ({ modal, setModal, setOptionEditListData, optionEditListData, setModalDeleteShow, onSuccess, ...props }) => {
    const OPTION_DETAILS_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product/standard-options-details`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const resetFormRef = useRef(null)

    const schemaFormModal = yup.object().shape({
        name: yup.string().required(),
        price: yup.number().required(),
    })

    const [initialValues, setInitialValues] = useState({
        'name': '',
        'price': '',
    })

    const postOptionDetails = async (values) => {

        try {
            const res = await fetch(OPTION_DETAILS_EDIT_URL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    useEffect(() => {
        if (modal.open && resetFormRef.current) {
            resetFormRef.current()
        }
    }, [modal.open])

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={'เพิ่มรายการตัวเลือกมาตราฐาน'}
            hbtn={true}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // initialValues={optiondata}
                validationSchema={schemaFormModal}
                onSubmit={async (values) => {
                    const createOptionDetails = await postOptionDetails({
                        ...values,
                        product_option_id: parseInt(optionID)
                    })

                    if (createOptionDetails.status === 201) {
                        setModal((prev) => ({ ...prev, open: false }))
                        setTimeout(() => {
                            onSuccess()
                        }, 1000)
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    resetForm,
                }) => {
                    resetFormRef.current = resetForm

                    return (
                        <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
                            <fieldset>
                                <div className="row ">
                                    <div className="col-12">
                                        <Form.Group className="mb-4 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="กรอกชื่อรายการ"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!(errors.name && touched.name)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-12">
                                        <Form.Group className="mb-4 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                className="form-control"
                                                placeholder="กรอกราคา"
                                                onChange={handleChange}
                                                value={values.price}
                                                isInvalid={!!(errors.price && touched.price)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="text-end pb-4 pt-4 pe-3">
                                <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                    <Button
                                        className="btn btn-st-6 me-3 bg-transparent"
                                        onClick={() => {
                                            setModal((prev) => ({ ...prev, open: false }))
                                            resetForm()
                                        }}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button type="submit" className="btn-st-3 ">
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </ModalAllder>
    )
}

const ModalEdit = ({
    modal,
    setModal,
    productOptionDetail,
    setProductOptionDetail,
    setOptionEditListData,
    optionEditListData,
    setModalDeleteShow,
    onSuccess,
    ...props
}) => {
    const OPTION_DETAILS_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product/standard-options-details`

    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const schemaFormModal = yup.object().shape({
        name: yup.string().required(),
        price: yup.number().required(),
    })

    const patchOptionDetails = async (values) => {
        console.log('update option details submitted!:', values)

        try {
            const res = await fetch(`${OPTION_DETAILS_EDIT_URL}/${values.id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    const deleteOptionDetails = async (values) => {
        console.log('update option details submitted!:', values)

        try {
            const res = await fetch(`${OPTION_DETAILS_EDIT_URL}/${values.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    console.log('productOptionDetail:', productOptionDetail)

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={'แก้ไขรายการตัวเลือกมาตราฐาน'}
            hbtn={true}
        >
            <Formik
                enableReinitialize
                initialValues={productOptionDetail}
                // initialValues={optiondata}
                validationSchema={schemaFormModal}
                onSubmit={async (values) => {
                    // console.log('modal edit submit value:', values)
                    // console.log(optionEditListData)

                    const updateOptionDetails = await patchOptionDetails(values)

                    if (updateOptionDetails.status === 200) {
                        setOptionEditListData(prev => prev.map((el) => {
                            if (el.id == values.id) {
                                console.log('values.name')
                                return {
                                    ...el,
                                    name: values.name,
                                    price: values.price,
                                }
                            }
                            return el
                        }))
                        setModal((prev) => ({ ...prev, open: false }))
                        setTimeout(() => {
                            onSuccess()
                        }, 1000)
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    isInvalid,
                    isValid,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
                        <fieldset>
                            <div className="row ">
                                <div className="col-12">
                                    <Form.Group className="mb-4 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`name`}
                                            className="form-control"
                                            placeholder="กรอกชื่อรายการ"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!(errors.name && touched.name)}

                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="mb-4 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={`price`}
                                            className="form-control"
                                            placeholder="กรอกราคา"
                                            onChange={handleChange}
                                            value={values.price}
                                            isInvalid={!!(errors.price && touched.price)}

                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </fieldset>
                        <div className="text-end pb-4 pt-4 pe-3">
                            <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                <Button
                                    type="button"
                                    className="btn-st-5 me-auto px-4 "
                                    style={{ width: '90px' }}
                                    onClick={async () => {
                                        const deleteDetails = await deleteOptionDetails(productOptionDetail)
                                        if (deleteDetails.status === 200) {
                                            setModal((prev) => ({ ...prev, open: false }))
                                            onSuccess()
                                        }
                                        // setOptionEditListData(optionlist => optionlist.filter(item => item.id !== values.id))
                                    }}
                                >
                                    ลบ
                                </Button>
                                <Button

                                    className="btn btn-st-6 me-3 bg-transparent"
                                    onClick={() => setModal((prev) => ({ ...prev, open: false }))}
                                >
                                    ยกเลิก
                                </Button>
                                <Button type="submit" className="btn-st-3 ">
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalAllder>
    )
}

const OptionList = (props) => {
    useEffect(() => {
        props.setFieldValue(`option_details[${props.n}].name`, props.item.name)
        props.setFieldValue(`option_details[${props.n}].price`, props.item.price)
        // if (props.item.stock.listName != '') {
        //     props.setFieldValue(`optionList[${props.n}].stock`, props.item.stock)
        // } else {
        //     props.setFieldValue(`optionList[${props.n}].stock`, null)
        // }
    }, [])

    return (
        <div className="row gx-xl-3 align-items-center">
            <div className="col-xl-6 col-lg-6 col-8">
                <div className="row ">
                    <div className="col-6">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                            <Form.Control
                                type="text"
                                name={`option_details[${props.n}].name`}
                                className="form-control"
                                placeholder="กรอกชื่อมาตราฐาน"
                                value={props.item.name}
                                isInvalid={!!(
                                    props.errors.option_details &&
                                    props.errors.option_details[props.n] &&
                                    props.errors.option_details[props.n].name &&
                                    props.touched.option_details &&
                                    props.touched.option_details[props.n] &&
                                    props.touched.option_details[props.n].name
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`option_details[${props.n}].name`, e.target.value)
                                }
                                disabled={true}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-6">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                            <Form.Control
                                type="number"
                                name={`option_details[${props.n}].price`}
                                className="form-control"
                                placeholder="กรอกราคา"
                                value={props.item.price}
                                isInvalid={!!(
                                    props.errors.option_details &&
                                    props.errors.option_details[props.n] &&
                                    props.errors.option_details[props.n].price &&
                                    props.touched.option_details &&
                                    props.touched.option_details[props.n] &&
                                    props.touched.option_details[props.n].price
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`option_details[${props.n}].price`, parseInt(e.target.value))
                                }
                                disabled={true}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-4 ">
                {/* <button
                    type="button"
                    className="btn btn-close-st-2 me-2"
                    onClick={props.deleteHandeler}
                /> */}

                <button
                    type="button"
                    className="btn d-block btn-edit-st-1 "
                    onClick={props.editHandeler}
                >
                </button>

                {/*<button type="button" className="btn btn-setting-st-1 me-2" onClick={props.settingHandeler} />*/}
                {/*{*/}
                {/*    props.values.optionList[props.n] ? (*/}
                {/*        props.values.optionList[props.n].stock ? (*/}
                {/*            <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>*/}
                {/*        ) : (*/}
                {/*            <span*/}
                {/*                className="fs-16"*/}
                {/*                style={{ color: '#B6BBC6', lineHeight: '24px' }}*/}
                {/*            >*/}
                {/*                ยังไม่ได้ตั้งค่า*/}
                {/*            </span>*/}
                {/*        )*/}
                {/*    ) : (*/}
                {/*        <span className="fs-16" style={{ color: '#B6BBC6', lineHeight: '24px' }}>ยังไม่ได้ตั้งค่า</span>*/}
                {/*    )*/}
                {/*}*/}
            </div>
        </div>
    )
}
// const OptionList = (props) => {
//     useEffect(() => {
//         props.setFieldValue(`product_option_detail[${props.n}].name`, props.item.name)
//         props.setFieldValue(`product_option_detail[${props.n}].price`, props.item.price)
//         // if (props.item.stock.listName != '') {
//         //     props.setFieldValue(`optionList[${props.n}].stock`, props.item.stock)
//         // } else {
//         //     props.setFieldValue(`optionList[${props.n}].stock`, null)
//         // }
//     }, [])
//
//     return (
//         <div className="row gx-xl-3 align-items-center">
//             <div className="col-xl-6 col-lg-6 col-8">
//                 <div className="row ">
//                     <div className="col-6">
//                         <Form.Group className="mb-2 form-st-3 pb-1">
//                             <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name={`product_option_detail[${props.n}].name`}
//                                 className="form-control"
//                                 placeholder="กรอกชื่อมาตราฐาน"
//                                 defaultValue={props.item.name}
//                                 isInvalid={!!(
//                                     props.errors.product_option_detail &&
//                                     props.errors.product_option_detail[props.n] &&
//                                     props.errors.product_option_detail[props.n].name &&
//                                     props.touched.product_option_detail &&
//                                     props.touched.product_option_detail[props.n] &&
//                                     props.touched.product_option_detail[props.n].name
//                                 )}
//                                 onChange={(e) =>
//                                     props.setFieldValue(`product_option_detail[${props.n}].name`, e.target.value)
//                                 }
//                             />
//                         </Form.Group>
//                     </div>
//                     <div className="col-6">
//                         <Form.Group className="mb-2 form-st-3 pb-1">
//                             <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name={`product_option_detail[${props.n}].price`}
//                                 className="form-control"
//                                 placeholder="กรอกราคา"
//                                 defaultValue={props.item.price}
//                                 isInvalid={!!(
//                                     props.errors.product_option_detail &&
//                                     props.errors.product_option_detail[props.n] &&
//                                     props.errors.product_option_detail[props.n].price &&
//                                     props.touched.product_option_detail &&
//                                     props.touched.product_option_detail[props.n] &&
//                                     props.touched.product_option_detail[props.n].price
//                                 )}
//                                 onChange={(e) =>
//                                     props.setFieldValue(`product_option_detail[${props.n}].price`, e.target.value)
//                                 }
//                             />
//                         </Form.Group>
//                     </div>
//                 </div>
//             </div>
//             <div className="col-xl-6 col-4 ">
//                 <button
//                     type="button"
//                     className="btn btn-close-st-2 me-2"
//                     onClick={props.deleteHandeler}
//                 />
//                 {/*<button type="button" className="btn btn-setting-st-1 me-2" onClick={props.settingHandeler} />*/}
//                 {/*{*/}
//                 {/*    props.values.optionList[props.n] ? (*/}
//                 {/*        props.values.optionList[props.n].stock ? (*/}
//                 {/*            <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>*/}
//                 {/*        ) : (*/}
//                 {/*            <span*/}
//                 {/*                className="fs-16"*/}
//                 {/*                style={{ color: '#B6BBC6', lineHeight: '24px' }}*/}
//                 {/*            >*/}
//                 {/*                ยังไม่ได้ตั้งค่า*/}
//                 {/*            </span>*/}
//                 {/*        )*/}
//                 {/*    ) : (*/}
//                 {/*        <span className="fs-16" style={{ color: '#B6BBC6', lineHeight: '24px' }}>ยังไม่ได้ตั้งค่า</span>*/}
//                 {/*    )*/}
//                 {/*}*/}
//             </div>
//         </div>
//     )
// }
const OptionCreateList = (props) => {
    useEffect(() => {
        props.setFieldValue(`option_details[${props.n}].name`, props.item.name)
        props.setFieldValue(`option_details[${props.n}].price`, props.item.price)
        // if (props.item.stock.listName != '') {
        //     props.setFieldValue(`optionList[${props.n}].stock`, props.item.stock)
        // } else {
        //     props.setFieldValue(`optionList[${props.n}].stock`, null)
        // }
    }, [])

    return (
        <div className="row gx-xl-3 align-items-center">
            <div className="col-xl-6 col-lg-6 col-8">
                <div className="row ">
                    <div className="col-6">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                            <Form.Control
                                type="text"
                                name={`option_details[${props.n}].name`}
                                className="form-control"
                                placeholder="กรอกชื่อมาตราฐาน"
                                defaultValue={props.item.name}
                                isInvalid={!!(
                                    props.errors.option_details &&
                                    props.errors.option_details[props.n] &&
                                    props.errors.option_details[props.n].name &&
                                    props.touched.option_details &&
                                    props.touched.option_details[props.n] &&
                                    props.touched.option_details[props.n].name
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`option_details[${props.n}].name`, e.target.value)
                                }

                            />
                        </Form.Group>
                    </div>
                    <div className="col-6">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                            <Form.Control
                                type="number"
                                name={`option_details[${props.n}].price`}
                                className="form-control"
                                placeholder="กรอกราคา"
                                defaultValue={props.item.price}
                                isInvalid={!!(
                                    props.errors.option_details &&
                                    props.errors.option_details[props.n] &&
                                    props.errors.option_details[props.n].price &&
                                    props.touched.option_details &&
                                    props.touched.option_details[props.n] &&
                                    props.touched.option_details[props.n].price
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`option_details[${props.n}].price`, parseInt(e.target.value))
                                }

                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-4 ">
                <button
                    type="button"
                    className="btn btn-close-st-2 me-2"
                    onClick={props.deleteHandeler}
                />


                {/*<button type="button" className="btn btn-setting-st-1 me-2" onClick={props.settingHandeler} />*/}
                {/*{*/}
                {/*    props.values.optionList[props.n] ? (*/}
                {/*        props.values.optionList[props.n].stock ? (*/}
                {/*            <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>*/}
                {/*        ) : (*/}
                {/*            <span*/}
                {/*                className="fs-16"*/}
                {/*                style={{ color: '#B6BBC6', lineHeight: '24px' }}*/}
                {/*            >*/}
                {/*                ยังไม่ได้ตั้งค่า*/}
                {/*            </span>*/}
                {/*        )*/}
                {/*    ) : (*/}
                {/*        <span className="fs-16" style={{ color: '#B6BBC6', lineHeight: '24px' }}>ยังไม่ได้ตั้งค่า</span>*/}
                {/*    )*/}
                {/*}*/}
            </div>
        </div>
    )
}

function StockModal (props) {

    const { settingStock, setFieldValue, stockindex, deletestock, ...rest } = props

    const schema = yup.object().shape({
        listName: yup.string().required(),
        productID: yup.string().required(),
    })

    const optionsProduct = [
        { value: '1', label: 'ไข่ไก่สด' },
        { value: '2', label: 'ไก่สด' },
    ]

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">ตั้งค่าเชื่อมต่อสต๊อกสินค้า</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        initialValues={props.settingStock}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            props.setFieldValue('stock', values)
                            props.setFieldValue(`optionList[${props.stockindex}].stock`, values)
                            props.onHide()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="listName"
                                        className="form-control"
                                        placeholder="ชื่อรายการ"
                                        value={values.listName}
                                        onChange={handleChange}
                                        isInvalid={!!errors.listName}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ประเภทคลังสินค้าที่ตัดสต๊อก </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="storeHouse"
                                        className="form-control"
                                        placeholder="ประเภทคลังสินค้าที่ตัดสต๊อก"
                                        value={values.storeHouse}
                                        onChange={handleChange}
                                        isInvalid={!!errors.storeHouse}
                                        disabled={true}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">สินค้าที่ตัดสต๊อก</Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsProduct.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsProduct}
                                            placeholder="สินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">จำนวน </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="qty"
                                        className="form-control"
                                        placeholder="จำนวน"
                                        value={values.qty}
                                        onChange={handleChange}
                                        isInvalid={!!errors.qty}


                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">หน่วยวัตถุดิบ / UMS </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="unit"
                                        className="form-control"
                                        placeholder="หน่วยวัตถุดิบ / UMS"
                                        value={values.unit}
                                        onChange={handleChange}
                                        isInvalid={!!errors.unit}
                                        disabled={true}

                                    />
                                </Form.Group>

                                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                                    {
                                        props.values.optionList[stockindex].stock ? (
                                            <Button type="button" className="btn-st-5 me-auto "
                                                    style={{ width: '80px' }} onClick={props.deletestock}>ลบ</Button>
                                        ) : (
                                            <div className="me-auto"></div>
                                        )
                                    }

                                    <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}
                                            onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className="btn-st-3  "
                                            style={{ width: '100px' }}>บันทึก</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}

function ConfirmDeleteModal (props) {
    const { onSuccess, onHide, ...rest } = props

    const OPTION_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product-standard-options`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()


    const handleDelete = (categoryID) => {
        props.onHide()
        props.onSuccess()
    }

    const deleteOption = async (values) => {
        console.log('Delete option submitted!:', values)

        try {
            const res = await fetch(`${OPTION_EDIT_URL}/${values.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to delete data:', error)
        }
    }

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="px-3 py-4">
                    {
                        (props.optiondata && props.optiondata.productQty !== undefined && props.optiondata.productQty > 0) ? (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">ขออภัยไม่สามารถลบได้</div>
                                    <div className="text-center fs-22 tc-7">เนื่องจากมีสินค้าอยู่ในตัวเลือกมาตราฐาน
                                    </div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ตกลง
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
                                    <div className="text-center fs-22 tc-7">ตัวเลือกมาตราฐานนี้ใช่หรือไม่</div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3"
                                        style={{ width: '100px' }}
                                        onClick={async () => {
                                            // handleDelete(props.optiondata.optionID)
                                            const deleteOptionResponse = await deleteOption(props.optiondata)
                                            if (deleteOptionResponse.status === 200) {
                                                props.onHide()
                                                props.onSuccess()
                                            }
                                        }}
                                    >
                                        ใช่
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn-st-6 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ไม่ใช่
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

function SuccessDeleteModal (props) {
    return (
        <Modal
            {...props}
            size="sm"
            centered
        >
            <Modal.Body>

                <div className="px-3 py-4">
                    <div className="text-center mb-4">
                        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                            <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>
                    <div className="text-center fs-22 tc-7">ลบรายการสินค้าสำเร็จ</div>
                </div>

            </Modal.Body>

        </Modal>
    )
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
        <>
            <div className="form-check form-check-st-3" style={{ backgroundColor: '' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '18px', width: '18px' }}
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                />
                <label className="form-check-label" id="booty-check"/>
            </div>
        </>
    )
})