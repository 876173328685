import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import DataTableLayout from "layouts/DatatableLayout";
// import ProductSelectModal from "layouts/ModalProductSelect";
import ModalKitchenProduct from "components/modal/branch/ModalKitchenProduct";
import ModalSuccess from "layouts/ModalSuccess";
import Modal from "react-bootstrap/Modal";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";
import ProductCategoryApi from "services/branch/kitchen/ProductCategoryApi";
import BranchKitchenProductApi from "services/branch/BranchKitchenProductApi";

const KitchenProductList = () => {
  const { branchID, kitchenID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();

  const [modalproductlistShow, setModalproductlistShow] = useState(false);
  const [modalcategoryDeleteShow, setModalcategoryDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  const [categoryeditdata, setCategoryeditdata] = useState({});

  const [categorydata, setCategorydata] = useState([]);
  const [excludeproductdata, setExcludeproductdata] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": user.businessId,
    },
  };

  useEffect(() => {
    if (user.businessId && token && branchID && kitchenID) {
      getProductData();
      getExcludeProductData();
    }
  }, [user.businessId, token, branchID, kitchenID]);

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.productNo,
      cell: (row, index, column, id) => (
        <div className="ps-4">{row.productNo}</div>
      ),
    },
    {
      name: "รหัสรายการ",
      selector: (row) => row.productCode,
    },
    {
      name: "ชื่อรายการ",
      selector: (row) => row.productName,
    },
    {
      name: "หมวดหมู่หลักสินค้า",
      selector: (row) => row.category,
    },
    {
      name: "จัดการ",
      selector: (row) => row.categoryID,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3 "
            onClick={() => handleDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const getProductData = async () => {
    ProductCategoryApi.GetAllProducts(branchID, kitchenID, config).then(
      (res) => {
        // console.log("🚀 ~ getProductData ~ res:", res);
        // push no to data
        res.data.map((item, index) => {
          item.productNo = index + 1;
          item.productCode = item.branchproduct.product.code;
          item.productName = item.branchproduct.product.name;
          item.category =
            item.branchproduct.product.sub_product_category.product_category.name;
          return item;
        });
        setCategorydata(res.data);
      }
    );
  };

  const getExcludeProductData = async () => {
    BranchKitchenProductApi.GetAllBranchExcludeKitchenProduct(
      branchID,
      kitchenID,
      config
    ).then((res) => {
      setExcludeproductdata(res.data);
    });
  };

  const [filterText, setFilterText] = useState("");
  const filteredItems = categorydata.filter(
    (item) =>
      item.productName &&
      item.productName.toLowerCase().includes(filterText.toLowerCase())
  );

  const AddProduct = async (product) => {
    // get product id from product list
    let productList = [];
    product.map((item) => {
      // format int
      productList.push(parseInt(item.id));
    });

    BranchKitchenProductApi.CreateBranchKitchensProduct(
      branchID,
      kitchenID,
      productList,
      configCreate
    )
      .then(
        (res) => {
          console.log("🚀 ~ ).then ~ res:", res);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        getProductData();
        getExcludeProductData();
        setModalproductlistShow(false);
      });
  };

  const DeleteProduct = async (product) => {
    // console.log("🚀 ~ DeleteProduct ~ product:", product.branchproduct.id);
    BranchKitchenProductApi.DeleteBranchKitchensProduct(
      branchID,
      kitchenID,
      product.id,
      config
    )
      .then(
        (res) => {
          // console.log("🚀 ~ DeleteProduct ~ res:", res);
          setModalsuccessShow(true);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        getProductData();
        getExcludeProductData();
        setModalcategoryDeleteShow(false);
      });
    // setModalcategoryDeleteShow(false);
  };

  const handleDelete = (item) => {
    // console.log("🚀 ~ handleDelete ~ item:", item)
    // show confirm delete modal
    setCategoryeditdata(item);
    setModalcategoryDeleteShow(true);
  };

  return (
    <div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <div className="tc-7 fs-20 fw-6   me-auto">รายการเมนู</div>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อเมนู"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              disabled={false}
              type="button"
              className="btn btn btn-st-7"
              onClick={() => setModalproductlistShow(true)}
            >
              เพิ่มรายการ
            </Button>
          </div>

          <DataTableLayout
            columns={columns}
            item={filteredItems}
            isSelectRows={false}
          />
        </div>
      </div>

      <ModalKitchenProduct
        show={modalproductlistShow}
        excludeproductdata={excludeproductdata}
        onHide={() => setModalproductlistShow(false)}
        handleSubmit={(product) => AddProduct(product)}
      />

      <ConfirmDeleteModal
        show={modalcategoryDeleteShow}
        onHide={() => setModalcategoryDeleteShow(false)}
        onConfirm={() => DeleteProduct(categoryeditdata)}
        onSuccess={() => setModalsuccessShow(true)}
        categoryeditdata={categoryeditdata}
      />
      <ModalSuccess
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
        message="ลบรายการครัวสำเร็จ"
      />
    </div>
  );
};

function ConfirmDeleteModal(props) {
  // console.log("🚀 ~ ConfirmDeleteModal ~ props:", props.categoryeditdata)
  const { categoryeditdata } = props;
  const productNames = categoryeditdata.productName
  const handleDelete = (categoryID) => {
    console.log("🚀 ~ handleDelete ~ categoryID:", categoryID);
    props.onHide();
    props.onSuccess();
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการลบ รายการ {productNames} 
              </div>
              <div className="text-center fs-22 tc-7">
                ออกจากเมนูครัวหรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={props.onConfirm}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default KitchenProductList;
