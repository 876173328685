import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAll = (config) =>
    axiosClient.get(`/employee`, config);

const Get = (id, config) =>
    axiosClient.get(`/employee/${id}`, config);

const Create = (data, config) =>
    axiosClient.post(`/employee`, data, config);

const Update = (id, data, config) =>
    axiosClient.patch(`/employee/${id}`, data, config);

export default {
    GetAll,
    Get,
    Create,
    Update,
};