import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react'
import {Link,useNavigate} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import useToken from '../utils/UseToken'
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'

export function  BusinessLink() {
    const BusinessLinkURL = `${process.env.REACT_APP_API_URL}/businesslink`

    const { token, setToken } = useToken()
    const { user } = useRecoilValue(authState)
    const [businessLinkData, setBusinessLinkData] = useState()

    const getBusinessLink = async () => {
        try {
            const res = await fetch(BusinessLinkURL, {
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setBusinessLinkData(data)
            console.log('BusinessLink:', data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    useEffect(() => {
        getBusinessLink()
    }, [])

    return (
        <div>
            <nav  aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">ตั้งค่าธุรกิจ</a></li>
                <li className="breadcrumb-item active tc-1 " aria-current="page"> ข้อมูลธุรกิจ   </li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">ข้อมูลธุรกิจ </div>

            <div className="card card-business-link">
                <div className="card-body px-5">
                    {/*<div className='text-center fs-24 tc-6 mb-4'>*/}
                    {/*    สร้างข้อมูลบัญชีธุรกิจสำเร็จ 🎉*/}
                    {/*</div>*/}
                    <div className="w-full h-full flex justify-center items-center mb-4">
                        <div className="bx-upload">
                            {businessLinkData &&
                                <img
                                    src={`${process.env.REACT_APP_API_URL_SOCKET}/${businessLinkData.img_url}`}
                                    alt="business-logo"
                                />
                            }
                        </div>
                    </div>
                    <div className="text-center fs-20 mb-3">
                        <span className="tc-15 font-bold">ชื่อกิจการ  : </span>
                        {businessLinkData &&
                            <span className="tc-18 font-bold">{businessLinkData.name}</span>
                        }
                    </div>
                    <div className="text-center tc-19 mb-4">ท่านสามารถจัดการร้านค้าของท่านได้ตามลิงค์ดังนี้</div>
                    <div className='row align-items-center mb-4 gx-0'>
                        <div className='col-2 font-bold'>Backoffice</div>
                        <div className='col'>
                            {businessLinkData &&
                                <Form.Control
                                    type="text"
                                    name="backoffice"
                                    className="form-control form-link tc-9"
                                    value={businessLinkData.backoffice_url}
                                    disabled={true}
                                />
                            }
                        </div>
                        <div className='col-auto'>
                            {businessLinkData &&
                                <CopyToClipboard
                                    text={businessLinkData.backoffice_url}
                                    // onCopy={() => oncopy('copied!', true)}
                                >
                                    <button className=' btn btn-copy-link ms-3'>คัดลอก</button>
                                </CopyToClipboard>
                            }
                        </div>
                    </div>
                    <div className='row align-items-center mb-4 gx-0'>
                        <div className='col-2 font-bold'>POS</div>
                        <div className='col'>
                            {businessLinkData &&
                                <Form.Control
                                    type="text"
                                    name="pos"
                                    className="form-control form-link tc-9"
                                    value={businessLinkData.pos_url}
                                    disabled={true}
                                />
                            }
                        </div>
                        <div className='col-auto'>
                            {businessLinkData &&
                                <CopyToClipboard text={businessLinkData.pos_url}>
                                    <button className='btn btn-copy-link ms-3'>คัดลอก</button>
                                </CopyToClipboard>
                            }
                        </div>
                    </div>

                    {/*hidden - not use for now*/}
                    <div className='row align-items-center mb-3 gx-0 hidden'>
                        <div className='col-3'> STAFF</div>
                        <div className='col'>
                            <Form.Control
                                type="text"
                                name="staff"
                                className="form-control form-link tc-1"
                                value="กรุณาเปิดใช้งานระบบ Staff ก่อน"
                                disabled={true}
                            />
                        </div>
                        <div className='col-auto'>
                            <CopyToClipboard text="Alldercafe.com/MonkeyShop/POS/Login">
                                <button className=' btn btn-copy-link ms-3 invisible'>คัดลอก</button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className='text-center py-4'>
                        {businessLinkData &&
                            <Link to={`/${businessLinkData.pos_slug}/setting/business`} className='btn btn-st-3'>จัดการข้อมูลธุรกิจ</Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}