import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";

import DownloadExcel from "utils/DownloadExcel";
import SelectStoreHouse from "fragments/SelectStoreHouse";
import ProductSelectModal from "layouts/ModalProductSelect";
import CategoryListModal from "layouts/ModalCategorySelect";
import DataTableLayout from "layouts/DatatableLayout";

import ModalSuccess from "layouts/ModalSuccess";
import Modal from "react-bootstrap/Modal";
import KitchenApi from "services/KitchenApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";

export function Kitchen() {
  const { branchID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();
  const { slug } = useRecoilValue(appState);

  const [tabledata, setTabledata] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const getkitchenData = () => {
    KitchenApi.GetBranchKitchens(branchID, config).then((res) => {
      // push no to data
      res.data.map((item, index) => {
        item.kitchenNo = index + 1;
        return item;
      });

      setTabledata(res.data);
    });
  };

  useEffect(() => {
    if (user.businessId && token) {
      getkitchenData();
    }
  }, [user.businessId, token]);

  const handleDelete = (kitchenID) => {
    KitchenApi.DeleteBranchKitchens(branchID, kitchenID, config).then((res) => {
      getkitchenData();
    });
  };

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.kitchenNo,
    },
    {
      name: "ชื่อห้องครัว",
      selector: (row) => row.name,
    },
    {
      name: "จำนวนรายการเมนู",
      selector: (row) => row.menu_count,
    },
    {
      name: "จัดการ",
      selector: (row) => row.id,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Link
            className="btn btn-edit-st-1 me-2"
            to={`/${slug}/branch/setting/kitchen/${branchID}/manage/${row.id}`}
          ></Link>
          <Button
            className="btn btn-delete-st-3"
            // onClick={() => handleDelete(row.id)}
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?")){
                handleDelete(row.id)
              } 
            }}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = tabledata.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );


  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="tc-7 fs-20 fw-6 px-4 mb-3">จัดการครัว</div>
          <div className="d-flex align-items-center px-4 mb-4">
            <DownloadExcel data={filteredItems} filename="รายชื่อครัว" />
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา ชื่อครัว"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Link
              to={`/${slug}/branch/setting/kitchen/${branchID}/manage`}
              className="btn btn btn-st-7"
            >
              เพิ่มครัว
            </Link>
          </div>

          <DataTableLayout
            columns={columns}
            item={filteredItems}
            isSelectRows={true}
          />
        </div>
      </div>
    </div>
  );
}

export function KitchenManage() {
  const { branchID, kitchenID } = useParams();
  const [formdata, setFormdata] = useState({});

  const schema = yup.object().shape({
    kitchenName: yup.string().required(),
    storeHouse: yup.string().required(),
  });

  const getKitchen = () => {
    setFormdata({
      kitchenID: 1,
      kitchenName: "ครัวร้อน",
      productQty: "100",
      kitchenNo: 1,
      storeHouse: 1,
      productSelectType: 2,
    });
  };

  useEffect(() => {
    getKitchen(kitchenID);
  }, []);

  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-0 py-4">
          <Formik
            enableReinitialize
            initialValues={formdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              console.log(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form className="px-4" noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center  mb-4">
                  <div className="tc-7 fs-20 fw-6   me-auto">เพิ่มครัว</div>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {" "}
                    บันทึก
                  </Button>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        ชื่อห้องครัว <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="kitchenName"
                        className="form-control"
                        placeholder="กรอกชื่อห้องครัว"
                        value={values.kitchenName}
                        onChange={handleChange}
                        isInvalid={!!errors.kitchenName}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-6">
                    <Form.Group className={`mb-2 form-st-3 pb-1`}>
                      <Form.Label className="mb-1">
                        คลังสินค้าที่เชื่อม{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <SelectStoreHouse
                        handleChange={(e) => {
                          setFieldValue("storeHouse", e.value);
                        }}
                        selectedID={values.storeHouse}
                        errors={errors}
                      />
                    </Form.Group>
                  </div>
                </div>

                <Form.Label className="mb-2  tc-10">
                  กำหนดรายการอาหารที่จะปรากฏในหน้าจอครัว
                </Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="ทุกรายการอาหาร"
                      name="productSelectType"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.productSelectType === 1}
                      onChange={(e) => {
                        setFieldValue("productSelectType", 1);
                      }}
                    />
                    <Form.Check
                      inline
                      label="กำหนดรายการเอง"
                      name="productSelectType"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.productSelectType === 2}
                      onChange={(e) => {
                        setFieldValue("productSelectType", 2);
                      }}
                    />
                  </div>
                ))}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <CategoryDataList />

      <ProductDataList />
    </div>
  );
}

function CategoryDataList() {
  const [modalcategorylistShow, setModalcategorylistShow] = useState(false);
  const [categorydata, setCategorydata] = useState([
    {
      categoryID: 1,
      categoryNo: 1,
      categoryName: "ราดข้าว",
      subcategoryName: "กระเพรา",
      productQty: 23,
    },
  ]);

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.categoryNo,
      cell: (row, index, column, id) => (
        <div className="ps-4">{row.categoryNo}</div>
      ),
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.categoryName,
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.subcategoryName,
    },
    {
      name: "จำนวนสินค้า",
      selector: (row) => row.productQty,
      center: "true",
    },
    {
      name: "จัดการ",
      selector: (row) => row.categoryID,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3"
            onClick={() => handleDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = categorydata.filter(
    (item) =>
      item.categoryName &&
      item.categoryName.toLowerCase().includes(filterText.toLowerCase()),
    (item) =>
      item.categoryCode &&
      item.categoryCode.toLowerCase().includes(filterText.toLowerCase())
  );

  const AddCategory = (category) => {
    console.log(category);
    setModalcategorylistShow(false);
  };

  const handleDelete = (item) => {
    console.log(item);
  };

  return (
    <div>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <div className="tc-7 fs-20 fw-6   me-auto">หมวดหมู่</div>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อหมวดหมู่"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7"
              onClick={() => setModalcategorylistShow(true)}
            >
              เพิ่มหมวดหมู่
            </Button>
          </div>

          <DataTableLayout
            columns={columns}
            item={filteredItems}
            isSelectRows={false}
          />
        </div>
      </div>

      <CategoryListModal
        show={modalcategorylistShow}
        onHide={() => setModalcategorylistShow(false)}
        handleSubmit={(category) => AddCategory(category)}
      />
    </div>
  );
}

function ProductDataList() {
  const [modalproductlistShow, setModalproductlistShow] = useState(false);
  const [modalcategoryDeleteShow, setModalcategoryDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  const [categoryeditdata, setCategoryeditdata] = useState({});

  const [categorydata, setCategorydata] = useState([
    {
      productID: 1,
      productNo: 1,
      productCode: "16586235641",
      productName: "ต้มยำกุ้ง",
      category: "กับข้าว",
    },
    {
      productID: 1,
      productNo: 1,
      productCode: "16586235641",
      productName: "กระเพราหมูสับ",
      category: "ราดข้าว",
    },
    {
      productID: 1,
      productNo: 1,
      productCode: "16586235641",
      productName: "หมูแดดเดียวทอด",
      category: "กับข้าว",
    },
  ]);

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row) => row.productNo,
      cell: (row, index, column, id) => (
        <div className="ps-4">{row.productNo}</div>
      ),
    },
    {
      name: "รหัสรายการ",
      selector: (row) => row.productCode,
    },
    {
      name: "ชื่อรายการ",
      selector: (row) => row.productName,
    },
    {
      name: "หมวดหมู่หลักสินค้า",
      selector: (row) => row.category,
    },
    {
      name: "จัดการ",
      selector: (row) => row.categoryID,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            className="btn-delete-st-3 "
            onClick={() => handleDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const filteredItems = categorydata.filter(
    (item) =>
      item.productCode &&
      item.productCode.toLowerCase().includes(filterText.toLowerCase()),
    (item) =>
      item.productName &&
      item.productName.toLowerCase().includes(filterText.toLowerCase())
  );

  const AddProduct = (product) => {
    console.log(product);
    setModalproductlistShow(false);
  };

  const handleDelete = (item) => {
    console.log(item);
  };

  return (
    <div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <div className="tc-7 fs-20 fw-6   me-auto">รายการเมนู</div>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อเมนู"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7"
              onClick={() => setModalproductlistShow(true)}
            >
              เพิ่มรายการ
            </Button>
          </div>

          <DataTableLayout
            columns={columns}
            item={filteredItems}
            isSelectRows={false}
          />
        </div>
      </div>

      <ProductSelectModal
        show={modalproductlistShow}
        onHide={() => setModalproductlistShow(false)}
        handleSubmit={(product) => AddProduct(product)}
      />

      <ConfirmDeleteModal
        show={modalcategoryDeleteShow}
        onHide={() => setModalcategoryDeleteShow(false)}
        onConfirm={() => setModalcategoryDeleteShow(false)}
        onSuccess={() => setModalsuccessShow(true)}
        categoryeditdata={categoryeditdata}
      />
      <ModalSuccess
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
        message="ลบรายการครัวสำเร็จ"
      />
    </div>
  );
}

function ConfirmDeleteModal(props) {
  const handleDelete = (categoryID) => {
    props.onHide();
    props.onSuccess();
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการลบ รายการ X
              </div>
              <div className="text-center fs-22 tc-7">
                ออกจากเมนูครัวหรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.categoryeditdata.categoryID)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
