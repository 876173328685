import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { ModalAllder } from 'layouts/ModalAllder';
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'

export function EditOptionStandard (props){

    const [optionstandard, setOptionstandard] = useState([])
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    // const getoptionData = (props) => {
    //     fetch('/json/optionstandard.json')
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setOptionstandard(result)
    //         })
    //         .catch((error) => console.log('error', error))
    // }

    const getoptionData = (props) => {
        // console.log("🚀 ~ getoptionData ~ props:", props.values.id)
        fetch(`${process.env.REACT_APP_API_URL}/business-product/standard-options-binding/${props.values.id}`, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
                'business-id': user.businessId,
                // 'Content-Type': 'application/json'
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setOptionstandard(result)
            })
            .catch((error) => console.log('error', error))
    }

    useEffect(() => {
        getoptionData(props)
    }, [])

    const handleDeleteoptionList = async (optionID) => {

        // props.setListOptionstandard(prev => prev.filter(item => item.id !== optionID))
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-binding/${props.values.id}/${optionID}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            const result = await res.text();
            console.log('Delete successful:', result);
    
            // Update the state to remove the deleted option
            props.setListOptionstandard(prev => prev.filter(item => item.id !== optionID));
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }

    const handleChangeSelecttype = (e, optionID) => {
        let status = e.target.checked === true ? true : false
        props.setListOptionstandard((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        selecttype: el.selecttype = status,
                    }
                    : el,
            ),
        )
    }

    const [modaledit, setModaledit] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:340,
        pin:false,
        mode:'normal',
        modalname:"modal_edit_option_product"
    });
 

    return (
        <div className="px-5 py-4">
            <div className="row">
                <div className="col-xl-6 col">

                    <div className="mb-2 mt-4">
                        <button type="button" className="btn btn-st-3 mb-4" 
                        onClick={()=>{
                            setModaledit( (prev) => ({...prev,open:true}) )
                        }}
                        >
                            เพิ่มตัวเลือกมาตราฐาน
                        </button>
                    </div>
                </div>
            </div>
            <div className="ms-xl-4 mt-2 ps-2">
                {props?.listoptionstandard &&
                props?.listoptionstandard?.map((item, index) => (
                    <div className="row gx-4 align-items-center mb-5" key={index}>
                        <div className="col-xl-6 col-6">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">ตัวเลือกมาตราฐาน</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="note"
                                    className="form-control"
                                    placeholder="ตัวเลือกมาตราฐาน"
                                    value={item.name}
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-xl-4 col-6">
                            <Form.Check
                                type="switch"
                                id={`custom-switch-stardard${index}`}
                                label="เลือกได้มากกว่า 1 รายการ"
                                name={`selecttype[${index}]`}
                                className="custom-switch-st-1 tc-10  s1 mb-1"
                                defaultChecked={item.selecttype}
                                onChange={(e) => handleChangeSelecttype(e, item.id)}
                            />
                        </div> 
                        <div className="col-xl-2 col-6">
                            <button type="button" className="btn  btn-st-3"
                                    onClick={() => handleDeleteoptionList(item.id)}
                                    style={{ background: '#F95057', borderColor: '#F95057' }}>ลบตัวเลือกสินค้า
                            </button>
                        </div>

                        {item.product_option_detail.map((item2, index2) => (
                            <div className="col-xl-6 row gx-4 align-items-center" key={index2}>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="listName"
                                            className="form-control"
                                            placeholder="รายการ"
                                            value={item2.name}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-auto">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ราคา</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="note"
                                            className="form-control"
                                            placeholder="ราคา"
                                            value={item2.price}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
  
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <ModalEditOption
                modal={modaledit}
                setModal={setModaledit}
                optionstandard={optionstandard}
                setOptionstandard={setOptionstandard}
                setListOptionstandard={props.setListOptionstandard}
                values = {props.values.id}
    
            />
        </div>
    )
}

const ModalEditOption = ({modal,setModal,optionstandard,setOptionstandard,setListOptionstandard, values,...props}) => {

    const [optionselected, setOptionselected] = useState([])
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const handleChangeOptionStandard = (options) => {
        setOptionselected(options)
    }

    const handleCreateoption = async (optionselected) => {
        const product_id = values
        const option_id = optionselected.id
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-binding/${product_id}/${option_id}`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }

    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"เพิ่มตัวเลือกมาตราฐาน"} 
                hbtn={true}
            >
                <div className='px-4'>
                    <div className="text-end pb-4 pt-5 mt-5 pe-3">
                        
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อรายการ <span className="text-danger">*</span></Form.Label>
                            <Select as="select"
                                    name="storeH"
                                    onChange={(e) => {
                                        handleChangeOptionStandard(e)
                                    }}
                                    className="w-100 select-st-1 text-start "
                                    options={optionstandard}
                                    getOptionLabel ={(option)=>option.name}
                                    getOptionValue ={(option)=>option.id}
                                    placeholder="ชื่อรายการ"
                                    
                                    value={optionstandard.filter(
                                        i => optionselected.id == i.id,
                                    )
                                    }
                            />
                        </Form.Group>
        
                    </div>
                    <div className="text-end pb-4 pt-4 pe-3">
                        

                        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                            <Button
                                className="btn btn-st-6 me-3 bg-transparent"
                                onClick={() => setModal( (prev) => ({...prev,open:false}) )}
                            >
                                ยกเลิก
                            </Button>
                            <Button type="button" className="btn-st-3 "
                                onClick={() => {

                                    setListOptionstandard(prev => [...prev,optionselected])
                                    handleCreateoption(optionselected)
                                    setModal( (prev) => ({...prev,open:false}) )
                                }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </div>
        </ModalAllder>
    )
}