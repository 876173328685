import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DownloadExcel from "utils/DownloadExcel";
import DataTableLayout from "layouts/DatatableLayout";
import UserApi from "services/branch/UserApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";

const EmpList = () => {
  const [btnmenu, setBtnmenu] = useState(1);
  const { branchID } = useParams();
  const [empdata, setEmpdata] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
      "branch-id": branchID,
    },
  };

  const getempData = () => {
    UserApi.GetAll(config).then((res) => {
      const data = res.data;
      console.log("🚀 ~ UserApi.GetAll ~ data:", data)
      // loop for get full name
      data.map((item) => {
        item.businessSlug = slug;
        item.branchID = branchID;
        item.permissionID = item.id;
        item.empCode = item.employee.code;
        item.empName = item.employee.first_name_th + " " + item.employee.last_name_th;
        item.position = item.employee.department.name_th;
        item.permission = item.role.name;
      });
      setEmpdata(data);

    });
  };

  useEffect(() => {
    if (user.businessId && token && branchID) {
      getempData();
    }
  }, [user.businessId, token]);

  const filteredItems = empdata.filter(
    (item) =>
      item.empName &&
      item.empName.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: "รหัสพนักงาน",
      selector: (row) => row.empCode,
      width: "150px",
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.empCode}</div>
      ),
    },
    {
      name: "ชื่อพนักงาน",
      selector: (row) => row.positionName,
      cell: (row, index, column, id) => <div>{row.empName}</div>,
    },
    {
      name: "ตำแหน่ง",
      selector: (row) => row.position,
    },
    {
      name: "สิทธิ์การใช้งาน",
      selector: (row) => row.permission,
      cell: (row, index, column, id) =>
        row.permission == "แอดมินธุรกิจสูงสุด" ? (
          <div
            className="py-1 px-3"
            style={{
              color: "#565DFF",
              background: "#EEF4FF",
              borderRadius: "8px",
            }}
          >
            {row.permission}
          </div>
        ) : (
          <div className="ps-3">{row.permission}</div>
        ),
    },
    {
      name: "จัดการ",
      selector: (row) => row.permissionID,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div>
          <Link
            className="btn btn-edit-st-1 me-2"
            to={`/${row.businessSlug}/branch/setting/emp/${row.branchID}/manage/${row.permissionID}`}
          ></Link>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "30px 33px 33px 31px" }}>
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการพนักงาน </div>

      <div className="mb-3">
        <button
          className={`btn btn-category me-3 ${btnmenu == 1 ? "active" : ""} `}
          onClick={() => setBtnmenu(1)}
        >
          จัดการพนักงาน
        </button>

        <button
          className={`btn btn-category ${btnmenu == 2 ? "active" : ""}`}
          onClick={() => setBtnmenu(2)}
        >
          จัดการสิทธิระดับสาขา
        </button>
      </div>
      {/* {
            btnmenu == 1 ? (
                <Emp />
            ) : ''
        } */}
      <div>
        <div className="card card-st-2">
          <div className="card-body px-0 py-4">
            <div className="d-flex align-items-center px-4 mb-4">
              <DownloadExcel data={filteredItems} filename="จัดการพนักงาน" />
              <div className="me-3">
                <input
                  type="text"
                  className="form-control form-st-4"
                  placeholder="ค้นหา ชื่อ, รหัสพนักงาน"
                  onKeyUp={(e) => setFilterText(e.target.value)}
                />
              </div>
              <Link
                to={`/${slug}/branch/setting/emp/${branchID}/manage`}
                className="btn btn btn-st-7 text-decoration-none"
              >
                ดึงข้อมูล
              </Link>
            </div>

            <DataTableLayout
              columns={columns}
              item={filteredItems}
              isSelectRows={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpList;
