import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import KitchenApi from "services/KitchenApi";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import ModalSuccess from "layouts/ModalSuccess";

const initialValues = {
  name: "",
  productSelectType: 2,
};

const KitchenForm = () => {
  const { branchID, kitchenID } = useParams();
  const { token } = useToken();
  const [formdata, setFormdata] = useState({});
  const { user } = useRecoilValue(authState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { slug } = useRecoilValue(appState);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(),
    productSelectType: yup.number().required(),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const getKitchenInfo = () => {
    KitchenApi.GetBranchKitchenById(branchID, kitchenID, config).then(
      (res) => {
        // add productSelectType to res.data
        res.data.productSelectType = 2;
        setFormdata(res.data);
      },
      (error) => {
        console.log(
          "🚀 ~ file: KitchenForm.jsx ~ line 69 ~ KitchenApi.GetBranchKitchenById ~ error",
          error
        );
      }
    );
  };

  useEffect(() => {
    kitchenID ? getKitchenInfo() : setFormdata(initialValues);
  }, [kitchenID, branchID, user.businessId]);

  const handleSubmit = async (values) => {
    // add kitchen branch_id
    // form data branch_id format int32
    setLoading(true);
    const data = {
      ...values,
      branch_id: parseInt(branchID),
      printer_name: "printer",
    };

    // if kitchenID is not null, update kitchen
    if (kitchenID) {
      KitchenApi.UpdateBranchKitchens(branchID, kitchenID, data, config).then(
        (res) => {
          setLoading(false);
          setModalsuccessShow(true);
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      KitchenApi.CreateBranchKitchens(branchID, data, config).then(
        (res) => {
          setModalsuccessShow(true);
          setTimeout(() => {
            navigate(`/${slug}/branch/setting/kitchen/${branchID}`);
            setLoading(false);
          }, 2000);
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className="card card-st-2 mb-4">
      <div className="card-body px-0 py-4">
        <Formik
          enableReinitialize
          initialValues={formdata}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
            isInvalid,
            isValid,
          }) => (
            <Form className="px-4" noValidate onSubmit={handleSubmit}>
              <div className="d-flex align-items-center  mb-4">
                <div className="tc-7 fs-20 fw-6   me-auto">
                  {kitchenID ? "แก้ไข" : "เพิ่ม"}ครัว
                </div>
                <Button
                  type="submit"
                  className="btn-st-3  "
                  style={{ width: "100px" }}
                  disabled={isInvalid || !isValid || loading}
                >
                  {" "}
                  บันทึก
                </Button>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">
                      ชื่อห้องครัว <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="กรอกชื่อห้องครัว"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      disabled={loading}
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-xl-6">
                  <Form.Group className={`mb-2 form-st-3 pb-1`}>
                    <Form.Label className="mb-1">
                      คลังสินค้าที่เชื่อม <span className="text-danger">*</span>
                    </Form.Label>
                    <SelectStoreHouse
                      handleChange={(e) => {
                        setFieldValue("storeHouse", e.value);
                      }}
                      selectedID={values.storeHouse}
                      errors={errors}
                    />
                  </Form.Group>
                </div> */}
              </div>

              <Form.Label className="mb-2  tc-10">
                กำหนดรายการอาหารที่จะปรากฏในหน้าจอครัว
              </Form.Label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  {/* <Form.Check
                    inline
                    label="ทุกรายการอาหาร"
                    name="productSelectType"
                    type={type}
                    id={`inline-${type}-21`}
                    className="form-check-st-2 tc-7"
                    checked={values.productSelectType === 1}
                    onChange={(e) => {
                      setFieldValue("productSelectType", 1);
                    }}
                  /> */}
                  <Form.Check
                    inline
                    label="กำหนดรายการเอง"
                    name="productSelectType"
                    type={type}
                    id={`inline-${type}-22`}
                    className="form-check-st-2 tc-7"
                    checked={values.productSelectType === 2}
                    onChange={(e) => {
                      setFieldValue("productSelectType", 2);
                    }}
                    isInvalid={!!errors.productSelectType}
                    disabled={loading}
                  />
                </div>
              ))}
            </Form>
          )}
        </Formik>
      </div>
      <ModalSuccess
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
        message="บันทึกข้อมูลครัวสำเร็จ"
      />
    </div>
  );
};

export default KitchenForm;
