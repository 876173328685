import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAllBranchByBussiness = (config) =>
  axiosClient.get(`/back-office-dashboard/branch`, config);

const GetAllPosByBusiness = (config, branchId) =>
  axiosClient.get(`/pos/branch/${branchId}`, config);

const GetReport = (config, data) =>
  axiosClient.post(
    `/back-office-info-dashboard`,
    data,
    config
  );

export default {
    GetAllBranchByBussiness,
    GetAllPosByBusiness,
    GetReport
};