import { utils, writeFileXLSX } from 'xlsx';

export default function downloadExcel ({data,filename}) {
    const download = (data,filename) =>  {
        if(data){
            const ws = utils.json_to_sheet(data);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, filename);
            writeFileXLSX(wb, filename+".xlsx"); 
        }
    };

    return (
        <button className='btn btn-export-st-1 me-auto' onClick={() => download(data,filename)} >Export</button>
    )
}