import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'

export default function SelectCategory ({ setFilterText, ...props }) {
    const PRODUCT_CATEGORY_URL = `${process.env.REACT_APP_API_URL}/business-product-category`

    const { user } = useRecoilValue(authState)
    const { token } = useToken()
    const [productCategoryData, setProductCategoryData] = useState(undefined)
    const [optionsCategory, setOptionsCategory] = useState([])

    const getProductCategoryData = async () => {
        try {
            const res = await fetch(PRODUCT_CATEGORY_URL, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                })
            })
            const data = await res.json()
            setProductCategoryData(data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    useEffect(() => {
        getProductCategoryData()
    }, [])

    useEffect(() => {
        if (productCategoryData !== undefined && productCategoryData.length > 0) {
            const options = []
            const initialObj = {
                'label': 'ทั้งหมด',
                'value': ''
            }
            options.push(initialObj)
            for (let i = 0; i < productCategoryData.length; i++) {
                const obj = {}
                obj['value'] = productCategoryData[i].id
                obj['label'] = productCategoryData[i].name
                options.push(obj)
            }
            setOptionsCategory(options)
        }
    }, [productCategoryData])

    return (
        <Select
            as="select"
            name="caregory"
            onChange={(e) => {
                (e.label !== 'ทั้งหมด' ? setFilterText(e.label) : setFilterText(''))
            }}
            className="w-100 select-st-1 pd"
            options={optionsCategory}
            placeholder="เลือกหมวดหมู่"
        />
    )
}