import React from 'react';
import DataTable from 'react-data-table-component';
import Datatablenoresults from "layouts/Datatablenoresults"
import * as styleTable from 'styles/datatable';
import CheckboxTable from 'fragments/CheckboxTable';
export default function DataTableLayout({item,handleRowSelected,columns,isSelectRows,PerPage = 10,...props}) {

    return (
        <DataTable
            columns={columns}
            data={item}
            customStyles={styleTable.customStyles}
            selectableRows={isSelectRows}
            selectableRowsComponent={CheckboxTable}
            pagination
            paginationComponentOptions={styleTable.paginationComponentOptions}
            onSelectedRowsChange={handleRowSelected}
            noDataComponent={ <Datatablenoresults  />}
            persistTableHead={true}
            paginationPerPage={PerPage}
            paginationRowsPerPageOptions={[5,10, 25, 50, 100]}
            
        />
    )


}