
import React, { useState  } from 'react';
import {useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Cashpaymentsetting(){

    const { branchID } = useParams();


    const [paymentsetting, setPaymentsetting] = useState({
        "branchID":branchID,
        "paymentChannelID":1,
        "status":1
    });

    const handleChange = (e) => {
        if(e.target.checked === true){
            setPaymentsetting((prev) => ({  ...prev,status:1 }) );
        }else{
            setPaymentsetting((prev) => ({  ...prev,status:0 }) );
        }  

    }
    
    const handleSubmit = () => {
        console.log(paymentsetting)
    }
    
    return (
        
        <div>
            <Form.Check 
                type="switch"
                id="cashpayment"
                label='ตั้งค่าการชำระเงิน'
                name='cashpayment'
                className='custom-switch-st-1 tc-10  s1 mb-1'
                defaultChecked={paymentsetting.status === 1}
                onChange={(e) => {
                    handleChange(e)
                }}
            />

            <div className="text-end mt-5  pt-5 pb-2 btn-group-control" >
                <Button type="submit" className='btn-st-3  ' style={{width:'100px'}} onClick={handleSubmit}>  บันทึก</Button>
            </div>

            
        </div>
    )
}