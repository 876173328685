import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAllBranchByBussiness = (config) =>
  axiosClient.get(`/branch-info`, config);

export default {
    GetAllBranchByBussiness,
};