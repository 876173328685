
import React, { useState,useEffect  } from 'react';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import { utils, writeFileXLSX } from 'xlsx';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import {Link,useParams,useNavigate} from "react-router-dom";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import DataTableLayout from "layouts/DatatableLayout";
import  DownloadExcel from 'utils/DownloadExcel';

export function  EmpSetting(){
    const [btnmenu, setBtnmenu] = useState(1);
    return (
        <div style={{padding: '30px 33px 33px 31px'}}>

            <div className="fs-24 tc-7  mb-4 lh-1">จัดการพนักงาน </div>

            <div className='mb-3'>
                <button className={`btn btn-category me-3 ${btnmenu == 1 ? 'active' : '' } `}  
                onClick={() => setBtnmenu(1) }
                >จัดการพนักงาน</button>

                <button className={`btn btn-category ${btnmenu == 2 ? 'active' : ''}` }
                onClick={() => setBtnmenu(2) }
                >จัดการสิทธิระดับสาขา</button>

            </div>
            {
                btnmenu == 1 ? (
                    <Emp />
                ) : ''
            }
            
            
        </div>
    )
}

function  Emp() {

    const { branchID } = useParams();
    const [empdata, setEmpdata] = useState([]);
    const getempData = () => {

        fetch('/json/empbusiness.json')
          .then((response) => response.json())
          .then((result) => setEmpdata(result) )
          .catch((error) => console.log("error", error));
      };
    useEffect(() => {
        getempData();
    
    }, []);

    const columns = [
        {
            name: 'รหัสพนักงาน',
            selector: row => row.empCode,
            width:"150px",
            cell: (row, index, column, id) => <div style={{color:"#565DFF",fontWeight:500}}>{row.empCode}</div>,
        },
        {
            name: 'ชื่อพนักงาน',
            selector: row => row.positionName,
            cell: (row, index, column, id) => <div>{row.empName}</div>,
        },
        {
            name: 'ตำแหน่ง',
            selector: row => row.position,
  
        },
        {
            name: 'สิทธิ์การใช้งาน',
            selector: row => row.permission,
            cell: (row, index, column, id) =>  row.permission == 'แอดมินธุรกิจสูงสุด' ? <div className='py-1 px-3' style={{color:'#565DFF',background: '#EEF4FF',borderRadius: '8px'}}>{row.permission}</div> :  <div className='ps-3'>{row.permission}</div>,
  
        },
        {
            name: 'จัดการ',
            selector: row => row.permissionID,
            center:'true',
            width:"150px",
            cell: (row, index, column, id) => <div><Link className='btn btn-edit-st-1 me-2' to={`/branch/setting/emp/${branchID}/manage/${row.permissionID}`}  ></Link></div>,
        }
    ];

    const [filterText, setFilterText] = useState('');
	const filteredItems = empdata.filter(
        item => item.empName && item.empName.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div>
            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className='d-flex align-items-center px-4 mb-4'>
                        <DownloadExcel data={filteredItems} filename="จัดการพนักงาน" />
                        <div className='me-3'>
                            <input type='text' className='form-control form-st-4' placeholder='ค้นหา ชื่อ, รหัสพนักงาน' onKeyUp={e => setFilterText(e.target.value)} />
                        </div>
                        <Link to={`/branch/setting/emp/${branchID}/manage`} className='btn btn btn-st-7 text-decoration-none' >ดึงข้อมูล</Link>
                    </div>

                    <DataTableLayout 
                        columns={columns}
                        item={filteredItems}
                        isSelectRows={true}
                    />
                    
                </div>

                
            </div>

        </div>
    )
}

const getbranchData = () => {
    const options = [];
    fetch('/json/branch.json')
      .then((response) => response.json())
      .then((result) => {
        

        for(var i = 0; i < result.length; i++) {
            var obj = {};
            obj['value'] = result[i].branchID;
            obj['label'] = result[i].branchName;
            options.push(obj);
        }
      } )
      .catch((error) => console.log("error", error));

      return options;
};

const getpermissionData = () => {
    const options = [];
    fetch('/json/permission.json')
      .then((response) => response.json())
      .then((result) => {
        

        for(var i = 0; i < result.length; i++) {
            var obj = {};
            obj['value'] = result[i].permissionID;
            obj['label'] = result[i].permissionName;
            options.push(obj);
        }
      } )
      .catch((error) => console.log("error", error));

      return options;
};

const getEditData = (productID) => {
    return  fetch('/json/permission3.json')
      .then((response) => response.json())
      .then((result) => {
        return result
      } )
      .catch((error) => console.log("error", error));
};
export function  EmpManage() {

    const navigate = useNavigate();

    const { branchID,permissionID } = useParams();

    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);

    const [modalsuccessShow, setModalsuccessShow] = useState(false);
    const [optionsPermission, setOptionsPermission] = useState([]);
    const [empdata,setEmpdata] = useState([]);

    const schema = yup.object().shape({
        empCode: yup.string().required(),
        empName: yup.string().required(),
        empEmail: yup.string().required(),
        pin: yup.string()
        .when("usePin", (usePin, schema) => {
            if(usePin == 1) 
              return schema.required()
            return schema
        }),
        pinConfirm: yup.string()
        .when("usePin", (usePin, schema) => {
            if(usePin == 1) 
              return schema.oneOf([yup.ref('pin'), undefined], 'Passwords must match').required()
            return schema
        }),
    })

    const [formdisabled, setFormdisabled] = useState(false);
    const [pdata, setPdata] = useState(
        {
            "empID": "",
            "empCode": "",
            "empName": "",
            "empEmail": "",
            "permission": "",
            "usePin": "",
            "pin":""
        }
    );
    
    const getempData = async () => {
        try {
            const response = await fetch('/json/emp.json');
            const result_1 = await response.json();
            setEmpdata(result_1);
        } catch (error) {
            return console.log("error", error);
        }
    };

    useEffect(() => {
        setOptionsPermission(getpermissionData());
        getempData();
        if(permissionID){
            getEditData(permissionID).then(response => { 
                setPdata(response) 
                setFormdisabled(true)
            } )
        }
    }, [permissionID]);

    const handleOnSelect = (item) => {
        setPdata((prev) => ({  ...prev,empID:item.empID,empCode:item.empCode,empName:item.empName,empEmail:item.email }) );
    }
    
    const formatResult = (item) => {
        return (
          <div className='d-flex align-items-center' key={item.empID+item.empCode}>
            <div className='me-2 fs-14' style={{ display: 'block', color: '#565DFF' }}>{item.empCode}</div>
            <div>{item.empName}</div>
          </div>
        )
      }

    return (
        <div style={{padding: '30px 33px 33px 31px'}}>


            <div className="card card-st-2 mb-4">
                <div className="card-body px-xl-4 py-4">
                
                    <Formik
                    enableReinitialize
                    initialValues={ pdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        console.log(values)
                        navigate('/branch/setting/emp/1')
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form className=' ' noValidate  onSubmit={ (e) => {
                        e.preventDefault();
                        handleSubmit()
                    } }>
                        <div className='d-flex align-items-center  mb-4 '>
                            <div className='fs-18 fw-bold me-auto' style={{color:"#565DFF"}}> { permissionID ? 'แก้ไขข้อมูลพนักงาน' : 'ดึงข้อมูลพนักงาน'}</div>

                        </div>
                        <div className=''>
                        <div className='row'>
                        <div className='col-12'>
                            <fieldset disabled={formdisabled}>
                                <div className='row'>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-3 form-st-3 pb-1">
                                            <Form.Label className="mb-1" style={{zIndex:'12'}} >ค้นหาพนักงาน  </Form.Label>
                                            <ReactSearchAutocomplete
                                                items={empdata}
                                                placeholder="ค้นหาพนักงาน"
                                                onSelect={handleOnSelect}
                                                autoFocus
                                                formatResult={formatResult}
                                                fuseOptions={{ keys: ["empID","empCode", "empName"] }}
                                                resultStringKeyName="empName"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">รหัสพนักงาน  <span className='text-danger'>*</span> </Form.Label>
                                            <Form.Control
                                            type="text" 
                                            name="empCode" 
                                            className="form-control" 
                                            placeholder="รหัสพนักงาน"
                                            value={values.empCode}
                                            onChange={handleChange}
                                            isInvalid={!!errors.empCode}
                                            disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-2 mt-3 form-st-3 pb-1  ">
                                            <Form.Label className="mb-1">ชื่อพนักงาน</Form.Label>
                                            <Form.Control
                                                type="text" 
                                                name="empName" 
                                                className="form-control" 
                                                placeholder="ชื่อพนักงาน"
                                                value={values.empName}
                                                onChange={handleChange}
                                                isInvalid={!!errors.empName} 
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">E-mail </Form.Label>
                                            <Form.Control
                                            type="text" 
                                            name="empEmail" 
                                            className="form-control" 
                                            placeholder="E-mail "
                                            value={values.empEmail}
                                            onChange={handleChange}
                                            isInvalid={!!errors.empEmail}
                                            disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className='col-xl-3 align-self-center'>
                                        <div style={{width:'160px'}}>
                                        <Form.Check 
                                            type="switch"
                                            id="custom-switch"
                                            label="เปิดใช้งาน PIN"
                                            name='usePin'
                                            className='custom-switch-st-1 tc-10 s2 mb-1'
                                            defaultChecked={values.usePin}
                                            checked={values.usePin === 1} 
                                            onChange={(e) => {
                                                if(e.target.checked === true){
                                                    setFieldValue("usePin",1)
                                                }else{
                                                    setFieldValue("usePin",0)
                                                } 
                                            }}
                                        />
                                        </div>
                                    </div>
                                    <div className='col-xl-5'>
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">รหัส PIN 4 หลัก <span className='text-danger'>*</span> </Form.Label>
                                            <Form.Control
                                                type="text" 
                                                name="pin" 
                                                className="form-control" 
                                                placeholder="รหัส PIN 4 หลัก"
                                                value={values.pin}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pin}
                                                disabled={values.usePin != 1}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ยืนยันรหัส PIN 4 หลัก <span className='text-danger'>*</span>   </Form.Label>
                                            <Form.Control
                                                type="text" 
                                                name="pinConfirm" 
                                                className="form-control" 
                                                placeholder="ยืนยันรหัส PIN 4 หลัก"
                                                value={values.pinConfirm}
                                                onChange={handleChange}
                                                isInvalid={!!errors.pinConfirm}
                                                disabled={values.usePin != 1}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className='card card-st-1 mt-4 mb-4'>
                                    <div className='card-body px-0'>
                                        <div className='fs-18 px-3 fw-bold me-auto mb-3' style={{color:"#565DFF"}}>สิทธิการเข้าถึง</div>
                                        <table className='table table-st-1'>
                                            <thead>
                                                <tr>
                                                    <th>ตำแหน่ง</th>
                                                    <th className='text-center'>ครัว</th>
                                                    <th className='text-center'>แคชเชียร์</th>
                                                    <th className='text-center'>พนักงานเสริฟ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Back - Office</td>
                                                    <td><div className='icon-p1'></div></td>
                                                    <td><div className='icon-p1'></div></td>
                                                    <td><div className='icon-p1'></div></td>
                                                </tr>
                                                <tr>
                                                    <td>POS</td>
                                                    <td>
                                                        <div className='icon-p2'></div>
                                                        <div className='text-center fs-12'>หน้าจอครัว</div>
                                                    </td>
                                                    <td><div className='icon-p2'></div></td>
                                                    <td><div className='icon-p1'></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile</td>
                                                    <td><div className='icon-p1'></div></td>
                                                    <td><div className='icon-p1'></div></td>
                                                    <td><div className='icon-p2'></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-4'>
                                        <Form.Group className="mb-3 form-st-3 pb-1">
                                            <Form.Label className="mb-1">สิทธิผู้ใช้งานระดับสาขา  </Form.Label>
                                            <Select as="select"
                                                name="permission"
                                                onChange={(e) => {
                                                    setFieldValue("permission", e.value)
                                                }}
                                                className="w-100 select-st-1"
                                                options={optionsPermission}
                                                placeholder="ค้นหาสิทธิผู้ใช้งาน"
                                                value={
                                                    optionsPermission.filter(
                                                        item => item.value === values.permission
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        </div>
                        </div>

                        <div className="text-end pb-4 pt-4 pe-3">
                            {
                                formdisabled === true ? (
                                    <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { setFormdisabled(false) }}  >แก้ไข</Button>
                                ) : (
                                    <div className=" pt-3 d-flex btn-group-control w-100" >
                                        {
                                            permissionID ? (
                                                <Button type="button" className='btn-st-5 me-auto ' style={{width:'80px'}} 
                                                onClick={ () => setModalDeleteShow(true) } >นำออกจากข้อมูล</Button>
                                            ) : <div className='me-auto'></div>
                                        }
                                        
                    
                                        <Link to={`/branch/setting/emp/${branchID}`}  className='btn-st-6 me-3 btn text-decoration-none '>ยกเลิก</Link>
                                        <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                                    </div>
                                )
                            }
                        </div>

                        <ConfirmDeleteModal
                            show={modalDeleteShow}
                            onHide={() => setModalDeleteShow(false)}
                            onConfirm={() => setModalDeleteShow(false)}
                            onSuccess={() => {
                                setModalsuccessShow(true)
                                setDeleteStatus(true)
                            }}
                            pdata={pdata}
                            
                        />
                        <SuccessDeleteModal
                            show={modalsuccessShow}
                            onHide={() => setModalsuccessShow(false)}
                            modalObj={{
                                modalsuccessShow,
                                setModalsuccessShow,
                                deleteStatus
                            }}
                        />

                    </Form>
                    )}
                    </Formik>
                    
                </div>
            </div>
            


        </div>    
    )
}

function ConfirmDeleteModal(props) {

    const handleDelete = (permissionID) => {
        props.onHide();
        props.onSuccess();
    }

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='px-3 py-4'>
                <div>
                    <div>
                        <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                        <div className='text-center fs-22 tc-7'>สิทธิผู้ใช้งานนี้ใช่หรือไม่</div>
                    </div>
                    <div className="text-center pt-4 mt-3  btn-group-control" >
                        <Button type="button" className='btn-st-3 mx-3  ' style={{width:'100px'}}  onClick={ () => handleDelete(props.pdata.permissionID)  }>ใช่</Button>
                        <Button type="button" className='btn-st-6 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ไม่ใช่</Button>
                    </div>
                </div>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function SuccessDeleteModal({modalObj,...props}) {
    const navigate = useNavigate();
    useEffect(() => {
        if(!modalObj.modalsuccessShow && modalObj.deleteStatus){
            navigate('/branch/setting/emp/1')
        }
    }, [modalObj.modalsuccessShow]);

    return (
      <Modal
        {...props}
        size="sm"
        centered
      >
        <Modal.Body>
            <div className='px-3 py-4'>
                <div className='text-center mb-4'>
                    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                    <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className='text-center fs-22 tc-7'>ลบรายการสำเร็จ</div>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
{
 return(
     <>
         <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
             <input 
                 type="checkbox"
                 className="form-check-input"
                 style={{ height: '18px', width: '18px' }}
                 ref={ref}
                 onClick={ onClick }
                 {...rest}
             />
             <label className="form-check-label" id="booty-check" />
         </div>
     </>
 )
})