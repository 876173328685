import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Form from 'react-bootstrap/Form'
import { utils, writeFileXLSX } from 'xlsx'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import 'react-datepicker/dist/react-datepicker.css'
import { ModalAllder } from 'layouts/ModalAllder'
import { WithContext as ReactTags } from 'react-tag-input'
import { useRecoilValue } from 'recoil'
import { authState } from '../../state/auth'
import useToken from '../../utils/UseToken'
import { appState } from '../../state/app'
import { getCurrentDate } from '../../utils/dateFormat'
import { nanoid } from 'nanoid'

export function Optionnote () {
    // const OPTION_REMARK_URL = `${process.env.REACT_APP_API_URL}/business-product/remark-options`
    const OPTION_REMARK_URL = `${process.env.REACT_APP_API_URL}/business-product-remark-options`

    const { user } = useRecoilValue(authState)
    const { token } = useToken()
    const { slug } = useRecoilValue(appState)

    const [optiondata, setOptiondata] = useState([])

    const getData = () => {
        fetch('/json/optionnote.json')
            .then((response) => response.json())
            .then((result) => setOptiondata(result))
            .catch((error) => console.log('error', error))
    }

    const getOptionRemarkData = async () => {
        if (user.businessId) {
            try {
                const res = await fetch(OPTION_REMARK_URL, {
                    headers: new Headers({
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'business-id': user.businessId,
                    }),
                })
                const data = await res.json()
                setOptiondata(data)
                // setOnSuccess(false)
            } catch (error) {
                console.log('Failed to get data:', error)
            }
        } else {
            console.log('Error: User ID not found.')
            return []
        }
    }

    useEffect(() => {
        getOptionRemarkData()
        // getData()
    }, [user.businessId])

    // console.log('Option Remark Data:', optiondata)

    const columns = [
        // {
        //     name: 'รหัสหมายเหตุ',
        //     selector: row => row.code,
        //     width: '160px',
        // },
        {
            name: 'ชื่อตัวเลือกหมายเหตุ',
            selector: row => row.name,
            cell: (row, index, column, id) => <div style={{ color: '#565DFF', fontWeight: 500 }}>{row.name}</div>,
            width: '25%',
        },
        {
            name: 'รายการ',
            selector: row => row.product_option_detail,
            cell: (row, index, column, id) =>
                <div>
                    {row.product_option_detail.map((item, index) => (
                        index === 0 ? (
                            <span key={index}>{item.name}</span>
                        ) : (
                            <span key={index}><span className="pr-1">,</span>{item.name}</span>
                        )
                    ))}
                </div>,
        },
        {
            name: 'สถานะ',
            selector: row => row.is_active,
            center: 'true',
            cell: (row, index, column, id) => <div className={`datalist-status status${row.is_active === true ? '1' : '0'}`} />,
            width: '160px',
        },
        {
            name: 'จัดการ',
            selector: row => row.id,
            center: 'true',
            width: '160px',
            cell: (row, index, column, id) => <div>

                <Link
                    to={`/${slug}/product/options/note/edit/${row.id}`}
                    className="btn btn-edit-st-1"
                />
            </div>,
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                background: ' #F5F5F7',
                paddingTop: '15px',
                paddingBottom: '15px',

            },
        },
        cells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                fontWeight: '300',
                paddingTop: '10px',
                paddingBottom: '10px',
            },
        },
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'จำนวนรายการต่อหน้า',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const [filterText, setFilterText] = useState('')
    const filteredItems = optiondata.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    )

    const downloadExcel = () => {
        const ws = utils.json_to_sheet(filteredItems)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'รายชื่อสาขา')
        writeFileXLSX(wb, 'รายชื่อสาขา.xlsx')
    }

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li className="breadcrumb-item "><a href="#"
                                                            className="tc-7  text-decoration-none">สินค้าและบริการ</a>
                        </li>
                        <li className="breadcrumb-item active tc-1 " aria-current="page"> จัดการตัวเลือกหมายเหตุ</li>
                    </ol>
                </nav>

            </div>

            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className="d-flex align-items-center px-4 mb-4">
                        <button className="btn btn-export-st-1 me-auto" onClick={downloadExcel}>Export</button>
                        <div className="me-3">
                            <input type="text" className="form-control form-st-4" placeholder="ค้นหาตัวเลือกหมายเหตุ"
                                   onKeyUp={e => setFilterText(e.target.value)}/>
                        </div>

                        <Link
                            to={`/${slug}/product/options/note/create`}
                            className="btn  btn-st-7"
                        >
                            เพิ่มตัวเลือกหมายเหตุ
                        </Link>
                        {/* <button className='btn btn btn-st-7'  onClick={() => setModalShow(true)} >เพิ่มตัวเลือกหมายเหตุ</button> */}
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    />

                </div>
            </div>


        </div>
    )
}

const KeyCodes = {
    comma: 188,
    enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

export function OptionNoteCreate () {
    // const OPTION_REMARK_URL = `${process.env.REACT_APP_API_URL}/business-product/remark-options`
    const OPTION_REMARK_URL = `${process.env.REACT_APP_API_URL}/business-product-remark-options`

    const navigate = useNavigate()
    const newId = nanoid()
    const { slug } = useRecoilValue(appState)
    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [modalShow, setModalShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})
    const [optionCreateListData, setOptionCreateListData] = useState([])

    const [optionCreateData, setOptionCreateData] = useState({
        'name': '',
        'is_active': true,
        'is_show': true,
        'created_at': new Date(),
        'code': '',
        'remark_details': [{
            'name': '',
        }],
        'created_by': user.accountId,
        'modified_by': user.accountId,
        'creator_name': `${user.firstNameEN} ${user.lastNameEN}`,
    })

    const schema = yup.object().shape({
        name: yup.string().required(),
        is_active: yup.boolean().required(),
        is_show: yup.boolean().required(),
        created_at: yup.string().required(),
        // code: yup.string().required(),
        remark_details: yup.array().of(yup.object().shape({
            name: yup.string().required(),
        })),
        created_by: yup.string().required(),
    })

    const addHandler = () => {
        setOptionCreateListData(optionList => [...optionList, {
            'name': '',
        }])
    }

    const deleteHandeler = (removeId) => {
        setOptionCreateListData(optionlist => optionlist.filter(item => item.id !== removeId))
    }

    const postRemarkOptionsData = async (values) => {
        console.log('Create submitted!:', values)

        try {
            const res = await fetch(OPTION_REMARK_URL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to post data:', error)
        }
    }

    useEffect(() => {
        setOptionCreateListData(optionCreateData.remark_details)
    }, [optionCreateData])

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li
                            className="breadcrumb-item "
                        >
                            <a
                                href="#"
                                className="tc-7  text-decoration-none"
                            >
                                สินค้าและบริการ
                            </a>
                        </li>
                        <li className="breadcrumb-item active tc-1 " aria-current="page"> จัดการตัวเลือกมาตราฐาน</li>
                    </ol>
                </nav>
                <div className="card card-st-1 mb-4">
                    <div className="card-body p-4">
                        <div className="fs-22 fw-500 tc-7  pb-2 pt-2 mb-5 lh-1">สร้างตัวเลือกหมายเหตุ</div>
                        <Formik
                            initialValues={optionCreateData}
                            validationSchema={schema}
                            onSubmit={async (values) => {
                                postRemarkOptionsData({
                                    ...values,
                                    created_at: `${values.created_at.toISOString()}`,
                                    option_type_id: 0,
                                    created_by: 0,
                                    modified_by: 0,
                                    // code: 'CODE01'
                                })
                                    .then(() => navigate(`/${slug}/product/options/note`))
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                values,
                                setFieldValue,
                                isInvalid,
                                isValid,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset>
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">ชื่อตัวเลือกหมายเหตุ<span
                                                        className="text-danger">*</span> </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="กรอกชื่อตัวเลือกหมายเหตุ"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.name && touched.name)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/* <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">รหัสตัวเลือกหมายเหตุ<span
                                                        className="text-danger">*</span> </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="code"
                                                        className="form-control"
                                                        placeholder="กรอกรหัสตัวเลือกหมายเหตุ"
                                                        value={values.code}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.code && touched.code)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div> */}
                                        {optionCreateListData !== undefined && optionCreateListData.map((item, index) =>
                                            <OptionCreateList
                                                item={item}
                                                key={item.id}
                                                n={index}
                                                deleteHandeler={() => {
                                                    values.remark_details.splice(index, 1)
                                                    deleteHandeler(item.id)
                                                }}
                                                // settingHandeler={() => {
                                                //     setModalShow(true)
                                                //     setSettingStock(item.stock)
                                                //     setStockindex(index)
                                                // }}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                            />,
                                        )}
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <div className="mb-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-st-3 mb-4"
                                                        onClick={addHandler}
                                                    >
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                                {/* <div className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="แสดงหน้าจอลูกค้า"
                                                        name="is_show"
                                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                                        checked={values.is_show}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setFieldValue('is_show', true)
                                                            } else {
                                                                setFieldValue('is_show', false)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="เปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio1"
                                                        className="form-check-st-2 tc-7"
                                                        checked={values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', true)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="ปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio2"
                                                        className="form-check-st-2 tc-7"
                                                        checked={!values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', false)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="created_at"
                                                                className="form-control"
                                                                placeholder="วันที่สร้าง"
                                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.created_at}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="creator_name"
                                                                className="form-control"
                                                                placeholder="ชื่อผู้สร้าง"
                                                                value={values.creator_name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.creator_name}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="text-end pb-4 pe-3">
                                        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                            <Link
                                                to={`/${slug}/product/options/note`}
                                                className="btn btn-st-20 me-3 bg-transparent"
                                            >
                                                ยกเลิก
                                            </Link>
                                            <Button type="submit" className="btn-st-3 ">
                                                บันทึก
                                            </Button>
                                        </div>
                                        <OptionModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            settingStock={settingStock}
                                            setFieldValue={setFieldValue}
                                            stockindex={stockindex}
                                            deletestock={() => {
                                                values.remark_details[stockindex].stock = null
                                                setModalShow(false)
                                            }}
                                            values={values}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function OptionNoteEdit () {
    const OPTION_NOTE_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product-remark-options`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const { slug } = useRecoilValue(appState)
    const navigate = useNavigate()

    const [optiondata, setOptiondata] = useState({
        'name': '',
        'dateCreate': getCurrentDate('/'),
        'userCreate': 'Admin Pat',
        'is_active': 1,
        'displayCustomer': 0,
        'product_option_detail': [],
    })

    const [optionEditData, setOptionEditData] = useState({
        'name': '',
        'code': '',
        'created_at': getCurrentDate('/'),
        'whos_updated': '',
        'is_active': undefined,
        'is_show': undefined,
        'remark_details': [],
    })

    const [optionEditListData, setOptionEditListData] = useState([])
    const [onSuccess, setOnSuccess] = useState(false)

    const getData = () => {
        setOptionEditData({
            'id': 1,
            'code': 'N0001',
            'name': 'เครื่องดื่ม',
            'product_option_detail': [
                { 'id': '1', 'name': 'น้ำแข็ง 2ก้อน' },
                { 'id': '2', 'name': 'เอาแต่น้ำไม่เอาโซดา' },
            ],
            'displayCustomer': 0,
            'is_active': 1,
            'productQty': 0,
            'dateCreate': '22/01/2023',
            'userCreate': 'Admin Pat',
            'dateEdit': '22/01/2023',
            'userEdit': 'Admin Pat',
        })
        setOptionEditListData([
            { 'id': '1', 'name': 'น้ำแข็ง 2ก้อน' },
            { 'id': '2', 'name': 'เอาแต่น้ำไม่เอาโซดา' },
        ])
    }

    const getOptionEditData = async (id, tokens, users) => {
        try {
            const res = await fetch(`${OPTION_NOTE_EDIT_URL}/${id}`, {
                headers: new Headers({
                    Authorization: `Bearer ${tokens}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': users.businessId,
                }),
            })
            const data = await res.json()
            setOptionEditData(data)
            if (data.product_option_detail) {
                setOptionEditListData(data.product_option_detail)
            }
            
            setOnSuccess(false)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const updateRemarkOption = async (values) => {
        console.log('update submitted!:', values)

        try {
            const res = await fetch(`${OPTION_NOTE_EDIT_URL}/${optionID}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to post data:', error)
        }
    }

    useEffect(() => {
        getOptionEditData( optionID, token, user)
    }, [onSuccess, optionID, token, user])

    // useEffect(() => {
    //     if (onSuccess === true) {
    //         getOptionEditData()
    //     }
    // }, [onSuccess])

    console.log('optionEditData', optionEditData)
    console.log('optionEditListData:', optionEditListData)

    const [formdisabled, setFormdisabled] = useState(false)

    const [optionlist, setOptionlist] = useState([])

    const schema = yup.object().shape({
        name: yup.string().required(),
        created_at: yup.string().required(),
        // code: yup.string().required(),
        whos_updated: yup.string().required(),
        is_active: yup.boolean().required(),
        is_show: yup.boolean().required(),
        remark_details: yup.array().of(yup.object().shape({
            name: yup.string().required(),
        })),
    })

    const [modalShow, setModalShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})
    const [modalDeleteShow, setModalDeleteShow] = useState(false)
    const [modalsuccessShow, setModalsuccessShow] = useState(false)

    const [productOptionDetail, setProductOptionDetail] = useState({
        'name': null,
    })

    const editHandeler = (item) => {
        setProductOptionDetail(item)
        setModaledit((prev) => ({ ...prev, open: true }))
    }
    const [modaledit, setModaledit] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 560,
        height: 380,
        pin: false,
        mode: 'normal',
        modalname: 'modal_edit_option_standard',
    })

    const [modaladd, setModaladd] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 560,
        height: 380,
        pin: false,
        mode: 'normal',
        modalname: 'modal_add_option_standard',
    })

    return (
        <div>
            <div>
                <nav aria-label="breadcrumb" className="mt-2">
                    <ol className="breadcrumb fs-16 ps-0">
                        <li className="breadcrumb-item ">
                            <a href="#" className="tc-7 text-decoration-none">
                                สินค้าและบริการ
                            </a>
                        </li>
                        <li className="breadcrumb-item active tc-1" aria-current="page">จัดการตัวเลือกหมายเหตุ</li>
                    </ol>
                </nav>

                <div className="card card-st-1 mb-4">
                    <div className="card-body p-4">
                        <div className="fs-22 fw-500 tc-7  mb-4 pb-3 lh-1">แก้ไขตัวเลือกหมายเหตุ</div>
                        <Formik
                            enableReinitialize
                            initialValues={optionEditData}
                            // initialValues={optiondata}
                            validationSchema={schema}
                            // onSubmit={async (values) => {
                            //     setFormdisabled(true)
                            //     console.log('OptionEdit Submitted!:', values)
                            // }}
                            onSubmit={async (values) => {
                                const updateData = await updateRemarkOption({
                                    id: values.id,
                                    name: values.name,
                                    is_show: values.is_show,
                                    is_active: values.is_active
                                })
                                if (updateData.status === 200) {
                                    setFormdisabled(true)
                                    navigate(`/${slug}/product/options/note`)
                                }
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                touched,
                                errors,
                                values,
                                setFieldValue,
                                isInvalid,
                                isValid,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset disabled={formdisabled}>
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">
                                                        ชื่อตัวเลือกหมายเหตุ
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={values.name}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.name && touched.name)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/* <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">รหัสตัวเลือกหมายเหตุ<span
                                                        className="text-danger">*</span> </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="code"
                                                        className="form-control"
                                                        placeholder="กรอกรหัสตัวเลือกหมายเหตุ"
                                                        value={values.code}
                                                        onChange={handleChange}
                                                        isInvalid={!!(errors.code && touched.code)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div> */}
                                        {optionEditListData !== undefined && optionEditListData.map((item, index) =>
                                            <OptionList
                                                item={item}
                                                key={item.id}
                                                n={index}
                                                editHandeler={() => {
                                                    editHandeler(item)
                                                }}
                                                settingHandeler={() => {
                                                    setModalShow(true)
                                                    setSettingStock(item.stock)
                                                    setStockindex(index)
                                                }}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                            />,
                                        )}
                                        <div className="row gx-xl-3">
                                            <div className="col-xl-6 col-lg-6 col">
                                                <div className="mb-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-st-3 mb-4"
                                                        onClick={() => setModaladd((prev) => ({ ...prev, open: true }))}
                                                    >
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                                {/* <div className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="แสดงหน้าจอลูกค้า"
                                                        name="is_show"
                                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                                        checked={values.is_show}
                                                        // defaultChecked={() => {
                                                        //     console.log('is_show:', values.is_show)
                                                        // }}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setFieldValue('is_show', true)
                                                            } else {
                                                                setFieldValue('is_show', false)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="เปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio1"
                                                        className="form-check-st-2 tc-7"
                                                        checked={values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', true)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="ปิดใช้งาน"
                                                        name="is_active"
                                                        type="radio"
                                                        id="radio2"
                                                        className="form-check-st-2 tc-7"
                                                        checked={!values.is_active}
                                                        onChange={(e) => {
                                                            setFieldValue('is_active', false)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="created_at"
                                                                className="form-control"
                                                                placeholder="วันที่สร้าง"
                                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                                onChange={handleChange}
                                                                // isInvalid={!!errors.created_at}
                                                                isInvalid={!!(errors.created_at && touched.created_at)}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                                            <Form.Label className="mb-1">ชื่อผู้สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="whos_updated"
                                                                className="form-control"
                                                                placeholder="ชื่อผู้สร้าง"
                                                                value={values.whos_updated}
                                                                onChange={handleChange}
                                                                // isInvalid={!!errors.whos_updated}
                                                                isInvalid={!!(errors.whos_updated && touched.whos_updated)}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="text-end pb-4 pe-3">
                                        {
                                            formdisabled === true ? (
                                                <Button
                                                    type="button"
                                                    className="btn-st-3 mx-3"
                                                    onClick={(e) => {
                                                        setFormdisabled(false)
                                                    }}
                                                >
                                                    แก้ไข
                                                </Button>
                                            ) : (
                                                <div
                                                    className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                                    <Button
                                                        type="button"
                                                        className="btn-st-5 me-auto px-4 "
                                                        style={{ width: '90px' }}
                                                        onClick={() => setModalDeleteShow(true)}
                                                    >
                                                        ลบ
                                                    </Button>
                                                    <Link
                                                        to={`/${slug}/product/options/note`}
                                                        className="btn btn-st-20 me-3 bg-transparent"
                                                    >
                                                        ยกเลิก
                                                    </Link>
                                                    <Button type="submit" className="btn-st-3 ">
                                                        บันทึก
                                                    </Button>
                                                </div>
                                            )
                                        }
                                        <OptionModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            settingStock={settingStock}
                                            setFieldValue={setFieldValue}
                                            stockindex={stockindex}
                                            deletestock={() => {
                                                values.product_option_detail[stockindex].stock = null
                                                setModalShow(false)
                                            }}
                                            values={values}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ConfirmDeleteModal
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                onSuccess={() => setModalsuccessShow(true)}
                optiondata={optionEditData}
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => setModalsuccessShow(false)}
            />
            <ModalEdit
                modal={modaledit}
                setModal={setModaledit}
                productOptionDetail={productOptionDetail}
                setProductOptionDetail={setProductOptionDetail}
                setOptionEditListData={setOptionEditListData}
                optionEditListData={optionEditListData}
                onSuccess={() => setOnSuccess(true)}
            />
            <ModalAdd
                modal={modaladd}
                setModal={setModaladd}
                setOptionEditListData={setOptionEditListData}
                optionEditListData={optionEditListData}
                onSuccess={() => setOnSuccess(true)}
            />
        </div>
    )
}

// const ModalAdd = ({ modal, setModal, setOptionEditListData, optionEditListData, setModalDeleteShow, ...props }) => {
//
//     const schemaFormModal = yup.object().shape({
//         name: yup.string().required(),
//     })
//
//     return (
//         <ModalAllder
//             modal={modal}
//             setModal={setModal}
//             htitle={'เพิ่มรายการตัวเลือกหมายเหตุ'}
//             hbtn={true}
//         >
//             <Formik
//                 enableReinitialize
//                 initialValues={{
//                     'id': null,
//                     'name': null,
//                 }}
//                 validationSchema={schemaFormModal}
//                 onSubmit={async (values) => {
//                     const newId = nanoid()
//                     setOptionEditListData(optionlist => [...optionlist, {
//                         'id': newId,
//                         'name': values.name,
//                     }])
//
//                     setModal((prev) => ({ ...prev, open: false }))
//
//                 }}
//             >
//                 {({
//                     handleSubmit,
//                     handleChange,
//                     touched,
//                     errors,
//                     values,
//                     setFieldValue,
//                     isInvalid,
//                     isValid,
//                 }) => (
//                     <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
//                         <fieldset>
//                             <div className="row ">
//                                 <div className="col-12">
//                                     <Form.Group className="mb-4 form-st-3 pb-1">
//                                         <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
//                                         <Form.Control
//                                             type="text"
//                                             name={`name`}
//                                             className="form-control"
//                                             placeholder="กรอกชื่อรายการ"
//                                             value={values.name}
//                                             onChange={handleChange}
//                                             isInvalid={!!(errors.name && touched.name)}
//
//                                         />
//                                     </Form.Group>
//                                 </div>
//                             </div>
//                         </fieldset>
//                         <div className="text-end pb-4 pt-4 pe-3">
//                             <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
//                                 <Button
//                                     className="btn btn-st-6 me-3 bg-transparent"
//                                     onClick={() => setModal((prev) => ({ ...prev, open: false }))}
//                                 >
//                                     ยกเลิก
//                                 </Button>
//                                 <Button type="submit" className="btn-st-3 ">
//                                     บันทึก
//                                 </Button>
//                             </div>
//
//
//                         </div>
//                     </Form>
//                 )}
//             </Formik>
//
//         </ModalAllder>
//     )
// }

const ModalAdd = ({ modal, setModal, setOptionEditListData, optionEditListData, setModalDeleteShow, onSuccess, ...props }) => {
    const OPTION_DETAILS_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product/remark-options-details`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const resetFormRef = useRef(null)

    const schemaFormModal = yup.object().shape({
        name: yup.string().required(),
    })

    const [initialValues, setInitialValues] = useState({
        'name': '',
    })

    const postOptionDetails = async (values) => {
        console.log('create option details submitted!:', values)

        try {
            const res = await fetch(OPTION_DETAILS_EDIT_URL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    useEffect(() => {
        if (modal.open && resetFormRef.current) {
            resetFormRef.current()
        }
    }, [modal.open])

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={'เพิ่มรายการตัวเลือกมาตราฐาน'}
            hbtn={true}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // initialValues={optiondata}
                validationSchema={schemaFormModal}
                onSubmit={async (values) => {
                    const createOptionDetails = await postOptionDetails({
                        ...values,
                        product_option_id: parseInt(optionID)
                    })

                    if (createOptionDetails.status === 201) {
                        setModal((prev) => ({ ...prev, open: false }))
                        onSuccess()
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    resetForm,
                }) => {
                    resetFormRef.current = resetForm

                    return (
                        <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
                            <fieldset>
                                <div className="row ">
                                    <div className="col-12">
                                        <Form.Group className="mb-4 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="กรอกชื่อรายการ"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!(errors.name && touched.name)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="text-end pb-4 pt-4 pe-3">
                                <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                    <Button
                                        className="btn btn-st-6 me-3 bg-transparent"
                                        onClick={() => {
                                            setModal((prev) => ({ ...prev, open: false }))
                                            resetForm()
                                        }}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button type="submit" className="btn-st-3 ">
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </ModalAllder>
    )
}

const ModalEdit = ({
    modal,
    setModal,
    productOptionDetail,
    setProductOptionDetail,
    setOptionEditListData,
    optionEditListData,
    setModalDeleteShow,
    onSuccess,
    ...props
}) => {
    const OPTION_DETAILS_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product/remark-options-details`

    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const schemaFormModal = yup.object().shape({
        name: yup.string().required(),
    })

    const patchOptionDetails = async (values) => {
        console.log('update option details submitted!:', values)

        try {
            const res = await fetch(`${OPTION_DETAILS_EDIT_URL}/${values.id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    const deleteOptionDetails = async (values) => {
        console.log('update option details submitted!:', values)

        try {
            const res = await fetch(`${OPTION_DETAILS_EDIT_URL}/${values.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    console.log('productOptionDetail:', productOptionDetail)

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={'แก้ไขรายการตัวเลือกมาตราฐาน'}
            hbtn={true}
        >
            <Formik
                enableReinitialize
                initialValues={productOptionDetail}
                // initialValues={optiondata}
                validationSchema={schemaFormModal}
                onSubmit={async (values) => {
                    // console.log('modal edit submit value:', values)
                    // console.log(optionEditListData)

                    const updateOptionDetails = await patchOptionDetails(values)

                    if (updateOptionDetails.status === 200) {
                        setOptionEditListData(prev => prev.map((el) => {
                            if (el.id == values.id) {
                                console.log('values.name')
                                return {
                                    ...el,
                                    name: values.name,
                                }
                            }
                            return el
                        }))
                        setModal((prev) => ({ ...prev, open: false }))
                        onSuccess()
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    isInvalid,
                    isValid,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="p-lg-4">
                        <fieldset>
                            <div className="row ">
                                <div className="col-12">
                                    <Form.Group className="mb-4 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`name`}
                                            className="form-control"
                                            placeholder="กรอกชื่อรายการ"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!(errors.name && touched.name)}

                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </fieldset>
                        <div className="text-end pb-4 pt-4 pe-3">
                            <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                                <Button
                                    type="button"
                                    className="btn-st-5 me-auto px-4 "
                                    style={{ width: '90px' }}
                                    onClick={async () => {
                                        const deleteDetails = await deleteOptionDetails(productOptionDetail)
                                        if (deleteDetails.status === 200) {
                                            setModal((prev) => ({ ...prev, open: false }))
                                            onSuccess()
                                        }
                                        // setOptionEditListData(optionlist => optionlist.filter(item => item.id !== values.id))
                                    }}
                                >
                                    ลบ
                                </Button>
                                <Button

                                    className="btn btn-st-6 me-3 bg-transparent"
                                    onClick={() => setModal((prev) => ({ ...prev, open: false }))}
                                >
                                    ยกเลิก
                                </Button>
                                <Button type="submit" className="btn-st-3 ">
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalAllder>
    )
}

const OptionCreate = (props) => {
    return (
        <div className="row gx-xl-3 align-items-center">
            <div className="col-xl-4 col-lg-6 col-8">
                <div className="row ">
                    <div className="col-12">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                            <Form.Control
                                type="text"
                                name={`product_option_detail[${props.n}].name`}
                                className="form-control"
                                placeholder=""
                                value={props.item.name}
                                // defaultValue={props.item.name}
                                isInvalid={!!(
                                    props.errors.product_option_detail &&
                                    props.errors.product_option_detail[props.n] &&
                                    props.errors.product_option_detail[props.n].name &&
                                    props.touched.product_option_detail &&
                                    props.touched.product_option_detail[props.n] &&
                                    props.touched.product_option_detail[props.n].name
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`product_option_detail[${props.n}].name`, e.target.value)
                                }
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-4 ">
                <button
                    type="button"
                    className="btn btn-close-st-2 me-2"
                    onClick={props.deleteHandeler}
                />
            </div>
        </div>
    )
}

const OptionList = (props) => {
    return (
        <div className="row gx-xl-3 align-items-center">
            <div className="col-xl-4 col-lg-6 col-8">
                <div className="row ">
                    <div className="col-12">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                            <Form.Control
                                type="text"
                                name={`remark_details[${props.n}].name`}
                                className="form-control"
                                placeholder="กรอกชื่อมาตราฐาน"
                                value={props.item.name}
                                isInvalid={!!(
                                    props.errors.remark_details &&
                                    props.errors.remark_details[props.n] &&
                                    props.errors.remark_details[props.n].name &&
                                    props.touched.remark_details &&
                                    props.touched.remark_details[props.n] &&
                                    props.touched.remark_details[props.n].name
                                )}
                                onChange={(e) =>
                                    props.setFieldValue(`remark_details[${props.n}].name`, e.target.value)
                                }
                                disabled={true}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-4 ">
                <button
                    type="button"
                    className="btn d-block btn-edit-st-1 "
                    onClick={props.editHandeler}
                >
                </button>
            </div>
        </div>
    )
}

const OptionCreateList = (props) => {
    useEffect(() => {
        props.setFieldValue(`remark_details[${props.n}].name`, props.item.name)
        // if (props.item.stock.listName != '') {
        //     props.setFieldValue(`optionList[${props.n}].stock`, props.item.stock)
        // } else {
        //     props.setFieldValue(`optionList[${props.n}].stock`, null)
        // }
    }, [])

    return (
        <div className="row gx-xl-3 align-items-center">
            <div className="col-xl-6 col-lg-6 col-8">
                <Form.Group className="mb-2 form-st-3 pb-1">
                    <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                    <Form.Control
                        type="text"
                        name={`remark_details[${props.n}].name`}
                        className="form-control"
                        placeholder="กรอกชื่อมาตราฐาน"
                        defaultValue={props.item.name}
                        isInvalid={!!(
                            props.errors.remark_details &&
                            props.errors.remark_details[props.n] &&
                            props.errors.remark_details[props.n].name &&
                            props.touched.remark_details &&
                            props.touched.remark_details[props.n] &&
                            props.touched.remark_details[props.n].name
                        )}
                        onChange={(e) =>
                            props.setFieldValue(`remark_details[${props.n}].name`, e.target.value)
                        }
                    />
                </Form.Group>
            </div>
            <div className="col-xl-6 col-4 ">
                <button
                    type="button"
                    className="btn btn-close-st-2 me-2"
                    onClick={props.deleteHandeler}
                />

                {/*<button type="button" className="btn btn-setting-st-1 me-2" onClick={props.settingHandeler} />*/}
                {/*{*/}
                {/*    props.values.optionList[props.n] ? (*/}
                {/*        props.values.optionList[props.n].stock ? (*/}
                {/*            <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>*/}
                {/*        ) : (*/}
                {/*            <span*/}
                {/*                className="fs-16"*/}
                {/*                style={{ color: '#B6BBC6', lineHeight: '24px' }}*/}
                {/*            >*/}
                {/*                ยังไม่ได้ตั้งค่า*/}
                {/*            </span>*/}
                {/*        )*/}
                {/*    ) : (*/}
                {/*        <span className="fs-16" style={{ color: '#B6BBC6', lineHeight: '24px' }}>ยังไม่ได้ตั้งค่า</span>*/}
                {/*    )*/}
                {/*}*/}
            </div>
        </div>
    )
}

function OptionModal (props) {

    const { handleSuccess, onHide, ...rest } = props
    const schema = yup.object().shape({
        optionCode: yup.string().required(),
        name: yup.string().required(),
    })

    const [optiondata, setOptiondata] = useState(
        {
            'optionCode': '',
            'name': '',
            'displayCustomer': 0,
            'is_active': 1,
            'dateCreate': getCurrentDate('/'),
            'userCreate': 'Admin Pat',
        },
    )

    const [tags, setTags] = React.useState([])
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i))
    }

    const handleAddition = tag => {
        setTags([...tags, tag])
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice()

        newTags.splice(currPos, 1)
        newTags.splice(newPos, 0, tag)
        setTags(newTags)
    }

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">สร้างตัวเลือกหมายเหตุ</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        enableReinitialize
                        initialValues={optiondata}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            console.log(values)
                            props.handleSuccess()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">รหัสหมายเหตุ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="optionCode"
                                        className="form-control"
                                        placeholder="รหัสหมายเหตุ"
                                        value={values.optionCode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.optionCode}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อตัวเลือกหมายเหตุ </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="ชื่อตัวเลือกหมายเหตุ"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}


                                    />
                                </Form.Group>

                                <div>
                                    <Form.Group className="mb-3 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ </Form.Label>
                                        <ReactTags
                                            tags={tags}
                                            delimiters={delimiters}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleDrag={handleDrag}
                                            inputFieldPosition="top"
                                            removeComponent={RemoveComponent}
                                            placeholder="พิมพ์ชื่อตัวเลือก"

                                            handleInputBlur={() =>
                                                setFieldValue('product_option_detail', tags)
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="แสดงหน้าจอลูกค้า"
                                        name="displayCustomer"
                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                        defaultChecked={values.displayCustomer}
                                        onChange={(e) => {
                                            if (e.target.checked === true) {
                                                setFieldValue('displayCustomer', 1)
                                            } else {
                                                setFieldValue('displayCustomer', 0)
                                            }

                                        }}

                                    />
                                </div>

                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="เปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-21`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === 1}
                                            onChange={(e) => {
                                                setFieldValue('is_active', 1)
                                            }}
                                        />
                                        <Form.Check
                                            inline
                                            label="ปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-22`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === 0}
                                            onChange={(e) => {
                                                setFieldValue('is_active', 0)
                                            }}

                                        />
                                    </div>
                                ))}

                                <div className="row">
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">วันที่สร้าง </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="dateCreate"
                                                className="form-control"
                                                placeholder="วันที่สร้าง"
                                                value={values.dateCreate}
                                                onChange={handleChange}
                                                isInvalid={!!errors.dateCreate}
                                                disabled={true}

                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">ชื่อผู้สร้าง </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="userCreate"
                                                className="form-control"
                                                placeholder="ชื่อผู้สร้าง"
                                                value={values.userCreate}
                                                onChange={handleChange}
                                                isInvalid={!!errors.userCreate}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="text-end pt-2 pb-3 btn-group-control">
                                    <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}
                                            onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className="btn-st-3  "
                                            style={{ width: '100px' }}>เพิ่ม</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}

function ConfirmDeleteModal (props) {
    const { onSuccess, onHide, ...rest } = props

    const OPTION_EDIT_URL = `${process.env.REACT_APP_API_URL}/business-product-remark-options`

    let { optionID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    // console.log('optionData !!!!!:', props.optiondata)

    const handleDelete = (categoryID) => {
        props.onHide()
        props.onSuccess()
    }

    const deleteOption = async (values) => {
        console.log('Delete option submitted!:', values)

        try {
            const res = await fetch(`${OPTION_EDIT_URL}/${values.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify({is_deleted: true,}),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to delete data:', error)
        }
    }

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="px-3 py-4">
                    {
                        (props.optiondata && props.optiondata.productQty !== undefined && props.optiondata.productQty > 0) ? (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">ขออภัยไม่สามารถลบได้</div>
                                    <div className="text-center fs-22 tc-7">เนื่องจากมีสินค้าอยู่ในตัวเลือกมาตราฐาน
                                    </div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ตกลง
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
                                    <div className="text-center fs-22 tc-7">ตัวเลือกมาตราฐานนี้ใช่หรือไม่</div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3"
                                        style={{ width: '100px' }}
                                        onClick={async () => {
                                            // handleDelete(props.optiondata.optionID)
                                            const deleteOptionResponse = await deleteOption(props.optiondata)
                                            if (deleteOptionResponse.status === 200) {
                                                props.onHide()
                                                props.onSuccess()
                                            }
                                        }}
                                    >
                                        ใช่
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn-st-6 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ไม่ใช่
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

function SuccessDeleteModal (props) {

    return (
        <Modal
            {...props}
            size="sm"
            centered
        >
            <Modal.Body>

                <div className="px-3 py-4">
                    <div className="text-center mb-4">
                        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                            <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>
                    <div className="text-center fs-22 tc-7">ลบรายการสินค้าสำเร็จ</div>
                </div>

            </Modal.Body>

        </Modal>
    )
}

class RemoveComponent extends React.Component {
    render () {
        const { className, onRemove } = this.props
        return (
            <button onClick={onRemove} className="remove-tags">
            </button>
        )
    }
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
        <>
            <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '18px', width: '18px' }}
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                />
                <label className="form-check-label" id="booty-check"/>
            </div>
        </>
    )
})

