import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from 'react'

import Select from 'react-select'

import { ThailandAddressTypeahead, ThailandAddressValue } from 'react-thailand-address-typeahead'

import { Formik } from 'formik'
import * as yup from 'yup'
import useToken from '../utils/UseToken'
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'

export function BusinessCreate () {
    const BusinessDetailURL = `${process.env.REACT_APP_API_URL}/businessdetail`
    const BusinessContactURL = `${process.env.REACT_APP_API_URL}/businesscontact`
    const BusinessFinanceDetailURL = `${process.env.REACT_APP_API_URL}/businessfinancedetail`
    const UploadImageFileURL = `${process.env.REACT_APP_API_URL}/business-info/logo`

    const { token, setToken } = useToken()
    const { user } = useRecoilValue(authState)
    const [tab, setTab] = useState(1)
    const [mode, setMode] = useState('view')
    const [formDisabled, setFormdisabled] = useState(true)
    const [isBusinessEdit, setIsBusinessEdit] = useState(false)
    const [isContactEdit, setIsContactEdit] = useState(false)
    const [isSaleEdit, setIsSaleEdit] = useState(false)

    const [address, setAddress] = useState(
        ThailandAddressValue.fromDatasourceItem({
            d: '',
            p: '',
            po: '',
            s: '',
        })
    )

    const [businessData, setBusinessData] = useState(
        {
            'name': '',
            'prefix': '',
            'suffix': '',
            'img_url': '',
            'taxpaper_id_number': '',
            'vat_code': '',
            'address': '',
            'type': '',
            'province': '',
            'district': '',
            'subdistrict': '',
            'zipcode': '',
        },
    )

    const [contactData, setContactData] = useState(
        {
            'email': '',
            'facebook': '',
            'line': '',
            'location': '',
            'mobile_number': '',
            'phone_number': '',
            'website': '',
        },
    )

    const [saleData, setSaleData] = useState(
        {
            'cal_tax_solution_id': 0,
            'currency': '',
            'is_include_service_charge': false,
            'is_include_vat': false,
            'service_charge': '',
            'vat': '',
        },
    )

    const getBusinessData = async () => {
        try {
            const res = await fetch(BusinessDetailURL, {
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setBusinessData(data)
            console.log('businessData:', data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const getContactData = async () => {
        try {
            const res = await fetch(BusinessContactURL, {
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setContactData(data)
            console.log('contactData:', data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const getBusinessFinanceDetailData = async () => {
        try {
            const res = await fetch(BusinessFinanceDetailURL, {
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setSaleData(data)
            console.log('saleData:', data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const updateBusinessData = async (values) => {
        try {
            setBusinessData(values);

            const res = await fetch(BusinessDetailURL, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to update data:', error)
        } finally {
            setIsBusinessEdit(false)
        }
    }

    const updateContactData = async (values) => {
        try {
            setContactData(values);

            const res = await fetch(BusinessContactURL, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to update data:', error)
        } finally {
            setIsContactEdit(false)
        }
    }

    const updateSaleData = async (values) => {
        try {
            setSaleData(values);

            const res = await fetch(BusinessFinanceDetailURL, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to update data:', error)
        } finally {
            setIsSaleEdit(false)
        }
    }

    const uploadImageFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(UploadImageFileURL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'business-id': user.businessId,
                }),
                body: formData,
            });

            return await response.text();
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }

    useEffect(() => {
        if (tab === 1 && isBusinessEdit === false) {
            console.log('Fetching business data!!!!!!');
            getBusinessData()
        } else if (tab === 2 && isContactEdit === false) {
            getContactData()
        } else if (tab === 3 && isSaleEdit === false) {
            getBusinessFinanceDetailData()
        }
    }, [tab])

    useEffect(() => {
        if (tab === 1 && businessData) {
            setAddress(
                ThailandAddressValue.fromDatasourceItem({
                    d: (businessData.district !== undefined && businessData.district.length > 0) ? businessData.district : '',
                    p: (businessData.province !== undefined && businessData.province.length > 0) ? businessData.province : '',
                    po: (businessData.zipcode !== undefined && businessData.zipcode.toString().length > 0) ? businessData.zipcode : '',
                    s: (businessData.subdistrict !== undefined && businessData.subdistrict.length > 0) ? businessData.subdistrict : '',
                })
            )
        }
    }, [businessData])

    return (
        <div>
            <nav aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                    <li className="breadcrumb-item ">
                        <a
                            href="#"
                            className="tc-7  text-decoration-none"
                        >
                            ตั้งค่าธุรกิจ
                        </a>
                    </li>
                    <li className="breadcrumb-item active tc-1 " aria-current="page"> ข้อมูลธุรกิจ</li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">ข้อมูลธุรกิจ</div>
            <div className="card card-st-1">
                <div className="card-header">
                    <nav>
                        <div className="nav nav-tabs nav-tabs-st-2" id="nav-tab" role="tablist">
                            <button
                                className={tab === 1 ? ('active nav-link') : ('nav-link ')}
                                type="button"
                                onClick={() => { setTab(1) }}
                            >
                                <span className="i-document me-2"/>
                                ข้อมูลธุรกิจ
                            </button>
                            <button
                                className={tab === 2 ? ('active nav-link') : ('nav-link ')}
                                type="button"
                                onClick={() => { setTab(2) }}
                            >
                                <span className="i-location me-2"/>
                                ข้อมูลติดต่อ
                            </button>
                            <button
                                className={tab === 3 ? ('active nav-link') : ('nav-link ')}
                                type="button"
                                onClick={() => { setTab(3) }}
                            >
                                <span className="i-setting me-2"/>
                                ตั้งค่าการขาย
                            </button>
                        </div>
                    </nav>
                </div>
                {/*{tab === 1 &&*/}
                {/*    <Business*/}
                {/*        address={address}*/}
                {/*        setAddress={setAddress}*/}
                {/*        businessData={businessData}*/}
                {/*        setBusinessData={setBusinessData}*/}
                {/*        mode={mode}*/}
                {/*        setTab={setTab}*/}
                {/*        formdisabled={formDisabled}*/}
                {/*        setFormdisabled={setFormdisabled}*/}
                {/*        isBusinessEdit={isBusinessEdit}*/}
                {/*        setIsBusinessEdit={setIsBusinessEdit}*/}
                {/*        updateBusinessData={updateBusinessData}*/}
                {/*        uploadImageFile={uploadImageFile}*/}
                {/*    />*/}
                {/*}*/}
                {/*{tab === 2 &&*/}
                {/*    <Contact*/}
                {/*        contactData={contactData}*/}
                {/*        setContactData={setContactData}*/}
                {/*        mode={mode}*/}
                {/*        setTab={setTab}*/}
                {/*        isContactEdit={isContactEdit}*/}
                {/*        setIsContactEdit={setIsContactEdit}*/}
                {/*        updateContactData={updateContactData}*/}
                {/*    />*/}
                {/*}*/}
                {/*{tab === 3 &&*/}
                {/*    <Sale*/}
                {/*        contactData={contactData}*/}
                {/*        businessData={businessData}*/}
                {/*        saleData={saleData}*/}
                {/*        setSaleData={setSaleData}*/}
                {/*        mode={mode}*/}
                {/*        etTab={setTab}*/}
                {/*        formdisabled={formDisabled}*/}
                {/*        setFormdisabled={setFormdisabled}*/}
                {/*        isSaleEdit={isSaleEdit}*/}
                {/*        setIsSaleEdit={setIsSaleEdit}*/}
                {/*        updateSaleData={updateSaleData}*/}
                {/*    />*/}
                {/*}*/}
                <div className={`${tab === 1 ? '' : 'hidden'}`}>
                    <Business
                        address={address}
                        setAddress={setAddress}
                        businessData={businessData}
                        setBusinessData={setBusinessData}
                        mode={mode}
                        setTab={setTab}
                        formdisabled={formDisabled}
                        setFormdisabled={setFormdisabled}
                        isBusinessEdit={isBusinessEdit}
                        setIsBusinessEdit={setIsBusinessEdit}
                        updateBusinessData={updateBusinessData}
                        uploadImageFile={uploadImageFile}
                    />
                </div>
                <div className={`${tab === 2 ? '' : 'hidden'}`}>
                    <Contact
                        contactData={contactData}
                        setContactData={setContactData}
                        mode={mode}
                        setTab={setTab}
                        isContactEdit={isContactEdit}
                        setIsContactEdit={setIsContactEdit}
                        updateContactData={updateContactData}
                    />
                </div>
                <div className={`${tab === 3 ? '' : 'hidden'}`}>
                    <Sale
                        contactData={contactData}
                        businessData={businessData}
                        saleData={saleData}
                        setSaleData={setSaleData}
                        mode={mode}
                        etTab={setTab}
                        formdisabled={formDisabled}
                        setFormdisabled={setFormdisabled}
                        isSaleEdit={isSaleEdit}
                        setIsSaleEdit={setIsSaleEdit}
                        updateSaleData={updateSaleData}
                    />
                </div>
            </div>
        </div>
    )
}

const Business = (props) => {
    const schema = yup.object().shape({
        address: yup.string().required(),
        business_product_type: yup.string(),
        business_type: yup.string().required(),
        district: yup.string().required(),
        img_url: yup.string().required(),
        name: yup.string().required(),
        pos_slug: yup.string().required(),
        prefix: yup.string().when('business_type', {
            is: (v) => v !== 'อื่นๆ',
            then: (schema) => schema.required(),
        }),
        province: yup.string().required(),
        restaurant_type: yup.string().required(),
        subdistrict: yup.string().required(),
        suffix: yup.string().when('business_type', {
            is: (v) => v === 'บุคคลธรรมดา' || v === 'บริษัทจำกัด' || v === 'บริษัทจำกัดมหาชน' || v === 'ห้างหุ้นส่วนจำกัด',
            then: (schema) => schema.required(),
        }),
        taxpaper_id_number: yup.string().required(),
        type: yup.string().required(),
        vat_code: yup.string().required(),
        zipcode: yup.string().required(),
    })

    const optionsBusinessType = [
        { value: '1', label: 'บุคคลธรรมดา', suffix: '' },
        { value: '2', label: 'ร้านค้า', suffix: '' },
        { value: '3', label: 'บริษัทจำกัด', suffix: 'จำกัด' },
        { value: '4', label: 'บริษัทจำกัดมหาชน', suffix: 'จำกัดมหาชน' },
        { value: '5', label: 'ห้างหุ้นส่วนสามัญ', suffix: '' },
        { value: '6', label: 'ห้างหุ้นส่วนจำกัด', suffix: 'จำกัด' },
        { value: '7', label: 'มูลนิธิ', suffix: '' },
        { value: '8', label: 'สมาคม', suffix: '' },
        { value: '9', label: 'กิจการร่วมค้า', suffix: '' },
        { value: '10', label: 'คณะบุลคล', suffix: '' },
        { value: '11', label: 'อื่นๆ', suffix: '' },
    ]

    const optionsProductType = [
        { value: '1', label: 'อาหารไทย' },
        { value: '2', label: 'อาหารญี่ปุ่น' },
        { value: '3', label: 'อาหารตะวันตก' },
        { value: '4', label: 'อาหารจีน' },
        { value: '5', label: 'อาหารเกาหลี' },
        { value: '6', label: 'ก๋วยเตี๋ยว' },
        { value: '7', label: 'ขนมหวาน' },
        { value: '8', label: 'อาหารว่าง' },
        { value: '9', label: 'ฟาสฟูต์ฟู้ด' },
        { value: '10', label: 'เบเกอรี' },
        { value: '11', label: 'ร้านกาแฟ' },
        { value: '12', label: 'อื่นๆ' },
    ]

    const optionsRestaurantType = [
        // { value: '1', label: 'Cafe ( ร้านกาแฟ / เบเกอร์รี่ )' },
        { value: '1', label: 'Cafe' },
        { value: '2', label: 'Buffet' },
        { value: '3', label: 'Fine Dining' },
        { value: '4', label: 'โอมากาเสะ' },
        { value: '5', label: 'A La Carte' },
        { value: '6', label: 'ร้านอาหารจานด่วน' },
        { value: '7', label: 'ฟาสฟูต์ฟู้ด' },
        { value: '8', label: 'ร้านอาหารจานเดียว' },
        { value: '9', label: 'ร้านอาหารทั่วไป' },
        { value: '10', label: 'รถเข็น' },
        { value: '11', label: 'อื่นๆ' },
    ]

    const prefixType = [
        { value: '1', label: 'นาย' },
        { value: '2', label: 'นาง' },
        { value: '3', label: 'นางสาว' },
        { value: '4', label: 'ไม่ระบุ' },
    ]

    function getBusinessNameLabel (val) {
        let result = 'ชื่อ'
        if (val === 'บุคคลธรรมดา') {
            result = 'ชื่อจริง'
        }
        return result
    }

    function getBusinessLastNameLabel (val) {
        let result = 'คำลงท้าย'
        if (val === 'บุคคลธรรมดา') {
            result = 'นามสกุล'
        }
        return result
    }

    function disabledBusinessLastName (val) {
        let result = false
        if (val === 'บริษัทจำกัด' || val === 'บริษัทจำกัดมหาชน' || val === 'ห้างหุ้นส่วนจำกัด') {
            result = true
        }
        return result
    }

    function displayBusinessLastName (val) {
        let result = false
        if (val === 'บุคคลธรรมดา' || val === 'บริษัทจำกัด' || val === 'บริษัทจำกัดมหาชน' || val === 'ห้างหุ้นส่วนจำกัด') {
            result = true
        }
        return result
    }

    return (
        <Formik
            enableReinitialize
            initialValues={props.businessData}
            validationSchema={schema}
            onSubmit={async (values) => {
                console.log('contact submit:', values)
                props.updateBusinessData({
                    ...values,
                    prefix: values.business_type === 'อื่นๆ' ? '' : values.prefix,
                    suffix: (values.business_type === 'ร้านค้า' ||
                        values.business_type === 'ห้างหุ้นส่วนสามัญ' ||
                        values.business_type === 'มูลนิธิ' ||
                        values.business_type === 'สมาคม' ||
                        values.business_type === 'กิจการร่วมค้า' ||
                        values.business_type === 'คณะบุลคล' ||
                        values.business_type === 'อื่นๆ'
                    ) ? '' : values.suffix,
                })
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
                resetForm,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <fieldset disabled={!props.isBusinessEdit}>
                        <div className="card-body px-xl-4">
                            <div>
                                <ImageUpload
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    isBusinessEdit={props.isBusinessEdit}
                                    uploadImageFile={props.uploadImageFile}
                                />
                                <div className="pt-3">
                                    <div className="row gx-xl-4">
                                        <div className="col-xl-4">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">เลขที่ประจำตัวผู้เสียภาษี</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="taxpaper_id_number"
                                                    className="form-control"
                                                    placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                                                    value={values.taxpaper_id_number}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.taxpaper_id_number}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    maxLength={13}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-4 align-self-center">
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="ไม่ระบุ"
                                                        name="type"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        className="form-check-st-2 tc-7"
                                                        isInvalid={!!errors.type}
                                                        checked={values.type === 'NotSpecified'}
                                                        onChange={(e) => {
                                                            setFieldValue('type', 'NotSpecified')
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="สำนักงานใหญ่"
                                                        name="type"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        className="form-check-st-2 tc-7"
                                                        isInvalid={!!errors.type}
                                                        checked={values.type === 'HeadLevel'}
                                                        onChange={(e) => {
                                                            setFieldValue('type', 'HeadLevel')
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="สาขา"
                                                        name="type"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        className="form-check-st-2 tc-7"
                                                        isInvalid={!!errors.type}
                                                        checked={values.type === 'BranchLevel'}
                                                        onChange={(e) => {
                                                            setFieldValue('type', 'BranchLevel')
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-xl-4">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">VAT CODE</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="vat_code"
                                                    className="form-control"
                                                    placeholder="-"
                                                    value={values.vat_code}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.vat_code}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-4">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">POS Slug</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="pos_slug"
                                                    className="form-control"
                                                    placeholder="-"
                                                    value={values.pos_slug}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pos_slug}
                                                />
                                            </Form.Group>
                                        </div>
                                        {props.isBusinessEdit ? (
                                            <>
                                                <div className="col-xl-4 flex justify-start items-center fs-12 text-red-500">
                                                    การแก้ POS Slug มีผลต่อ URL path ที่ใช้ในการ login
                                                </div>
                                                <div className="col-xl-4" />
                                            </>
                                        ) : (
                                            <div className="col-xl-8"/>
                                        )}
                                        <div className="col-xl-4">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">ประเภทกิจการ</Form.Label>
                                                <Select
                                                    as="select"
                                                    name="business_type"
                                                    onChange={(e) => {
                                                        const newBusinessType = optionsBusinessType.find(item => item.value === e.value)
                                                        setFieldValue('business_type', newBusinessType.label)
                                                        let prefix = ''
                                                        let suffix = ''
                                                        if (newBusinessType) {
                                                            if (e.value === '1' || e.value === '11') {
                                                                prefix = ''
                                                            } else {
                                                                prefix = newBusinessType.label
                                                            }
                                                            suffix = newBusinessType.suffix
                                                        }
                                                        setFieldValue('prefix', prefix)
                                                        setFieldValue('suffix', suffix)
                                                    }}
                                                    className={!!errors.business_type ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                    options={optionsBusinessType}
                                                    placeholder="เลือกประเภทกิจการ"
                                                    isInvalid={!!errors.business_type}
                                                    value={optionsBusinessType.filter(item => item.label === values.business_type)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-8">
                                            <div className="row gx-xl-4">
                                                {values.business_type !== 'อื่นๆ' && (
                                                    <>
                                                        <div className="col-xl-4">
                                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                                <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                                                                {values.business_type === 'บุคคลธรรมดา' ?
                                                                    <Select
                                                                        as="select"
                                                                        name="prefix"
                                                                        onChange={(e) => {
                                                                            const newPrefixType = prefixType.find(item =>
                                                                                item.value === e.value,
                                                                            )
                                                                            setFieldValue('prefix', newPrefixType.label)
                                                                        }}
                                                                        className={!!errors.prefix ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                                        options={prefixType}
                                                                        placeholder="เลือกคำนำหน้า"
                                                                        isInvalid={!!errors.prefix}
                                                                        value={
                                                                            prefixType.filter(
                                                                                item => item.label === values.prefix,
                                                                            )
                                                                        }
                                                                    />
                                                                    :
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prefix"
                                                                        className="form-control"
                                                                        placeholder="คำนำหน้า"
                                                                        value={values.prefix}
                                                                        onChange={handleChange}
                                                                        isValid={touched.prefix && !errors.prefix}
                                                                        isInvalid={!!errors.prefix}
                                                                        disabled
                                                                    />
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                    </>
                                                )}
                                                <div
                                                    className={`col-xl-${values.business_type === 'อื่นๆ' ? '8' : '4'}`}>
                                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                                        <Form.Label className="mb-1">
                                                            {getBusinessNameLabel(values.business_type)}
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder={getBusinessNameLabel(values.business_type)}
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            isValid={touched.name && !errors.name}
                                                            isInvalid={!!errors.name}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xl-4">
                                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                                        {displayBusinessLastName(values.business_type) && (
                                                            <>
                                                                <Form.Label className="mb-1">
                                                                    {getBusinessLastNameLabel(values.business_type)}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="suffix"
                                                                    className="form-control"
                                                                    placeholder={getBusinessLastNameLabel(values.business_type)}
                                                                    value={values.suffix}
                                                                    onChange={handleChange}
                                                                    isValid={touched.suffix && !errors.suffix}
                                                                    isInvalid={!!errors.suffix}
                                                                    disabled={disabledBusinessLastName(values.business_type)}
                                                                />
                                                            </>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">ที่อยู่</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    className="form-control"
                                                    placeholder="กรอกที่อยู่"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <ThailandAddressTypeahead
                                        value={props.address}
                                        onValueChange={(val) => {
                                            props.setAddress(val)
                                            setFieldValue('subdistrict', val.subdistrict)
                                            setFieldValue('district', val.district)
                                            setFieldValue('province', val.province)
                                            setFieldValue('zipcode', val.postalCode)
                                        }}
                                    >
                                        <div className="row gx-xl-4">
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
                                                    <ThailandAddressTypeahead.SubdistrictInput
                                                        type="text"
                                                        className={`form-control ${values.subdistrict ? '' : !!errors.subdistrict ? 'is-invalid' : ''}`}
                                                        placeholder="กรอก แขวง/ตำบล"
                                                        name="subdistrict"
                                                        value={values.subdistrict}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">อำเภอ/เขต</Form.Label>
                                                    <ThailandAddressTypeahead.DistrictInput
                                                        type="text"
                                                        className={`form-control ${values.district ? '' : !!errors.district ? 'is-invalid' : ''}`}
                                                        placeholder="กรอก อำเภอ/เขต"
                                                        name="district"
                                                        value={values.district}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">จังหวัด</Form.Label>
                                                    <ThailandAddressTypeahead.ProvinceInput
                                                        type="text"
                                                        className={`form-control ${values.province ? '' : !!errors.province ? 'is-invalid' : ''}`}
                                                        placeholder="กรอกจังหวัด"
                                                        name="province"
                                                        value={values.province}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
                                                    <ThailandAddressTypeahead.PostalCodeInput
                                                        type="text"
                                                        className={`form-control ${values.postalcode ? '' : !!errors.postalcode ? 'is-invalid' : ''}`}
                                                        placeholder="กรอกรหัสไปรษณีย์"
                                                        name="postalcode"
                                                        value={values.postalcode}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <ThailandAddressTypeahead.Suggestion
                                            containerProps={{ className: 'suggestion-container' }}
                                            optionItemProps={{ className: 'suggestion-option' }}
                                        />
                                    </ThailandAddressTypeahead>
                                    <div className="row gx-xl-4">
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">ประเภทร้านอาหาร</Form.Label>
                                                <Select
                                                    as="select"
                                                    name="restaurant_type"
                                                    onChange={(e) => {
                                                        const newRestaurantType = optionsRestaurantType.find(item =>
                                                            item.value === e.value
                                                        )
                                                        setFieldValue('restaurant_type', newRestaurantType.label)
                                                    }}
                                                    className={!!errors.restaurant_type ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                    options={optionsRestaurantType}
                                                    placeholder="เลือกประเภทร้านอาหาร"
                                                    value={optionsRestaurantType.filter(item =>
                                                        item.label === values.restaurant_type
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">เลือกประเภทสินค้า</Form.Label>
                                                <Select
                                                    as="select"
                                                    name="business_product_type"
                                                    onChange={(e) => {
                                                        const newProductType = optionsProductType.find(item =>
                                                            item.value === e.value
                                                        )
                                                        setFieldValue('business_product_type', newProductType.label)
                                                    }}
                                                    className={!!errors.business_product_type ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                    options={optionsProductType}
                                                    placeholder="เลือกประเภทสินค้า"
                                                    value={optionsProductType.filter(item =>
                                                        item.label === values.business_product_type,
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="text-end pb-4 pe-3">
                        {props.isBusinessEdit === true ? (
                            <div className="text-end pt-3 btn-group-control">
                                <Button
                                    type="button"
                                    className="btn-st-5 me-3 hover:bg-red-500 hover:text-white"
                                    onClick={() => {
                                        props.setIsBusinessEdit(false)
                                        resetForm()
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button type="submit" className="btn-st-3 hover:bg-transparent hover:text-[#565DFF]">บันทึก</Button>
                            </div>
                        ) : (
                            <Button
                                type="button"
                                className="btn-st-3 mx-3"
                                onClick={() => { props.setIsBusinessEdit(true) }}
                            >
                                แก้ไข
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const Contact = (props) => {
    const schema = yup.object().shape({
        email: yup.string().required().email('Invalid email'),
        facebook: yup.string().required(),
        line: yup.string().required(),
        location: yup.string().required(),
        mobile_number: yup.string().required().min(10).max(10),
        phone_number: yup.string().required(),
        website: yup.string().required(),
    })

    return (
        <Formik
            enableReinitialize
            initialValues={props.contactData}
            validationSchema={schema}
            onSubmit={async (values) => {
                console.log('contact submit:', values)
                props.updateContactData(values)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
                resetForm,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <fieldset disabled={!props.isContactEdit}>
                    <div className="card-body px-xl-4">
                            <div>
                                <div className="py-3">
                                    <div className="row gx-xl-4">
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">เบอร์โทรศัพท์</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phone_number"
                                                    className="form-control"
                                                    placeholder="กรอกเบอร์โทรศัพท์"
                                                    value={values.phone_number}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.phone_number}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">เบอร์มือถือ</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mobile_number"
                                                    className="form-control"
                                                    placeholder="กรอกเบอร์มือถือ"
                                                    value={values.mobile_number}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.mobile_number}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    maxLength={10}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">E-mail</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="E-mail"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">Website</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="website"
                                                    className="form-control"
                                                    placeholder="Website"
                                                    value={values.website}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.website}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">Facebook</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="facebook"
                                                    className="form-control"
                                                    placeholder="Facebook"
                                                    value={values.facebook}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.facebook}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">Line</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="line"
                                                    className="form-control"
                                                    placeholder="Line"
                                                    value={values.line}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.line}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-12">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">URL Google Map</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="location"
                                                    className="form-control"
                                                    placeholder="URL Google Map"
                                                    value={values.location}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.location}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="text-end pb-4 pe-3">
                        {props.isContactEdit === true ? (
                            <div className="text-end pt-3 btn-group-control">
                                <Button
                                    type="button"
                                    className="btn-st-5 me-3 hover:bg-red-500 hover:text-white"
                                    onClick={() => {
                                        props.setIsContactEdit(false)
                                        resetForm()
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button type="submit" className="btn-st-3 hover:bg-transparent hover:text-[#565DFF]">บันทึก</Button>
                            </div>
                        ) : (
                            <Button
                                type="button"
                                className="btn-st-3 mx-3"
                                onClick={() => { props.setIsContactEdit(true) }}
                            >
                                แก้ไข
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const Sale = (props) => {
    const [checkServiceCharge, setCheckServiceCharge] = useState(props.saleData.is_include_service_charge)
    const [checkVat, setCheckVat] = useState(props.saleData.is_include_vat)

    const schema = yup.object().shape({
        cal_tax_solution_id: yup.number().required(),
        currency: yup.string().required(),
        is_include_service_charge: yup.boolean().required(),
        is_include_vat: yup.boolean().required(),
        service_charge: yup.string().when('is_include_service_charge', {
            is: (v) => v === true,
            then: (schema) => schema.required(),
        }),
        vat: yup.string().when('is_include_vat', {
            is: (v) => v === true,
            then: (schema) => schema.required(),
        }),
    })

    const optionsCurrency = [
        { value: '1', label: 'บาท' },
    ]

    const optionsVatcalc = [
        { value: 1, label: 'รวมภาษี' },
        { value: 2, label: 'ไม่รวมภาษี' },
        { value: 3, label: 'ไม่มีภาษี' },
    ]

    useEffect(() => {
        setCheckServiceCharge(props.saleData.is_include_service_charge)
        setCheckVat(props.saleData.is_include_vat)
    }, [props.saleData.is_include_service_charge, props.saleData.is_include_vat])

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...props.saleData,
                service_charge: props.saleData.service_charge === '0' ? '' : props.saleData.service_charge,
                vat: props.saleData.vat === '0' ? '' : props.saleData.vat,
            }}
            validationSchema={schema}
            onSubmit={async (values) => {
                console.log('sale submit:', values)
                props.updateSaleData({
                    ...values,
                    service_charge: values.is_include_service_charge === true ? values.service_charge : '',
                    vat: values.is_include_vat === true ? values.vat : '',
                })
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
                resetForm,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <fieldset disabled={!props.isSaleEdit}>
                        <div className="card-body px-xl-4">
                            <div>
                                <div className="py-3">
                                    <div className="row gx-xl-4">
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">สกุลเงิน <span
                                                    className="text-danger">*</span>
                                                </Form.Label>
                                                <Select
                                                    as="select"
                                                    name="currency"
                                                    onChange={(e) => {
                                                        setFieldValue('currency', e.value)
                                                    }}
                                                    className={!!errors.currency ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                    options={optionsCurrency}
                                                    placeholder="สกุลเงิน"
                                                    isInvalid={!!errors.currency}
                                                    value={optionsCurrency.filter(item => item.label === values.currency)}
                                                />
                                            </Form.Group>
                                            <div className="d-flex align-items-center">
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                    name="is_include_service_charge"
                                                    className="custom-switch-st-1"
                                                    onChange={() => {
                                                        setCheckServiceCharge(!checkServiceCharge)
                                                        setFieldValue('is_include_service_charge', !checkServiceCharge)
                                                    }}
                                                    checked={checkServiceCharge === true}
                                                />
                                                <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                                                    <Form.Label className="mb-1">ค่าบริการ (Service Charge) <span
                                                        className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="service_charge"
                                                        className="form-control "
                                                        placeholder="ค่าบริการ (Service Charge)"
                                                        value={(values.service_charge === '0' || checkServiceCharge === false) ? '' : values.service_charge}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.service_charge}
                                                        disabled={checkServiceCharge === false}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault()
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="custom-switch2"
                                                    label=""
                                                    name="is_include_vat"
                                                    className="custom-switch-st-1"
                                                    onChange={() => {
                                                        setCheckVat(!checkVat)
                                                        setFieldValue('is_include_vat', !checkVat)
                                                    }}
                                                    checked={checkVat === true}
                                                />
                                                <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                                                    <Form.Label className="mb-1">
                                                        ภาษีมูลค่าเพิ่ม (VAT)
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="vat"
                                                        className="form-control "
                                                        placeholder="ภาษีมูลค่าเพิ่ม (VAT)"
                                                        value={(values.vat === '0' || checkVat === false) ? '' : values.vat}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.vat}
                                                        disabled={checkVat === false}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault()
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">
                                                    วิธีคิดภาษีมูลค่าเพิ่ม
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Select
                                                    as="select"
                                                    name="cal_tax_solution_id"
                                                    onChange={(e) => {
                                                        setFieldValue('cal_tax_solution_id', e.value)
                                                    }}
                                                    className={!!errors.vatcalc ? ('w-100 select-st-1 isInvalid ') : 'w-100 select-st-1'}
                                                    options={optionsVatcalc}
                                                    placeholder="วิธีคิดภาษีมูลค่าเพิ่ม"
                                                    isInvalid={!!errors.vatcalc}
                                                    value={optionsVatcalc.filter(item => item.value === values.cal_tax_solution_id,)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div
                                            className="col-xl-6 text-center d-flex align-items-center justify-content-center">
                                            <Image src="/images/img2.png" width={143}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="text-end pb-4 pe-3">
                        {props.isSaleEdit === true ? (
                            <div className="text-end pt-3 btn-group-control">
                                <Button
                                    type="button"
                                    className="btn-st-5 me-3 hover:bg-red-500 hover:text-white"
                                    onClick={() => {
                                        props.setIsSaleEdit(false)
                                        resetForm()
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button type="submit" className="btn-st-3 hover:bg-transparent hover:text-[#565DFF]">บันทึก</Button>
                            </div>
                        ) : (
                            <Button
                                type="button"
                                className="btn-st-3 mx-3"
                                onClick={() => { props.setIsSaleEdit(true) }}
                            >
                                แก้ไข
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>

    )
}

const ImageUpload = (props) => {
    const [imgData, setImgData] = useState(false)
    const [imageURL, setImageURL] = useState(props.values.img_url)

    const handlePreviewImage = async (e) => {
        if (e.target.files[0]) {
            const res = await props.uploadImageFile(e.target.files[0])
            setImageURL(res)
            props.setFieldValue('img_url', res)
            // const reader = new FileReader()
            // reader.addEventListener('load', () => {
            //     setImgData(reader.result)
            //     props.setFieldValue('img_url', reader.result)
            // })
            // reader.readAsDataURL(e.target.files[0])
        }
    }

    useEffect(() => {
        setImageURL(props.values.img_url)
    }, [props.values.img_url, props.isBusinessEdit])

    return (
        <div className="d-flex align-items-center mb-4 mt-3 ">
            <div className="bx-upload">
                {(imageURL !== undefined && imageURL.length > 0) ? (
                    <img
                        src={`${process.env.REACT_APP_API_URL_SOCKET}/${imageURL}`}
                        alt="business"
                    />
                ) : (
                    <div className="preimg"></div>
                )}
            </div>
            <input
                type="file"
                name=""
                className="fileUpload hide-input"
                id="fileUpload"
                accept="image/*"
                onChange={(e) => { handlePreviewImage(e) }}
            />
            {props.isBusinessEdit && (
                <div className="flex-fill ms-3">
                    <div className="mb-3">
                        <button
                            type="button"
                            className="btn btn-st-4 btn-upload me-3 hover:bg-transparent hover:text-[#565DFF] hover:border-[#565DFF]"
                            onClick={() => document.getElementById('fileUpload').click()}
                        >
                            แก้ไขรูปภาพ
                        </button>
                        <button
                            type="button"
                            className="btn btn-st-2 btn-remove-image hover:bg-[#FF4D49] hover:text-white"
                            onClick={(e) => {
                                setImgData(false)
                                props.setFieldValue('img_url', '')
                            }}
                        >
                            ลบ
                        </button>
                    </div>
                    <div className="tc-7 fs-12">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                </div>
            )}
        </div>
    )
}