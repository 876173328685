export function getCurrentDate(separator = "", dateb = new Date()) {
  let newDate = dateb;

  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

export function getCurrentDateM(separator = "") {
  let d = new Date();

  let newDate = new Date(d.setMonth(d.getMonth() - 1));
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

export function getTimeDifference(startDate, endDate) {
  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Format the result as "hh:mm:ss"
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    seconds
  )}`;

  return formattedTime;
}

export function getActualTime(separator = ":", time = "") {
  let currentDate;
  if (time.length === 0) {
    currentDate = new Date();
  } else {
    currentDate = new Date(time);
  }

  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  const formattedHours = padZero(hours);
  const formattedMinutes = padZero(minutes);

  const formattedTime = `${formattedHours}${separator}${formattedMinutes}`;

  return formattedTime;
}

export const formatDateToYMD = (dateInput, separator = "/") => {
  // Split the input date string into day, month, and year components
  const [day, month, year] = dateInput.split("/").map(Number);

  // Construct a new Date object with the components (month - 1 because months are zero-based)
  const fullDate = new Date(year, month - 1, day);

  // Extract the date, month, and year from the new Date object
  const formattedDate = fullDate.getDate();
  const formattedMonth = fullDate.getMonth() + 1; // Add 1 because months are zero-based
  const formattedYear = fullDate.getFullYear();

  // Format the components with the provided separator
  const formattedDateString = `${formattedYear}${separator}${
    formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth
  }${separator}${formattedDate}`;

  console.log(`formatted date: ${formattedDateString}`);

  return formattedDateString;
};

// Helper function to pad zeros
function padZero(number) {
  return number < 10 ? `0${number}` : number;
}

export const localeStringDateTimeOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};

export const localeStringDateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export const convertDateFormat = (dateString) => {
  return dateString.replace(/\//g, "-");
};

export const getCurrentDateforApi = (separator = "", dateb = new Date()) => {
  let newDate = dateb;
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
};

export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};
