
import React, { useState,useEffect  } from 'react';
import Form from 'react-bootstrap/Form';
import { utils, writeFileXLSX } from 'xlsx';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";

import DataTableLayout from "layouts/DatatableLayout";
import  DownloadExcel from 'utils/DownloadExcel';


export function  Printer() {
    
    const [modalprinterShow, setModalprinterShow] = useState(false);
    const [modaleditprinterShow, setModaleditprinterShow] = useState(false);
    const [modalprinterDeleteShow, setModalprinterDeleteShow] = useState(false);
    const [modalsuccessShow, setModalsuccessShow] = useState(false);
    const [printereditdata, setprintereditdata] = useState({});

    const [printerdata, setPrinterdata] = useState([]);

    const getprinterData = () => {

        fetch('/json/printer.json')
          .then((response) => response.json())
          .then((result) => setPrinterdata(result) )
          .catch((error) => console.log("error", error));
      };
    useEffect(() => {
        getprinterData();
    }, []);

    const columns = [
        {
            name: 'ลำดับที่',
            selector: row => row.printerNo,
            cell: (row, index, column, id) => <div className='ps-4'>{row.printerNo}</div>,
        },
        {
            name: 'ชื่อเครื่องพิมพ์',
            selector: row => row.printerName,
        },
        {
            name: 'ประเภทการเชื่อมต่อ',
            selector: row => row.printerConnectType,
            center:'true'
        },
        {
            name: 'จัดการ',
            selector: row => row.categoryID,
            center:'true',
            cell: (row, index, column, id) => <div><Button type='button' className='btn-edit-st-1 ' onClick={ () => { setprintereditdata(row);setModaleditprinterShow(true); }   }></Button></div>,
        }
    ];

    const [filterText, setFilterText] = useState('');
	const filteredItems = printerdata.filter(
        item => item.printerName && item.printerName.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div style={{padding: '30px 33px 33px 31px'}}>
   

            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className='tc-7 fs-20 fw-6 px-4 mb-3'>ตั้งค่าเครื่องพิมพ์</div>
                    <div className='d-flex align-items-center px-4 mb-4'>
                        <DownloadExcel data={filteredItems} filename="รายชื่อเครื่องพิมพ์" />
                        <div className='me-3'>
                            <input type='text' className='form-control form-st-4' placeholder='ค้นหา ชื่อเครื่องพิมพ์' onKeyUp={e => setFilterText(e.target.value)} />
                        </div>
                        <Button type='button' className='btn btn btn-st-7' onClick={() => setModalprinterShow(true)}>เพิ่มเครื่องพิมพ์</Button>
                    </div>
 
                    <DataTableLayout 
                        columns={columns}
                        item={filteredItems}
                        isSelectRows={true}
                    />
                    
                </div>

                
            </div>
            <PrinterModal
                show={modalprinterShow}
                onHide={() => setModalprinterShow(false)}
            />
            <PrinterEditModal
                show={modaleditprinterShow}
                onHide={() => setModaleditprinterShow(false)}
                handleDelete={() => setModalprinterDeleteShow(true)}
                printereditdata={printereditdata}
            />
            <ConfirmDeleteModal
                show={modalprinterDeleteShow}
                onHide={() => setModalprinterDeleteShow(false)}
                onConfirm={() => setModalprinterDeleteShow(false)}
                onSuccess={() => setModalsuccessShow(true)}
                printereditdata={printereditdata}
                
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => setModalsuccessShow(false)}
            />
        </div>
    )
}

function PrinterModal(props) {

    const schema = yup.object().shape({
        printerName: yup.string().required(),
        printerConnect: yup.string().required(),
        paperSize: yup.string().required(),
        printerConnectType: yup.string().required(),
        
        printerIPAddress: yup.string()
        .when("printerConnectType", (printerConnectType, schema) => {
            if(printerConnectType == 'IP Address') 
              return schema.required()
            return schema
        }),

        printerUSBPort: yup.string()
        .when("printerConnectType", (printerConnectType, schema) => {
            if(printerConnectType == 'USB Port') 
              return schema.required()
            return schema
        }),
        

    });
    

    const [printerdata, setPrinterdata] = useState(
        {
            "printerName": "",
            "printerConnect": "",
            "paperSize": "",
            "printerConnectType": '',
        }
    );

    const optionPaper= [
        { value: 1, label: 'A4' },
        { value: 2, label: 'A5' }
    ];

    const optionConnect= [
        { value: 1, label: 'เครื่อง 1' },
        { value: 2, label: 'เครื่อง 2' }
    ];

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-20 tc-7 me-auto'>เพิ่มเครื่องพิมพ์</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ printerdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        props.onHide();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อเครื่องพิมพ์  <span className='text-danger'>*</span>   </Form.Label>
                            <Form.Control
                            type="text" 
                            name="printerName" 
                            className="form-control" 
                            placeholder="กรอกชื่อเครื่องพิมพ์"
                            value={values.printerName}
                            onChange={handleChange}
                            isInvalid={!!errors.printerName}  
                            />
                        </Form.Group>

                        <Form.Group className={`mb-2 form-st-3 pb-1` }>
                            <Form.Label className="mb-1">เชื่อมต่อกับจุดสั่งพิมพ์  <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="optionConnect"
                                onChange={(e) => {
                                    setFieldValue("printerConnect", e.value)
                                }}
                                className={`w-100 ${ errors.printerConnect ? 'isInvalid' : '' } select-st-1`}
                                options={optionConnect}
                                placeholder="เลือกจุดเชื่อมต่อ"
                            />
                        </Form.Group>

                        <Form.Group className={`mb-2 form-st-3 pb-1` }>
                            <Form.Label className="mb-1">ขนาดกระดาษ   <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="paperSize"
                                onChange={(e) => {
                                    setFieldValue("paperSize", e.value)
                                }}
                                className={`w-100 ${ errors.paperSize ? 'isInvalid' : '' } select-st-1`}
                                options={optionPaper}
                                placeholder="เลือกขนาดกระดาษ"
                            />
                        </Form.Group>
                        
                        <Form.Label className="mb-2  tc-10">ประเภทการเชื่อมต่อ</Form.Label>
                        <div className={`${ errors.printerConnectType ? 'isInvalid' : '' } form-checkCustom`}>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-2">
                            <Form.Check
                                inline
                                label="IP Address"
                                name="printerConnectType"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.printerConnectType == 'IP Address'}
                                onChange={(e) => {
                                    setFieldValue("printerConnectType", 'IP Address')
                                }}
                            />
                            <Form.Check
                                inline
                                label="USB Port"
                                name="printerConnectType"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.printerConnectType == 'USB Port'}
                                onChange={(e) => {
                                    setFieldValue("printerConnectType", 'USB Port')
                                }}
                            />
                            </div>
                        ))}
                        </div>
                        <div className='pt-1'></div>

                        {
                            values.printerConnectType == 'IP Address' ? (
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">IP Address   <span className='text-danger'>*</span>   </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="printerIPAddress" 
                                    className="form-control" 
                                    placeholder="กรอก IP Address"
                                    value={values.printerIPAddress}
                                    onChange={handleChange}
                                    isInvalid={!!errors.printerIPAddress}  
                                    />
                                </Form.Group>
                            )  : ''
                        }

                        {
                            values.printerConnectType == 'USB Port' ? (
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">USB Port  <span className='text-danger'>*</span>   </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="printerUSBPort" 
                                    className="form-control" 
                                    placeholder="USB Port"
                                    value={values.printerUSBPort}
                                    onChange={handleChange}
                                    isInvalid={!!errors.printerUSBPort}  
                                    />
                                </Form.Group>
                            )  : ''
                        }
                        
                        <div className="text-end pt-4 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'138px'}}>เพิ่มเครื่องพิมพ์</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function PrinterEditModal({printereditdata,handleDelete,...props}) {

    const schema = yup.object().shape({
        printerName: yup.string().required(),
        printerConnect: yup.string().required(),
        paperSize: yup.string().required(),
        printerConnectType: yup.string().required(),
        
        printerIPAddress: yup.string()
        .when("printerConnectType", (printerConnectType, schema) => {
            if(printerConnectType == 'IP Address') 
              return schema.required()
            return schema
        }),

        printerUSBPort: yup.string()
        .when("printerConnectType", (printerConnectType, schema) => {
            if(printerConnectType == 'USB Port') 
              return schema.required()
            return schema
        }),
        

    });
    

    const [printerdata, setPrinterdata] = useState(
        {
            "printerName": "",
            "printerConnect": "",
            "paperSize": "",
            "printerConnectType": '',
        }
    );

    const optionPaper= [
        { value: 1, label: 'A4' },
        { value: 2, label: 'A5' }
    ];

    const optionConnect= [
        { value: 1, label: 'เครื่อง 1' },
        { value: 2, label: 'เครื่อง 2' }
    ];

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-20 tc-7 me-auto'>แก้ไขเครื่องพิมพ์</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ printereditdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        props.onHide();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อเครื่องพิมพ์  <span className='text-danger'>*</span>   </Form.Label>
                            <Form.Control
                            type="text" 
                            name="printerName" 
                            className="form-control" 
                            placeholder="กรอกชื่อเครื่องพิมพ์"
                            value={values.printerName}
                            onChange={handleChange}
                            isInvalid={!!errors.printerName}  
                            />
                        </Form.Group>

                        <Form.Group className={`mb-2 form-st-3 pb-1` }>
                            <Form.Label className="mb-1">เชื่อมต่อกับจุดสั่งพิมพ์  <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="optionConnect"
                                onChange={(e) => {
                                    setFieldValue("printerConnect", e.value)
                                }}
                                value={
                                    optionConnect.filter(
                                        item => item.value === values.printerConnect
                                    )
                                }
                                className={`w-100 ${ errors.printerConnect ? 'isInvalid' : '' } select-st-1`}
                                options={optionConnect}
                                placeholder="เลือกจุดเชื่อมต่อ"
                            />
                        </Form.Group>

                        <Form.Group className={`mb-2 form-st-3 pb-1` }>
                            <Form.Label className="mb-1">ขนาดกระดาษ   <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="paperSize"
                                onChange={(e) => {
                                    setFieldValue("paperSize", e.value)
                                }}
                                value={
                                    optionPaper.filter(
                                        item => item.value === values.paperSize
                                    )
                                }
                                className={`w-100 ${ errors.paperSize ? 'isInvalid' : '' } select-st-1`}
                                options={optionPaper}
                                placeholder="เลือกขนาดกระดาษ"
                            />
                        </Form.Group>
                        
                        <Form.Label className="mb-2  tc-10">ประเภทการเชื่อมต่อ</Form.Label>
                        <div className={`${ errors.printerConnectType ? 'isInvalid' : '' } form-checkCustom`}>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-2">
                            <Form.Check
                                inline
                                label="IP Address"
                                name="printerConnectType"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.printerConnectType == 'IP Address'}
                                onChange={(e) => {
                                    setFieldValue("printerConnectType", 'IP Address')
                                }}
                            />
                            <Form.Check
                                inline
                                label="USB Port"
                                name="printerConnectType"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.printerConnectType == 'USB Port'}
                                onChange={(e) => {
                                    setFieldValue("printerConnectType", 'USB Port')
                                }}
                            />
                            </div>
                        ))}
                        </div>
                        <div className='pt-1'></div>

                        {
                            values.printerConnectType == 'IP Address' ? (
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">IP Address   <span className='text-danger'>*</span>   </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="printerIPAddress" 
                                    className="form-control" 
                                    placeholder="กรอก IP Address"
                                    value={values.printerIPAddress}
                                    onChange={handleChange}
                                    isInvalid={!!errors.printerIPAddress}  
                                    />
                                </Form.Group>
                            )  : ''
                        }

                        {
                            values.printerConnectType == 'USB Port' ? (
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">USB Port  <span className='text-danger'>*</span>   </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="printerUSBPort" 
                                    className="form-control" 
                                    placeholder="USB Port"
                                    value={values.printerUSBPort}
                                    onChange={handleChange}
                                    isInvalid={!!errors.printerUSBPort}  
                                    />
                                </Form.Group>
                            )  : ''
                        }

                        <div className="d-flex align-items-center pt-2 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-5 me-auto ' style={{width:'80px'}} 
                            onClick={ () => { 
                                handleDelete();
                                props.onHide() 
                            } } >ลบ</Button>
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>อัพเดท</Button>
                        </div>
                    </Form>
      
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function ConfirmDeleteModal(props) {
    const { onSuccess,onConfirm, ...rest } = props;

    const handleDelete = (printerID) => {
        props.onHide();
        props.onSuccess();
    }

    return (
      <Modal
        {...rest}
        size="md"
        centered
      >
        <Modal.Body>

            <div className='px-3 py-4'>

                <div>
                    <div>
                        <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                        <div className='text-center fs-22 tc-7'>เครื่องพิมพ์ {props.printereditdata.printerName} ใช่หรือไม่</div>
                    </div>

                    <div className="text-center pt-4 mt-3  btn-group-control" >
                        <Button type="button" className='btn-st-3 mx-3  ' style={{width:'100px'}}  onClick={ () => handleDelete(props.printereditdata.printerID)  }>ใช่</Button>
                        <Button type="button" className='btn-st-6 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ไม่ใช่</Button>
                    </div>
                </div>

            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function SuccessDeleteModal(props) {


    return (
      <Modal
        {...props}
        size="sm"
        centered
      >
        <Modal.Body>

            <div className='px-3 py-4'>
                <div className='text-center mb-4'>
                    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                    <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </div>
                <div className='text-center fs-22 tc-7'>ลบเครื่องพิมพ์สำเร็จ</div>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
{
 return(
     <>
         <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
             <input 
                 type="checkbox"
                 className="form-check-input"
                 style={{ height: '18px', width: '18px' }}
                 ref={ref}
                 onClick={ onClick }
                 {...rest}
             />
             <label className="form-check-label" id="booty-check" />
         </div>
     </>
 )
})

function getCurrentDate(separator='',dateb=new Date()){

    let newDate = dateb;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}