import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MonthPicker from "simple-react-month-picker";
import { MultiSelect } from "react-multi-select-component";

import CheckboxTable from "fragments/CheckboxTable";
import  DownloadExcel from 'utils/DownloadExcel';

import Button from "react-bootstrap/Button";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { appState } from "../../state/app";
import { authState } from "../../state/auth";
import pos2 from "../../img/pos2.png";
import { MoonLoader } from "react-spinners";
import {
  getCurrentDate,
  convertDateFormat,
  getCurrentDateM,
  formatDateToYMD,
  subtractDays,
  getCurrentDateforApi,
} from "utils/dateFormat";
import Select from "react-select";
import { default as ReactSelect, components, InputAction } from "react-select";
import DailyApi from "services/report/DailyApi";




export function ReportProduct() {
  const { token, setToken } = useToken();
  let { businessId } = useParams();
  const navigate = useNavigate();
  const { slug } = useRecoilValue(appState);
  const { user } = useRecoilValue(authState);
  const BASE_URL =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard/total";
  const BASE_URL_POS =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard";

  const [business, setBusiness] = useState(businessId);
  const [reportdata, setreportdata] = useState([]);
  const [searchdata, setSearchdata] = useState({
    branchID: "",
    posID: "",
    startDate: getCurrentDate("/"),
    endDate: getCurrentDate("/"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResetSearch, setShowResetSearch] = useState(false);
  const [pos, setPos] = useState([]);
  const [dateApplied, setDateApplied] = useState(false);
  const currentDate = new Date();
  const endDate = getCurrentDateforApi("-", currentDate);
  const startDate = getCurrentDateforApi("-", subtractDays(currentDate, 7));
  const [fetchDate, setCurrentDate] = useState({ startDate, endDate });
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPos, setOptionsPos] = useState([]);
  const keyRef = useRef();


  const [columns, setColumns] = useState([]);


  const defaultColumns = [
    {
        name: (
          <div className="cheadpadding w-100 text-center" >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3 ">รหัสสินค้า</div>
          </div>
        ),
        selector: row => row.code,
        width: "125px",
    },
    {
        name: (
          <div className="cheadpadding w-100 " >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3">สินค้า</div>
          </div>
        ),
        selector: row => row.name,
        cell: (row, index, column, id) => (
          <div className="pimage">
            <img
              alt={`product-${row.image}`}
              src={`${row.image}`}
            />
            <div>
              <div
                style={{fontWeight: 500, marginBottom: "5px" }}
              >
                {row.name}
              </div>
              <div style={{ fontSize: "12px", color: "#565DFF" }}>{row.code}</div>
            </div>
          </div>
        ),
        minWidth: "300px",
     
    },
    {
        name: (
          <div className="cheadpadding w-100 text-center" >
            <div className=" d-flex align-items-center  text-nowrap" style={{paddingLeft:'35px',height:'42px'}}>รวมทุกสาขา</div>
            <div className="py-3">ราคาขาย</div>
          </div>
        ),
        selector: row => row.price,
        center: 'true',
        width: "125px",
    },
    {
        name: (
          <div className="cheadpadding w-100 text-center"  >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3">จำนวนที่ขายได้</div>
          </div>
        ),

        selector: row => row.quantity_sold,
        center: 'true',
        width: "125px",
    },
    {
        name: (
          <div className="cheadpadding w-100 text-center" >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3">ยอดขาย</div>
          </div>
        ),
        selector: row => row.sales,
        center: 'true',
        width: "125px",
    },
    {
        name: (
          <div className="cheadpadding w-100 text-center" >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3">ยอดขายตัวเลือก</div>
          </div>
        ),
        selector: row => row.sales_option,
        center: 'true',
        width: "125px",
    },
    {
        name: (
          <div className="cheadpadding w-100 text-center" >
            <div className=" d-flex align-items-center" style={{height:'42px'}}></div>
            <div className="py-3">ยอดขายรวม</div>
          </div>
        ),
        selector: row => row.sales_total,
        center: 'true',
        cell: (row, index, column, id) => <div style={{color:"#565DFF",fontWeight:700}}>{row.sales_total}</div>,
        width: "125px",
    },


]


  const customStyles = {
    rows: {
      style: {
        minHeight: "62px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "14px",
        background: " #F5F5F7",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: "500",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background: " #FFF",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    expanderRow: {
      style: {
        minHeight: "52px",
      },
    },
    pagination: {
      style: {
        borderBottomRightRadius: "0.75rem",
        borderBottomLeftRadius: "0.75rem",
      },
    },
  };


  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const setNewColumn = (branchColumn) => {


    if (branchColumn !== undefined && branchColumn.length > 0) {
      branchColumn.map((item,index) => {
        
        let coln =     
          [
          {

              name: (
                <div className="cheadpadding w-100 text-center" >
                  <div className=" d-flex align-items-center text-nowrap" style={{paddingLeft:'20px',height:'42px',backgroundColor:'#565DFF',color:'#FFF',width:'125px'}}>{item.name} ( {item.code} )</div>
                  <div className="py-3">จำนวนที่ขายได้</div>
                </div>
              ),
              selector: row => row.branch[index].quantity_sold,
              center: 'true',
              width: "125px",
          },
          {
              name: (
                <div className="cheadpadding w-100 text-center" >
                  <div className=" d-flex align-items-center" style={{height:'42px',backgroundColor:'#565DFF',color:'#FFF'}}></div>
                  <div className="py-3">ยอดขาย</div>
                </div>
              ),
              selector: row => row.branch[index].sales,
              center: 'true',
              width: "125px",
          },
          {
              name: (
                <div className="cheadpadding w-100 text-center" >
                  <div className=" d-flex align-items-center" style={{height:'42px',backgroundColor:'#565DFF',color:'#FFF'}}></div>
                  <div className="py-3">ยอดขายตัวเลือก</div>
                </div>
              ),
              selector: row => row.branch[index].sales_option,
              center: 'true',
              width: "125px",
          },
          {
              name: (
                <div className="cheadpadding w-100 text-center">
                  <div className=" d-flex align-items-center" style={{height:'42px',backgroundColor:'#565DFF',color:'#FFF'}}></div>
                  <div className="py-3">ยอดขายรวม</div>
                </div>
              ),
              selector: row => row.branch[index].sales_total,
              center: 'true',
              cell: (row, index, column, id) => <div style={{color:"#565DFF",fontWeight:700}}>{row.branch[0].sales_total}</div>,
              width: "125px",
          }];

          Array.prototype.push.apply(defaultColumns,coln); 

      })

      setColumns(defaultColumns)
    }

  

  }


  const handleSearch = () => {
    setIsLoading(true);
    let formattedStartDate = searchdata.startDate;
    let formattedEndDate = searchdata.endDate;

    

    let exSearchData = {
      "branch":[
        {
          "name":"สาขา ",
          "code":"00001"
        },
        {
          "name":"สาขา xxx",
          "code":"00002"
        }
      ],
      "data" : [
        {
            "code":"MT-SH008",
            "name":"ผัดพริกแกงหมู",
            "image":"/images/product/p1.jpg",
            "barcode":"-",
            "price":"169.00",
            "quantity_sold":"10",
            "sales":"1,690.00",
            "sales_option":"500.00",
            "sales_total":"2,190.00",
            "branch":[
              {
                  "quantity_sold":"10",
                  "sales":"1,690.00",
                  "sales_option":"500.00",
                  "sales_total":"2,190.00",
              },
              {
                "quantity_sold":"10",
                "sales":"1,690.00",
                "sales_option":"500.00",
                "sales_total":"2,190.00",
              }
            ]
        },
        {
          "code":"MT-SH008",
          "name":"ผัดพริกแกงหมู",
          "image":"/images/product/p1.jpg",
          "barcode":"-",
          "price":"169.00",
          "quantity_sold":"10",
          "sales":"1,690.00",
          "sales_option":"500.00",
          "sales_total":"2,190.00",
          "branch":[
            {
                "quantity_sold":"10",
                "sales":"1,690.00",
                "sales_option":"500.00",
                "sales_total":"2,190.00",
            },
            {
              "quantity_sold":"10",
              "sales":"1,690.00",
              "sales_option":"500.00",
              "sales_total":"2,190.00",
            }
          ]
        }
      ]
    };



    setNewColumn(exSearchData.branch)
    setShowResetSearch(true);
    setreportdata(exSearchData.data);
    setIsLoading(false);



  };
  const resetFilter = () => {
    setShowResetSearch(false);
    setreportdata([]);
    setBranchSelected([])
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const handleApply = (event, picker) => {
    setSearchdata((prev) => ({
      ...prev,
      startDate: picker.startDate.format("YYYY/MM/DD"),
    }));
    setSearchdata((prev) => ({
      ...prev,
      endDate: picker.endDate.format("YYYY/MM/DD"),
    }));
    setDateApplied(true);
  };

  const getBranchData = async () => {
    DailyApi.GetAllBranchByBussiness(config).then((res) => {
      const data = res.data;
      const options = [];


      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsBranch(options);
    });
  };


  useEffect(() => {
    if (user.businessId && token) {
      getBranchData();
      setColumns(defaultColumns)

    }
  }, [user.businessId, token]);


  const [branchSelected, setBranchSelected] = useState([]);


  const [filterText, setFilterText] = useState("");

  let filteredItems;
  if (reportdata !== undefined && reportdata.length > 0) {
    filteredItems = reportdata.filter((item) => {
      const matchCode = item.code
        .toLowerCase()
        .includes(filterText.toLowerCase());
        const matchName = item.name
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchCode || matchName;
    });
  }

  return (
    <div>

      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายงาน
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            ยอดขายรายสินค้า{" "}
          </li>
        </ol>
      </nav>

      <div className="card card-st-2 mb-4">
        <div className="card-body  py-4">
          <div className="fw-bold mb-3">ค้นหาขั้นสูง</div>
          <div className="row items-center">
            <div className="col-xl-2 col-lg-3 col-2">


              <MultiSelect
                options={optionsBranch}
                className={`w-100 select-st-1 pd`}
                value={branchSelected}
                onChange={setBranchSelected}
                labelledBy="Select"
                overrideStrings={ {"selectAll":"ทั้งหมด","allItemsAreSelected": "ทั้งหมด","selectSomeItems": "เลือกสาขา...",} }
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-2">
                <DateRangePicker
                    initialSettings={{
                    startDate: searchdata.startDate,
                    endDate: searchdata.endDate,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    }}
                    onApply={handleApply}
                >
                    <input
                    type="text"
                    className="form-control iconcalendar-right"
                    />
                </DateRangePicker>
            </div>
            <div className="col-auto">
              <Button
                type="button"
                className="btn-st-3 flex justify-center items-center"
                style={{ width: "120px", height: "40px" }}
                onClick={handleSearch}
              >
                ค้นหา
              </Button>
            </div>
            {showResetSearch && (
              <div className="col-auto">
                <Button
                  type="button"
                  className="bg-orange-400 flex justify-center items-center hover:bg-orange-300"
                  style={{ width: "150px", height: "40px",border:0 }}
                  onClick={resetFilter}
                >
                  ล้างการค้นหา
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-start">
          <MoonLoader
            color="#565DFF"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="mt-40"
          />
        </div>
      )}
      {showResetSearch && (
        <div className="card card-st-2">
          <div className="card-body p-0 rounded-xl">
            <div className="d-flex align-items-center px-4 mb-4 pt-4 rd-0">
              <DownloadExcel data={filteredItems} filename="ยอดขายรายสินค้า" />
              <div>
                <input
                  type="text"
                  className="form-control form-st-4"
                  placeholder="ค้นหา"
                  onKeyUp={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredItems}
              customStyles={customStyles}
              selectableRows
              selectableRowsComponent={CheckboxTable}
              className="data-custom-header"
              pagination
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </div>
      )}  
    </div>
  );
}
