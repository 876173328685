export const customStyles = {
    rows: {
        style: {
            minHeight: '52px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '15px', 
            paddingRight: '15px',
            color: 'rgba(76, 78, 100, 0.87)',
            fontFamily: 'Sarabun',
            fontSize: '16px',
            background: ' #F5F5F7',
            paddingTop: '15px', 
            paddingBottom: '15px',

        },
    },
    cells: {
        style: {
            paddingLeft: '15px', 
            paddingRight: '15px',
            paddingTop: '10px', 
            paddingBottom: '10px',
            color: 'rgba(76, 78, 100, 0.87)',
            fontFamily: 'Sarabun',
            fontSize: '16px',
            fontWeight: '300'
        },
    },
};
export const paginationComponentOptions = {
    rowsPerPageText: 'จำนวนรายการต่อหน้า',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};