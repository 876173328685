import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

import { utils, writeFileXLSX } from "xlsx";

import Button from "react-bootstrap/Button";

import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "components/BusinessEmp/Checkbox";
import BusinessEmpApi from "services/BusinessEmpApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";

export default function BusinessEmpList() {
  const [empdata, setEmpdata] = useState([]);
  const [empCanceldata, setEmpCanceldata] = useState([]);
  const [empAlldata, setEmpAlldata] = useState([]);

  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const getEmpData = () => {
    BusinessEmpApi.GetAll(config)
      .then((res) => {
        // console.log(res.data);
        const data = res.data;

        data.map((item) => {
            // first_name + last_name + nickname
            item.empName =`${item.first_name_th} ${item.last_name_th}`;
            item.empNickname = item.nickname;
        });
        setEmpdata(data);
        // setEmpAlldata(res.data);
        // setEmpCanceldata(res.data.filter((item) => item.status == 0));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user.businessId && token) {
      getEmpData();
    }
  }, [user.businessId, token]);

  const columns = [
    {
      name: "รหัสพนักงาน",
      selector: (row) => row.code,
      width: "150px",
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.code}</div>
      ),
    },
    {
      name: "ชื่อพนักงาน (ชื่อเล่น)",
      selector: (row) => row.empName,
      cell: (row, index, column, id) => (
        <div>
          {row.empName} ( {row.empNickname} )
        </div>
      ),
    },

    {
      name: "แผนก",
      selector: (row) => row.department.name_th,
    },
    {
      name: "ตำแหน่ง",
      selector: (row) => row.position.name_th,
      cell: (row, index, column, id) =>
        row.position == "เจ้าของธุรกิจ" ? (
          <div
            className="py-1 px-3"
            style={{
              color: "#565DFF",
              background: "#EEF4FF",
              borderRadius: "8px",
            }}
          >
            {row.position}
          </div>
        ) : (
          <div className="ps-3">{row.position.name_th}</div>
        ),
    },
    {
      name: "เบอร์โทร",
      selector: (row) => row.phone_number,
    },
    {
      name: "E-MAIL",
      selector: (row) => row.email,
    },
    {
      name: "จัดการ",
      selector: (row) => row.empID,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div>
          <Link
            className="btn btn-edit-st-1 me-2"
            to={"edit/" + row.id}
          ></Link>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        background: " #F5F5F7",
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        fontWeight: "300",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const [filterText, setFilterText] = useState("");
  const filteredItems = empdata.filter(
    (item) =>
      (item.code &&
        item.code.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.empName &&
        item.empName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "รายชื่อพนักงาน");
    writeFileXLSX(wb, "รายชื่อพนักงาน.xlsx");
  };

  const [btnempstatus, setBtnempstatus] = useState(1);

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" className="mt-2">
          <ol className="breadcrumb fs-16 ps-0">
            <li className="breadcrumb-item ">
              <a href="#" className="tc-7  text-decoration-none">
                รายชื่อพนักงาน
              </a>
            </li>
            <li className="breadcrumb-item active tc-1 " aria-current="page">
              {" "}
              รายชื่อพนักงาน{" "}
            </li>
          </ol>
        </nav>

        <div className="mb-3">
          <Button
            type="button"
            className={
              btnempstatus == 1
                ? "btn-category px-4 active"
                : "btn-category px-4"
            }
            onClick={() => {
              setBtnempstatus(1);
              setEmpdata(empAlldata);
            }}
          >
            พนักงานทั้งหมด (<span>{empAlldata.length}</span>)
          </Button>
          <Button
            type="button"
            className={
              btnempstatus == 0
                ? "btn-category px-4 active"
                : "btn-category px-4"
            }
            onClick={() => {
              setBtnempstatus(0);
              setEmpdata(empCanceldata);
            }}
          >
            พนักงานที่ลาออก (<span>{empCanceldata.length}</span>){" "}
          </Button>
        </div>
      </div>

      <div className="card card-st-2">
        <div className="card-body px-0 pt-4 pb-0">
          <div
            className="fs-18 fw-bold me-auto px-4 mb-4"
            style={{ color: "#565DFF" }}
          >
            รายชื่อพนักงาน
          </div>

          <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
            <button
              className="btn btn-export-st-1 mb-2 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3 mb-2">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อ, ชื่อเล่น, รหัสพนักงาน, เบอร์โทร"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Link 
              to={"create"}
              className="btn btn btn-st-7 mb-2 px-4"
              >
              เพิ่มพนักงาน
            </Link>
          </div>

          <DataTable
            columns={columns}
            data={filteredItems}
            customStyles={customStyles}
            selectableRows
            selectableRowsComponent={Checkbox}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </div>
  );
}
