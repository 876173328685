import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAll = (config) =>
  axiosClient.get(`/branch-users`, config);

const GetUserById = (config, id) =>
  axiosClient.get(`/branch-users/${id}`, config);

const CreateUserBranch = (config, data) =>
  axiosClient.post(`/branch-users`, data, config);

export default {
    GetAll,
    GetUserById,
    CreateUserBranch
};
