import React, { useEffect, useState } from 'react'
import { LoginBusiness } from './pages/business/login'
import { RegisterBusiness } from './pages/business/register'
import { Business } from './pages/business/business'
import { Login } from './pages/login'
import { Layout } from './layouts/Layout'
import { BusinessCreate } from './pages/business'
import { BusinessLink } from './pages/businessLink'
import { BranchCreate, BranchDataList, BranchEdit } from './pages/Branch'
import { CategoryDataList } from './pages/product/Category'
import { SubCategoryDataList } from './pages/product/Subcategory'
import { OptionCreate, OptionEdit, OptionStandard } from './pages/product/OptionStandard'
import { Optionnote,OptionNoteCreate,OptionNoteEdit } from './pages/product/OptionNote'


import { Product, ProductCreate,ProductEdit } from './pages/product/product'
import { Productunit } from './pages/product/productunit'
import { WarehouseDataList, WarehouseEdit } from './pages/warehouse/warehouse'
import { IngredientManage, IngredientsList } from 'pages/warehouse/Ingredients'
import { Department } from './pages/employee/department'
import { Position } from './pages/employee/position'
import { EmpManageBusiness, EmpManagePermission } from './pages/employee/business'
import { Emp, EmpCreate } from './pages/employee/emp'
import BusinessEmpList from 'pages/employee/list'
import EmpForm from 'pages/employee/EmpForm'
import { BranchSettingMenu } from './layouts/layoutbranch'
import { BranchDetails } from './pages/branch/BranchDetails'
import { Workingtime } from './pages/branch/workingtime'
import { Salesmodel } from './pages/branch/salesmodel'
import { Payment } from './pages/branch/payment'
import { Kitchen } from './pages/branch/kitchen'
import KitchenManage from 'pages/branch/kitchenManage'
import { Printer } from './pages/branch/printer'
import { DocumentSetting } from 'pages/branch/document'
import { Notification } from 'pages/branch/notification'
import { Pos } from 'pages/branch/pos'
import { EmpManage, EmpSetting } from 'pages/branch/emp'
import { TableSetting } from 'pages/branch/tables'
import { Delivery } from 'pages/branch/delivery'
import { ProductBranch, ProductBranchEdit} from 'pages/branch/product'
import { Customer, CustomerCreate } from 'pages/customers/customer'

import { ReportSales } from 'pages/report/sales'
import { ReportMonthlySales } from 'pages/report/ReportMonthlySales'
import { ReportProduct } from 'pages/report/ReportProduct'
import { ReportCategory } from 'pages/report/ReportCategory'
import { ReportBill } from 'pages/report/ReportBill'
import { ReportBillDetail } from 'pages/report/BillDetail'



import { MarketingPoint } from 'pages/marketing/Point'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useToken from 'utils/UseToken'
import { appState, useBusinessSlugCheck } from './state/app'
import { authState, useAuthTokenCheck, useClientCheck } from './state/auth'
import { posState } from './state/pos'
import NotFoundPage from './components/pages/NotFoundPage'
import { socket } from './socket';
import LoginPage from './components/pages/LoginPage'
import CloseBalancePrint from './components/pages/CloseBalancePrintPage'
import EmpList from 'pages/branch/employee/list'
import EmployeeManage from 'pages/branch/employee/form'

function App () {
    const clientCheck = useClientCheck()
    const authTokenCheck = useAuthTokenCheck()
    const businessSlugCheck = useBusinessSlugCheck();
    const navigate = useNavigate()
    const location = useLocation();

    const [selectbranchID, setSelectBranchID] = useState(1)
    const [isConnected, setIsConnected] = useState(socket.connected);
    const { slug, isValidSlugChecked, isValidSlug } = useRecoilValue(appState)
    const { isLoggedIn, isTokenChecked, isFromLoginPage, user } = useRecoilValue(authState)
    const { isPosStarted, isKitchenStarted } = useRecoilValue(posState)
    const { token, setToken } = useToken()

    // if (!token) {
    //     return <Login setToken={setToken}/>
    // }

    // return (
    //   <Routes>
    //     <Route path="/" element={<Business />}>
    //       {" "}
    //     </Route>
    //     <Route
    //       path="/"
    //       element={
    //         <Layout
    //           setSelectBranchID={setSelectBranchID}
    //           selectbranchID={selectbranchID}
    //         />
    //       }
    //     >
    //       <Route path="/report/sales/:businessId" element={<ReportSales />} />

    //     </Route>

    //     <Route path="/business/login" element={<LoginBusiness />}>
    //       {" "}
    //     </Route>
    //   </Routes>
    // );

    // useEffect(() => {
    //     console.log('dev fix 0.4.8')
    //     if(location.pathname.split("/").length > 0) {
    //         var posSlug = location.pathname.split("/")[1];
    //         businessSlugCheck(posSlug);
    //     }
    //
    //     function onConnect() {
    //         setIsConnected(true);
    //     }
    //
    //     function onDisconnect() {
    //         setIsConnected(false);
    //     }
    //
    //     socket.on('connect', onConnect);
    //     socket.on('disconnect', onDisconnect);
    //
    //     return () => {
    //         socket.off('connect', onConnect);
    //         socket.off('disconnect', onDisconnect);
    //     };
    // }, []);

    // Init load, check slug
    useEffect(() => {
        console.log('dev fix 0.5.0')
        if(location.pathname.split("/").length > 0) {
            var posSlug = location.pathname.split("/")[1];
            // console.log('pos slug:', posSlug);
            businessSlugCheck(posSlug);
        }

        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, []);

    // After check slug
    useEffect(() => {
        if (isValidSlugChecked) {
            // If slug valid, continue client and auth check
            if (isValidSlug) {
                clientCheck()
                authTokenCheck()
            } else {
                // For seamless migration
                authTokenCheck()
            }
        }
    }, [isValidSlugChecked, isValidSlug])

    useEffect(() => {
        console.log('dev fix 0.4.10', user)
        if (isTokenChecked) {
            if (slug) {
                if (!isLoggedIn) {
                    navigate(`/${slug}/login`)
                } else if (isLoggedIn && isFromLoginPage) {
                    navigate(`/${slug}`)
                    // navigate(`/${slug}/report/sales/${user.businessId}`)
                    // if (isLoggedIn && !isPosStarted) {
                    //     // navigate(`/${slug}/pos-setting`)
                    //     navigate(`/${slug}/report/sales/${user.businessId}`)
                    // } else if (isLoggedIn && isPosStarted) {
                    //     navigate(`/${slug}/`)
                    // } else {
                    //     // navigate(`/${slug}/pos-setting`)
                    //     navigate(`/${slug}/report/sales/${user.businessId}`)
                    // }
                } else if (isLoggedIn) {
                    // navigate(`/${slug}`)
                    // navigate(`/${slug}/report/sales/${user.businessId}`)
                    // go to current page
                    // navigate(location.pathname)
                } else {
                    navigate(`/${slug}/login`)
                }
            } else {
                // console.log('slug not found')
                // navigate('/not-found')
            }
        }
    }, [isTokenChecked, isLoggedIn])

    return (
        <Routes>
            <Route path={`/${slug}/login`} element={<LoginPage />}> </Route>
            <Route path="/business/register" element={<RegisterBusiness/>}> </Route>
            {/*<Route path="/" element={<Business/>}> </Route>*/}
            {/* <Route path="/" element={<Login />}> </Route> */}

            <Route
                path={`/${slug}`}
                element={
                    <Layout setSelectBranchID={setSelectBranchID} selectbranchID={selectbranchID}>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path={`setting/business`} element={<BusinessCreate/>}/>
                <Route path={`setting/uselink`} element={<BusinessLink/>}/>
                <Route path={`setting/product/unit`} element={<Productunit/>}/>

                <Route path={`branch`} element={<BranchDataList/>}/>
                <Route path={`branch/create`} element={<BranchCreate/>}/>
                <Route path={`branch/edit/:branchID`} element={<BranchEdit/>}/>

                <Route path={`${slug}/branch/setting/workingtime/:branchID`} element={<Workingtime/>}/>
                <Route path={`${slug}/branch/setting/salesmodel/:branchID`} element={<Salesmodel/>}/>
                <Route path={`${slug}/branch/setting/payment/:branchID`} element={<Payment/>}/>

                <Route path={`${slug}/branch/setting/kitchen/:branchID`} element={<Kitchen/>}/>
                <Route path={`${slug}/branch/setting/kitchen/:branchID/manage`} element={<KitchenManage/>}/>
                <Route path={`${slug}/branch/setting/kitchen/:branchID/manage/:kitchenID`} element={<KitchenManage/>}/>

                <Route path={`${slug}/branch/setting/printer/:branchID`} element={<Printer/>}/>

                <Route path={`${slug}/branch/setting/document/:branchID`} element={<DocumentSetting/>}/>

                <Route path={`${slug}/branch/setting/notification/:branchID`} element={<Notification/>}/>

                <Route path={`${slug}/branch/setting/pos/:branchID`} element={<Pos/>}/>

                {/* <Route path={`${slug}/branch/setting/emp/:branchID`} element={<EmpSetting/>}/>
                <Route path={`${slug}/branch/setting/emp/:branchID/manage`} element={<EmpManage/>}/>
                <Route path={`${slug}/branch/setting/emp/:branchID/manage/:permissionID`} element={<EmpManage/>}/> */}

                <Route path={`${slug}/branch/setting/table/:branchID`} element={<TableSetting/>}/>

                <Route path={`${slug}/branch/setting/delivery/:branchID`} element={<Delivery/>}/>

                {/*<Route path={`${slug}/branch/setting/product/:branchID`} element={<ProductBranch/>}/>*/}

                <Route
                    path={`branch/setting`}
                    element={
                        <BranchSettingMenu
                            setSelectBranchID={setSelectBranchID}
                            selectbranchID={selectbranchID}
                        >
                            <Outlet />
                        </BranchSettingMenu>
                    }
                >
                    <Route path={`general/:branchID`} element={<BranchDetails />} />
                    <Route path={`product/:branchID`} element={<ProductBranch />} />
                    <Route path={`product/:branchID/manage/:productID`} element={<ProductBranchEdit />} />
                    <Route path={`kitchen/:branchID`} element={<Kitchen/>}/>
                    <Route path="kitchen/:branchID/manage" element={<KitchenManage/>}/>
                    <Route path="kitchen/:branchID/manage/:kitchenID" element={<KitchenManage/>}/>
                    {/* <Route path={`emp/:branchID`} element={<EmpSetting/>}/> */}
                    <Route path={`emp/:branchID`} element={<EmpList/>}/>
                    <Route path={`emp/:branchID/manage`} element={<EmployeeManage/>}/>
                    <Route path={`emp/:branchID/manage/:permissionID`} element={<EmployeeManage/>}/>
                    {/* <Route path={`branch/setting/general/:branchID`} element={<BranchDetails/>} /> */}
                    {/* <Route path={`branch/setting/general`} element={<BranchDetails/>} /> */}
                    {/*    <Route path={`${slug}/branch/setting/general/:branchID`} element={<BranchDetails/>} />*/}
                    {/*</Route>*/}
                    {/*<Route path="/branch/setting/workingtime/:branchID" element={<Workingtime/>}/>*/}
                    {/*<Route path="/branch/setting/salesmodel/:branchID" element={<Salesmodel/>}/>*/}
                    {/*<Route path="/branch/setting/payment/:branchID" element={<Payment/>}/>*/}

                    {/*<Route path="/branch/setting/kitchen/:branchID" element={<Kitchen/>}/>*/}
                    {/*<Route path="/branch/setting/kitchen/:branchID/manage" element={<KitchenManage/>}/>*/}
                    {/*<Route path="/branch/setting/kitchen/:branchID/manage/:kitchenID" element={<KitchenManage/>}/>*/}

                    {/*<Route path="/branch/setting/printer/:branchID" element={<Printer/>}/>*/}

                    {/*<Route path="/branch/setting/document/:branchID" element={<DocumentSetting/>}/>*/}

                    {/*<Route path="/branch/setting/notification/:branchID" element={<Notification/>}/>*/}

                    {/*<Route path="/branch/setting/pos/:branchID" element={<Pos/>}/>*/}

                    {/*<Route path="/branch/setting/emp/:branchID" element={<EmpSetting/>}/>*/}
                    {/*<Route path="/branch/setting/emp/:branchID/manage" element={<EmpManage/>}/>*/}
                    {/*<Route path="/branch/setting/emp/:branchID/manage/:permissionID" element={<EmpManage/>}/>*/}

                    {/*<Route path="/branch/setting/table/:branchID" element={<TableSetting/>}/>*/}

                    {/*<Route path="/branch/setting/delivery/:branchID" element={<Delivery/>}/>*/}

                    {/*<Route path="/branch/setting/product/:branchID" element={<ProductBranch/>}/>*/}
                    {/*<Route path="/branch/setting/product/:branchID/manage/:productID" element={<ProductBranchCreate/>}/>*/}
                </Route>

                <Route path={`products`} element={<Product/>}/>
                <Route path={`products/create`} element={<ProductCreate/>}/>
                {/* <Route path="/products/:pset/create/" element={<ProductCreate/>}/> */}
                <Route path={`products/edit/:productID`} element={<ProductEdit/>}/>

                {/* <Route path="/products/:pset/edit/:productID" element={<ProductCreate/>}/> */}

                <Route path={`product/category`} element={<CategoryDataList/>}/>
                <Route path={`product/subcategory`} element={<SubCategoryDataList/>}/>
                <Route path={`product/options/standard`} element={<OptionStandard/>}/>
                <Route path={`product/options/standard/create`} element={<OptionCreate/>}/>
                <Route path={`product/options/standard/edit/:optionID`} element={<OptionEdit/>}/>

                <Route path={`product/options/note`} element={<Optionnote/>}/>
                <Route path={`product/options/note/create`} element={<OptionNoteCreate/>}/>
                <Route path={`product/options/note/edit/:optionID`} element={<OptionNoteEdit/>}/>

                {/* พนักงาน / รายชื่อพนักงาน */}
                <Route path={`emps`} >
                    <Route index element={<BusinessEmpList />}/>
                    <Route path={`create`} element={<EmpForm />}/>
                    <Route path={`edit/:empID`} element={<EmpForm />}/>
                </Route>
                {/* end พนักงาน / รายชื่อพนักงาน */}

                {/* <Route path="product/unit" element={<Productunit/>}/>

                <Route path="/warehouse" element={<WarehouseDataList/>}/>
                <Route path="/warehouse/edit/:warehouseID" element={<WarehouseEdit/>}/>
                <Route path="/ingredients" element={<IngredientsList/>}/>
                <Route path="/ingredients/manage" element={<IngredientManage/>}/>
                <Route path="/ingredients/manage/:ingredientID" element={<IngredientManage/>}/>

                <Route path="/emps" element={<Emp/>}/>
                <Route path="/emps/create" element={<EmpCreate/>}/>
                <Route path="/emps/edit/:empID" element={<EmpCreate/>}/>

                <Route path="/emp/department" element={<Department/>}/>
                <Route path="/emp/position" element={<Position/>}/>

                <Route path="/emp/business" element={<EmpManageBusiness/>}/>
                <Route path="/emp/business/create" element={<EmpManagePermission/>}/>
                <Route path="/emp/business/edit/:permissionID" element={<EmpManagePermission/>}/>

                <Route path="/customer" element={<Customer/>}/>
                <Route path="/customer/create" element={<CustomerCreate/>}/>
                <Route path="/customer/edit/:customerID" element={<CustomerCreate/>}/> */}

                <Route path={`report/sales`} element={<ReportSales/>}/>
                <Route path={`report/sales/:businessId`} element={<ReportSales/>}/>
                <Route path={`report/sales/:businessId/close-balance-print`} element={<CloseBalancePrint/>} />


                <Route path={`report/monthly/sales/:businessId`} element={<ReportMonthlySales/>}/>
                <Route path={`report/product/sales/:businessId`} element={<ReportProduct/>}/>
                <Route path={`report/category/sales/:businessId`} element={<ReportCategory/>}/>
                <Route path={`report/bill/sales/:businessId`} element={<ReportBill/>}/>
                <Route path={`report/bill/detail/:billID`} element={<ReportBillDetail/>}/>

                {/* <Route path="/marketing/point" element={<MarketingPoint/>}/> */}
                
            </Route>
            <Route path={`/not-found`} element={<NotFoundPage />}></Route>
            <Route path={`*`} element={<NotFoundPage />}></Route>
        </Routes>
    )
}

function Home () {
    return <div></div>
}

function Layoutadmin () {
    return (
        <div className="page">
            <Layout />
            <div className="main-content app-content"></div>
        </div>
    )
}

export default App