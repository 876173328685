import CustomBtn from 'components/fragments/CustomBtn'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
// import 'print.css';
// import { useUpdatePos } from "state/pos";
import { useRecoilValue } from 'recoil'
import { posState, useGetPOSClosureSummary } from 'state/pos'
import { authState, useFetchBranch } from 'state/auth'
import { getActualTime, getCurrentDate, getCurrentDateM } from 'utils/dateFormat'
import { numberWithTwoDecimals } from 'utils/numberFormat'
import { appState } from 'state/app'
import useToken from 'utils/UseToken'
import { MoonLoader } from 'react-spinners'

export default function CloseBalancePrint () {
    const navigate = useNavigate()
    const { slug } = useRecoilValue(appState)

    // const updatePOS = useUpdatePos();
    const getPOSClosureSummary = useGetPOSClosureSummary()
    const { currentPOS, currentPOSActivity, closure, branch } = useRecoilValue(posState)
    const { user } = useRecoilValue(authState)
    const fetchBranch = useFetchBranch()
    const BASE_URL = process.env.REACT_APP_API_URL + '/back-office-info-dashboard/export'
    const { token, setToken } = useToken()
    let { businessId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [summary, setSummary] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)

    const summaryType = searchParams.get('summary-type')
    const operationDate = searchParams.get('date')

    // console.log('summary type:', summaryType)
    // console.log('operationDate:', operationDate)

    useEffect(() => {
        if (user.branchId) {
            fetchBranch(user.branchId)
        }
    }, [user])

    useEffect(() => {
        setIsLoading(true)
        const abortController = new AbortController()
        const signal = abortController.signal

        const fetchSummary = async () => {
            try {
                const response = await fetch(`${BASE_URL}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'business-id': businessId,
                    },
                    method: 'POST',
                    body: JSON.stringify({ startDate: operationDate }),
                })

                if (!response.ok) {
                    throw new Error('Failed to fetch summary data')
                }

                const data = await response.json()
                setSummary(data)
            } catch (error) {
                console.error('Error fetching summary data:', error)
            } finally {
                setIsLoading(false);
            }
        }

        fetchSummary()

        return () => {
            abortController.abort()
        }
    }, [token, businessId, operationDate])

    console.log('summary data:', summary)

    const onEndBtnHandler = async () => {
        navigate(`/${slug}/report/sales/${user.businessId}`)
    }

    const exportDate = getCurrentDate('/', new Date(summary?.business[0]?.date))
    const actualDate = getCurrentDate('/')
    const startDate = getCurrentDate('/', new Date(summary?.sale_time[0]?.start_time))
    const endDate = getCurrentDate('/', new Date(summary?.sale_time[0]?.end_time))

    // console.log('startDate:', startDate)
    // console.log('endDate:', endDate)

    return (
        <div className="bg-[#EFEFEF] pt-[4.00vw] pb-[12.79vw]">
            {isLoading ? (
                <div className="w-full h-screen flex justify-center items-start">
                    <MoonLoader
                        color="#565DFF"
                        loading={isLoading}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="mt-40"
                    />
                </div>
            ) : (
                <>
                    <div className="flex justify-center text-[0.78vw] w-full">
                        <div
                            id={'section-to-print'}
                            className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw]"
                        >
                            <div className="text-center">
                                <div className="font-[400]">
                                    สรุปยอดขาย{summaryType === 'by-date' ? 'รวมสิ้นวัน' : ` POS${summary && summary.business[0].pos_id}`}&emsp;ณ&emsp;วันที่:&nbsp;{summary && exportDate}
                                </div>
                                <div className="font-[400]">
                                    วันที่พิมพ์:&nbsp;{actualDate}&emsp;{getActualTime()}&nbsp;น.
                                </div>
                                <div>
                                    {`${summary?.business[0].company_name}`}
                                </div>
                                <div>
                                    Tax ID
                                    : {summary?.business[0].tax_id ?? '-'} {branch?.financial?.is_include_vat && '(VAT Included)'}
                                </div>
                                {summaryType === 'by-pos' && (
                                    <>
                                        <div className="w-full h-0 mt-[1.28vw]"/>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">POS#
                                                : {summary && summary.business[0].pos_code}</div>
                                            <div className="text-right">POS NO
                                                : {summary && summary.business[0].pos_id}</div>
                                        </div>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">RD#</div>
                                        </div>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">เลขที่ / เล่มที่</div>
                                            <div className="text-right">{summary && summary.business[0].order_no}</div>
                                        </div>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">เวลาเริ่มขาย</div>
                                            <div
                                                className="text-right">{startDate}&nbsp;{getActualTime(':', summary?.sale_time[0]?.start_time)}&nbsp;น.
                                            </div>
                                        </div>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">เวลาสิ้นสุด</div>
                                            <div
                                                className="text-right">{endDate}&nbsp;{getActualTime(':', summary?.sale_time[0]?.end_time)}&nbsp;น.
                                            </div>
                                        </div>
                                        <div className="flex justify-between gap-[0.98vw]">
                                            <div className="whitespace-nowrap">ระยะเวลาขาย</div>
                                            <div
                                                className="text-right">{summary && summary.sale_time[0].total_time}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="flex flex-col gap-[0.59vw]">
                                {/* line */}
                                <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">จำนวนบิลขาย</div>
                                    <div className="text-right">{summary?.business[0].number_of_bill ?? 0}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">จำนวนบิลยกเลิก</div>
                                    <div className="text-right">{summary?.business[0].number_of_bill_void ?? 0}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">จำนวนบิล Void</div>
                                    <div className="text-right">{summary?.business[0].number_of_bill_cancel ?? 0}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">จำนวนบิล Refund</div>
                                    <div className="text-right">{summary?.business[0].number_of_refund_bill ?? 0}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">จำนวนบิล ออกใบกำกับภาษีเต็มรูปแบบ</div>
                                    <div className="text-right">{summary?.business[0].number_of_bill_tax ?? 0}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">มูลค่า ออกใบกำกับภาษี เต็มรูปแบบ</div>
                                    <div className="text-right">{summary?.business[0].full_tax_invoice_value ?? 0}</div>
                                </div>
                                {/* line */}
                                <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">ยอดขาย</div>
                                    <div
                                        className="text-right">{summary?.business[0].total_sale ? numberWithTwoDecimals(summary.business[0].total_sale) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- Sale Refund</div>
                                    <div
                                        className="text-right">{summary?.business[0].Sale_refund ? numberWithTwoDecimals(summary.business[0].Sale_refund) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- After Refund</div>
                                    <div
                                        className="text-right">{summary?.business[0].After_refund ? numberWithTwoDecimals(summary.business[0].After_refund) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- Item Discount</div>
                                    <div
                                        className="text-right">{summary?.business[0].Discount ? numberWithTwoDecimals(summary.business[0].Discount) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- Sub Discount</div>
                                    <div
                                        className="text-right">{summary?.business[0].discount_end_bill ? numberWithTwoDecimals(summary.business[0].discount_end_bill) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- Point Discount</div>
                                    <div
                                        className="text-right">{summary?.business[0].discount_from_point ? numberWithTwoDecimals(summary.business[0].discount_from_point) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">- Coupon Discount</div>
                                    <div
                                        className="text-right">{summary?.business[0].Discount_coupon ? numberWithTwoDecimals(summary.business[0].Discount_coupon) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">ค่าธรรมเนียม</div>
                                    <div
                                        className="text-right">{summary?.business[0].fee ? numberWithTwoDecimals(summary.business[0].fee) : '0.00'}</div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">มูลค่าสินค้าไม่มีภาษี</div>
                                    <div className="text-right">
                                        {summary?.business[0].product_price_no_vat ? numberWithTwoDecimals(summary.business[0].product_price_no_vat) : '0.00'}
                                    </div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">มูลค่าสินค้ามีภาษี</div>
                                    <div className="text-right">
                                        {summary?.business[0].product_price_vat ? numberWithTwoDecimals(summary.business[0].product_price_vat) : '0.00'}
                                    </div>
                                </div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">VAT</div>
                                    <div className="text-right">
                                        {summary?.business[0].vat ? numberWithTwoDecimals(summary.business[0].vat) : '0.00'}
                                    </div>
                                </div>
                                <div className="w-full flex justify-center">
                                    <div className="flex justify-center gap-[3vw] font-bold">
                                        <div className="whitespace-nowrap">Net Amount</div>
                                        <div className="text-right">
                                            {summary?.business[0].net_balance ? numberWithTwoDecimals(summary.business[0].net_balance) : '0.00'}
                                        </div>
                                    </div>
                                </div>

                                {/* line */}
                                <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
                                <div className="flex justify-between gap-[0.98vw] ">
                                    <div className="whitespace-nowrap">ช่องทางการชำระเงิน</div>
                                </div>

                                <div className="flex justify-between gap-[0.98vw]">
                                    <div className="whitespace-nowrap">โอนเงิน</div>
                                    <div className="text-right">
                                        {summary?.transfer[0]?.total_all ? numberWithTwoDecimals(summary.transfer[0].total_all) : '0.00'}
                                    </div>
                                </div>
                                {summary?.transfer && summary.transfer.map((item, index) => (
                                    <div key={`transfer_log_${index}`} className="flex justify-between gap-[0.98vw]">
                                        <div className="whitespace-nowrap indent-1">- {item.bank_title}</div>
                                        <div className="text-right">
                                            {item.total_sale ? numberWithTwoDecimals(item.total_sale) : '0.00'}
                                        </div>
                                    </div>
                                ))}

                                <div className="flex justify-between gap-[0.98vw]">
                                    <div className="whitespace-nowrap">เงินสด</div>
                                    <div className="text-right">
                                        {summary?.cash[0]?.total_all ? numberWithTwoDecimals(summary?.cash[0].total_all) : '0.00'}
                                    </div>
                                </div>

                                <div className="flex justify-between gap-[0.98vw]">
                                    <div className="whitespace-nowrap">บัตรเครดิต</div>
                                    <div className="text-right">
                                        {summary?.debit[0]?.total_all ? numberWithTwoDecimals(summary?.debit[0]?.total_all) : '0.00'}
                                    </div>
                                </div>
                                {summary?.debit && summary.debit.map((item, index) => (
                                    <div key={`debit_log_${index}`} className="flex justify-between gap-[0.98vw]">
                                        <div className="whitespace-nowrap indent-1">- {item.bank_title}</div>
                                        <div className="text-right">
                                            {item.total_sale ? numberWithTwoDecimals(item.total_sale) : '0.00'}
                                        </div>
                                    </div>
                                ))}

                                <div className="flex justify-between gap-[0.98vw]">
                                    <div className="whitespace-nowrap">เดลิเวอรี่</div>
                                    <div className="text-right">
                                        {summary?.delivery[0]?.total_all ? numberWithTwoDecimals(summary.delivery[0].total_all) : '0.00'}
                                    </div>
                                </div>
                                {summary?.delivery && summary.delivery.map((item, index) => (
                                    <div key={`delivery_log_${index}`} className="flex justify-between gap-[0.98vw]">
                                        <div
                                            className="whitespace-nowrap indent-1 uppercase">- {item.delivery_name}</div>
                                        <div className="text-right">
                                            {item.total_sale ? numberWithTwoDecimals(item.total_sale) : '0.00'}
                                        </div>
                                    </div>
                                ))}

                                {/* line */}
                                <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>

                                <div className="flex justify-between gap-[0.98vw]">
                                    <div className="whitespace-nowrap">ยอดนับเงินสด</div>
                                    <div className="text-right">
                                        {summary?.cash_diff[0]?.cash ? numberWithTwoDecimals(summary.cash_diff[0].cash) : '0.00'}
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    <div className="flex justify-between w-[50%] gap-[0.98vw] ">
                                        <div className="whitespace-nowrap">Diff</div>
                                        <div className="text-right">
                                            {summary?.cash_diff[0]?.diff ? numberWithTwoDecimals(summary.cash_diff[0].diff) : '0.00'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-center w-full h-auto fixed bottom-0 gap-[min(2.56vw,26.18px)] bg-[#EFEFEF] py-[min(3.42vw,35px)]">
                        <CustomBtn
                            onClick={onEndBtnHandler}
                            className={`leading-[0.8] w-[min(16.89vw,173px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-error-active`}
                            btntxt={'กลับสู่หน้ารายงาน'}
                        />
                        <CustomBtn
                            onClick={() => window.print()}
                            className={`leading-[0.8] w-[min(16.89vw,173px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active capitalize`}
                            btntxt={'Print'}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
