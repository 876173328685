import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmDeleteModal = (props) => {
  const handleDelete = (permissionID) => {
    props.onHide();
    props.onSuccess();
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">
                สิทธิผู้ใช้งานนี้ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.pdata.permissionID)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
