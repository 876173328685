
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import { nanoid } from 'nanoid'
import { ModalAllder } from 'layouts/ModalAllder';

export function OptionProduct(props){

    const [modalStockShow, setModalStockShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})

    const addHandeler = () => {
        props.setOptionproduct(optionproduct => [...optionproduct,
            {
                'id': nanoid(),
                'name': '',
                'selecttype': false,
                'optionList': [],
            }])
    }

    const handleDeleteoption = (id) => {
        props.setOptionproduct(optionlist => optionlist.filter(item => item.id !== id))
    }

    const addListHandeler = (id) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        optionList: el.optionList.concat(
                            { 
                                'id': nanoid(), 
                                'name': '', 
                                'price': '',
                                'stock': {
                                    "storeHouse": null,
                                    "productID":null,
                                    "qty": null,
                                    "unit": null
                                },
                            }),
                    }
                    : el,
            ),
        )
    }
    const handleDeleteoptionList = (optionID, listID) => {
        props.setOptionproduct(optionproduct => optionproduct.map((el) =>
            el.id === optionID
                ? {
                    ...el,
                    optionList: el.optionList.filter(dx => dx.id !== listID),
                } : el,
        ))
    }
    const handleChangeSelecttype = (e, id) => {
        let status = e.target.checked === true ? true : false
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        selecttype: el.selecttype = status,
                    }
                    : el,
            ),
        )
    }

    const handleInputChange = (id, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        name: el.name = value,
                    }
                    : el,
            ),
        )
    }

    const handlelistNameChange = (optionID, listID, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        optionList: el.optionList.map((el2) =>
                            el2.id == listID
                                ? {
                                    ...el2,
                                    name: el2.name = value,
                                } : el2,
                        ),
                    }
                    : el,
            ),
        )
    }

    const handlelistPriceChange = (optionID, listID, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        optionList: el.optionList.map((el2) =>
                            el2.id == listID
                                ? {
                                    ...el2,
                                    price: el2.price = value,
                                } : el2,
                        ),
                    }
                    : el,
            ),
        )
    }


    const [productOptionDetail, setProductOptionDetail] = useState({
        "optionID":null,
        "optionDetail":null
    })

    const [modaledit, setModaledit] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:380,
        pin:false,
        mode:'normal',
        modalname:"modal_edit_option_product"
    });

    const [modaladd, setModaladd] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:380,
        pin:false,
        mode:'normal',
        modalname:"modal_add_option_product"
    });

    return (
        <div className="px-5 py-4">
            <div className=" mt-2 ps-2">
                {props.optionproduct.map((item, index) => (
                    <div key={item.id}>
                        <div className="row gx-4 align-items-center">
                            <div className="col-xl-6 col-6 ">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ตัวเลือกมาตราฐาน</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="optionName"
                                        className="form-control"
                                        placeholder="ตัวเลือกมาตราฐาน"
                                        value={item.name}
                                        onChange={(e) => handleInputChange(item.id, e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-xl-4 col-6">
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-stardard${index}`}
                                    label="เลือกได้มากกว่า 1 รายการ"
                                    name={`selecttype[${index}]`}
                                    className="custom-switch-st-1 tc-10  s1 mb-1"
                                    defaultChecked={item.selecttype}
                                    onChange={(e) => handleChangeSelecttype(e, item.id)}
                                   
                                />
                            </div>
                            <div className="col-xl-2 col-6">
                                <button type="button" className="btn  btn-st-3"
                                        onClick={() => handleDeleteoption(item.id)}
                                        style={{ background: '#F95057', borderColor: '#F95057' }}>ลบตัวเลือกสินค้า
                                </button>
                            </div>         
                            {item.optionList.map((item2, index2) => (
                                <div className="col-xxl-6 row gx-4 align-items-center" key={item2.id}>
                                    <div className="col">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">รายการ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="รายการ"
                                                value={item2.name}
                                                onChange={(e) => handlelistNameChange(item.id, item2.id, e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-auto">
                                        <Form.Group className="mb-2 form-st-3 pb-1" style={{ width: '200px' }}>
                                            <Form.Label className="mb-1">ราคา</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                className="form-control"
                                                placeholder="ราคา"
                                                value={item2.price}
                                                onChange={(e) => handlelistPriceChange(item.id, item2.id, e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-auto ">
                                        <div className='d-flex align-items-center' style={{width:'170px'}}>
                                        {
      
                                            <button type="button" className="btn btn-delete-st-1 me-0"
                                                onClick={() => handleDeleteoptionList(item.id, item2.id)}>
                                            </button>
                                            
                                        }

                                        {/* <button type="button" className="btn btn-setting-st-2 ms-2 me-3" onClick={() => {
                                            setModalStockShow(true)
                                            setProductOptionDetail( (prev) => ({...prev,id:item.id,optionDetail:item2}) )
                                            setSettingStock(item2.stock)
                                        }} /> */}

                                    
                                        {/* {
                                            
                                            item2.stock.productID ? (
                                                <span className="fs-16" style={{ color: '#2DC76D', lineHeight: '24px' }}>ตั้งค่าแล้ว</span>
                                            ) : (
                                                <span
                                                    className="fs-16"
                                                    style={{ color: '#B6BBC6', lineHeight: '24px' }}
                                                >
                                                    ยังไม่ได้ตั้งค่า
                                                </span>
                                            )
                                            
                                        } */}
                                    </div>
                                        
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mb-2 mt-4">
                            <button type="button" className="btn btn-st-3 mb-4"
                                    onClick={() => addListHandeler(item.id)}
                                    style={{ background: '#FF731D', borderColor: '#FF731D' }}> เพิ่มรายการ
                            </button>
                        </div>
                    </div>
                ))}

                <div className="mb-2 mt-4">
                    <button type="button" className="btn btn-st-3 mb-4" onClick={addHandeler}>เพิ่มตัวเลือกสินค้า
                    </button>
                </div>
            </div>

            <StockModal
                show={modalStockShow}
                onHide={() => setModalStockShow(false)}
                settingStock={settingStock}
                deletestock={() => {
                    // values.optionList[stockindex].stock = null
                    setModalStockShow(false)
                }}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
            />

        </div>
    )

}

function StockModal (props) {

    const { settingStock, deletestock,productOptionDetail,setProductOptionDetail,optionproduct,setOptionproduct, ...rest } = props


   
    const schema = yup.object().shape({

    })



    const optionsProduct = [
        { value: 1, label: 'ไข่ไก่สด' },
        { value: 2, label: 'ไก่สด' },
    ]

    const optionsStoreHouse = [
        { value: 1, label: 'คลังสินค้าวัตถุดิบ' },
        { value: 2, label: 'คลังสินค้าวัตถุดิบ' },
    ]
    

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">ตั้งค่าเชื่อมต่อสต๊อกสินค้า</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        initialValues={props.settingStock}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            // props.setFieldValue('stock', values)
                            // props.setFieldValue(`optionList[${props.stockindex}].stock`, values)

                            props.setOptionproduct((prev) =>
                                prev.map((el) =>
                                    el.optionID === productOptionDetail.optionID
                                        ? {
                                            ...el,
                                            optionList: el.optionList.map((el2) =>
                                                el2.listID == productOptionDetail.optionDetail.listID
                                                    ? {
                                                        ...el2,
                                                        stock: values,
                                                    } : el2,
                                            ),
                                        }
                                        : el,
                                ),
                            )

                            props.onHide()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="listName"
                                        className="form-control"
                                        placeholder="ชื่อรายการ"
                                        disabled={true}
                                        value={productOptionDetail.optionDetail ? productOptionDetail.optionDetail.listName : ''}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ประเภทคลังสินค้าที่ตัดสต๊อก </Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsStoreHouse.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsStoreHouse}
                                            placeholder="ประเภทคลังสินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">สินค้าที่ตัดสต๊อก</Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsProduct.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsProduct}
                                            placeholder="สินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">จำนวน </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="qty"
                                        className="form-control"
                                        placeholder="จำนวน"
                                        value={values.qty}
                                        onChange={handleChange}
                                        isInvalid={!!errors.qty}


                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">หน่วยวัตถุดิบ / UMS </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="unit"
                                        className="form-control"
                                        placeholder="หน่วยวัตถุดิบ / UMS"
                                        value={values.unit}
                                        disabled={true}

                                    />
                                </Form.Group>

                                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                                    {
                                        productOptionDetail.optionDetail.stock.productID ? (
                                            <Button type="button" className="btn-st-5 me-auto "
                                                    style={{ width: '80px' }} onClick={props.deletestock}>ลบ</Button>
                                        ) : (
                                            <div className="me-auto"></div>
                                        )
                                    }

                                    <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}
                                            onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className="btn-st-3  "
                                            style={{ width: '100px' }}>บันทึก</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}