import React, { useState  } from 'react';
import {useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Pointpaymentsetting(){

    const { branchID } = useParams();

    const [paymentsetting, setPaymentsetting] = useState({
        "branchID":branchID,
        "paymentChannelID":9,
        "usepoint":"",
        "status":1
    });

    const handleSubmit = () => {
        console.log(paymentsetting)
    }

    return (
        <div>
            <Form.Check 
                type="switch"
                id="pointpayment"
                label='รับชำระด้วยคะแนน'
                name='pointpayment'
                className='custom-switch-st-1 tc-10  s1 mb-1'
                defaultChecked={paymentsetting.status === 1}
                onChange={(e) => {
                    if(e.target.checked === true){
                        setPaymentsetting((prev) => ({  ...prev,status:1 }) );
                    }else{
                        setPaymentsetting((prev) => ({  ...prev,status:0 }) );
                    }  
                }}
            />

            {
                paymentsetting.status === 1 ? (
            
                    <div className=' d-flex align-items-center ps-2 pe-0 py-2  mb-4 mt-4' style={{width:'283px',borderRadius: '5px',border: '1px solid rgba(76, 78, 100, 0.22)'}}>
                        <div className=' me-2' >
                            <div className='icon-star'></div>
                        </div>
                        <div className='flex-fill'>
                            <div style={{color:' #2C2C2C'}}>คะแนน</div>
                        </div>
                        <div className='ms-2'>
                            <Form.Check 
                                type="switch"
                                id="usepoint"
                                label=''
                                name='usepoint'
                                className='custom-switch-st-1 tc-10 me-0 s1 mb-1'
                                defaultChecked={paymentsetting.usepoint === 1}
                                onClick={(e) => {
                                    if(e.target.checked === true){
                                        setPaymentsetting((prev) => ({  ...prev,usepoint:1 }) );
                                    }else{
                                        setPaymentsetting((prev) => ({  ...prev,usepoint:0 }) );
                                    }  
                                }}
                            />
                        </div>
                    </div>
                ) : ''
            }



            <div className="text-end  pt-5 pb-3 btn-group-control" >
    
                <Button type="submit" className='btn-st-3  ' style={{width:'100px'}} onClick={handleSubmit}>  บันทึก</Button>
            </div>
        </div>
    )
}