
import Developing from "layouts/Developing"

export function  Notification(){
    return (
        <div style={{padding: '30px 33px 33px 31px'}}>
            <Developing />
        </div>
    )
}
