import React, { useState,useEffect,useCallback ,useMemo,useLayoutEffect,useRef } from 'react';

import ReactDOM ,{ render,createPortal} from "react-dom";

import { Rnd } from "react-rnd";

const RenderInWindow = (props) => {
    const [container, setContainer] = useState(null);
    const newWindow = useRef(null);
  
    useEffect(() => {
      setContainer(document.createElement("div"));
    }, []);
    

    const close = () => {
        setContainer(null)
        props.setModal( (prev) => ({...prev,mode:'normal',open:false}) )
    }
    useEffect(() => {
      if (container) {
        newWindow.current = window.open(
          "",
          "",
          "width=600,height=400,left=200,top=200"
        );
        container.classList.add('p-4')
        newWindow.current.document.body.appendChild(container);
        newWindow.current.document.head.innerHTML = window.document.head.innerHTML

        const curWindow = newWindow.current;
        curWindow.addEventListener("beforeunload", close);
        
        return () => {
            curWindow.close();
            curWindow.removeEventListener("beforeunload", close);
        };
      }
    }, [container]);

    return container && createPortal(props.child, container);
   
};




export function ModalAllder({modal,setModal,hbtn,htitle,children,...props}){

    const [WindowWidth, WindowHeight] = useWindowSize();
    const [prevModal, setPrevModal] = useState(null);

    const [windowpopShow, setWindowpopShow] = useState(false);

    // console.log(modal)



    const getCenterModal = () => {
        setModal( (prev) => (
        {...prev,
            x:`${ (WindowWidth - modal.width) / 2 }`,
            y:`${ (WindowHeight - modal.height) / 2}`
        }) ) 
    }
    useEffect(() => {
        const cpin = localStorage.getItem(`${modal.modalname}`);
        if(cpin){
            setModal(JSON.parse(cpin))
        }else{
            getCenterModal() 
        }
    }, [WindowWidth,WindowHeight]);

    useEffect(() => {
        if(modal.pin){
            localStorage.setItem(`${modal.modalname}`,  JSON.stringify(modal))
        }
    }, [modal]);

    const setFitscreen = () => {
        setPrevModal(modal)
        setModal( (prev) => ({...prev,width:WindowWidth,height:WindowHeight,mode:'fit',x:0,y:0}) )
    }

    const setPin = () => {
        if(!modal.pin){
            setModal( (prev) => ({...prev,pin:true}) )
            modal.pin = true;
            localStorage.setItem(`${modal.modalname}`,  JSON.stringify(modal))
            
        }else{
            setModal( (prev) => ({...prev,pin:false}) )
            localStorage.removeItem(`${modal.modalname}`);
        }
    }


    return (
        <>
          
            {
                modal.mode == 'popup' ? (
                    <RenderInWindow child={children} setModal={setModal} setWindowpopShow={setWindowpopShow}  />
                ) : (
                <div
                    className="tg-bp3-dialog-resizable-draggable"
                    style={{ top: 0, left: 0, position: "fixed",zIndex:1600 }}
                >
                    <Rnd
                        style={{
                            border: "solid 1px #ddd",
                            background: "#FFF",
                            zIndex: modal.open ?  1100 : 'hidden',
                            borderRadius:"10px",
                            overflow:'hidden',
                            visibility:  `${modal.open ?  'visible' : 'hidden'}`,
                            position:'fixed' ,
                            transform: 'rotate(30deg)',
        
                        }}
                        size={{ width: modal.open ?  modal.width : 0 ,  height: modal.open ? modal.height : 0 }}
                        position={{ x: modal.x, y: modal.y }}
                        onResizeStop={(e, d, ref, delta, position) => {
                            setModal( (prev) => ({...prev,width:`${ref.style.width}`,height:`${ref.style.height}`,x:position.x,y:position.y}) ) 
                        }}
                        onDragStop={(e, d) => { 
                            setModal( (prev) => ({...prev,x:d.x,y:d.y}) ) 
                        }}
                        maxHeight={WindowHeight}
                        maxWidth={WindowWidth  - 20}
        
                        dragHandleClassName="dragHandle"
                    >
                        <div className='pagezoom d-flex align-items-center dragHandle' style={{
                            backgroundColor:'#565DFF',
                            height:'45px',
                            borderTopLeftRadius:"10px",
                            borderTopRightRadius:"10px",
                            padding:'10px',
                            cursor:'move'
                        }}>
                            <div className='text-white fs-18 me-auto pt-1'> {htitle}</div>
        
                            {
                                hbtn ? (
                                    <>
                                        <button type='button' className="btn btn-modal-export mx-2"
                                            onClick={() => {
                                                setModal( (prev) => ({...prev,mode:'popup'}) ) 
                                            } }
                                        
                                        ></button>
                                        <button type='button' className={`btn btn-modal-pin mx-2  ${modal.pin ? 'active' : ''}`}
                                            onClick={() => {
                                                setPin()
                                            }}
                                        ></button>
                                        <button type='button' className="btn btn-modal-window mx-2"
                                            
                                            onClick={() => {
                                                modal.mode == 'normal' ?  (
                                                    setFitscreen()
                                                ) : (
                                                    setModal( (prev) => ({...prev,width:prevModal.width,height:prevModal.height,mode:'normal',x:prevModal.x,y:prevModal.y}) ) 
                                                )
                                                
                                            } }
                                        ></button>
                                    </>
                                ) : ''
                            }
                            
                            <button  type='button' className="btn btn-modal-close" onClick={() => setModal( (prev) => ({...prev,open:false}) ) }></button>
                        </div>
                        <div className='p-4 pagezoom'>
                            {children}
                        </div>       
                    </Rnd>
    
                </div>
                )
            }
            


        </>
    )

}
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
const NewWindow = ({ children, close }) => {
    const newWindow = useMemo(() =>
      window.open(
        "about:blank",
        "newWin",
        `width=400,height=300,left=${window.screen.availWidth / 2 -
          200},top=${window.screen.availHeight / 2 - 150}`
      )
    );
    newWindow.onbeforeunload = () => {
      close();
    };
    useEffect(() => () => newWindow.close());
    return createPortal(children, newWindow.document.body);
};