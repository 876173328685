import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select, { StylesConfig } from 'react-select';

import React, { useState, useEffect,Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Outlet,
    useParams 
  } from "react-router-dom";
  

import { Formik } from 'formik';
import * as yup from 'yup';

export function  RegisterBusiness() {
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        phonenumber: yup.string().required().min(10).max(10),
        email: yup.string().required().email('Invalid email'),
    });

    

    const optionsPrefix= [
      { value: 'นาย', label: 'นาย' },
      { value: 'นาง', label: 'นาง' },
      { value: 'นางสาว', label: 'นางสาว' },
    ];
    

    return (
        <Container>
            <div className='m-0 w-100 py-sm-2 h-100vh d-flex flex-column'>
                <div className='mb-auto'>
                    <Link to="/business/login" className="btn btn-back-st-1 mb-auto"  >กลับหน้า Login</Link>
                </div> 
                <div className='mb-auto'> 
                    <Formik
                        initialValues={{prefix: '', firstname: '', lastname: '',phonenumber: '',email:'' }}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                    
                            alert(JSON.stringify(values, null, 2));
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            isValid,
                            errors,
                            values,setFieldValue
                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card className='card-register-st-1 mb-4'>
                                <Card.Body>
                                    <div className='text-start pt-4 fs-20 fw-5 mb-4 pb-2'>ข้อมูลส่วนตัว</div>

                                    <Form.Group className="mb-3 form-st-2 pb-1">
                                        <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                                        <Select as="select"
                                            name="prefix"
                                            onChange={(e) => {
                                                setFieldValue("prefix", e.value)
                                            }}
                                            className="w-100 select-st-1"
                                            options={optionsPrefix}
                                            placeholder="เลือกคำนำหน้า"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3 form-st-2 pb-1">
                                        <Form.Label className="mb-1">ชื่อ <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstname"
                                            placeholder="กรอกชื่อ"
                                            value={values.firstname}
                                            onChange={handleChange}
                                            isValid={touched.firstname && !errors.firstname}
                                            isInvalid={!!errors.firstname}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-2 pb-1">
                                        <Form.Label className="mb-1">นามสกุล <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastname"
                                            placeholder="กรอกนามสกุล"
                                            value={values.lastname}
                                            onChange={handleChange}
                                            isValid={touched.lastname && !errors.lastname}
                                            isInvalid={!!errors.lastname}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-2 pb-1">
                                        <Form.Label className="mb-1">เบอร์โทร <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phonenumber"
                                            placeholder="กรอกเบอร์โทร"
                                            value={values.phonenumber}
                                            onChange={handleChange}
                                            
                                            isValid={touched.phonenumber && !errors.phonenumber}
                                            isInvalid={!!errors.phonenumber}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                            }}
                                            maxLength={10}

                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-2 pb-1">
                                        <Form.Label className="mb-1">อีเมล <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="กรอกอีเมล"
                                            value={values.email}
                                            onChange={handleChange}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}
                                        />
                                    </Form.Group>
                                    
                                </Card.Body>
                            </Card>
                            <div className='text-center py-2'>
                                <Link to="/business/login" className="btn-st-2 btn mx-3"  >ยกเลิก</Link>
                                <Button type="submit" className='btn-st-1 mx-3'>ถัดไป</Button>
                            </div>
                            
                        </Form>
                        )}
                    </Formik>
                </div> 
                <div className='text-center fs-20 pb-4 tc-3 d-flex align-items-end justify-content-center'>
                    <span className='me-3 '>Power By</span> 
                    <div className='pb-05'><Image src="images/logo_allder.png" width={98} /></div>
                </div>

            </div>
        </Container>
    );
}
