
import React, { useState,useEffect  } from 'react';
import {NavLink} from "react-router-dom";
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import { utils, writeFileXLSX } from 'xlsx';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export function  Productunit() {
    const [unitdata, setUnitdata] = useState([]);

    const [modalunitShow, setModalunitShow] = useState(false);
    const [modaleditunitShow, setModaleditunitShow] = useState(false);
    const [modalunitDeleteShow, setModalunitDeleteShow] = useState(false);
    const [modalsuccessShow, setModalsuccessShow] = useState(false);
    

    const [uniteditdata, setUniteditdata] = useState({});

    const getunitData = () => {

        fetch('/json/productunit.json')
          .then((response) => response.json())
          .then((result) => setUnitdata(result) )
          .catch((error) => console.log("error", error));
      };

    useEffect(() => {
        getunitData();
    
    }, []);




    const columns = [
        {
            name: 'หน่วยนับ',
            selector: row => row.unitCode,
            width:"150px"
        },
        {
            name: 'ชื่อหน่วยนับสินค้า',
            selector: row => row.unitName,
            cell: (row, index, column, id) => <div style={{color:"#565DFF",fontWeight:500}}>{row.unitName}</div>,
        },
        {
            name: 'ประเภทหน่วยนับ',
            selector: row => row.unitType,
            cell: (row, index, column, id) => row.unitType == 1 ? <div>หน่วยนับสินค้าสำเร็จรูป</div> : <div>หน่วยนับสินค้าวัตถุดิบ</div>
  
        },
        {
            name: 'สถานะ',
            selector: row => row.unitStatus,
            center:'true',
            width:"200px",
            cell: (row, index, column, id) => <div className={"datalist-status status"+row.unitStatus}></div>,
        },
        {
            name: 'จัดการ',
            selector: row => row.unitID,
            center:'true',
            width:"150px",
            cell: (row, index, column, id) => <div><Button type='button' className='btn-edit-st-1 ' onClick={ () => { setUniteditdata(row);setModaleditunitShow(true); }   }></Button></div>,
        }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '15px', 
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                background: ' #F5F5F7',
                paddingTop: '15px', 
                paddingBottom: '15px',

            },
        },
        cells: {
            style: {
                paddingLeft: '15px', 
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                fontWeight: '300'
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'จำนวนรายการต่อหน้า',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const [filterText, setFilterText] = useState('');
	const filteredItems = unitdata.filter(
        item => item.unitName && item.unitName.toLowerCase().includes(filterText.toLowerCase())
    );

    const downloadExcel = () => {
        const ws = utils.json_to_sheet(filteredItems);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "รายชื่อสาขา");
        writeFileXLSX(wb, "รายชื่อสาขา.xlsx"); 
    };

    return (
        <div>
            <nav  aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">สินค้าและบริการ</a></li>
                <li className="breadcrumb-item active tc-1 " aria-current="page"> จัดการหน่วยนับสินค้า   </li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">จัดการหน่วยนับสินค้า </div>

            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className='d-flex align-items-center px-4 mb-4'>
                        <button className='btn btn-export-st-1 me-auto' onClick={downloadExcel} >Export</button>
                        <div className='me-3'>
                            <input type='text' className='form-control form-st-4' placeholder='ค้นหาชื่อหน่วยนับ' onKeyUp={e => setFilterText(e.target.value)} />
                        </div>
                        <Button type='button' className='btn btn btn-st-7' onClick={() => setModalunitShow(true)}>เพิ่มหน่วยนับ</Button>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    />
                    
                </div>

                
            </div>
            <UnitModal
                show={modalunitShow}
                onHide={() => setModalunitShow(false)}
            />
            <UnitEditModal
                show={modaleditunitShow}
                onHide={() => setModaleditunitShow(false)}
                onDelete={() => setModalunitDeleteShow(true)}
                uniteditdata={uniteditdata}
            />
            <ConfirmDeleteModal
                show={modalunitDeleteShow}
                onHide={() => setModalunitDeleteShow(false)}
                onConfirm={() => setModalunitDeleteShow(false)}
                onSuccess={() => setModalsuccessShow(true)}
                uniteditdata={uniteditdata}
                
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => setModalsuccessShow(false)}
            />
        </div>
    )
}

function UnitModal(props) {

    const schema = yup.object().shape({
        unitCode: yup.string().required(),
        unitName: yup.string().required(),
    });


    const [unitdata, setUnitdata] = useState(
        {
            "unitCode": "",
            "unitName": "",
            "unitStatus": 1,
            "unitType": 1,
            "dateCreate": getCurrentDate("/"),
            "userCreate": "Admin Pat",
        }
    );

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-24 fw-bold me-auto' style={{color:'#565DFF'}}>เพิ่มหน่วยใหม่</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ unitdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        props.onHide();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">รหัสหน่วยนับ  <span className='text-danger'>*</span>   </Form.Label>
                            <Form.Control
                            type="text" 
                            name="unitCode" 
                            className="form-control" 
                            placeholder="รหัสหน่วยนับ"
                            value={values.unitCode}
                            onChange={handleChange}
                            isInvalid={!!errors.unitCode}
                            
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อหน่วยนับสินค้า <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="unitName" 
                            className="form-control" 
                            placeholder="ชื่อหน่วยนับสินค้า"
                            value={values.unitName}
                            onChange={handleChange}
                            isInvalid={!!errors.unitName}
                            
                            />
                        </Form.Group>

                        <div>
                            <Form.Label className="mb-2  tc-10">หน่วยนับสินค้า</Form.Label>
                            <div className="mb-3">
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าสำเร็จรูป"
                                name="unitType"
                                type="radio"
                                id={`unitType-1`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 1}
                                onChange={(e) => {
                                    setFieldValue("unitType", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าวัตถุดิบ"
                                name="unitType"
                                type="radio"
                                id={`unitType-2`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 2}
                                onChange={(e) => {
                                    setFieldValue("unitType", 2)
                                }}
                            />
                            </div>
                        </div>
   
                        <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="เปิดใช้งาน"
                                name="unitStatus"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitStatus === 1}
                                onChange={(e) => {
                                    setFieldValue("unitStatus", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="ปิดใช้งาน"
                                name="unitStatus"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitStatus === 0}
                                onChange={(e) => {
                                    setFieldValue("unitStatus", 0)
                                }}
                            />
                            </div>
                        ))}
                    
                        <div className='row'>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">วันที่สร้าง  </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="dateCreate" 
                                    className="form-control" 
                                    placeholder="วันที่สร้าง"
                                    value={values.dateCreate}
                                    onChange={handleChange}
                                    isInvalid={!!errors.dateCreate}
                                    disabled={true}
                                    
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">ชื่อผู้สร้าง </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="userCreate" 
                                    className="form-control" 
                                    placeholder="ชื่อผู้สร้าง"
                                    value={values.userCreate}
                                    onChange={handleChange}
                                    isInvalid={!!errors.userCreate}
                                    disabled={true}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="text-end pt-2 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>เพิ่ม</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function UnitEditModal(props) {

    const schema = yup.object().shape({
        unitCode: yup.string().required(),
        unitName: yup.string().required(),
    });


    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-24 fw-bold me-auto' style={{color:'#565DFF'}}>แก้ไขหน่วยนับ</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ props.uniteditdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        props.onHide();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">รหัสหน่วยนับ  <span className='text-danger'>*</span>   </Form.Label>
                            <Form.Control
                            type="text" 
                            name="unitCode" 
                            className="form-control" 
                            placeholder="รหัสหน่วยนับ"
                            value={values.unitCode}
                            onChange={handleChange}
                            isInvalid={!!errors.unitCode}
                            
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อหน่วยนับสินค้า <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="unitName" 
                            className="form-control" 
                            placeholder="ชื่อหน่วยนับสินค้า"
                            value={values.unitName}
                            onChange={handleChange}
                            isInvalid={!!errors.unitName}
                            
                            />
                        </Form.Group>

                        <div>
                            <Form.Label className="mb-2  tc-10">หน่วยนับสินค้า</Form.Label>
                            <div className="mb-3">
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าสำเร็จรูป"
                                name="unitType"
                                type="radio"
                                id={`unitType-1`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 1}
                                onChange={(e) => {
                                    setFieldValue("unitType", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าวัตถุดิบ"
                                name="unitType"
                                type="radio"
                                id={`unitType-2`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 2}
                                onChange={(e) => {
                                    setFieldValue("unitType", 2)
                                }}
                            />
                            </div>
                        </div>
   
                        <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="เปิดใช้งาน"
                                name="unitStatus"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitStatus === 1}
                                onChange={(e) => {
                                    setFieldValue("unitStatus", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="ปิดใช้งาน"
                                name="unitStatus"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitStatus === 0}
                                onChange={(e) => {
                                    setFieldValue("unitStatus", 0)
                                }}
                            />
                            </div>
                        ))}
                    
                        <div className='row'>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">วันที่แก้ไข  </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="dateEdit" 
                                    className="form-control" 
                                    placeholder="วันที่สร้าง"
                                    value={values.dateEdit}
                                    onChange={handleChange}
                                    isInvalid={!!errors.dateEdit}
                                    disabled={true}
                                    
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">ชื่อผู้แก้ไข </Form.Label>
                                    <Form.Control
                                    type="text" 
                                    name="userEdit" 
                                    className="form-control" 
                                    placeholder="ชื่อผู้สร้าง"
                                    value={values.userEdit}
                                    onChange={handleChange}
                                    isInvalid={!!errors.userEdit}
                                    disabled={true}
                                    
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="d-flex align-items-center pt-2 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-5 me-auto ' style={{width:'80px'}} 
                            onClick={ () => { 
                                props.onDelete();
                                props.onHide() 
                            } } >ลบ</Button>
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>อัพเดท</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function ConfirmDeleteModal(props) {


    const handleDelete = (unitID) => {
        props.onHide();
        props.onSuccess();
    }

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>

            <div className='px-3 py-4'>

                {
                    props.uniteditdata.productQty > 0 ? (
                        <div>
                            <div>
                                <div className='text-center fs-22 tc-7 mb-2'>ขออภัยไม่สามารถลบได้</div>
                                <div className='text-center fs-22 tc-7'>เนื่องจากมีสินค้าอยู่ในหน่วยนับ</div>
                            </div>
                            <div className="text-center pt-4 mt-3  btn-group-control" >
                                <Button type="button" className='btn-st-3 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ตกลง</Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                                <div className='text-center fs-22 tc-7'>จัดการหน่วยนับนี้ใช่หรือไม่</div>
                            </div>

                            <div className="text-center pt-4 mt-3  btn-group-control" >
                                <Button type="button" className='btn-st-3 mx-3  ' style={{width:'100px'}}  onClick={ () => handleDelete(props.uniteditdata.unitID)  }>ใช่</Button>
                                <Button type="button" className='btn-st-6 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ไม่ใช่</Button>
                            </div>
                        </div>
                    )
                }
   
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function SuccessDeleteModal(props) {


    return (
      <Modal
        {...props}
        size="sm"
        centered
      >
        <Modal.Body>

            <div className='px-3 py-4'>
                <div className='text-center mb-4'>
                    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                    <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </div>
                <div className='text-center fs-22 tc-7'>ลบหน่วยนับสำเร็จ</div>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
{
 return(
     <>
         <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
             <input 
                 type="checkbox"
                 className="form-check-input"
                 style={{ height: '18px', width: '18px' }}
                 ref={ref}
                 onClick={ onClick }
                 {...rest}
             />
             <label className="form-check-label" id="booty-check" />
         </div>
     </>
 )
})

function getCurrentDate(separator='',dateb=new Date()){

    let newDate = dateb;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}