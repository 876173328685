import React, { useState,useEffect  } from 'react';
export default React.forwardRef(
    function CheckboxTable({ onClick, ...rest }, ref) {
        return(
            <>
                <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
                    <input 
                        type="checkbox"
                        className="form-check-input"
                        style={{ height: '18px', width: '18px' }}
                        ref={ref}
                        onClick={ onClick }
                        {...rest}
                    />
                    <label className="form-check-label" id="booty-check" />
                </div>
            </>
        )
    }
  );