import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from "react-paginate";

import { nanoid } from "nanoid";

import { useRecoilValue } from "recoil";
import { authState } from "../../state/auth";
import useToken from "../../utils/UseToken";
import { appState } from "../../state/app";

import { EditOptionProduct } from "layouts/ProductOptionEdit";
import { EditOptionStandard } from "layouts/ProductOptionStandardEdit";
import { EditOptionNote } from "layouts/ProductOptionNoteEdit";

import { OptionProduct } from "layouts/ProductOptionCreate";
import { OptionStandard } from "layouts/ProductOptionStandardCreate";
import { OptionNote } from "layouts/ProductOptionNoteCreate";
import UploadFile from "utils/UploadFile";
import Global from "utils/Global";

export function Product() {
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();
  const { slug } = useRecoilValue(appState);
  const [productdata, setProductdata] = useState([]);
  const [productCanceldata, setProductCanceldata] = useState([]);
  const [productAlldata, setProductAlldata] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [onSuccess, setOnSuccess] = useState(false);
  const GetAllBusinessProduct = `${process.env.REACT_APP_API_URL}/business-product`;
  const GetCategoriesProduct = `${process.env.REACT_APP_API_URL}/business-product-category`;

  const getProductData = async (users, tokens) => {
    if (users.businessId) {
      try {
        const res = await fetch(GetAllBusinessProduct, {
          headers: new Headers({
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "business-id": users.businessId,
          }),
        });

        const data = await res.json();
        // add index to data
        data.map((item, index) => {
          item.index = index + 1;
        });

        setProductdata(data);
        setProductAlldata(data);

        const productCancle = data.filter(
          (item) => item.status === "ปิดใช้งาน"
        );

        setProductCanceldata(productCancle);
        setOnSuccess(false);
      } catch (error) {
        console.log("Failed to get data:", error);
      }
    } else {
      console.log("Error: User ID not found.");
      return [];
    }
  };


  useEffect(() => {
    getProductData(user, token);
    // getCategoryData()
  }, [user, token]);

  const columns = [
    {
      name: "ลำดับที่",
      selector: (row, index) => row.index,
      center: "true",
      width: "100px",
    },
    {
      name: "รหัสสินค้า",
      selector: (row) => row.code,
    },
    {
      name: "ชื่อสินค้า",
      selector: (row) => row.name,
      cell: (row, index, column, id) => {
        const exists = Global.checkURL(row.img_url);
        const img_url = exists ? row.img_url : process.env.REACT_APP_API_URL_SOCKET + "/" + row.img_url;
        // console.log("🚀 ~ Product ~ img_url:", img_url)
        return (
          <div className="pimage">
            <img
              alt={`product-${row.id}`}
              src={img_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/blank.png";
              }}
            />
            <div>
              <div
                style={{
                  color: "#565DFF",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                {row.name}
              </div>
              <div style={{ fontSize: "12px" }}>{row.code}</div>
            </div>
          </div>
        );
      },
      width: "350px",
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.product_category,
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.sub_product_category,
    },
    {
      name: "ภาษี",
      selector: (row) => row.is_include_vat,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          {row.is_include_vat == true ? (
            <div className="text-end mb-1">รวม VAT</div>
          ) : (
            ""
          )}
          {row.is_include_vat == false ? (
            <div className="text-end mb-1">ไม่รวม VAT</div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "ราคา",
      selector: (row) => row.price,
      center: "true",
    },
    {
      name: "สถานะ",
      selector: (row) => row.status,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          {row.status == "ขายอยู่" ? (
            <div className={"product-status status" + 1}></div>
          ) : (
            ""
          )}
          {row.status == "ปิดใช้งาน" ? (
            <div className={"product-status status" + 0}></div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "จัดการ",
      selector: (row) => row.id,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          {row.productType == "สินค้าจัดชุด" ? (
            <Link
              to={`/${slug}/products/pset/edit/${row.id}`}
              className="btn btn-edit-st-1 "
            ></Link>
          ) : (
            <Link
              to={`/${slug}/products/edit/${row.id}`}
              className="btn btn-edit-st-1 "
            ></Link>
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        background: " #F5F5F7",
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        fontWeight: "300",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const [filterText, setFilterText] = useState("");

  let filteredItems;
  if (productdata !== undefined && productdata.length > 0) {
    filteredItems = productdata.filter((item) => {
      const matchCode = item.code
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchName = item.name
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchCategory = item.product_category
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchCode || matchName || matchCategory;
    });
  }

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "รายชื่อสาขา");
    writeFileXLSX(wb, "รายชื่อสาขา.xlsx");
  };

  const [selectedProductStatus, setSelectedProductStatus] = useState(1);
  const [displayedProduct, setDisplayedProduct] = useState(0);

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" className="mt-2">
          <ol className="breadcrumb fs-16 ps-0">
            <li className="breadcrumb-item ">
              <a href="#" className="tc-7 text-decoration-none">
                สินค้าและบริการ
              </a>
            </li>
            <li className="breadcrumb-item active tc-1 " aria-current="page">
              {" "}
              จัดการข้อมูลสินค้า
            </li>
          </ol>
        </nav>
        <div className="fs-24 tc-7  mb-4 lh-1">จัดการข้อมูลสินค้า</div>
        <div className="mb-3">
          <Button
            type="button"
            className={
              selectedProductStatus === 1
                ? "btn-category px-4 active"
                : "btn-category px-4"
            }
            onClick={() => {
              setSelectedProductStatus(1);
              setProductdata(productAlldata);
            }}
          >
            สินค้าทั้งหมด (<span>{productAlldata.length}</span>)
          </Button>
          <Button
            type="button"
            className={
              selectedProductStatus === 0
                ? "btn-category px-4 active"
                : "btn-category px-4"
            }
            onClick={() => {
              setSelectedProductStatus(0);
              setProductdata(productCanceldata);
            }}
          >
            สินค้าที่ยกเลิก (<span>{productCanceldata.length}</span>)
          </Button>
        </div>
      </div>

      <div className="card card-st-2">
        <div className="card-body px-0 pt-4 pb-0">
          <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
            <button
              className={
                displayedProduct === 0
                  ? "btn btn-display-product d1 active mb-2 me-2"
                  : "btn btn-display-product d1 mb-2 me-2"
              }
              data-select
              onClick={() => setDisplayedProduct(0)}
            ></button>
            {/* <button
                            className={displayedProduct === 1 ? 'btn btn-display-product d2 active mb-2 me-2' : 'btn btn-display-product d2 mb-2 me-2'}
                            onClick={() => setDisplayedProduct(1)}></button> */}
            {/* <div className="me-3 mb-2" style={{ flex: '0 0 200px' }}>
                            <Select as="select"
                                    name="caregory"
                                    onChange={(e) => {
                                        (e.label != 'ทั้งหมด' ? setFilterText(e.label) : setFilterText(''))
                                    }}
                                    className="w-100 select-st-1 pd"
                                    options={optionsCategory}
                                    placeholder="เลือกหมวดหมู่"
                            />
                        </div> */}
            <button
              disabled
              className="btn btn-export-st-1 mb-2 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3 mb-2">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหารหัสสินค้า ชื่อสินค้า"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>

            {/* <Link
                            to="/products/pset/create"
                            className="btn mb-2 btn-st-7 px-4 me-3"
                            style={{ backgroundColor: '#FF731D' }}
                        >
                            เพิ่มสินค้าจัดชุด
                        </Link> */}
            <Link
              to={`/${slug}/products/create`}
              className="btn btn btn-st-7 mb-2 px-4"
            >
              เพิ่มสินค้า
            </Link>
          </div>

          {displayedProduct === 0 ? (
            <DataTable
              columns={columns}
              data={filteredItems}
              customStyles={customStyles}
              // selectableRows
              // selectableRowsComponent={Checkbox}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              noDataComponent={NotableData()}
              persistTableHead={true}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {displayedProduct === 1 ? (
        <PaginatedItems items={filteredItems} itemsPerPage={10} />
      ) : (
        ""
      )}
    </div>
  );
}

const NotableData = (props) => {
  return (
    <div className="my-5 py-5">
      <div className="table-no-result mx-auto mb-4"></div>
      <div className="text-center fs-24 tc-13">ไม่มีรายการ</div>
    </div>
  );
};

function Items({ currentItems }) {
  return (
    <div className="py-4 d-flex flex-wrap">
      {currentItems &&
        currentItems.map((item, index) => (
          <div className="card card-food me-3 mb-3" key={index}>
            <div className="position-relative">
              <img src={item.productImage} className="food-img" />
              <div
                className="position-absolute"
                style={{ top: "10px", right: "10px" }}
              >
                {item.buffet == 1 ? (
                  <div className="tag-img buffet">เป็นแพคเกจบุฟเฟต์</div>
                ) : (
                  ""
                )}
                {item.saleperweight == 1 ? (
                  <div className="tag-img saleperweight">ขายตามน้ำหนัก</div>
                ) : (
                  ""
                )}
                {item.productSet == 1 ? (
                  <div className="tag-img productset">สินค้าจัดชุด</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="card-body pt-1 px-3 ">
              <div className="card-name ">{item.productName}</div>
              <div className="card-price mb-1">฿{item.productPrice}.-</div>
              <div className="card-barcode mb-2">฿{item.productCode}.-</div>
              <div className="d-flex flex-wrap">
                <div className="tag-category me-2">{item.productCategory}</div>
                <div className="tag-display me-2"></div>
                <div className="tag-vat me-2">Vat</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

function PaginatedItems(props) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + props.itemsPerPage;
  const currentItems = props.items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(props.items.length / props.itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * props.itemsPerPage) % props.items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <div>
        <Items currentItems={currentItems} />
      </div>

      <div className="d-flex justify-content-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

const getcategoryData = async (
  user,
  token,
  GetCategoriesProduct,
  setOptionsCategory
) => {
  const options = [];
  if (user.businessId) {
    try {
      const res = await fetch(GetCategoriesProduct, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      var obj = {};
      obj["value"] = "";
      obj["label"] = "กรุณาเลือกหมวดหมู่";
      options.push(obj);
      for (var i = 0; i < data.length; i++) {
        obj = {};

        obj["value"] = data[i].id;
        obj["label"] = data[i].name;
        options.push(obj);
      }
      //   console.log(options);
      setOptionsCategory(options);
    } catch (error) {
      console.log("Failed to get data:", error);
    }
  } else {
    console.log("Error: User ID not found.");
    return [];
  }
};

const getsubcategoryData = async (
  user,
  token,
  GetSubCategoriesProduct,
  setOptionsSubCategory,
  selectedCat
) => {
  const options = [];
  if (user.businessId) {
    try {
      const res = await fetch(GetSubCategoriesProduct + String(selectedCat), {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      var obj = {};
      obj["value"] = "";
      obj["label"] = "กรุณาเลือกหมวดหมู่ย่อย";
      options.push(obj);
      for (var i = 0; i < data.length; i++) {
        obj = {};
        obj["value"] = data[i].id;
        obj["label"] = data[i].name;
        options.push(obj);
      }
      // console.log(options);
      setOptionsSubCategory(options);
    } catch (error) {
      console.log("Failed to get data:", error);
    }
  } else {
    console.log("Error: User ID not found.");
    return [];
  }
};

const getunitData = async (user, token, GetProductUnit, setOptionsUnit) => {
  const options = [];
  if (user.businessId) {
    try {
      const res = await fetch(GetProductUnit, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();

      var obj = {};
      obj["value"] = "";
      obj["label"] = "กรุณาเลือกหน่วยนับ";
      options.push(obj);
      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj["value"] = data[i].id;
        obj["label"] = data[i].name;
        options.push(obj);
      }

      setOptionsUnit(options);
    } catch (error) {
      console.log("Failed to get data:", error);
    }
  } else {
    console.log("Error: User ID not found.");
    return [];
  }
};

const getproductData = () => {
  const options = [];
  fetch("/json/product.json")
    .then((response) => response.json())
    .then((result) => {
      for (var i = 0; i < result.length; i++) {
        var obj = {};
        obj["value"] = result[i].productID;
        obj["label"] = result[i].productName;
        obj["productPrice"] = result[i].productPrice;
        options.push(obj);
      }
    })
    .catch((error) => console.log("error", error));

  return options;
};

const submitCreateProduct = async (values, user, token) => {
  // if image_url is not set set mock image
  if (!values.img_url) {
    values.img_url = "images/blank.png";
  }

  try {
    if (values.specific_product_options.length != 0) {
      values.specific_product_options = values.specific_product_options.map(
        (option) => ({
          name: option.name,
          can_select_multiple: option.selecttype,
          specific_option_details: option.optionList.map((optionDetail) => ({
            name: optionDetail.name,
            price: parseInt(optionDetail.price, 10),
          })),
        })
      );
    }
    if (values.standard_products.length != 0) {
      values.standard_products = values.standard_products.map((option) => ({
        product_option_id: option.id,
        can_select_multiple: option.selecttype,
        standard_option_details: option.product_option_detail.map(
          (optionDetail) => ({
            product_option_detail_id: optionDetail.id,
          })
        ),
      }));
    }
    if (values.remark_products.length != 0) {
      values.remark_products = values.remark_products.map((option) => ({
        product_option_id: option.id,
        remark_option_details: option.product_option_detail.map(
          (optionDetail) => ({
            product_option_detail_id: optionDetail.id,
          })
        ),
      }));
    }
    values.price = parseInt(values.price, 10);

    const { category, ...removeCatField } = values;
    const { created_at, ...final_values } = removeCatField;

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/business-product`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(final_values),
      }
    );
    return res.text();
  } catch (error) {
    console.log("Failed to update data:", error);
  }
};

export function ProductCreate() {
  const { productID } = useParams();

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-1">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item">
            <a href="#" className="tc-7  text-decoration-none">
              สินค้า/บริการ
            </a>
          </li>
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายการสินค้าและบริการ
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            {productID ? "แก้ไขสินค้า" : "สร้างสินค้า"}{" "}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1 pt-2 ">
        {" "}
        {productID ? "แก้ไขสินค้า" : "สร้างสินค้า"}
      </div>
      <Tab.Container id="tabs" defaultActiveKey="product">
        <Nav variant="pills" className=" mb-4 pills-st-1">
          <Nav.Item>
            <Nav.Link eventKey="product">ข้อมูลสินค้า</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
                        <Nav.Link eventKey="foodrecipes">สูตรอาหาร</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="setting">ตั้งค่าขั้นสูง</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="history">ประวัติ</Nav.Link>
                    </Nav.Item> */}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="product">
            <ProductdataCreate />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="foodrecipes">
                        <FoodrecipesData/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="setting">
                        <SettingData/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="history">
                        <HistoryData/>
                    </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export function ProductEdit() {
  const { productID } = useParams();

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-1">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item">
            <a href="#" className="tc-7  text-decoration-none">
              สินค้า/บริการ
            </a>
          </li>
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายการสินค้าและบริการ
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            {productID ? "แก้ไขสินค้า" : "สร้างสินค้า"}{" "}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1 pt-2 ">
        {" "}
        {productID ? "แก้ไขสินค้า" : "สร้างสินค้า"}
      </div>
      <Tab.Container id="tabs" defaultActiveKey="product">
        <Nav variant="pills" className=" mb-4 pills-st-1">
          <Nav.Item>
            <Nav.Link eventKey="product">ข้อมูลสินค้า</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
                        <Nav.Link eventKey="foodrecipes">สูตรอาหาร</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="setting">ตั้งค่าขั้นสูง</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="history">ประวัติ</Nav.Link>
                    </Nav.Item> */}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="product">
            <ProductdataEdit />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="foodrecipes">
                        <FoodrecipesData/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="setting">
                        <SettingData/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="history">
                        <HistoryData/>
                    </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

function ProductdataCreate() {
  const { pset, productID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();
  const { slug } = useRecoilValue(appState);
  const navigate = useNavigate();

  const [modalcancel, setModalcancel] = useState(false);
  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState(false);
  const [modalconfirmcancelsale, setModalconfirmcancelsale] = useState(false);

  const schema = yup.object({
    code: yup.string().required("กรุณากรอกรหัสสินค้า"),
    name: yup.string().required("กรุณากรอกชื่อสินค้า"),
    category: yup.string().required("กรุณาเลือกหมวดหมู่"),
    sub_product_category_id: yup.string().required("กรุณาเลือกหมวดหมู่ย่อย"),
    price: yup.string().required("กรุณากรอกราคา"),
    barcode: yup.string().required("กรุณากรอกบาร์โค้ด"),
    unit_id: yup.string().required("กรุณาเลือกหน่วยนับ"),
  });

  const [formdisabled, setFormdisabled] = useState(false);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsubCategory, setOptionsubCategory] = useState([]);

  const [listoptionstandard, setListOptionstandard] = useState([]);
  const [listoptionnote, setListOptionnote] = useState([]);
  // const [optionproduct, setOptionproduct] = useState([{
  //     'optionID': nanoid(),
  //     'optionName': '',
  //     'selecttype': 0,
  //     'optionList': [],
  // }])
  const [optionproduct, setOptionproduct] = useState([]);
  const [productset, setProductset] = useState([
    {
      id: nanoid(),
      productID: "",
      productName: "",
      productNum: 1,
      productPrice: 0,
      productsumPrice: 0,
    },
  ]);

  const [optionsUnit, setOptionsUnit] = useState([]);

  const [formdata, setFormdata] = useState({
    code: "",
    name: "",
    category: "",
    sub_product_category_id: "",
    is_price_depend_weight: false,
    price: "",
    unit_id: "",
    barcode: "",
    img_url: "",
    is_include_vat: true,
    is_active: true,
    is_show: false,
    is_join_promotion: false,
    is_recommend: false,
    is_buffet: false,
    have_product_standard: false,
    standard_products: [],
    have_product_specific: false,
    specific_product_options: [],
    have_product_remark: false,
    remark_products: [],
    remark: "",
    created_at: "",
  });

  const [optionsProduct, setOptionsProduct] = useState([]);
  const GetCategoriesProduct = `${process.env.REACT_APP_API_URL}/business-product-category`;
  const GetSubCategoriesProduct = `${process.env.REACT_APP_API_URL}/business-product/sub-category/`;
  const GetProductUnit = `${process.env.REACT_APP_API_URL}/business-product/unit`;
  // const UploadImageFileURL = `${process.env.REACT_APP_API_URL}/business-product/product-image`;
  const UploadImageFileURL = `${process.env.REACT_APP_URL_UPLOAD_FILE}`;
  const [selectedCat, setSelectedCat] = useState("1");
  const [currentDateString, setCurrentDateString] = useState("");

  const uploadImageFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(UploadImageFileURL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "business-id": user.businessId,
        }),
        body: formData,
      });

      return await response.text();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    getcategoryData(user, token, GetCategoriesProduct, setOptionsCategory);
    getsubcategoryData(
      user,
      token,
      GetSubCategoriesProduct,
      setOptionsubCategory,
      selectedCat
    );
    getunitData(user, token, GetProductUnit, setOptionsUnit);
    setOptionsProduct(getproductData());
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDateString(formattedDate);
    // if (productID) {
    //     getproductEditData(productID).then(response => {
    //         setFormdata(response)
    //         setListOptionstandard(response.optionstandard)
    //         setOptionproduct(response.optionproduct)
    //         setListOptionnote(response.optionnote)
    //         setProductset(response.productset)
    //         setFormdisabled(false)
    //     })
    // }
  }, [productID]);

  useEffect(() => {
    getsubcategoryData(
      user,
      token,
      GetSubCategoriesProduct,
      setOptionsubCategory,
      selectedCat
    );
  }, [selectedCat]);

  const handleSaleproduct = (productID) => {
    setFormdata((prev) => ({ ...prev, productStatus: 1 }));
  };

  const handleCategoryChange = (value) => {
    setSelectedCat(value);
    // console.log(value);
  };

  const returnProductPage = () => {
    navigate(`/${slug}/products`);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formdata}
        validationSchema={schema}
        onSubmit={async (values) => {
          submitCreateProduct(values, user, token);
          setTextsuccess("สร้างรายการสินค้าสำเร็จ");
          setModalsuccess(true);
          // setFormdisabled(true);
          // returnProductPage();
          setTimeout(() => {
            setModalsuccess(false);
            setFormdisabled(true);
            returnProductPage();
          }, 2000);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              setFieldValue("standard_products", listoptionstandard);
              setFieldValue("specific_product_options", optionproduct);
              setFieldValue("remark_products", listoptionnote);
              // setFieldValue('productset', productset)

              handleSubmit();
            }}
          >
            <fieldset disabled={formdisabled}>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="tc-8 fs-18 fw-bold px-3">
                    ข้อมูลสินค้าและราคา
                  </div>

                  {values.status_id == 2 ? (
                    <div className="d-flex flex-wrap align-items-center align-items-center px-3 mt-4">
                      <div className="me-3 alert-st-1">
                        สินค้านี้ถูกยกเลิกขายไปเมื่อวันที่ {values.dateCancel}{" "}
                        โดย {values.userCancel}{" "}
                      </div>
                      <button
                        type="button"
                        className="btn  btn-st-3"
                        onClick={() => handleSaleproduct(productID)}
                        style={{
                          background: "#F95057",
                          borderColor: "#F95057",
                        }}
                      >
                        ขายอีกครั้ง
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <Imageupload
                      setFieldValue={setFieldValue}
                      values={values}
                      uploadImageFile={uploadImageFile}
                    />

                    <div className="pt-3 px-3">
                      <div className="row gx-xl-4">
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              รหัสสินค้า{" "}
                              <span className="text-danger ms-1"> *</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="code"
                              className="form-control"
                              placeholder="รหัสสินค้า"
                              value={values.code}
                              onChange={handleChange}
                              isInvalid={!!errors.code}
                            />
                            {errors.code ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.code}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อสินค้า{" "}
                              <span className="text-danger ms-1"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="ชื่อสินค้า"
                              value={values.name}
                              onChange={handleChange}
                              isInvalid={!!errors.name}
                            />
                            {errors.name ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่หลัก
                            </Form.Label>
                            <Form.Select
                              name="category"
                              onChange={(e) => {
                                const selectedCategory = e.target.value;
                                setFieldValue("category", selectedCategory);
                                handleCategoryChange(selectedCategory);
                                setFieldValue("sub_product_category_id", "");
                              }}
                              placeholder="เลือกหมวดหมู่หลัก"
                              value={values.category}
                              isInvalid={!!errors.category}
                            >
                              {optionsCategory.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            {errors.category ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.category}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่ย่อย
                            </Form.Label>
                            <Form.Select
                              // as="select"
                              name="sub_product_category_id"
                              onChange={(e) => {
                                const selectedSubCategory = e.target.value;
                                // format sub category to integer

                                const selectedSubCategoryInt = parseInt(
                                  selectedSubCategory,
                                  10
                                );

                                setFieldValue(
                                  "sub_product_category_id",
                                  selectedSubCategoryInt
                                );
                              }}
                              // className="w-100 select-st-1"
                              // options={optionsubCategory}
                              placeholder="เลือกหมวดหมู่ย่อย"
                              //   value={optionsubCategory.filter(
                              //     (item) =>
                              //       item.value === values.sub_product_category_id
                              //   )}
                              value={values.sub_product_category_id}
                              isInvalid={!!errors.sub_product_category_id}
                            >
                              {optionsubCategory.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            {errors.sub_product_category_id &&
                            touched.sub_product_category_id ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.sub_product_category_id}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div style={{ width: "160px" }}>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label="ขายตามน้ำหนัก"
                                  name="is_price_depend_weight"
                                  className="custom-switch-st-1 tc-10 s2 mb-1"
                                  defaultChecked={values.is_price_depend_weight}
                                  checked={
                                    values.is_price_depend_weight === true
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue(
                                        "is_price_depend_weight",
                                        true
                                      );
                                    } else {
                                      setFieldValue(
                                        "is_price_depend_weight",
                                        false
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ราคาสินค้า{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="price"
                                  className="form-control"
                                  placeholder="ราคาสินค้า"
                                  value={values.price}
                                  onChange={handleChange}
                                  isInvalid={!!errors.price}
                                />
                                {errors.price ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.price}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หน่วยขาย / หน่วยเล็ก UMS
                                </Form.Label>
                                <Form.Select
                                  // as="select"
                                  name="unit_id"
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;

                                    // format unit to integer

                                    const selectedUnitInt = parseInt(
                                      selectedUnit,
                                      10
                                    );

                                    setFieldValue("unit_id", selectedUnitInt);
                                  }}
                                  // className="w-100 select-st-1"
                                  // options={optionsUnit}
                                  placeholder="เลือกหน่วยขาย"
                                  // value={optionsUnit.filter(
                                  //   (item) => item.value === values.unit_id
                                  // )}
                                  value={values.unit_id}
                                  isInvalid={!!errors.unit_id}
                                >
                                  {optionsUnit.map((item) => (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Form.Select>
                                {errors.unit_id ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.unit_id}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  บาร์โค้ด
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="barcode"
                                  className="form-control icon-barcode"
                                  placeholder="บาร์โค้ด"
                                  value={values.barcode}
                                  onChange={handleChange}
                                  isInvalid={!!errors.barcode}
                                />
                                {errors.barcode ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.barcode}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-sm-6">
                              <Form.Check
                                name="is_include_vat"
                                type="checkbox"
                                id="is_include_vat"
                                label="สินค้ามีภาษีมูลค่าเพิ่ม"
                                className="form-check-st-1"
                                checked={values.is_include_vat === true}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue("is_include_vat", true);
                                  } else {
                                    setFieldValue("is_include_vat", false);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-1">
                    ตั้งค่าการขาย
                  </div>

                  <div className="d-flex flex-wrap px-3">
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch2"
                        label="พร้อมจำหน่าย"
                        name="is_active"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_active}
                        checked={values.is_active === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_active", true);
                          } else {
                            setFieldValue("is_active", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch3"
                        label="แสดงหน้าจอลูกค้า"
                        name="is_show"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_show}
                        checked={values.is_show === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_show", true);
                          } else {
                            setFieldValue("is_show", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch4"
                        label="ไม่เข้าร่วมทุกโปรโมชั่น"
                        name="is_join_promotion"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_join_promotion}
                        checked={values.is_join_promotion === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_join_promotion", true);
                          } else {
                            setFieldValue("is_join_promotion", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch5"
                        label="เมนูแนะนำ"
                        name="is_recommend"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_recommend}
                        checked={values.is_recommend === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_recommend", true);
                          } else {
                            setFieldValue("is_recommend", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch6"
                        label="เป็นแพคเกจบุฟเฟต์"
                        name="is_buffet"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_buffet}
                        checked={values.is_buffet === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_buffet", true);
                          } else {
                            setFieldValue("is_buffet", false);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {pset ? (
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="pt-2 pb-1 px-3">
                      <Form.Check
                        type="switch"
                        id="option-set-switch2"
                        label="ตัวเลือกสินค้าจัดชุด"
                        name="productsetStatus"
                        className="custom-switch-st-1 tc-10  s1 mb-1"
                        defaultChecked={values.productsetStatus}
                        checked={values.productsetStatus === 1}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("productsetStatus", 1);
                          } else {
                            setFieldValue("productsetStatus", 0);
                          }
                        }}
                      />
                      <span className="ms-2" style={{ color: "#858796" }}>
                        {" "}
                        (เช่น ชุดอาหารกลางวัน Combo ข้าว+น้ำ){" "}
                      </span>
                    </div>
                    {values.productsetStatus === 1 ? (
                      <Productset
                        productset={productset}
                        setProductset={setProductset}
                        setFieldValue={setFieldValue}
                        optionsProduct={optionsProduct}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="custom-sd"
                      label="กำหนดตัวเลือกมาตรฐาน"
                      name="have_product_standard"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_standard}
                      checked={values.have_product_standard === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_standard", true);
                        } else {
                          setFieldValue("have_product_standard", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      ( ใช้ข้อมูลจากตัวเลือกมาตรฐานที่สร้างไว้ ){" "}
                    </span>
                  </div>
                  {values.have_product_standard === true ? (
                    <OptionStandard
                      listoptionstandard={listoptionstandard}
                      setListOptionstandard={setListOptionstandard}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="option-food-switch2"
                      label="ตัวเลือกเฉพาะสินค้า"
                      name="have_product_specific"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_specific}
                      checked={values.have_product_specific === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_specific", true);
                        } else {
                          setFieldValue("have_product_specific", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      (เพิ่มตัวเลือกเฉพาะสินค้านี้เท่านั้น เช่น เพิ่มไข่ดาว,
                      Size S M L, อื่นๆ){" "}
                    </span>
                  </div>
                  {values.have_product_specific === true ? (
                    <OptionProduct
                      optionproduct={optionproduct}
                      setOptionproduct={setOptionproduct}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="custom-notef"
                      label="ตัวเลือกหมายเหตุ"
                      name="have_product_remark"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_remark}
                      checked={values.have_product_remark === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_remark", true);
                        } else {
                          setFieldValue("have_product_remark", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      ( ใช้ข้อมูลจากตัวเลือกหมายเหตุที่สร้างไว้ ){" "}
                    </span>
                  </div>
                  {values.have_product_remark === true ? (
                    <OptionNote
                      listoptionnote={listoptionnote}
                      setListOptionnote={setListOptionnote}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3 row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                      <Form.Control
                        type="text"
                        name="remark"
                        className="form-control"
                        placeholder="หมายเหตุ"
                        value={values.remark}
                        onChange={handleChange}
                        isInvalid={!!errors.remark}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                      <Form.Control
                        type="text"
                        name="created_at"
                        className="form-control"
                        placeholder="วันที่สร้าง"
                        value={values.created_at || currentDateString}
                        onChange={handleChange}
                        isInvalid={!!errors.created_at}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">ผู้แก้ไข</Form.Label>
                      <Form.Control
                        type="text"
                        name="userCreate"
                        className="form-control"
                        placeholder="ผู้แก้ไข"
                        value={user.nickName}
                        onChange={handleChange}
                        isInvalid={!!errors.userCreate}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </fieldset>

            {/* <div className="card card-st-3 mb-3">
                            <div className="card-body px-xl-3">
                                <button type="button" className="btn  btn-st-3"
                                        onClick={() => setModalconfirmcancelsale(true)}
                                        style={{ background: '#F95057', borderColor: '#F95057' }}>ยกเลิกการขาย
                                </button>

                            </div>
                        </div> */}
            <div className="text-end pb-4 pe-3">
              {formdisabled === true ? (
                <Button
                  type="button"
                  className="btn-st-3 mx-3"
                  onClick={(e) => {
                    setFormdisabled(false);
                  }}
                >
                  แก้ไข
                </Button>
              ) : (
                <div className="text-end pt-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-6 me-3 btn"
                    onClick={() => setModalcancel(true)}
                  >
                    ยกเลิก
                  </Button>
                  <Button type="submit" className="btn-st-3 ">
                    บันทึก
                  </Button>
                </div>
              )}
            </div>

            <ConfirmCancelsaleModal
              show={modalconfirmcancelsale}
              onHide={() => setModalconfirmcancelsale(false)}
              onSuccess={() => setModalsuccess(true)}
              formdata={formdata}
              productID={productID}
              setTextsuccess={setTextsuccess}
              setFieldValue={setFieldValue}
            />
          </Form>
        )}
      </Formik>

      <ConfirmCancelModal
        show={modalcancel}
        onHide={() => setModalcancel(false)}
      />
      <SuccessModal
        show={modalsuccess}
        onHide={() => setModalsuccess(false)}
        textsuccess={textsuccess}
      />
    </div>
  );
}

const submitUpdateProduct = async (values, user, token) => {
  // console.log("🚀 ~ submitUpdateProduct ~ values:", values);

  // return;

  try {
    values.price = parseInt(values.price, 10);
    if (values.specific_product_options.length > 0) {
      values.specific_product_options = values.specific_product_options.map(
        (option) => ({
          product_option_id: option.id,
          can_select_multiple: option.selecttype,
        })
      );
    }
    if (values.standard_products.length > 0) {
      values.standard_products = values.standard_products.map((option) => ({
        product_option_id: option.id,
        can_select_multiple: option.selecttype,
      }));
    }

    // const {
    //     category,
    //     modified,
    //     remark_products,
    //     created_at,
    //     specific_product_options,
    //     standard_products,
    //     status,
    //     sub_product_category,
    //     unit,
    //     modified_by,
    //     updated_at,
    //     status_id,
    //      ...final_values} = values;
    const {
      category,
      modified,
      created_at,
      status,
      sub_product_category,
      unit,
      modified_by,
      updated_at,
      remark_products,
      status_id,
      ...final_values
    } = values;

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/business-product/` + String(values.id),
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(final_values),
      }
    );
    return res.text();
  } catch (error) {
    console.log("Failed to update data:", error);
  }
};

function ProductdataEdit() {
  const { pset, productID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();
  const { slug } = useRecoilValue(appState);
  const navigate = useNavigate();

  const [modalcancel, setModalcancel] = useState(false);
  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState(false);
  const [modalconfirmcancelsale, setModalconfirmcancelsale] = useState(false);

  const schema = yup.object().shape({
    code: yup.string().required("กรุณากรอกรหัสสินค้า"),
    name: yup.string().required("กรุณากรอกชื่อสินค้า"),
    category: yup.string().required("กรุณาเลือกหมวดหมู่"),
    sub_product_category_id: yup.string().required("กรุณาเลือกหมวดหมู่ย่อย"),
    price: yup.string().required("กรุณากรอกราคา"),
    barcode: yup.string().required("กรุณากรอกบาร์โค้ด"),
    unit_id: yup.string().required("กรุณาเลือกหน่วยนับ"),
  });

  const [formdisabled, setFormdisabled] = useState(false);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsubCategory, setOptionsubCategory] = useState([]);

  const [listoptionstandard, setListOptionstandard] = useState([]);
  const [listoptionnote, setListOptionnote] = useState([]);
  const [optionproduct, setOptionproduct] = useState([]);

  const [activeCancelBtn, setActiveCancelBtn] = useState(false);

  const [productset, setProductset] = useState([
    {
      id: nanoid(),
      productID: "",
      productName: "",
      productNum: 1,
      productPrice: 0,
      productsumPrice: 0,
    },
  ]);

  const [optionsUnit, setOptionsUnit] = useState([]);

  const [formdata, setFormdata] = useState({
    code: "",
    name: "",
    sub_product_category_id: "",
    is_price_depend_weight: false,
    price: "",
    unit_id: "",
    barcode: "",
    img_url: "",
    is_include_vat: false,
    is_active: false,
    is_show: false,
    is_join_promotion: false,
    is_recommend: false,
    is_buffet: false,
    have_product_standard: false,
    standard_products: [],
    have_product_specific: false,
    specific_product_options: [],
    have_product_remark: false,
    remark_products: [],
    remark: "",
    created_at: "",
  });

  const [optionsProduct, setOptionsProduct] = useState([]);
  const GetCategoriesProduct = `${process.env.REACT_APP_API_URL}/business-product-category`;
  const GetSubCategoriesProduct = `${process.env.REACT_APP_API_URL}/business-product/sub-category/`;
  const GetProductUnit = `${process.env.REACT_APP_API_URL}/business-product/unit`;
  // const UploadImageFileURL = `${process.env.REACT_APP_API_URL}/business-product/product-image`;
  const UploadImageFileURL = `${process.env.REACT_APP_URL_UPLOAD_FILE}`;
  const [selectedCat, setSelectedCat] = useState("1");
  const [currentDateString, setCurrentDateString] = useState("");

  const uploadImageFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(UploadImageFileURL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "business-id": user.businessId,
        }),
        body: formData,
      });

      return await response.text();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    getcategoryData(user, token, GetCategoriesProduct, setOptionsCategory);
    getsubcategoryData(
      user,
      token,
      GetSubCategoriesProduct,
      setOptionsubCategory,
      selectedCat
    );
    getunitData(user, token, GetProductUnit, setOptionsUnit);
    setOptionsProduct(getproductData());
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDateString(formattedDate);
    if (productID) {
      getproductEditData(productID).then((response) => {
        setFormdata(response);
        handleCategoryChange(response.category);
        setListOptionstandard(response.standard_products);
        setOptionproduct(response.specific_product_options);
        setListOptionnote(response.remark_products);
        setProductset(response.productset);
        setFormdisabled(false);

        // set option sub category in select box
      });
    }
  }, [productID, user, token]);

  useEffect(() => {
    getsubcategoryData(
      user,
      token,
      GetSubCategoriesProduct,
      setOptionsubCategory,
      selectedCat
    );
  }, [selectedCat]);

  const handleSaleproduct = async (productID) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/business-product/reuse-product/` +
          String(productID),
        {
          method: "PATCH",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "business-id": user.businessId,
          }),
        }
      );
      returnProductPage();
      // return res.text()
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCat(value);
  };

  const getproductEditData = async (productID) => {
    if (user.businessId) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/business-product/` +
            String(productID),
          {
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
              "business-id": user.businessId,
            }),
          }
        );
        const data = await res.json();
        return data[0];
      } catch (error) {
        console.log("Failed to get data:", error);
      }
    } else {
      console.log("Error: User ID not found.");
      return [];
    }
  };

  const returnProductPage = () => {
    navigate(`/${slug}/products`);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formdata}
        validationSchema={schema}
        onSubmit={async (values) => {
          submitUpdateProduct(values, user, token);
          setTextsuccess("แก้ไขรายการสินค้าสำเร็จ");
          setModalsuccess(true);
          // add interval to close modal
          setTimeout(() => {
            setModalsuccess(false);
            returnProductPage();
          }, 2000);
          // returnProductPage();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              setFieldValue("standard_products", listoptionstandard);
              setFieldValue("specific_product_options", optionproduct);
              setFieldValue("remark_products", listoptionnote);
              // setFieldValue('productset', productset)

              handleSubmit();
            }}
          >
            <fieldset disabled={formdisabled}>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="tc-8 fs-18 fw-bold px-3">
                    ข้อมูลสินค้าและราคา
                  </div>

                  {values.status_id == 2 ? (
                    <div className="d-flex flex-wrap align-items-center align-items-center px-3 mt-4">
                      <div className="me-3 alert-st-1">
                        สินค้านี้ถูกยกเลิกขายไปเมื่อวันที่ {values.updated_at}{" "}
                        โดย {values.modified_by}{" "}
                      </div>
                      <button
                        type="button"
                        className="btn  btn-st-3"
                        onClick={() => handleSaleproduct(productID)}
                        style={{
                          background: "#F95057",
                          borderColor: "#F95057",
                        }}
                      >
                        ขายอีกครั้ง
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <Imageupload
                      setFieldValue={setFieldValue}
                      values={values}
                      uploadImageFile={uploadImageFile}
                    />
                    <div className="pt-3 px-3">
                      <div className="row gx-xl-4">
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              รหัสสินค้า{" "}
                              <span className="text-danger ms-1"> *</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="productCode"
                              className="form-control"
                              placeholder="รหัสสินค้า"
                              value={values.code}
                              onChange={handleChange}
                              isInvalid={!!errors.code}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อสินค้า{" "}
                              <span className="text-danger ms-1"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="ชื่อสินค้า"
                              value={values.name}
                              onChange={handleChange}
                              isInvalid={!!errors.name}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่หลัก
                            </Form.Label>
                            <Select
                              as="select"
                              name="category"
                              onChange={(e) => {
                                setFieldValue("category", e.value);
                                setFieldValue("sub_product_category_id", "");
                                handleCategoryChange(e.value);
                              }}
                              className="w-100 select-st-1"
                              options={optionsCategory}
                              placeholder="เลือกหมวดหมู่หลัก"
                              value={optionsCategory.filter(
                                (item) => item.value === values.category
                              )}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">
                              หมวดหมู่ย่อย
                            </Form.Label>
                            {/* <Select
                              as="select"
                              name="sub_product_category_id"
                              onChange={(e) => {
                                setFieldValue("sub_product_category_id", "");
                              }}
                              className="w-100 select-st-1"
                              options={optionsubCategory}
                              placeholder="เลือกหมวดหมู่ย่อย"
                              value={optionsubCategory.filter(
                                (item) =>
                                  item.value === values.sub_product_category_id
                              )}
                            /> */}
                            <Form.Select
                              // as="select"
                              name="sub_product_category_id"
                              onChange={(e) => {
                                const selectedSubCategory = e.target.value;
                                // format sub category to integer

                                const selectedSubCategoryInt = parseInt(
                                  selectedSubCategory,
                                  10
                                );

                                setFieldValue(
                                  "sub_product_category_id",
                                  selectedSubCategoryInt
                                );
                              }}
                              // className="w-100 select-st-1"
                              placeholder="เลือกหมวดหมู่ย่อย"
                              // value={optionsubCategory.filter(
                              //   (item) =>
                              //     item.value === values.sub_product_category_id
                              // )}
                              value={values.sub_product_category_id}
                              isInvalid={!!errors.sub_product_category_id}
                            >
                              {optionsubCategory.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-xl-6">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div style={{ width: "160px" }}>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label="ขายตามน้ำหนัก"
                                  name="is_price_depend_weight"
                                  className="custom-switch-st-1 tc-10 s2 mb-1"
                                  defaultChecked={values.is_price_depend_weight}
                                  checked={
                                    values.is_price_depend_weight === true
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue(
                                        "is_price_depend_weight",
                                        true
                                      );
                                    } else {
                                      setFieldValue(
                                        "is_price_depend_weight",
                                        false
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ราคาสินค้า{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="price"
                                  className="form-control"
                                  placeholder="ราคาสินค้า"
                                  value={values.price}
                                  onChange={handleChange}
                                  isInvalid={!!errors.price}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หน่วยขาย / หน่วยเล็ก UMS
                                </Form.Label>
                                <Select
                                  as="select"
                                  name="unit_id"
                                  onChange={(e) => {
                                    setFieldValue("unit_id", e.value);
                                  }}
                                  className="w-100 select-st-1"
                                  options={optionsUnit}
                                  placeholder="เลือกหน่วยขาย"
                                  value={optionsUnit.filter(
                                    (item) => item.value === values.unit_id
                                  )}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  บาร์โค้ด
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="barcode"
                                  className="form-control icon-barcode"
                                  placeholder="บาร์โค้ด"
                                  value={values.barcode}
                                  onChange={handleChange}
                                  isInvalid={!!errors.barcode}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-sm-6">
                              <Form.Check
                                name="is_include_vat"
                                type="checkbox"
                                id="is_include_vat"
                                label="สินค้ามีภาษีมูลค่าเพิ่ม"
                                className="form-check-st-1"
                                checked={values.is_include_vat === true}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue("is_include_vat", true);
                                  } else {
                                    setFieldValue("is_include_vat", false);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-1">
                    ตั้งค่าการขาย
                  </div>

                  <div className="d-flex flex-wrap px-3">
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch2"
                        label="พร้อมจำหน่าย"
                        name="is_active"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_active}
                        checked={values.is_active === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_active", true);
                          } else {
                            setFieldValue("is_active", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch3"
                        label="แสดงหน้าจอลูกค้า"
                        name="is_show"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_show}
                        checked={values.is_show === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_show", true);
                          } else {
                            setFieldValue("is_show", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch4"
                        label="ไม่เข้าร่วมทุกโปรโมชั่น"
                        name="is_join_promotion"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_join_promotion}
                        checked={values.is_join_promotion === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_join_promotion", true);
                          } else {
                            setFieldValue("is_join_promotion", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch5"
                        label="เมนูแนะนำ"
                        name="is_recommend"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_recommend}
                        checked={values.is_recommend === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_recommend", true);
                          } else {
                            setFieldValue("is_recommend", false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2 me-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch6"
                        label="เป็นแพคเกจบุฟเฟต์"
                        name="is_buffet"
                        className="custom-switch-st-1 tc-10 s2 mb-1"
                        defaultChecked={values.is_buffet}
                        checked={values.is_buffet === true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("is_buffet", true);
                          } else {
                            setFieldValue("is_buffet", false);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {pset ? (
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="pt-2 pb-1 px-3">
                      <Form.Check
                        type="switch"
                        id="option-set-switch2"
                        label="ตัวเลือกสินค้าจัดชุด"
                        name="productsetStatus"
                        className="custom-switch-st-1 tc-10  s1 mb-1"
                        defaultChecked={values.productsetStatus}
                        checked={values.productsetStatus === 1}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setFieldValue("productsetStatus", 1);
                          } else {
                            setFieldValue("productsetStatus", 0);
                          }
                        }}
                      />
                      <span className="ms-2" style={{ color: "#858796" }}>
                        {" "}
                        (เช่น ชุดอาหารกลางวัน Combo ข้าว+น้ำ){" "}
                      </span>
                    </div>
                    {values.productsetStatus === 1 ? (
                      <Productset
                        productset={productset}
                        setProductset={setProductset}
                        setFieldValue={setFieldValue}
                        optionsProduct={optionsProduct}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="custom-sd"
                      label="กำหนดตัวเลือกมาตรฐาน"
                      name="have_product_standard"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_standard}
                      checked={values.have_product_standard === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_standard", true);
                        } else {
                          setFieldValue("have_product_standard", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      ( ใช้ข้อมูลจากตัวเลือกมาตรฐานที่สร้างไว้ ){" "}
                    </span>
                  </div>
                  {values.have_product_standard === true ? (
                    <EditOptionStandard
                      listoptionstandard={listoptionstandard}
                      setListOptionstandard={setListOptionstandard}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="option-food-switch2"
                      label="ตัวเลือกเฉพาะสินค้า"
                      name="have_product_specific"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_specific}
                      checked={values.have_product_specific === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_specific", true);
                        } else {
                          setFieldValue("have_product_specific", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      (เพิ่มตัวเลือกเฉพาะสินค้านี้เท่านั้น เช่น เพิ่มไข่ดาว,
                      Size S M L, อื่นๆ){" "}
                    </span>
                  </div>
                  {values.have_product_specific === true ? (
                    <EditOptionProduct
                      optionproduct={optionproduct}
                      setOptionproduct={setOptionproduct}
                      values={values}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <div className="pt-2 pb-1 px-3">
                    <Form.Check
                      type="switch"
                      id="custom-notef"
                      label="ตัวเลือกหมายเหตุ"
                      name="have_product_remark"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.have_product_remark}
                      checked={values.have_product_remark === true}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("have_product_remark", true);
                        } else {
                          setFieldValue("have_product_remark", false);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      ( ใช้ข้อมูลจากตัวเลือกหมายเหตุที่สร้างไว้ ){" "}
                    </span>
                  </div>
                  {values.have_product_remark === true ? (
                    <EditOptionNote
                      listoptionnote={listoptionnote}
                      setListOptionnote={setListOptionnote}
                      values={values}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3 row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                      <Form.Control
                        type="text"
                        name="remark"
                        className="form-control"
                        placeholder="หมายเหตุ"
                        value={values.remark}
                        onChange={handleChange}
                        isInvalid={!!errors.remark}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                      <Form.Control
                        type="text"
                        name="created_at"
                        className="form-control"
                        placeholder="วันที่สร้าง"
                        value={values.created_at || currentDateString}
                        onChange={handleChange}
                        isInvalid={!!errors.created_at}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                      <Form.Label className="mb-1">ผู้แก้ไข</Form.Label>
                      <Form.Control
                        type="text"
                        name="userCreate"
                        className="form-control"
                        placeholder="ผู้แก้ไข"
                        value={values.modified_by}
                        onChange={handleChange}
                        isInvalid={!!errors.modified_by}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </fieldset>

            {activeCancelBtn === true && (
              <div className="card card-st-3 mb-3">
                <div className="card-body px-xl-3">
                  <button
                    type="button"
                    className="btn  btn-st-3"
                    onClick={() => setModalconfirmcancelsale(true)}
                    style={{ background: "#F95057", borderColor: "#F95057" }}
                  >
                    ยกเลิกการขาย
                  </button>
                </div>
              </div>
            )}

            <div className="text-end pb-4 pe-3">
              {formdisabled === true ? (
                <Button
                  type="button"
                  className="btn-st-3 mx-3"
                  onClick={(e) => {
                    setFormdisabled(false);
                  }}
                >
                  แก้ไข
                </Button>
              ) : (
                <div className="text-end pt-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-6 me-3 btn"
                    onClick={() => setModalcancel(true)}
                  >
                    ยกเลิก
                  </Button>
                  <Button type="submit" className="btn-st-3 ">
                    บันทึก
                  </Button>
                </div>
              )}
            </div>

            <ConfirmCancelsaleModal
              show={modalconfirmcancelsale}
              onHide={() => setModalconfirmcancelsale(false)}
              onSuccess={() => setModalsuccess(true)}
              formdata={formdata}
              productID={productID}
              setTextsuccess={setTextsuccess}
              setFieldValue={setFieldValue}
            />
          </Form>
        )}
      </Formik>

      <ConfirmCancelModal
        show={modalcancel}
        onHide={() => setModalcancel(false)}
      />
      <SuccessModal
        show={modalsuccess}
        onHide={() => setModalsuccess(false)}
        textsuccess={textsuccess}
      />
    </div>
  );
}

const Productset = (props) => {
  const addHandeler = () => {
    props.setProductset((optionproduct) => [
      ...optionproduct,
      {
        id: nanoid(),
        productID: "",
        productName: "",
        productNum: 1,
        productPrice: 0,
        productsumPrice: 0,
      },
    ]);
  };

  const handleDeleteoption = (id) => {
    props.setProductset((optionlist) =>
      optionlist.filter((item) => item.id !== id)
    );
  };

  const handleproductChange = (e, id) => {
    props.setProductset((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              productPrice: (el.productPrice = e.productPrice),
              productID: (el.productID = e.value),
              productNum: (el.productNum =
                el.productNum > 1 ? el.productNum : 1),
            }
          : el
      )
    );
  };

  const handleproductNumChange = (id, value) => {
    props.setProductset((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              productNum: (el.productNum = value),
            }
          : el
      )
    );
  };

  const sumPrice = props.productset
    .map((pd) => pd.productPrice * pd.productNum)
    .reduce((prev, curr) => prev + curr);

  const sumNum = props.productset
    .map((pd) => pd.productNum)
    .reduce((prev, curr) => parseInt(prev) + parseInt(curr));

  return (
    <div className="px-5 py-4">
      <div className=" mt-2 ps-2">
        {props.productset.map((item, index) => (
          <div key={item.id}>
            <div className="row gx-4 align-items-center">
              <div className="col">
                <Form.Group className="mb-2 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อสินค้า {item.productID}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    as="select"
                    name="product"
                    className="w-100 select-st-1"
                    options={props.optionsProduct}
                    placeholder="เลือกสินค้า"
                    onChange={(e) => handleproductChange(e, item.id)}
                    value={props.optionsProduct.filter(
                      (i) => i.value === item.productID
                    )}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">จำนวน</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="จำนวน"
                    defaultValue={item.productNum}
                    onChange={(e) =>
                      handleproductNumChange(item.id, e.target.value)
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">ราคาขาย</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="ราคาขาย"
                    value={item.productPrice}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <Form.Group
                  className="mb-2 form-st-3 pb-1"
                  style={{ width: "150px" }}
                >
                  <Form.Label className="mb-1">ราคารวม</Form.Label>
                  <Form.Control
                    type="text"
                    name="optionName"
                    className="form-control"
                    placeholder="ราคารวม"
                    value={(item.productPrice * item.productNum).toFixed(2)}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <div className="col-auto">
                <div style={{ width: "50px" }}>
                  {index > 0 ? (
                    <button
                      type="button"
                      className="btn  btn-delete-st-1"
                      onClick={() => handleDeleteoption(item.id)}
                    ></button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="row gx-4 align-items-center">
          <div className="col">
            <button
              type="button"
              className="btn btn-st-3 mb-4"
              onClick={addHandeler}
            >
              เพิ่มสินค้าจัดชุด
            </button>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}>
              <Form.Group
                className="mb-2 form-st-3 pb-1"
                style={{ width: "150px" }}
              >
                <Form.Label className="mb-1">จำนวน</Form.Label>
                <Form.Control
                  type="text"
                  name="optionName"
                  className="form-control"
                  placeholder="จำนวน"
                  value={sumNum}
                  disabled={true}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}></div>
          </div>
          <div className="col-auto">
            <div style={{ width: "150px" }}>
              <Form.Group
                className="mb-2 form-st-3 pb-1"
                style={{ width: "150px" }}
              >
                <Form.Label className="mb-1">ราคารวม</Form.Label>
                <Form.Control
                  type="text"
                  name="optionName"
                  className="form-control"
                  placeholder="ราคารวม"
                  value={sumPrice.toFixed(2)}
                  disabled={true}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-auto">
            <div style={{ width: "50px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Imageupload = (props) => {
  const [imgData, setImgData] = useState(false);
  const [imageURL, setImageURL] = useState(
    props.values.img_url !== "" ? props.values.img_url : undefined
  );
  const [loading, setLoading] = useState(false);

  const handlePreviewimage = async (e) => {
    // console.log("e", e.target.files[0]);
    setLoading(true);
    if (e.target.files[0]) {
      // const res = await props.uploadImageFile(e.target.files[0]);
      // setImageURL(res);
      // props.setFieldValue("img_url", res);
      try {
        const res = await UploadFile("product-images", e.target.files[0]);
        // console.log("🚀 ~ handlePreviewimage ~ res:", res)

        setImageURL(res);
        props.setFieldValue("img_url", res);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("check_img", props.values);
    setImgData(props.values);
    const exists = Global.checkURL(props.values.img_url);
    const img_url = exists ? props.values.img_url : process.env.REACT_APP_API_URL_SOCKET + "/" + props.values.img_url;
    setImageURL(img_url);
    // setImageURL(props.values.img_url !== "" ? props.values.img_url : undefined);
  }, [props.values]);

  return (
    <div className="px-3">
      <div>
        <div className="tc-10 mb-1 pt-4">
          รูปภาพสินค้า <span className="text-danger">*</span>
        </div>
        <div className="tc-10 fs-14">ขนาดแนะนำ : กว้าง 146px สูง 107px</div>
      </div>
      <div className="d-flex align-items-center mb-4 mt-3 ">
        <div className="bx-upload">
          {imageURL ? (
            <img
              src={imageURL}
              alt="business logo"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/blank.png";
              }}
            />
          ) : (
            <div className="preimg"></div>
          )}
        </div>
        <input
          type="file"
          name=""
          className="fileUpload hide-input"
          id="fileUpload"
          accept="image/*"
          onChange={(e) => {
            handlePreviewimage(e);
          }}
        />

        <div className="flex-fill ms-3">
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-st-4 btn-upload me-3"
              onClick={(e) => {
                document.getElementById("fileUpload").click();
              }}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span>อัพโหลดรูปภาพ</span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-st-2 btn-remove-image"
              onClick={(e) => {
                setImgData(false);
                props.setFieldValue("img_url", "");
              }}
            >
              ลบ
            </button>
          </div>
          <div className="tc-7 fs-12">
            อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div
        className="form-check form-check-st-3 "
        style={{ backgroundColor: "" }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          style={{ height: "18px", width: "18px" }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
        <label className="form-check-label" id="booty-check" />
      </div>
    </>
  );
});

function FoodrecipesData() {
  return (
    <div>
      <div className="card card-st-2" style={{ height: "400px" }}>
        <div className="card-body px-0 py-4 d-flex align-items-center justify-content-center">
          <div>
            <div className="text-center mb-3">
              <img src="/images/tdesign_system-setting.png" width={100} />
            </div>
            <div className="fs-20 tc-12">กำลังพัฒนาระบบ</div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const getunitData = () => {
//     const options = []
//     fetch('/json/productunit.json')
//         .then((response) => response.json())
//         .then((result) => {

//             for (var i = 0; i < result.length; i++) {
//                 var obj = {}
//                 obj['value'] = result[i].unitID
//                 obj['label'] = result[i].unitName
//                 options.push(obj)
//             }
//         })
//         .catch((error) => console.log('error', error))

//     return options
// }

// function SettingData () {
//     const [optionsUnit, setOptionsUnit] = useState([])

//     useEffect(() => {
//         setOptionsUnit(getunitData())
//     }, [])

//     return (
//         <Formik
//             enableReinitialize
//             initialValues={{
//                 'ratio': '',
//                 'umlLabel': '',
//                 'ums': '',
//             }}
//             validationSchema=""
//             onSubmit={async (values) => {

//             }}
//         >
//             {({
//                 handleSubmit,
//                 handleChange,
//                 touched,
//                 errors,
//                 values,
//                 setFieldValue,
//                 isInvalid,
//                 isValid,

//             }) => (
//                 <Form noValidate onSubmit={handleSubmit}>
//                     <fieldset>
//                         <div className="card card-st-3 mb-3">
//                             <div className="card-body px-xl-3">
//                                 <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-2">ตั้งค่าขั้นสูง</div>
//                                 <div className="px-3">
//                                     <div className="pt-2 pb-1 mb-3">
//                                         <Form.Check
//                                             type="switch"
//                                             id="custom-switch2"
//                                             label="ตั้งค่าสต๊อก"
//                                             name="settingStock"
//                                             className="custom-switch-st-1 tc-10  s1 mb-1"
//                                             defaultChecked={values.settingStock}
//                                             onChange={(e) => {
//                                                 if (e.target.checked === true) {
//                                                     setFieldValue('settingStock', 1)
//                                                 } else {
//                                                     setFieldValue('settingStock', 0)
//                                                 }
//                                             }}
//                                         />
//                                         <span className="ms-2"
//                                               style={{ color: '#858796' }}> ( สินค้ามีการตัดสต๊อก ) </span>
//                                     </div>
//                                     {values.settingStock === 1 ? (
//                                         <div>
//                                             <div className="pb-2">
//                                                 <Form.Label
//                                                     className="mb-4 pt-2 tc-10">ตั้งค่าประเภทการตัดสต๊อก</Form.Label>
//                                                 <div className="mb-3">
//                                                     <Form.Check
//                                                         inline
//                                                         label="ตัดสต๊อกสินค้าสำเร็จรูป"
//                                                         name="stockType"
//                                                         type="radio"
//                                                         id={`stockType-1`}
//                                                         className="form-check-st-2 tc-7"
//                                                         checked={values.stockType === 1}
//                                                         onChange={(e) => {
//                                                             setFieldValue('stockType', 1)
//                                                         }}
//                                                     />
//                                                     <Form.Check
//                                                         inline
//                                                         label="ตัดสต๊อกตามสตรอาหาร"
//                                                         name="stockType"
//                                                         type="radio"
//                                                         id={`stockType-2`}
//                                                         className="form-check-st-2 tc-7"
//                                                         checked={values.stockType === 2}
//                                                         onChange={(e) => {
//                                                             setFieldValue('stockType', 2)
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             {values.stockType == 1 ?
//                                                 (
//                                                     <div className="row">
//                                                         <div className="col-md-2">
//                                                             <Form.Group className="mb-3 form-st-3 pb-1">
//                                                                 <Form.Label className="mb-1">หน่วยเล็ก
//                                                                     (UMS) </Form.Label>
//                                                                 <Form.Control
//                                                                     type="text"
//                                                                     name="ums"
//                                                                     className="form-control"
//                                                                     placeholder="หน่วยเล็ก"
//                                                                     value={values.ums}
//                                                                     onChange={handleChange}
//                                                                     isInvalid={!!errors.ums}

//                                                                 />
//                                                             </Form.Group>
//                                                         </div>
//                                                         <div className="col-md-2">

//                                                             <Form.Group className="mb-2 mt-3 form-st-3  ">
//                                                                 <Form.Label className="mb-1">หน่วยใหญ่
//                                                                     (UML) </Form.Label>
//                                                                 <Select as="select"
//                                                                         name="uml"
//                                                                         onChange={(e) => {
//                                                                             setFieldValue('uml', e.value)
//                                                                             setFieldValue('umlLabel', e.label)
//                                                                         }}
//                                                                         className="w-100 select-st-1"
//                                                                         options={optionsUnit}
//                                                                         placeholder="หน่วยใหญ่ (UML) "
//                                                                         value={
//                                                                             optionsUnit.filter(
//                                                                                 item => item.value === values.uml,
//                                                                             )
//                                                                         }
//                                                                 />
//                                                             </Form.Group>
//                                                         </div>
//                                                         <div className="col-md-3">
//                                                             <Form.Group className="mb-3 form-st-3 pb-1">
//                                                                 <Form.Label className="mb-1">อัตราส่วนต่อหน่วยย่อย
//                                                                     (Ratio) </Form.Label>
//                                                                 <Form.Control
//                                                                     type="text"
//                                                                     name="ratio"
//                                                                     className="form-control"
//                                                                     placeholder="อัตราส่วนต่อหน่วยย่อย"
//                                                                     value={values.ratio}
//                                                                     onChange={handleChange}
//                                                                     isInvalid={!!errors.ratio}

//                                                                 />
//                                                             </Form.Group>
//                                                         </div>
//                                                         <div className="col-md-5">
//                                                             <Form.Group className="mb-3 form-st-3 pb-1">
//                                                                 <Form.Label className="mb-1">ผลลัพธ์ </Form.Label>
//                                                                 <Form.Control
//                                                                     type="text"
//                                                                     name="result"
//                                                                     className="form-control"
//                                                                     placeholder="ผลลัพธ์"
//                                                                     value={`${values.ratio} X ${values.ums} เท่ากับ 1 ${values.umlLabel}`}
//                                                                     onChange={handleChange}
//                                                                     isInvalid={!!errors.result}
//                                                                     disabled={true}
//                                                                 />
//                                                             </Form.Group>
//                                                         </div>
//                                                     </div>
//                                                 ) : (
//                                                     values.stockType == 2 ? (
//                                                         <div
//                                                             style={{ color: '#F00' }}>กำหนดรายการที่ต้องการตัดสต๊อกตามเมนูสตรอาหาร</div>
//                                                     ) : ''
//                                                 )
//                                             }

//                                             <div className="text-end pt-2 pb-3 btn-group-control ">
//                                                 <Button type="button" className="btn-st-6 me-3 "
//                                                         style={{ width: '100px' }}>ยกเลิก</Button>
//                                                 <Button type="submit" className="btn-st-3  "
//                                                         style={{ width: '100px' }}>บันทึก</Button>
//                                             </div>

//                                         </div>
//                                     ) : ''
//                                     }
//                                 </div>

//                             </div>
//                         </div>
//                     </fieldset>
//                 </Form>
//             )}
//         </Formik>
//     )
// }

// function HistoryData () {
//     const [logdata, setLogdata] = useState([])

//     const getlogData = () => {
//         fetch('/json/productLog.json')
//             .then((response) => response.json())
//             .then((result) => {
//                 setLogdata(result)
//             })
//             .catch((error) => console.log('error', error))
//     }
//     useEffect(() => {
//         getlogData()
//     }, [])

//     const columns = [
//         {
//             name: 'วันที่',
//             selector: row => row.logDate,
//             width: '200px',
//         },
//         {
//             name: 'LOG',
//             selector: row => row.logName,
//             width: '300px',
//             cell: (row, index, column, id) => <div style={{ color: '#565DFF', fontWeight: 500 }}>{row.logName}</div>,
//         },
//         {
//             name: 'รายการ',
//             selector: row => row.logDetail,
//         },
//         {
//             name: 'สมาชิก',
//             selector: row => row.userUpdate,
//             width: '250px',
//             cell: (row, index, column, id) => <div>
//                 <div>{row.userUpdate}</div>
//                 <div className="fs-12 mt-1">{row.logDate}</div>
//             </div>,
//         },

//     ]

//     const customStyles = {
//         rows: {
//             style: {
//                 minHeight: '52px',
//             },
//         },
//         headCells: {
//             style: {
//                 paddingLeft: '15px',
//                 paddingRight: '15px',
//                 color: 'rgba(76, 78, 100, 0.87)',
//                 fontFamily: 'Sarabun',
//                 fontSize: '16px',
//                 background: ' #F5F5F7',
//                 paddingTop: '15px',
//                 paddingBottom: '15px',

//             },
//         },
//         cells: {
//             style: {
//                 paddingLeft: '15px',
//                 paddingRight: '15px',
//                 paddingTop: '10px',
//                 paddingBottom: '10px',
//                 color: 'rgba(76, 78, 100, 0.87)',
//                 fontFamily: 'Sarabun',
//                 fontSize: '16px',
//                 fontWeight: '300',
//             },
//         },
//     }

//     const paginationComponentOptions = {
//         rowsPerPageText: 'จำนวนรายการต่อหน้า',
//         rangeSeparatorText: 'of',
//         selectAllRowsItem: true,
//         selectAllRowsItemText: 'Todos',
//     }

//     const [filterText, setFilterText] = useState('')
//     const filteredItems = logdata.filter(
//         item => item.logName && item.logName.toLowerCase().includes(filterText.toLowerCase()),
//     )

//     const downloadExcel = () => {
//         const ws = utils.json_to_sheet(filteredItems)
//         const wb = utils.book_new()
//         utils.book_append_sheet(wb, ws, 'Log')
//         writeFileXLSX(wb, 'Log.xlsx')
//     }

//     // https://www.figma.com/file/mmOXwO3tPmRhjG8OL548LC/DEV-JIGSAW?node-id=986%3A58157&mode=dev

//     const optionsLog = [
//         { value: 'ทั้งหมด', label: 'ทั้งหมด' },
//         { value: 'ยกเลิกการขาย', label: 'ยกเลิกการขาย' },
//         { value: 'สร้าง', label: 'สร้าง' },
//         { value: 'แก้ไข', label: 'แก้ไข' },
//     ]

//     return (

//         <div>
//             <div className="card card-st-2">
//                 <div className="card-body px-0 py-4">
//                     <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
//                         <div className="me-3 mb-2" style={{ flex: '0 0 200px' }}>
//                             <Select as="select"
//                                     name="logname"
//                                     onChange={(e) => {
//                                         (e.label != 'ทั้งหมด' ? setFilterText(e.label) : setFilterText(''))
//                                     }}
//                                     className="w-100 select-st-1 pd"
//                                     options={optionsLog}
//                                     placeholder="ทั้งหมด"
//                             />
//                         </div>
//                         <button className="btn btn-export-st-1 mb-2 me-auto" onClick={downloadExcel}>Export</button>

//                     </div>
//                     <DataTable
//                         columns={columns}
//                         data={filteredItems}
//                         customStyles={customStyles}
//                         selectableRows
//                         selectableRowsComponent={Checkbox}
//                         pagination
//                         paginationComponentOptions={paginationComponentOptions}
//                     />
//                 </div>
//             </div>

//         </div>
//     )
// }

function ConfirmCancelsaleModal(props) {
  const { setTextsuccess, productID, onSuccess, setFieldValue, ...rest } =
    props;
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();
  const { slug } = useRecoilValue(appState);
  const navigate = useNavigate();

  const submitRemoveProduct = async (values) => {
    try {
      // const {created_at, ...final_values} = removeCatField;

      // console.log('Submit update product:', final_values)
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/business-product/del-product/` +
          String(values),
        {
          method: "PATCH",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "business-id": user.businessId,
          }),
        }
      );
      return res.text();
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const returnProductPage = () => {
    navigate(`/${slug}/products`);
  };

  const handleCancel = async (productID) => {
    submitRemoveProduct(productID);
    props.setFieldValue("status", 2);
    props.setTextsuccess("ยกเลิกการขายสำเร็จ");
    props.onHide();
    props.onSuccess();
    returnProductPage();
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องยืนยันยกเลิกขายสินค้า
              </div>
              <div className="text-center fs-22 tc-7">
                <span style={{ color: "#565DFF" }}>
                  {props.formdata.productName}
                </span>{" "}
                ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "150px" }}
                onClick={() => handleCancel(props.productID)}
              >
                ยกเลิกการขาย
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "150px" }}
                onClick={props.onHide}
              >
                ไม่ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmCancelModal(props) {
  const { user } = useRecoilValue(authState);
  const { token, setToken } = useToken();
  const { slug } = useRecoilValue(appState);

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการยกเลิก
              </div>
              <div className="text-center fs-22 tc-7">
                การสร้างรายการนี้ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Link
                to={`/${slug}/products`}
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "100px" }}
              >
                ใช่
              </Link>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SuccessModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4 mx-auto">
          <div className="text-center mb-4 flex justify-center">
            <svg
              width="84"
              height="83"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-18 tc-7">{props.textsuccess}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
