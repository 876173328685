import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authState, useAuthLogin, useAuthTokenCheck, useClientCheck } from '../../state/auth'
import { posState } from 'state/pos'
import { appState } from 'state/app'

const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string().required('อีเมลหรือเบอร์โทรศัพท์ is required'),
    password: Yup.string().required('รหัสผ่าน is required'),
})

export default function NotFoundPage () {
    const navigate = useNavigate()
    const { isLoggedIn, authErrorStatus, authErrorDisplayText } = useRecoilValue(authState)
    const { loginBusiness } = useRecoilValue(appState)
    const authUser = useAuthLogin()
    const authTokenCheck = useAuthTokenCheck()
    const clientCheck = useClientCheck()

    const formik = useFormik({
        initialValues: {
            emailOrPhone: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await authUser(values)
        },
    })

    // useEffect(() => {
    //   authTokenCheck();
    // },[]);

    useEffect(() => {
        // console.log("isLoggedIn: ", isLoggedIn)
        // if(isLoggedIn) {
        //   navigate("/");
        // }
        clientCheck()
        authTokenCheck()
    }, [isLoggedIn])

    return (
        <div className="bg-white w-full min-h-screen p-[min(2.92vw,30px)] flex justify-center items-center">
            <div>
                {/* <div className="text-24 font-[700] flex flex-col items-center">
          <div className="w-[min(4.83vw,49.5px)] aspect-square">
            <img className="w-full h-full object-contain" src="img/logo-3.png" alt="" />
          </div>
          <div className="comfortaa">
            <span className="text-[--color-grey]">Allder</span>{" "}
            <span className="text-[--color-green]">Cafe’</span>
          </div>
        </div> */}

                <div className="text-5xl font-bold text-center">ไม่พบหน้าที่คุณต้องการ</div>
                <div className="text-[200px] text-center text-gray-500 leading-none mt-5">404</div>
                <div className="text-3xl text-center text-gray-500 font-bold">Page Not Found</div>
                <div className="text-3xl text-center text-gray-400 mt-5">We couldn't find the page you are looking for
                </div>

                <div className="text-16 flex gap-[min(0.87vw,9px)] flex justify-center fixed inset-x-0 bottom-20">
                    <div>Power By</div>
                    <div className="aspect-square w-[min(2.05vw,21px)]">
                        <img
                            className="w-full h-full object-contain"
                            src="../../images/logo_allder_2-01.png"
                            alt=""
                        />
                    </div>
                    <div>Allder</div>
                </div>
            </div>
        </div>
    )
}
