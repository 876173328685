import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAll = (config) =>
    axiosClient.get(`/department`, config);

export default {
    GetAll,
};