import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import KitchenForm from "components/branch/kitchen/KitchenForm";
import KitchenCategoryList from "components/branch/kitchen/KitchenCategoryList";
import KitchenProductList from "components/branch/kitchen/KitchenProductList";

const KitchenManage = () => {

    return (
        <div style={{ padding: "20px 20px 20px 20px" }}>
            <KitchenForm />
            <KitchenCategoryList />
            <KitchenProductList />
        </div>
    )
}

export default KitchenManage;