import React, { useState,useEffect  } from 'react';
import Form from 'react-bootstrap/Form';

import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import {Link,useParams,useNavigate} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import ProductSelectModal from "layouts/ModalProductSelect";
import BranchProductList from "layouts/BranchProductList"
import SelectCategory from "fragments/SelectCategory";
import getCurrentDate from 'utils/CurrentDate';
import  DownloadExcel from 'utils/DownloadExcel';


export function  Salesmodel() {
    
    const { branchID } = useParams();
    
    const [selectsalemodel , setSelectsalemodell] = useState(null);
    const [salemodel , setSalemodel] = useState(null);
    
    const getModel = (branchID) => {
        fetch('/json/salemodel.json')
          .then((response) => response.json())
          .then((result) => {
            setSelectsalemodell(result.salemodelID)
            setSalemodel(result.salemodelID)
          })
          .catch((error) => console.log("error", error));
    }

    const handleSave = () => {
        // save
        console.log(selectsalemodel)
        setSalemodel(selectsalemodel)
        // getModel(branchID);
    }

    useEffect(() => {
        getModel(branchID);
    }, []);


    return (
        <div style={{padding: '30px 33px 33px 31px'}}>
            
            <div className="card card-st-1 mb-4">
                <div className='card-body pb-4'>
                    <div className='tc-8 fs-18 fw-bold px-3 mb-4'>รูปแบบการขาย</div>
                    <div className='d-flex px-3 flex-wrap mb-2'>
                        <div className='selectmodel mb-3 me-4'>
                            <input type='radio' className='hide-input' name="selectmodel" id='type1' 
                            onChange={() => setSelectsalemodell(1)}
                            checked={selectsalemodel == 1}
                            />
                            <label htmlFor='type1'>
                                <div className='check mb-2'> A La Cart </div>
                                <div>คิดราคาอาหารต่อจาน</div>
                            </label>
                        </div>
                        <div className='selectmodel mb-3'>
                            <input type='radio' className='hide-input' name="selectmodel" id='type2' 
                            onChange={() => setSelectsalemodell(2)}
                            checked={selectsalemodel == 2}
                            />
                            <label htmlFor='type2'>
                                <div className='check mb-2'> A La Cart +  Buffet </div>
                                <div>คิดราคาอาหารต่อจาน + ราคาเหมาจ่ายตามรายการด้านล่าง</div>
                            </label>
                        </div>
                    </div>
                    <div className="text-end pt-3 btn-group-control" >
                        
                        <Button type="submit" className='btn-st-3 ' 
                        onClick={() => handleSave()}
                        disabled={!salemodel}
                        >
                            { salemodel ? 'แก้ไข' : 'บันทึก'}
                            
                        </Button>
                    </div>
                </div>
            </div>

            {
                salemodel == 1 ? (
                    <AlacartModel />
                ) : ''
            }

            {
                salemodel == 2 ? (
                    <AlacartBuffetModel />
                ) : ''
            }
        </div>
    )
}

function AlacartModel({...props}){

    const [productdata, setProductdata] = useState([]);
    const [modalproductlistShow, setModalproductlistShow] = useState(false);

    const getproductData = () => {
        fetch('/json/product.json')
          .then((response) => response.json())
          .then((result) => { 
            setProductdata(result);
          })
          .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getproductData();
    }, []);



    const [filterText, setFilterText] = useState('');
    const filteredItems = productdata.filter(
        item => item.productCode && item.productCode.toLowerCase().includes(filterText.toLowerCase()) || item.productName && item.productName.toLowerCase().includes(filterText.toLowerCase()) || item.productCategory && item.productCategory.toLowerCase().includes(filterText.toLowerCase()),
    );

    const AddProduct = (product) => {
        console.log(product)
        setModalproductlistShow(false)
    }

    const deleteProduct = (item) => {
        console.log(item.productID)
    }


    return (
        <div className="card card-st-2">
            <div className="card-body px-0 py-3">
                <div className='d-flex align-items-center mb-3 px-4'>
                    <div className='fs-20 fw-bold tc-8  me-auto '>สินค้า</div>
                    <Button 
                        type='button' 
                        className='btn btn btn-st-7 ' 
                        onClick={() => {setModalproductlistShow(true) } } 
                    >เพิ่มสินค้า</Button>
                </div>
                <div className='d-flex align-items-center px-4 mb-4'>
                    <div className="me-3" style={{flex:"0 0 200px"}}>
                        <SelectCategory 
                        setFilterText={setFilterText}
                        />
                    </div>
                    <DownloadExcel 
                    data={filteredItems}
                    filename="รายชื่อสินค้า"
                    />
                    <div className=''>
                        <input type='text' className='form-control form-st-4' placeholder='ค้นหา ชื่อ, ประเภท,หมวดหมู่' onKeyUp={e => setFilterText(e.target.value)} />
                    </div>
                </div>

                <BranchProductList 
                filteredItems={filteredItems}
                handleDelete={(product) => deleteProduct(product) }
                />
                
            </div>

            <ProductSelectModal 
                show={modalproductlistShow}
                onHide={() => setModalproductlistShow(false)}
                handleSubmit={(product) => AddProduct(product) }
            />

        </div>
    )
}

function AlacartBuffetModel({...props}){

    const { branchID } = useParams();

    const [salepackage , setSalepackage] = useState(null);
    const [packageModal , setPackageModal] = useState(false);
    const [formdata , setFormdata] = useState({});

    const getSalePackage = (branchID) => {
        fetch('/json/salepackage.json')
          .then((response) => response.json())
          .then((result) => {
            setSalepackage(result)
          })
          .catch((error) => console.log("error", error));
    }

    useEffect(() => {
        getSalePackage(branchID);
    }, []);

    return (
        <>
        { 
            !salepackage ? (
                <div className="card card-st-2">
                    <div className="card-body row align-items-end p-0">
                        <div className='me-auto p-xl-5 p-4 col-xl-8'>
                            <div className='fs-24 tc-6 text-center ps-3 mb-3'>สร้างแพ็คเกจร้านค้าที่เหมาะกับธุรกิจของคุณ 🎉</div>
                            <div className='fs-14 tc-10 text-center mb-4'>พร้อมแล้วสำหรับการขาย สร้างแพ็คเกจเพื่อเปิดขายบนหน้าร้านออนไลน์ของคุณ</div>
                            <div className='text-center'>
                                <Button 
                                    type='button' 
                                    className='btn btn btn-st-7 ' 
                                    onClick={() => {setPackageModal(true) } } 
                                >สร้างแพ็คเกจ</Button>
                            </div>
                        </div>
                        <div className='col-xl-4 text-end'>
                            <img src='/images/image-1.png' width={300} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="card card-st-2">
                    <div className="card-body px-0 py-3">
                        <div className='d-flex align-items-center mb-3 px-4'>
                            <div className='fs-20 fw-bold tc-8  me-auto '>แพ็คเกจ</div>
                            <Button 
                                type='button' 
                                className='btn btn btn-st-7 ' 
                                onClick={() => {setPackageModal(true) } } 
                            >เพิ่มแพ็คเกจ</Button>
                        </div>

                        <SalePackageList
                        salepackage={salepackage}
                        packageModalObj={{
                            packageModal,
                            setPackageModal
                        }}
                        formdataObj={{
                            formdata,
                            setFormdata
                        }}
                        />
                    </div>
                </div>
            )
        }

        <PackageModal 
            show={packageModal}
            onHide={() => setPackageModal(false)}
            onSuccess={() =>  setPackageModal(false) }
            formdata={formdata}
        />

        </>
        
    )
}

function PackageModal(props) {

    const { setModalDeleteShow,onSuccess,handleDelete,formdata, ...rest } = props;

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const schema = yup.object().shape({
        packageName: yup.string().required(),
        menuConnect: yup.string().required(),
        hour: yup.string().required(),
        minute: yup.string().required(),
        startDate: yup.string()
        .when("useDate", (useDate, schema) => {
            if(useDate == 1) 
              return schema.required()
            return schema
        }),
        endDate: yup.string()
        .when("useDate", (useDate, schema) => {
            if(useDate == 1) 
              return schema.required()
            return schema
        }),
    });

    const optionMenu= [
        { value: 1, label: 'Premium Buffet บุฟเฟ่ต์ชาบู 699' },
        { value: 2, label: 'Buffet 199' }
    ];


    return (
      <Modal
        {...rest}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-24 fw-bold me-auto' style={{color:'#565DFF'}}>เพิ่มแพ็คเกจ</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ formdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        console.log(values)
                        props.onSuccess();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">วันที่สร้าง <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="dateCreate" 
                            className="form-control" 
                            placeholder="วันที่สร้าง"
                            value={getCurrentDate('/')}
                            onChange={handleChange}
                            isInvalid={!!errors.dateCreate}
                            disabled={true}
                            
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อแพ็คเกจ <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="packageName" 
                            className="form-control" 
                            placeholder="ชื่อแพ็คเกจ"
                            value={values.packageName}
                            onChange={handleChange}
                            isInvalid={!!errors.packageName}
                            
                            />
                        </Form.Group>

                        <Form.Group className={`mb-2 form-st-3 pb-1` }>
                            <Form.Label className="mb-1">เชื่อมต่อกับเมนู   <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="menuConnect"
                                onChange={(e) => {
                                    setFieldValue("menuConnect", e.value)
                                }}
                                value={
                                    optionMenu.filter(
                                        item => item.value === values.menuConnect
                                    )
                                }
                                className={`w-100 ${ errors.menuConnect ? 'isInvalid' : '' } select-st-1`}
                                options={optionMenu}
                                placeholder="เชื่อมต่อกับเมนู"
                            />
                        </Form.Group>

                        <div className='row'>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">ชั่วโมง <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control
                                    type="number" 
                                    name="hour" 
                                    className="form-control" 
                                    placeholder="ชั่วโมง"
                                    value={values.hour}
                                    onChange={handleChange}
                                    isInvalid={!!errors.hour}
                                    
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-6'>
                                <Form.Group className="mb-3 form-st-3 pb-1 ">
                                    <Form.Label className="mb-1">นาที <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control
                                    type="number" 
                                    name="minute" 
                                    className="form-control" 
                                    placeholder="นาที"
                                    value={values.minute}
                                    onChange={handleChange}
                                    isInvalid={!!errors.minute}
                                    
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='mb-4'>
                            <Form.Check 
                                type="switch"
                                id="useDate"
                                label='กำหนดวันที่ใช้งาน'
                                name='useDate'
                                className='custom-switch-st-1  tc-10 s1 mb-1'
                                checked={values.useDate == 1}
                                onChange={(e) => {
                                    if(e.target.checked === true){
                                        setFieldValue("useDate", 1)
                                    }else{
                                        setFieldValue("useDate", 0)
                                    }
                                    
                                }}
                            />
                        </div>

                        {
                            values.useDate == 1 ? (
                            <div>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">วันเปิดใช้งาน <span className='text-danger'>*</span> </Form.Label>
                                    <DatePicker 
                                    name='startDate'
                                    inputFormat="DD/MM/YYYY" 
                                    selected={startDate} 
                                    onChange={(date) => { setStartDate(date);setFieldValue("startDate",getCurrentDate('-',date) ); } } 
                                    placeholder="DD/MM/YYYY" 
                                    className={`w-100 datepicker2 ${ errors.startDate ? 'isInvalid' : '' } `} 
                                    value={values.startDate}
    
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">วันที่ปิดใช้งาน <span className='text-danger'>*</span> </Form.Label>
                                    <DatePicker 
                                    name='endDate'
                                    inputFormat="DD/MM/YYYY" 
                                    selected={endDate} 
                                    onChange={(date) => { setEndDate(date);setFieldValue("endDate",getCurrentDate('-',date) ); } } 
                                    placeholder="DD/MM/YYYY" 
                                    className={`w-100 datepicker2 ${ errors.endDate ? 'isInvalid' : '' } `} 
                                    value={values.endDate}
                                    />
                                </Form.Group>
                            </div>
                            ) : ''
                        }

                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                            <Form.Label className="mb-1">หมายเหตุ  </Form.Label>
                            <Form.Control
                            type="text" 
                            name="remark" 
                            className="form-control" 
                            placeholder="หมายเหตุ"
                            value={values.remark}
                            onChange={handleChange}
                            isInvalid={!!errors.remark}
                            
                            />
                        </Form.Group>
                            
                        <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="เปิดใช้งาน"
                                name="packageStatus"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.packageStatus === 1}
                                onChange={(e) => {
                                    setFieldValue("packageStatus", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="ปิดใช้งาน"
                                name="packageStatus"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.packageStatus === 0}
                                onChange={(e) => {
                                    setFieldValue("packageStatus", 0)
                                }}
                            />
                            </div>
                        ))}
                    
                        <div className="d-flex align-items-center pt-2 pb-3 btn-group-control" >
                            {
                                values.packageID ? (
                                    <Button type="button" className='btn-st-5 me-auto px-4 '  style={{width:"90px"}} onClick={props.handleDelete} >ลบ</Button>
                            
                                ) : <div className='me-auto'></div>
                            }
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'140px'}}> { values.bankID  ?  'แก้ไข' : 'เพิ่มแพ็คเกจ' }</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function SalePackageList({packageModalObj,formdataObj,salepackage,...props}){
    const [modalproductlistShow, setModalproductlistShow] = useState(false);
    const [packagedata, setPackagedata] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [packageselect, setPackageselect] = useState(null);

    const getpackageData = (packageID) => {
        fetch('/json/product.json')
          .then((response) => response.json())
          .then((result) => { 
            setPackagedata(result);
          })
          .catch((error) => console.log("error", error));
    };

    const filteredItems = packagedata.filter(
        item => item.productCode && item.productCode.toLowerCase().includes(filterText.toLowerCase()) || item.productName && item.productName.toLowerCase().includes(filterText.toLowerCase()) || item.productCategory && item.productCategory.toLowerCase().includes(filterText.toLowerCase()),
    );

    useEffect(() => {
        setPackageselect(salepackage[0])
        getpackageData();
    }, []);

    const AddProduct = (product) => {
        console.log(product)
        setModalproductlistShow(false)
    }

    const deleteProduct = (item) => {
        console.log(item.productID)
    }

    return (
        <div className=''>
            <div className='d-flex align-items-end px-4 mb-4'>
                {salepackage && salepackage.map((item,index) => (
                    <button 
                    className={`btn btn-tab-st-1 me-3 fw-500 tc-7 ${packageselect && packageselect.packageID == item.packageID ? 'active' : '' }`}  
                    key={index}    
                    onClick={() => setPackageselect(item) }
                    >{item.packageName}</button>
                ))}
            </div>
        
            <div>
                <div className='px-4 mb-3'>
                    <Button 
                    type='button' 
                    className='btn btn btn-st-7 me-3  ' 
                    onClick={() => {
                        formdataObj.setFormdata(packageselect)
                        packageModalObj.setPackageModal(true)
                    }}
                    >ตั้งค่าข้อมูลแพ็คเกจ</Button>

                    <Button 
                    type='button' 
                    className='btn btn btn-st-7 ' 
                    style={{background:'#FF731D'}}
                    onClick={() => setModalproductlistShow(true)}
                    >เพิ่มรายการสินค้าที่อยู่ใน บุฟเฟ่ต์</Button>
                </div>
                <div className='d-flex align-items-center px-4 mb-4'>
                    <div className="me-auto" style={{flex:"0 0 200px"}}>
                        <SelectCategory 
                        setFilterText={setFilterText}
                        />
                    </div>
                    <div className=''>
                        <input type='text' className='form-control form-st-4' placeholder='ค้นหา ชื่อ, ประเภท,หมวดหมู่' onKeyUp={e => setFilterText(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className=''>
                <BranchProductList 
                filteredItems={filteredItems}
                handleDelete={(product) => deleteProduct(product) }
                />
            </div>

            <ProductSelectModal 
                show={modalproductlistShow}
                onHide={() => setModalproductlistShow(false)}
                handleSubmit={(product) => AddProduct(product) }
            />

        </div>
    )
}

