import React, { useState,useEffect  } from 'react';
import {Link,useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';

export default  function QRpaymentsetting(){

    const { branchID } = useParams();

    const schema = yup.object().shape({
        merchantID: yup.string()
        .when("status", (status, schema) => {
            if(status == 1) 
              return schema.required()
            return schema
        }),
        publicID: yup.string()
        .when("status", (status, schema) => {
            if(status == 1) 
              return schema.required()
            return schema
        }),
        secretID: yup.string()
        .when("status", (status, schema) => {
            if(status == 1) 
              return schema.required()
            return schema
        }),
        token: yup.string()
        .when("status", (status, schema) => {
            if(status == 1) 
              return schema.required()
            return schema
        }),
    });

    const [paymentsetting, setPaymentsetting] = useState({
        "branchID":branchID,
        "paymentChannelID":2,
        "status":1,
        "merchantID": "",
        "publicID": "",
        "secretID": "",
        "token": "",
    });
    

    return (
        <div>

            <Formik
                enableReinitialize
                initialValues={ paymentsetting }
                validationSchema={schema}
                onSubmit={async (values) => {
                    console.log(values)
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                    setFieldValue,
                    isInvalid,
                    isValid

                }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    <div className='mb-4'>
                        <Form.Check 
                            type="switch"
                            id="status"
                            label='ตั้งค่าระบบ QR (Auto Check)'
                            name='status'
                            className='custom-switch-st-1 tc-10  s1 mb-1'
                            defaultChecked={values.status === 1}
                            onChange={(e) => {
                                if(e.target.checked === true){
                                    setFieldValue('status',1)
                                }else{
                                    setFieldValue('status',0)
                                }  
                            }}
                        />
                    </div>
                    {
                        values.status === 1 ? (
                        <>
                            <div className='d-flex align-items-center mb-4 pb-2'>
                                <img src='/images/payment/gbpay.png' className='me-4' width={100} />
                                <div style={{color:'rgba(76, 78, 100, 0.50)',fontWeight: 500}}>
                                    <div className='mb-1'>กรุณากรอกข้อมูลที่ได้รับจากระบบ GB PAY</div>
                                    <div>หากไม่ทราบข้อมูลกรุณาติดต่อเจ้าหน้าที่</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6'>
                                    <Form.Group className="mb-3 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Merchant  ID  <span className='text-danger'>*</span>   </Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="merchantID" 
                                        className="form-control" 
                                        placeholder="Merchant  ID"
                                        value={values.merchantID}
                                        onChange={handleChange}
                                        isInvalid={!!errors.merchantID}
                                        
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Public ID  <span className='text-danger'>*</span> </Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="publicID" 
                                        className="form-control" 
                                        placeholder="Public ID"
                                        value={values.publicID}
                                        onChange={handleChange}
                                        isInvalid={!!errors.publicID}
                                        
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Secret Key ID   <span className='text-danger'>*</span>   </Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="secretID" 
                                        className="form-control" 
                                        placeholder="Secret Key ID"
                                        value={values.secretID}
                                        onChange={handleChange}
                                        isInvalid={!!errors.secretID}
                                        
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Token  <span className='text-danger'>*</span> </Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="token" 
                                        className="form-control" 
                                        placeholder="Token"
                                        value={values.token}
                                        onChange={handleChange}
                                        isInvalid={!!errors.token}
                                        
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </>
                        ) : 
                            ''
                    }
                    <div className="text-end pt-2 pb-3 btn-group-control" >
                        <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>บันทึก</Button>
                    </div>

                </Form>
                )}
            </Formik>
            
        </div>
    )
}
