import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { ModalAllder } from 'layouts/ModalAllder';
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'
import { useParams } from 'react-router-dom'

export function EditOptionNote(props){
    const branchProductOptionURL = `${process.env.REACT_APP_API_URL}/branch-product-option`;

    const { productID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [modalStockShow, setModalStockShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)

    const deleteBranchProduct = async (productID, optionID) => {
        console.log(`Delete branch optionID: ${optionID}`)
        try {
            const res = await fetch(`${branchProductOptionURL}/${productID}/${optionID}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            return { status: res.status }
        } catch (error) {
            console.log('Failed to patch:', error)
        }
    }

    const handleDeleteoptionList = async (optionID) => {
        const removeBranchProductOption = await deleteBranchProduct(productID, optionID)
        if (removeBranchProductOption.status === 200) {
            props.onSuccess()
        }
    }

    const [optionnote, setOptionnote] = useState([])
    const getoptionData = (props) => {
        fetch('/json/optionnote.json')
            .then((response) => response.json())
            .then((result) => {
                setOptionnote(result)
            })
            .catch((error) => console.log('error', error))
    }

    useEffect(() => {
        getoptionData()
    }, [])

    const [modaledit, setModaledit] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:340,
        pin:false,
        mode:'normal',
        modalname:"modal_edit_option_product"
    });

    const [optionEditID, setOptionEditID] = useState(false)
    const [optionListEdit, setOptionListEdit] = useState(false)

  

    return (
        <div className="px-5 py-4">
            {/*<div className="row">*/}
            {/*    <div className="col-xl-6 col">*/}
            {/*        <div className="mb-2 mt-4">*/}
            {/*            <button type="button" className="btn btn-st-3 mb-4" */}
            {/*            onClick={()=>{*/}
            {/*                setModaledit( (prev) => ({...prev,open:true}) )*/}
            {/*            }}*/}
            {/*            >*/}
            {/*                ตัวเลือกหมายเหตุ*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className=" mt-3 ">
                <div className="d-flex flex-wrap">
                    {props.listoptionnote.map((item, index) => (
                        <div className="row gx-4 align-items-center mb-5" key={index}>
                        <div className="col-xl-8 col-6">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">ตัวเลือก</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="note"
                                    className="form-control"
                                    placeholder="ตัวเลือก"
                                    value={item.name}
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-xl-4 col-6">
                            <button
                                type="button"
                                className="btn  btn-st-3"
                                onClick={() => handleDeleteoptionList(item.id)}
                                style={{ background: '#F95057', borderColor: '#F95057' }}
                            >
                                ลบตัวเลือก
                            </button>
                        </div>
                        {item.product_option_detail.map((item2, index2) => (
                            <div className="col-xl-4 row gx-4 align-items-center" key={index2}>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="listName"
                                            className="form-control"
                                            placeholder="รายการ"
                                            value={item2.name}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-auto ">
                                    <div className="d-flex align-items-center" style={{ width: '60px' }}>
                                        <button
                                            type="button"
                                            className="btn d-block btn-edit-st-1 me-0  "
                                            onClick={() => {
                                                setOptionListEdit({
                                                    optionDetail: item2,
                                                })
                                                setModaledit((prev) => ({
                                                    ...prev,
                                                    open: true,
                                                }))
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className={`btn d-block btn-eye-st-1 ms-2 ${item2.is_active && 'active'}`}
                                            style={{ cursor: 'auto' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    ))}
                </div>
            </div>
            <ModalEditOption
                modal={modaledit}
                setModal={setModaledit}
                setListOptionnote={props.setListOptionnote}
                optionListEdit={optionListEdit}
                setOptionListEdit={setOptionListEdit}
                optionEditID={optionEditID}
                onSuccess={() => props.onSuccess()}
            />
        </div>
    )
}

const ModalEditOption = ({
    modal,
    setModal,
    setListOptionnote,
    optionListEdit,
    setOptionListEdit,
    optionEditID,
    onSuccess,
    ...props
}) => {
    const branchProductOptionURL = `${process.env.REACT_APP_API_URL}/branch-product-option`;

    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const [branchProductOption, setBranchProductOption] = useState({
        id: undefined,
        is_active: undefined,
        name: '',
        price: undefined,
    })

    const patchBranchProductOption = async (values) => {
        console.log(`Patch branch product option data to productID ${values.id}:`, values)
        try {
            const res = await fetch(`${branchProductOptionURL}/${values.id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values)
            })
            return { status: res.status }
        } catch (error) {
            console.log('Failed to patch:', error)
        }
    }

    const handleSubmit = async () => {
        const updateBranchProductOption = await patchBranchProductOption({
            ...branchProductOption,
            price: 0,
        });
        if (updateBranchProductOption.status === 200) {
            setModal((prev) => ({ ...prev, open:false }))
            onSuccess()
        }
    }

    useEffect(() => {
        if (optionListEdit) {
            setBranchProductOption({
                id: optionListEdit?.optionDetail?.id,
                is_active: optionListEdit?.optionDetail?.is_active,
                name: optionListEdit?.optionDetail?.name,
                price: optionListEdit?.optionDetail?.price,
            })
        }
    }, [optionListEdit])

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={'แก้ไขตัวเลือกหมายเหตุ'}
            hbtn={true}
        >
            {optionListEdit && (
                <div className="px-4">
                    <div className="text-end pb-4 pt-5 mt-5 pe-3">
                        <Form.Group className="mb-2 form-st-3 pb-1">
                            <Form.Label className="mb-1">รายการ</Form.Label>
                            <Form.Control
                                type="text"
                                name="listName"
                                className="form-control"
                                placeholder="รายการ"
                                value={branchProductOption.name}
                                disabled={true}
                            />
                        </Form.Group>
                    </div>
                    <div className="mb-2 me-3">
                        <Form.Check
                            type="switch"
                            id="custom-switch3"
                            label="แสดงในเมนู"
                            name="displayMenu"
                            className="custom-switch-st-1 tc-10 s2 mb-1"
                            defaultChecked={branchProductOption.is_active}
                            checked={branchProductOption.is_active}
                            onChange={(e) => {
                                setBranchProductOption({
                                    ...branchProductOption,
                                    is_active: e.target.checked,
                                })
                            }}
                        />
                    </div>
                    <div className="text-end pb-4 pt-4 pe-3">
                        <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                            <Button
                                className="btn btn-st-6 me-3 bg-transparent"
                                onClick={() => setModal((prev) => ({ ...prev, open: false }))}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                type="button"
                                className="btn-st-3 "
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </ModalAllder>
    )
}