import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import * as yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { BranchSettingMenu } from '../../layouts/layoutbranch'
import { useRecoilValue } from 'recoil'
import { authState } from '../../state/auth'
import useToken from '../../utils/UseToken'
import { appState } from '../../state/app'
import { ThailandAddressTypeahead, ThailandAddressValue } from 'react-thailand-address-typeahead'
import { getCurrentDate } from '../../utils/dateFormat'

export function BranchDetails () {
    const branchInfoURL = `${process.env.REACT_APP_API_URL}/branch-info`;
    const PosDataURL = `${process.env.REACT_APP_API_URL}/pos-setting`;

    const { branchID } = useParams()
    const { user } = useRecoilValue(authState)
    const { token } = useToken()
    const { slug } = useRecoilValue(appState)

    const schema = yup.object().shape()
    const [branchData, setBranchData] = useState(
        {
            'img_url': '',
            'business_name': '',
            'name': '',
            'code': '',
            'address': '',
            'created_at': '',
            'phone_number': '',
            'mobile_number': '',
            'subdistrict': '',
            'district': '',
            'province': '',
            'zipcode': '',
            'is_active': undefined,
            'location': '',
            // 'storeHouse': 0,
        },
    )

    const getBranchInfo = async (branchID) => {
        try {
            const res = await fetch(`${branchInfoURL}/${branchID}`, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setBranchData(data)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const getPosInfo = async (branchID) => {
        try {
            const res = await fetch(`${PosDataURL}/${branchID}`, {
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                }),
            })
            const data = await res.json()
            setPosData(data)
        } catch (error) {
            console.log('Failed to get data!', error)
        }
    }

    const optionsstoreHouse = [
        { value: '1', label: 'คลัง 1' },
        { value: '2', label: 'คลัง 2' },
    ]

    const [formdisabled, setFormdisabled] = useState(true)

    const [districtoption, setDistrictoption] = useState([])
    const [subdistrictoption, setSubDistrictoption] = useState([])
    const [provinceoption, setProvinceoption] = useState([])

    const [posShow, setPosShow] = useState(false)
    const [modalposShow, setModalposShow] = useState(false)
    const [modalposEditShow, setModalposEditShow] = useState(false)

    const [posFormData, setPosFormData] = useState(
        {
            'dateCreate': getCurrentDate('/'),
            'posNo': 1,
            'posID': '',
            'posName': '',
            'posStatus': 'ยังไม่มีการซื้อขาย',
            'taxInvoice': 0,
            'revenueNumber': '',
            'dateAuthorized': '',
            'usageStatus': 1,
        },
    )

    const [address, setAddress] = useState(
        ThailandAddressValue.fromDatasourceItem({
            d: '',
            p: '',
            po: '',
            s: '',
        })
    )

    const [posData, setPosData] = useState([])

    useEffect(() => {
        getBranchInfo(branchID)
            .then(() => getPosInfo(branchID))
            .then(() => setPosShow(true))
    }, [branchID, token, user.businessId])

    useEffect(() => {
        if (branchData) {
            setAddress(
                ThailandAddressValue.fromDatasourceItem({
                    d: (branchData.district !== undefined && branchData.district.length > 0) ? branchData.district : '',
                    p: (branchData.province !== undefined && branchData.province.length > 0) ? branchData.province : '',
                    po: (branchData.zipcode !== undefined && branchData.zipcode.toString().length > 0) ? branchData.zipcode : '',
                    s: (branchData.subdistrict !== undefined && branchData.subdistrict.length > 0) ? branchData.subdistrict : '',
                })
            )
        }
    }, [branchData, token])

    return (
        <div style={{ padding: '30px 33px 33px 31px' }}>
            <div className="card card-st-1 mb-4">
                <div className="card-body">
                    <div className="tc-8 fs-18 fw-bold px-3">ข้อมูลสาขา</div>
                    <Formik
                        enableReinitialize
                        initialValues={branchData}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            console.log(values)
                            setFormdisabled(true)
                            setPosShow(true)
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <fieldset disabled={formdisabled}>
                                    <div className="card-body px-xl-3">
                                        <div>
                                            <ImageUpload
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                isBusinessEdit={false}
                                            />
                                            <div className="pt-3">
                                                <div className="row gx-xl-4">
                                                    <div className="col-xl-4">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">ชื่อกิจการ</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="business_name"
                                                                className="form-control"
                                                                placeholder="ชื่อกิจการ"
                                                                value={values.business_name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.business_name}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">ชื่อสาขา <span
                                                                className="text-danger ms-1"> *</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder="กรอกชื่อสาขา"
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.name}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">รหัสสาขา <span
                                                                className="text-danger ms-1"> *</span> </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="code"
                                                                className="form-control"
                                                                placeholder="กรอกรหัสสาขา"
                                                                value={values.code}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.code}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">ที่อยู่</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="address"
                                                                className="form-control"
                                                                placeholder="กรอกที่อยู่"
                                                                value={values.address}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.address}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <ThailandAddressTypeahead
                                                        value={address}
                                                        onValueChange={(val) => {
                                                            setAddress(val)
                                                            setFieldValue('subdistrict', val.subdistrict)
                                                            setFieldValue('district', val.district)
                                                            setFieldValue('province', val.province)
                                                            setFieldValue('zipcode', val.postalCode)
                                                        }}
                                                    >
                                                        <div className="row gx-xl-4">
                                                            <div className="col-xl-6">
                                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                                    <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
                                                                    <ThailandAddressTypeahead.SubdistrictInput
                                                                        type="text"
                                                                        className={`form-control ${values.subdistrict ? '' : !!errors.subdistrict ? 'is-invalid' : ''}`}
                                                                        placeholder="กรอก แขวง/ตำบล"
                                                                        name="subdistrict"
                                                                        value={values.subdistrict}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                                    <Form.Label className="mb-1">อำเภอ/เขต</Form.Label>
                                                                    <ThailandAddressTypeahead.DistrictInput
                                                                        type="text"
                                                                        className={`form-control ${values.district ? '' : !!errors.district ? 'is-invalid' : ''}`}
                                                                        placeholder="กรอก อำเภอ/เขต"
                                                                        name="district"
                                                                        value={values.district}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                                    <Form.Label className="mb-1">จังหวัด</Form.Label>
                                                                    <ThailandAddressTypeahead.ProvinceInput
                                                                        type="text"
                                                                        className={`form-control ${values.province ? '' : !!errors.province ? 'is-invalid' : ''}`}
                                                                        placeholder="กรอกจังหวัด"
                                                                        name="province"
                                                                        value={values.province}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                                    <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
                                                                    <ThailandAddressTypeahead.PostalCodeInput
                                                                        type="text"
                                                                        className={`form-control ${values.postalcode ? '' : !!errors.postalcode ? 'is-invalid' : ''}`}
                                                                        placeholder="กรอกรหัสไปรษณีย์"
                                                                        name="postalcode"
                                                                        value={values.postalcode}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <ThailandAddressTypeahead.Suggestion
                                                            containerProps={{ className: 'suggestion-container' }}
                                                            optionItemProps={{ className: 'suggestion-option' }}
                                                        />
                                                    </ThailandAddressTypeahead>
                                                    <div className="col-xl-3">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">เบอร์โทรศัพท์</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone_number"
                                                                className="form-control"
                                                                placeholder="กรอกเบอร์โทรศัพท์"
                                                                value={values.phone_number}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.phone_number}
                                                            />
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">เบอร์มือถือ</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="mobile_number"
                                                                className="form-control"
                                                                placeholder="กรอกเบอร์มือถือ"
                                                                value={values.mobile_number}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.mobile_number}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault()
                                                                    }
                                                                }}
                                                                maxLength={10}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">URL Google Map</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="location"
                                                                className="form-control"
                                                                placeholder="URL Google Map"
                                                                value={values.location}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.location}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {/*<div className="col-xl-6">*/}
                                                    {/*    <Form.Group className="mb-2 form-st-3 pb-1">*/}
                                                    {/*        <Form.Label className="mb-1">คลังสินค้าที่เชื่อม</Form.Label>*/}
                                                    {/*        <Select*/}
                                                    {/*            as="select"*/}
                                                    {/*            name="storeH"*/}
                                                    {/*            onChange={(e) => {*/}
                                                    {/*                setFieldValue('storeHouse', e.value)*/}
                                                    {/*            }}*/}
                                                    {/*            className="w-100 select-st-1"*/}
                                                    {/*            options={optionsstoreHouse}*/}
                                                    {/*            placeholder="เลือกคลังสินค้า"*/}
                                                    {/*        />*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</div>*/}
                                                    <div className="col-xl-3">
                                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="created_at"
                                                                className="form-control"
                                                                placeholder="วันที่สร้าง"
                                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.created_at}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label="เปิดใช้งาน"
                                                            name="is_active"
                                                            type="radio"
                                                            id="radio1"
                                                            className="form-check-st-2 tc-7"
                                                            checked={values.is_active}
                                                            onChange={(e) => {
                                                                setFieldValue('is_active', true)
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="ปิดใช้งาน"
                                                            name="is_active"
                                                            type="radio"
                                                            id="radio2"
                                                            className="form-check-st-2 tc-7"
                                                            checked={!values.is_active}
                                                            onChange={(e) => {
                                                                setFieldValue('is_active', false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {posShow === true && (
                <div className="card card-st-1 mb-4">
                    <div className="card-body">
                        <div className="tc-8 fs-18 fw-bold px-3 mb-4">POS</div>
                        <div className="px-4 pb-5">
                            {posData.length > 0 ? (
                                posData.map((item, index) => (
                                    <button
                                        type="button"
                                        className="btn btn-pos"
                                        key={index}
                                        disabled={true}
                                    >
                                        <div className="text-center tc-8 mb-1">{item.code}</div>
                                        <div
                                            className="text-center fs-14 tc-11 mb-2">{getCurrentDate('/', new Date(item.created_at))}</div>
                                        <div className={item.posStatus === true ? 'text-center pos-status tc-8 active' : 'text-center pos-status tc-8'}>
                                            {item.posStatus === true ? 'มีการซื้อขายแล้ว' : 'ยังไม่มีการซื้อขาย'}
                                        </div>
                                    </button>
                                ))
                            ) : (
                                <div className="w-full h-full flex justify-center items-center text-gray-400">
                                    ยังไม่มี POS ในสาขานี้
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const ImageUpload = (props) => {
    const [imgData, setImgData] = useState(false)
    const [imageURL, setImageURL] = useState(props.values.img_url)

    const handlePreviewImage = async (e) => {
        if (e.target.files[0]) {
            const res = await props.uploadImageFile(e.target.files[0])
            setImageURL(res)
            props.setFieldValue('img_url', res)
        }
    }

    useEffect(() => {
        setImageURL(props.values.img_url)
    }, [props.values.img_url, props.isBusinessEdit])

    return (
        <div className="d-flex align-items-center mb-4 mt-3 ">
            <div className="bx-upload">
                {(imageURL !== undefined && imageURL.length > 0) ? (
                    <img
                        src={`${process.env.REACT_APP_API_URL_SOCKET}/${imageURL}`}
                        alt="business"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/blank.png";
                          }}
                    />
                ) : (
                    <div className="preimg"></div>
                )}
            </div>
            <input
                type="file"
                name=""
                className="fileUpload hide-input"
                id="fileUpload"
                accept="image/*"
                onChange={(e) => { handlePreviewImage(e) }}
            />
            {props.isBusinessEdit && (
                <div className="flex-fill ms-3">
                    <div className="mb-3">
                        <button
                            type="button"
                            className="btn btn-st-4 btn-upload me-3 hover:bg-transparent hover:text-[#565DFF] hover:border-[#565DFF]"
                            onClick={() => document.getElementById('fileUpload').click()}
                        >
                            แก้ไขรูปภาพ
                        </button>
                        <button
                            type="button"
                            className="btn btn-st-2 btn-remove-image hover:bg-[#FF4D49] hover:text-white"
                            onClick={(e) => {
                                setImgData(false)
                                props.setFieldValue('img_url', '')
                            }}
                        >
                            ลบ
                        </button>
                    </div>
                    <div className="tc-7 fs-12">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                </div>
            )}
        </div>
    )
}