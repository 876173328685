import { atom, selector, useRecoilState, useSetRecoilState } from 'recoil';
import { checkPOSSlug } from 'services/app';

export const appState = atom({
    key: 'appState',
    default: {
        isLoading: false,
        error: null,
        isValidSlugChecked: false,
        isValidSlug: false,
        validBusiness: false,
        slug: null,
        loginBusiness: "",
        isRequestLoading: false
    },
});

export const useBusinessSlugCheck = () => {
    const setState = useSetRecoilState(appState);

    return async (slug) => {
        try {
            const business = await checkPOSSlug(slug);
            // console.log("🚀 ~ return ~ business:", business)
            if(business.isValidSlug) {
                setState(prev => ({
                    ...prev,
                    isValidSlugChecked: true,
                    isValidSlug: true,
                    loginBusiness: `Back office ${business.business.name}`,
                    slug: slug
                }))
            } else {
                setState(prev => ({ ...prev, isValidSlugChecked: true, isValidSlug: false }))
            }
            // return business
        } catch (error) {
            console.error('Error token check:', error);
        }
    };
};

export const useSetRequestLoading = () => {
    const setState = useSetRecoilState(appState);

    return async ({ isRequestLoading }) => {
        try {
            setState(prev => ({ ...prev, isRequestLoading: isRequestLoading }))
        } catch (error) {
            console.error('Error token check:', error);
        }
    };
};