import React from "react";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Datatablenoresults from "layouts/Datatablenoresults";
import CheckboxTable from "fragments/CheckboxTable";
import * as styleTable from "styles/datatable";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { appState } from "../state/app";
import Global from "utils/Global";

export default function BranchProductList({
  filteredItems,
  handleDelete,
  btnedit,
  branchID,
  ...props
}) {
  const { slug } = useRecoilValue(appState);

  const columns = [
    {
      name: "รหัสสินค้า",
      selector: (row) => row.code,
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.code}</div>
      ),
      width: "150px",
    },
    {
      name: "ชื่อสินค้า",
      selector: (row) => row.name,
      cell: (row, index, column, id) => {
        const exists = Global.checkURL(row.img_url);
        const img_url = exists ? row.img_url : process.env.REACT_APP_API_URL_SOCKET + "/" + row.img_url;
        
        return (
          <div className="pimage">
            <img
              alt={`${row.name}-img`}
              src={img_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/blank.png";
              }}
            />
            <div>
              <div
                style={{
                  color: "rgba(76, 78, 100, 0.87)",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                {row.name}
              </div>
              <div style={{ fontSize: "12px", color: "#565DFF" }}>
                {row.code}
              </div>
            </div>
          </div>
        );
      },
      width: "300px",
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.product_category,
      width: "150px",
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.sup_product_category,
      width: "150px",
    },
    // {
    //     name: 'ภาษี',
    //     selector: row => row.productTax,
    //     center:'true'
    // },
    {
      name: "ราคา",
      selector: (row) => row.price,
      cell: (row, index, column, id) => (
        <div className="w-100">
          <div className="text-end mb-1">{row.price}</div>
          {row.is_price_depend_weight && (
            <div
              className="text-end"
              style={{ color: "#565DFF", fontSize: "12px" }}
            >
              ขายตามน้ำหนัก
            </div>
          )}
        </div>
      ),
      width: "110px",
    },
    {
      name: "สถานะ",
      selector: (row) => row.is_active,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div
          className={`product-status status${row.is_active === true ? 1 : 0}`}
        />
      ),
    },
    {
      name: "จัดการ",
      selector: (row) => row.id,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div className="d-flex align-items-center">
          {btnedit && (
            <Link
              to={`/${slug}/branch/setting/product/${branchID}/manage/${row.id}`}
              className="btn btn-edit-st-1"
            />
          )}
          {/* <div className='ms-2'>
                        <Button
                        className='btn btn-delete-st-2 '
                        onClick={() => handleDelete(row)}
                        />
                    </div> */}
        </div>
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      customStyles={styleTable.customStyles}
      selectableRows
      selectableRowsComponent={CheckboxTable}
      pagination
      paginationComponentOptions={styleTable.paginationComponentOptions}
      noDataComponent={<Datatablenoresults />}
      persistTableHead={true}
      paginationPerPage="5"
      paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
    />
  );
}
