import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/text.css'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { RecoilRoot } from 'recoil'
import './index.css'
import './tailwind.css'
// import './App.css'
import { StyledEngineProvider } from '@mui/material'
import "assets/css/comfortaa.css";
import "assets/css/sarabun.css";
import "assets/css/prompt.css";
import "assets/css/poppins.css";
import "assets/css/barlow.css";
import "assets/css/kanit.css";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <RecoilRoot>
        <BrowserRouter>
            <GoogleOAuthProvider clientId="224450368848-n18vttkv84nf51a7hknmd22ph48s5mjq.apps.googleusercontent.com">
                <StyledEngineProvider injectFirst>
                    <App/>
                </StyledEngineProvider>
            </GoogleOAuthProvider>
        </BrowserRouter>
    </RecoilRoot>,

    // <React.StrictMode>
    //     <BrowserRouter>
    //         <GoogleOAuthProvider clientId="224450368848-n18vttkv84nf51a7hknmd22ph48s5mjq.apps.googleusercontent.com">
    //             <App/>
    //         </GoogleOAuthProvider>
    //     </BrowserRouter>
    // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
