import React, { useState,useEffect  } from 'react';
import {Link,useParams} from "react-router-dom";

import Transferpaymentsetting from 'pages/branch/payment/transfer';
import QRpaymentsetting from 'pages/branch/payment/qrcode';
import Creditpaymentsetting from 'pages/branch/payment/credit';

import Deliverypaymentsetting from 'pages/branch/payment/delivery';
import Cashpaymentsetting from 'pages/branch/payment/cash';
import Debtorspaymentsetting from 'pages/branch/payment/debtors';
import Pointpaymentsetting from 'pages/branch/payment/point';
import Otherpaymentsetting from 'pages/branch/payment/other';


export function  Payment() {
    
    const [paymenttype, setPaymenttype] = useState(1);

    return (
        <div style={{padding: '20px 20px 20px 20px'}}>
            
            <div className="card card-st-1 mb-4">
                <div className='card-body pt-4 pb-4'>
                    <div className='d-flex align-items-center mb-2'>
                        <div className='tc-8 fs-18 fw-bold px-3 mb-3 me-auto'>การชำระเงิน</div>
                    </div>
                    
                    <div className='d-flex px-3 flex-wrap mb-2'>
                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type1' 
                            checked={paymenttype == 1}
                            onChange={(e) => setPaymenttype(1) } 
                            />
                            <label htmlFor='type1'>
                                <div className='icon icon-1'></div>
                                <div>เงินสด</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type2'
                             checked={paymenttype == 2} 
                            onChange={(e) => setPaymenttype(2) } 
                            />
                            <label htmlFor='type2'>
                                <div className='icon icon-2'></div>
                                <div>QR CODE</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type3' 
                            checked={paymenttype == 3}
                            onChange={(e) => setPaymenttype(3) } 
                            />
                            <label htmlFor='type3'>
                                <div className='icon icon-3'></div>
                                <div>โอนเงิน</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type4' 
                            checked={paymenttype == 4}
                            onChange={(e) => setPaymenttype(4) } 
                            />
                            <label htmlFor='type4'>
                                <div className='icon icon-4'></div>
                                <div>Delivery</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type5' 
                            checked={paymenttype == 5}
                            onChange={(e) => setPaymenttype(5) } 
                            />
                            <label htmlFor='type5'>
                                <div className='icon icon-5'></div>
                                <div>บัตรเครดิต/เดบิต</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type6' 
                            checked={paymenttype == 6}
                            onChange={(e) => setPaymenttype(6) } 
                            />
                            <label htmlFor='type6'>
                                <div className='icon icon-6'></div>
                                <div>บันทึกลูกหนี้</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type7'
                            checked={paymenttype == 7} 
                            onChange={(e) => setPaymenttype(7) } 
                            />
                            <label htmlFor='type7'>
                                <div className='icon icon-7'></div>
                                <div>ช่องทางอื่นๆ</div>
                            </label>
                        </div>

                        <div className='selectpayment mb-3 me-3'>
                            <input type='radio' className='hide-input' name="selectpayment" id='type8'
                            checked={paymenttype == 8} 
                            onChange={(e) => setPaymenttype(8) } 

                            />
                            <label htmlFor='type8'>
                                <div className='icon icon-8'></div>
                                <div>ตั้งค่าอื่นๆ</div>
                            </label>
                        </div>
                    </div>
                    
                    {
                        paymenttype > 0 ? (
                            <div className='px-3'>
                                <div className="card card-st-1 mb-4">
                                    <div className='card-body p-4' >
                                        {
                                            paymenttype === 1 ? (
                                                <Cashpaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 2 ? (
                                                <QRpaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 3 ? (
                                                <Transferpaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 4 ? (
                                                <Deliverypaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 5 ? (
                                                <Creditpaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 6 ? (
                                                <Debtorspaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 7 ? (
                                                <Pointpaymentsetting />
                                            ) : ''
                                        }

                                        {
                                            paymenttype === 8 ? (
                                                <Otherpaymentsetting />
                                            ) : ''
                                        }



                                    </div>
                                </div>
                            </div>
                        ) : ''
                    }

                    <div className='py-5'></div>
                    
                    
                </div>
            </div>

            
        </div>
    )
}



