import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectCategory from "fragments/SelectCategory";
import DataTableLayout from "layouts/DatatableLayout";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import { appState } from "state/app";
import { MoonLoader } from "react-spinners";
import UploadFile from "utils/UploadFile";
import Global from "utils/Global";

export default function ModalKitchenProduct({
  handleSubmit,
  isAdding,
  excludeproductdata,
  ...props
}) {
  // console.log("🚀 ~ excludeproductdata:", excludeproductdata)
  const branchProductFilterURL = `${process.env.REACT_APP_API_URL}/branch-product-filter`;

  const { branchID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();
  const { slug } = useRecoilValue(appState);

  const [productSelected, setProductSelected] = useState(null);
  const [productdata, setProductdata] = useState(excludeproductdata);
  const [isLoading, setIsLoading] = useState(true);

  const getProductData = async (branchID) => {
    try {
      const res = await fetch(`${branchProductFilterURL}/${branchID}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      // console.log("🚀 ~ getProductData ~ data:", data)
      setProductdata(data);
      setIsLoading(false);
    } catch (error) {
      console.log("Failed to get data:", error);
    }
  };

  useEffect(() => {
    console.log("branchID", branchID);
    // getProductData(branchID);
    // getProductData()
    setIsLoading(false);
  }, []);

  const columns = [
    {
      name: "รหัสสินค้า",
      selector: (row) => row.code,
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.code}</div>
      ),
      width: "150px",
    },
    {
      name: "ชื่อสินค้า",
      selector: (row) => row.name,
      cell: (row, index, column, id) => {
        const exists = Global.checkURL(row.img_url);
        const img_url = exists ? row.img_url : process.env.REACT_APP_API_URL_SOCKET + "/" + row.img_url;
        return (
          <div className="pimage">
            <img
              alt={`${row.name}-img`}
              src={img_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/blank.png";
              }}
            />
            <div>
              <div
                style={{
                  color: "rgba(76, 78, 100, 0.87)",
                  fontWeight: 500,
                  marginBottom: "5px",
                }}
              >
                {row.name}
              </div>
              <div style={{ fontSize: "12px", color: "#565DFF" }}>
                {row.code}
              </div>
            </div>
          </div>
        );
      },
      width: "300px",
    },
    {
      name: "หมวดหมู่หลัก",
      selector: (row) => row.product_category,
      width: "150px",
    },
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.sub_product_category,
      width: "150px",
    },
    // {
    //     name: 'ภาษี',
    //     selector: row => row.productTax,
    //     center:'true'
    // },
    {
      name: "ราคา",
      selector: (row) => row.price,
      cell: (row, index, column, id) => (
        <div className="w-100">
          <div className="text-end mb-1">{row.price}</div>
          {row.is_price_depend_weight && (
            <div
              className="text-end"
              style={{ color: "#565DFF", fontSize: "12px" }}
            >
              ขายตามน้ำหนัก
            </div>
          )}
        </div>
      ),
      width: "150px",
    },
    {
      name: "สถานะ",
      selector: (row) => row.is_active,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => (
        <div
          className={`product-status status${row.is_active === true ? 1 : 0}`}
        />
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  let filteredItems;
  if (excludeproductdata !== undefined && excludeproductdata.length > 0) {
    filteredItems = excludeproductdata.filter((item) => {
      const matchCode = item.code
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchName = item.name
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchCategory = item.product_category
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchCode || matchName || matchCategory;
    });
  }

  const handleRowSelected = React.useCallback((state) => {
    setProductSelected(state.selectedRows);
  });

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Body>
        <div className="d-flex align-items-start  mb-0">
          <div className="fs-20 tc-8 me-auto mt-2">ค้นหารายการ</div>
          <div className="d-flex align-items-center pt-2 pb-2 btn-group-control">
            <Button
              type="button"
              className="btn-st-5 me-auto "
              style={{ width: "80px" }}
              onClick={props.onHide}
            >
              ยกเลิก
            </Button>
            <div className="bx-count mx-3">
              เลือกไว้ {productSelected ? productSelected.length : 0} รายการ
            </div>
            <Button
              type="button"
              className="btn-st-3 flex justify-center items-center"
              style={{ width: "130px" }}
              disabled={!productSelected || productSelected.length == 0}
              onClick={() => handleSubmit(productSelected)}
            >
              {!isAdding ? (
                "เพิ่มรายการ"
              ) : (
                <MoonLoader
                  color="#FFFFFF"
                  loading={isAdding}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </div>
        </div>
        <div
          className="fs-18 fw-6  mb-3 d-inline-block text-white"
          style={{
            borderRadius: "8px",
            background: "#565DFF",
            padding: "6.5px 25px",
          }}
        >
          สินค้าทั้งหมด
        </div>
        <div className="d-flex align-items-center">
          <div className="me-auto" style={{ flex: "0 0 220px" }}>
            <SelectCategory setFilterText={setFilterText} />
          </div>
          <div className="" style={{ flex: "0 0 180px" }}>
            <input
              type="text"
              className="form-control form-st-4"
              placeholder="ค้นหาชื่อ,รหัสสินค้า"
              onKeyUp={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <DataTableLayout
        item={filteredItems}
        columns={columns}
        isSelectRows={true}
        PerPage={5}
        handleRowSelected={handleRowSelected}
      />
    </Modal>
  );
}
