import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams,NavLink } from "react-router-dom";
import DataTable from "@jcognard-actinvision/react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MonthPicker from "simple-react-month-picker";
import { MultiSelect } from "react-multi-select-component";

import CheckboxTable from "fragments/CheckboxTable";
import  DownloadExcel from 'utils/DownloadExcel';

import Button from "react-bootstrap/Button";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { appState } from "../../state/app";
import { authState } from "../../state/auth";
import pos2 from "../../img/pos2.png";
import { MoonLoader } from "react-spinners";
import {
  getCurrentDate,
  convertDateFormat,
  getCurrentDateM,
  formatDateToYMD,
  subtractDays,
  getCurrentDateforApi,
} from "utils/dateFormat";
import Select from "react-select";
import { default as ReactSelect, components, InputAction } from "react-select";
import DailyApi from "services/report/DailyApi";

import ReactPaginate from 'react-paginate';
import CurrencyFormat from 'react-currency-format';

export function ReportBill() {
  const { token, setToken } = useToken();
  let { businessId } = useParams();
  const navigate = useNavigate();
  const { slug } = useRecoilValue(appState);
  const { user } = useRecoilValue(authState);
  const BASE_URL =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard/total";
  const BASE_URL_POS =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard";

  const [business, setBusiness] = useState(businessId);
  const [reportdata, setreportdata] = useState([]);
  const [searchdata, setSearchdata] = useState({
    branchID: "",
    posID: "",
    startDate: getCurrentDate("/"),
    endDate: getCurrentDate("/"),
  });


  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResetSearch, setShowResetSearch] = useState(false);
  const [pos, setPos] = useState([]);
  const [dateApplied, setDateApplied] = useState(false);
  const currentDate = new Date();
  const endDate = getCurrentDateforApi("-", currentDate);
  const startDate = getCurrentDateforApi("-", subtractDays(currentDate, 7));
  const [fetchDate, setCurrentDate] = useState({ startDate, endDate });
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPos, setOptionsPos] = useState([]);
  const keyRef = useRef();


  const columns = [
    {
        name: 'ลำดับ',
        selector: row => row.no,
        center: true,
        width:'80px',
        
    },
    {
        name: 'วันที่/เวลา',
        selector: row => row.date,
        width:'170px',
        footerContent: rows => {
          return 'รวมบิล' ;
        },
    },
    {
        name: "ประเภทบิล",
        selector: row => row.type,
        width:'135px',
        footerContent: (rows,datas) => {
          console.log(datas)
          return   <>
            <div>จำนวนบิล {rows.length} บิล</div>
          </>
          
          
        },
    },
    {
        name: "เลขที่ใบเสร็จ",
        selector: row => row.receipt_code,
        width:'155px',
        cell: (row, index, column, id) =>  <NavLink to={`/${slug}/report/bill/detail/${row.id}`} style={{color:'#066AFF',fontWeight:500,textDecoration:'underline'}}>{row.receipt_code}</NavLink>,
    },
    {
        name: "ราคารวมก่อนส่วนลด",
        selector: row => row.total_price,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.total_price} />,
        width:'150px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.total_price, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
       
    },
    {
        name: 'ส่วนลดจากคูปอง',
        selector: row => row.coupon_discount,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.coupon_discount} />,
        width:'140px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.coupon_discount, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "ส่วนลดจากโปรโมชั่น",
        selector: row => row.promotion_discount,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.promotion_discount} />,
        width:'150px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.promotion_discount, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "ส่วนลดจากการแลกแต้ม",
        selector: row => row.point_discount,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.point_discount} />,
        width:'170px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.point_discount, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "ราคาหลังหักส่วนลด",
        selector: row => row.total_price_after_discount,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.total_price_after_discount} />,
        width:'150px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.total_price_after_discount, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: (
          <div className="lh-sm ">
            <div>Service</div>
            <div className="text-center pt-1">Charge</div>
          </div>
        ),
        selector: row => row.service_charge,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.service_charge} />,
        width:'120px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.service_charge, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "มูลค่าสินค้า(ไม่มีภาษี)",
        selector: row => row.total_price_no_tax,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.total_price_no_tax} />,
        width:'170px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.total_price_no_tax, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "มูลค่าสินค้า(มีภาษี)",
        selector: row => row.total_price_tax,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.total_price_tax} />,
        width:'170px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.total_price_tax, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
        name: "ภาษีมูลค่าเพิ่ม",
        selector: row => row.vat,
        center: true,
        cell: (row, index, column, id) =>  <CurrencyFormatC val={row.vat} />,
        width:'120px',
        footerContent: rows => {
          const total = rows.reduce((val, row) => val + row.vat, 0);
          return <CurrencyFormatC val={Math.round(total)} /> ;
        },
    },
    {
      name: "ราคาสุทธิ",
      selector: row => row.net_price,
      center: true,
      cell: (row, index, column, id) => <div style={{color:"#565DFF",fontWeight:600}}> <CurrencyFormatC val={row.net_price} /></div>,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.net_price, 0);
        return <div style={{color:"#565DFF",fontSize:'16px',textDecoration:'underline',fontWeight:600}}> <CurrencyFormatC val={Math.round(total)} /></div>  ;
      },
    },
    {
      name: "ประเภทการชำระเงิน",
      selector: row => row.payment_type,
      center: true,
      width:'150px',

    },
    {
      name: "เงินสดรับ",
      selector: row => row.cash_received,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.cash_received} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.cash_received, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
    },
    {
      name: "เงินทอน",
      selector: row => row.cash_change,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.cash_change} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.cash_change, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
    },
    {
      name: "เงินโอน",
      selector: row => row.transfer_money,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.transfer_money} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.transfer_money, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
    },
    {
      name: "บัตรเคดิต",
      selector: row => row.credit_card,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.credit_card} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.credit_card, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
    },
    {
      name: "Delivery",
      selector: row => row.delivery,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.delivery} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.delivery, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
    },
    {
      name: (
        <div className="lh-sm ">
          <div className="text-center">Delivery</div>
          <div className="text-center pt-1">ผ่านผู้ให้บริการ</div>
        </div>
      ),
      selector: row => row.delivery_provider,
      center: true,
      cell: (row, index, column, id) =>  <CurrencyFormatC val={row.delivery_provider} />,
      width:'120px',
      footerContent: rows => {
        const total = rows.reduce((val, row) => val + row.delivery_provider, 0);
        return <CurrencyFormatC val={Math.round(total)} /> ;
      },
      
    },
    

    
    
    
]


const CurrencyFormatC = ({val}) => {

  return  <CurrencyFormat value={val} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={''} />
}

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "14px",
        background: " #F5F5F7",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: "500",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background: " #FFF",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    expanderRow: {
      style: {
        minHeight: "52px",
      },
    },
    pagination: {
      style: {
        borderBottomRightRadius: "0.75rem",
        borderBottomLeftRadius: "0.75rem",
      },
    },
  };


  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

 

  const handleSearch = () => {
    setIsLoading(true);
    let formattedStartDate = searchdata.startDate;
    let formattedEndDate = searchdata.endDate;

    console.log(branchSelected)

    setShowResetSearch(true);

    var a=[];
   

    for(var i=0;i < 25 ;i++){
 
        var b = {
            "id":i+1,
            "no":i+1,
            "date":"28/11/2023 11:20:34",
            "type":"ทานที่ร้าน",
            "receipt_code":`R24060000010P${i}`,
            "total_price":100.00,
            "coupon_discount":10.00,
            "point_discount":0.00,
            "promotion_discount":0.00,
            "total_price_after_discount":90.00,
            "service_charge":10,
            "total_price_no_tax":90.00,
            "total_price_tax":0.00,
            "vat":0.00,
            "net_price":100.00,
            "payment_type":"เงินสด",
            "cash_received":100.00,
            "cash_change":0.00,
            "transfer_money":0.00,
            "credit_card":0.00,
            "delivery":0.00,
            "delivery_provider":0.00,
        };
        a.push(b); 
        
    }
    setreportdata(a);

    setIsLoading(false);
  };
  const resetFilter = () => {
    setShowResetSearch(false);
    setreportdata([]);
    setBranchSelected([])
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const getBranchData = async () => {
    DailyApi.GetAllBranchByBussiness(config).then((res) => {
      const data = res.data;
      const options = [];


      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsBranch(options);
    });
  };

  const getPosData = async (branchId) => {
    DailyApi.GetAllPosByBusiness(config, branchId).then((res) => {
      const data = res.data;
      const options = [];

      let obj = {};
      obj.value = "";
      obj.label = "ทั้งหมด";
      options.push(obj);

      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsPos(options);
    });
  };



  useEffect(() => {
    if (user.businessId && token) {
      getBranchData();

    }
  }, [user.businessId, token]);


  const [branchSelected, setBranchSelected] = useState([]);

  const handlerSelectBranchChange = (e) => {
    // check if e is not null
    if (e) {
      if (e.label != "ทั้งหมด") {
        setSearchdata((prev) => ({ ...prev, branchID: e.value }));
        // filter pos by branch
        getPosData(e.value);
      } else {
        setSearchdata((prev) => ({ ...prev, branchID: "", posID: "" }));
        setOptionsPos([]);
      }
    }
  };

  const handlerSelectPosChange = (e) => {
    e.label != "ทั้งหมด"
      ? setSearchdata((prev) => ({ ...prev, posID: e.value }))
      : setSearchdata((prev) => ({ ...prev, posID: "" }));
  };
  const handleApply = (event, picker) => {
    setSearchdata((prev) => ({
      ...prev,
      startDate: picker.startDate.format("YYYY/MM/DD"),
    }));
    setSearchdata((prev) => ({
      ...prev,
      endDate: picker.endDate.format("YYYY/MM/DD"),
    }));
    setDateApplied(true);
  };



  const [filterText, setFilterText] = useState("");

  let filteredItems;
  if (reportdata !== undefined && reportdata.length > 0) {
    filteredItems = reportdata.filter((item) => {
      const matchType = item.type
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchCode = item.receipt_code
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchType || matchCode;
    });
  }

  const CustomPagination = ({ rowsPerPage, rowCount, currentPage, onChangePage, onChangeRowsPerPage,data }) => {
    const count = rowCount/rowsPerPage;

    return (
      <>
      <div className=' d-flex align-items-center justify-content-end mx-0 pt-3 pb-5' style={{borderTop: '1px solid #e0e0e0'}}>
  
        <div className='  fs-12 d-flex justify-content-start  me-5'  style={{color:'#4C4E64'}}> 
          <p><span className="me-4">จำนวนรายการต่อหน้า <span className="ms-2">{ rowsPerPage }</span> </span>   
            
            { (currentPage*rowsPerPage + 1 ) - rowsPerPage } 
            <span className="mx-1 fs-18">-</span>
            {
                currentPage*rowsPerPage > rowCount ? rowCount : currentPage*rowsPerPage
            }
             <span className="mx-1">of</span> {rowCount}</p>
        </div>
  
        <div className=' d-flex justify-content-end'>
          <ReactPaginate
            previousLabel={<i class="icon-prev"></i>}
            nextLabel={<i class="icon-next"></i>}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={page => {

                onChangePage(page.selected+1,rowCount)

            }}
            pageCount={count || 1}
            breakLabel={'...'}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            activeClassName={'active'}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName={'pagination react-paginate pagination-sm justify-content-end pr-1 mt-1'}
          />
        </div>
      </div>
      </>
    )
  }


  return (
    <div>

      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายงาน
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            ยอดขายรายบิล{" "}
          </li>
        </ol>
      </nav>

      <div className="card card-st-2 mb-4">
        <div className="card-body  py-4">
          <div className="fw-bold mb-3">ค้นหาขั้นสูง</div>
          <div className="row items-center">
            <div className="col-xl-2 col-lg-3 col-2">

              <Select
                as="select"
                name="branch"
                onChange={(e) => handlerSelectBranchChange(e)}
                className={`w-100 select-st-1 pd`}
                options={optionsBranch}
                placeholder="เลือกสาขา"
                isClearable={true}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-2">

              <Select
                as="select"
                name="pos"
                onChange={(e) => handlerSelectPosChange(e)}
                className="w-100 select-st-1 pd"
                options={optionsPos}
                placeholder="เลือก POS"
                isDisabled={searchdata.branchID.length === 0}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-2">
              <DateRangePicker
                initialSettings={{
                  startDate: searchdata.startDate,
                  endDate: searchdata.endDate,
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
                onApply={handleApply}
              >
                <input
                  type="text"
                  className="form-control iconcalendar-right"
                />
              </DateRangePicker>
            </div>
            <div className="col-auto">
              <Button
                type="button"
                className="btn-st-3 flex justify-center items-center"
                style={{ width: "120px", height: "40px" }}
                onClick={handleSearch}
              >
                ค้นหา
              </Button>
            </div>
            {showResetSearch && (
              <div className="col-auto">
                <Button
                  type="button"
                  className="bg-orange-400 flex justify-center items-center hover:bg-orange-300"
                  style={{ width: "150px", height: "40px",border:0 }}
                  onClick={resetFilter}
                >
                  ล้างการค้นหา
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-start">
          <MoonLoader
            color="#565DFF"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="mt-40"
          />
        </div>
      )}
      {showResetSearch && (
        <div className="card card-st-2 mb-5">
          <div className="card-body p-0 rounded-xl">
            <div className="d-flex align-items-center px-4 mb-4 pt-4 rd-0">
              <DownloadExcel data={filteredItems} filename="ยอดขายรายบิล" />
              <div>
                <input
                  type="text"
                  className="form-control form-st-4"
                  placeholder="ค้นหา"
                  onKeyUp={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredItems}
              customStyles={customStyles}
              pagination
              footer={filteredItems}
              paginationComponent={(props) => (
                <CustomPagination
                    rowCount={props.rowCount}
                    rowsPerPage={10}
                    currentPage={props.currentPage}
                    onChangePage={(page) => props.onChangePage(page, props.rowCount)}
                    onChangeRowsPerPage={(currentRowsperPage, currentPage) => 
                        props.onChangeRowsPerPage(currentRowsperPage, currentPage)}
                    data={filteredItems}
                    
                />        
               )
              }
              paginationComponentOptions={paginationComponentOptions}
              showFooter 
            />
          </div>
        </div>
       )} 
    </div>
  );
}
