// create uuid
const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const checkURL = (url) => {
  const prefix = process.env.REACT_APP_URL_UPLOAD_FILE_PATH;
  return url.startsWith(prefix);
};

export default {
  uuid,
  checkURL,
};
