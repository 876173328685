import React, { useState,useEffect  } from 'react';
import {Link,useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';

export default  function Deliverypaymentsetting(){
    const { branchID } = useParams();
    const [delivery, setDelivery] = useState([])

    const getdelivery = (branchID) => {
        fetch('/json/delivery.json')
          .then((response) => response.json())
          .then((result) => { 
            setDelivery(result)
          } )
          .catch((error) => console.log("error", error));
      };
    useEffect(() => {
        getdelivery(branchID);
    }, [branchID]);

    const [paymentsetting, setPaymentsetting] = useState({
        "branchID":branchID,
        "paymentChannelID":4,
        "status":1
    });

    const handleSubmit = () => {
        console.log(paymentsetting)
    }

    const [formdata, setFormdata] = useState();
    const [modalcreateShow, setModalcreateShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalsuccessShow, setModalsuccessShow] = useState(false);

    return (
        <div>

            <div className='mb-4'>
                <Form.Check 
                    type="switch"
                    id="status"
                    label='ตั้งค่าเดลิเวอรี่'
                    name='status'
                    className='custom-switch-st-1 tc-10  s1 mb-1'
                    defaultChecked={paymentsetting.status === 1}
                    onChange={(e) => {
                        if(e.target.checked === true){
                            setPaymentsetting((prev) => ({  ...prev,status:1 }) );
                        }else{
                            setPaymentsetting((prev) => ({  ...prev,status:0 }) );
                        }  
                    }}
                />
            </div>

            {
                paymentsetting.status === 1 ? (
                <>
                    <Button 
                    type='button' 
                    className='btn btn btn-st-7 icon-plus mb-4' 
                    onClick={() => {
                        setModalcreateShow(true);
                        setFormdata(
                            {
                                "id": null,
                                "deliveryID": "",
                                "deliveryName": "",
                                "deliveryLogo": "",
                                "status": 1
                        })  
                    }} >
                        เพิ่มเดลิเวอรี่
                    </Button>

                    <div className='d-flex flex-wrap'>
                        { delivery.map((item,index) =>
                            
                            <div className={ item.status == 0 ? 'deliveryStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3' : 'd-flex align-items-center px-3 py-2 me-3 mb-3'   } key={item.deliveryID} style={{minWidth:'283px',borderRadius: '5px',border: '1px solid rgba(76, 78, 100, 0.22)'}}>
                                <div className='logo-delivery me-3' >
                                    <img src={item.deliveryLogo} style={{width:'30px',height:'30px',borderRadius:'15%',objectFit:'cover'}} />
                                </div>
                                <div className='flex-fill'>
                                    <div style={{color:' #2C2C2C'}}>{item.deliveryName}</div>
                                </div>
                                <div className='ms-2'>
                                    <button type='button' className='btn-edit-st-1' onClick={() => { setModalcreateShow(true);setFormdata(item) } }  ></button>
                                </div>
                            </div>

                            ) 
                        }
                    </div> 
                </> 
                ) : ''
            
            }

            <div className="text-end mt-4  pt-5 pb-2 btn-group-control" >
                <Button type="submit" className='btn-st-3  ' style={{width:'100px'}} onClick={handleSubmit}>  บันทึก</Button>
            </div>

            <DeliveryModal
                show={modalcreateShow}
                onHide={() => setModalcreateShow(false)}
                handleDelete={() => { setModalDeleteShow(true);setModalcreateShow(false) } }
                onSuccess={() => { setModalcreateShow(false);getdelivery(branchID); }}
                formdata={formdata}
            />

            <ConfirmDeleteModal
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                onConfirm={() => setModalDeleteShow(false)}
                onSuccess={() => { setModalsuccessShow(true);getdelivery(branchID); }}
                formdata={formdata}
                
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => setModalsuccessShow(false)}
            />
            
        </div>
    )
}

function DeliveryModal(props) {

    const { setModalDeleteShow,formdata,handleDelete,onSuccess, ...rest } = props;

    const schema = yup.object().shape({
        deliveryName: yup.string().required(),
    });

    return (
      <Modal
        {...rest}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-24 fw-bold me-auto' style={{color:'#565DFF'}}>ตั้งค่าเดลิเวอรี่</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ props.formdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        console.log(values)
                        props.onSuccess();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>


                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อช่องทาง  <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="deliveryName" 
                            className="form-control" 
                            placeholder="กรอกชื่อช่องทาง"
                            value={values.deliveryName}
                            onChange={handleChange}
                            isInvalid={!!errors.deliveryName}
                            
                            />
                        </Form.Group>

                        <div className='pt-1 pb-2'>
                            <Imageupload setFieldValue={setFieldValue} values={values} />
                        </div>
                        
                        <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="เปิดใช้งาน"
                                name="status"
                                type={type}
                                id={`inline-${type}-21`}
                                className='form-check-st-2 tc-7'
                                checked={values.status === 1}
                                onChange={(e) => {
                                    setFieldValue("status", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="ปิดใช้งาน"
                                name="status"
                                type={type}
                                id={`inline-${type}-22`}
                                className='form-check-st-2 tc-7'
                                checked={values.status === 0}
                                onChange={(e) => {
                                    setFieldValue("status", 0)
                                }}
                            />
                            </div>
                        ))}
                    

                        <div className="d-flex align-items-center pt-5 pb-3 btn-group-control" >
                            {
                                values.id !== null ? (
                                    <Button type="button" className='btn-st-5 me-auto px-4 '  style={{width:"90px"}} onClick={props.handleDelete} >ลบ</Button>
                            
                                ) : <div className='me-auto'></div>
                            }
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}> { values.deliveryID ?  'แก้ไข' : 'บันทึก' }</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

function ConfirmDeleteModal(props) {
    const handleDelete = (id) => {
        props.onHide();
        props.onSuccess();
    }
    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='px-3 py-4'>
                <div>
                    <div>
                        <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                        <div className='text-center fs-22 tc-7'>รายการนี้ใช่หรือไม่</div>
                    </div>

                    <div className="text-center pt-4 mt-3  btn-group-control" >
                        <Button type="button" className='btn-st-3 mx-3  ' style={{width:'100px'}}  onClick={ () => handleDelete(props.formdata.deliveryID)  }>ใช่</Button>
                        <Button type="button" className='btn-st-6 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ไม่ใช่</Button>
                    </div>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    );
}

function SuccessDeleteModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        centered
      >
        <Modal.Body>

            <div className='px-3 py-4'>
                <div className='text-center mb-4'>
                    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                    <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </div>
                <div className='text-center fs-22 tc-7'>ทำรายการสำเร็จ</div>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}

const Imageupload = (props) => {

    const [imgData, setImgData] = useState(false);
    
    const handlePreviewimage = e => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
            setImgData(reader.result);
            props.setFieldValue("deliveryLogo", reader.result)

            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    useEffect(() => {
        setImgData(props.values.deliveryLogo);
    }, [props.values.deliveryLogo]);


    return (
        <div className=''>
            <div className="d-flex align-items-center mb-4 mt-3 ">
                
                <div className="bx-upload">
                    {
                        imgData ? (
                            <img src={imgData} />
                        ) : (
                            <div className='preimg'></div>
                        )
                    }
                    
                </div>
                <input type="file" name="" className="fileUpload hide-input" id='fileUpload' accept="image/*" onChange={ (e) => { handlePreviewimage(e); } } />

                <div className="flex-fill ms-3">
                    <div className="mb-3">
                        <button type='button' className="btn btn-st-4 btn-upload me-3" onClick={ (e) => document.getElementById('fileUpload').click() }>อัปโหลดรูปภาพ</button>
                        <button type='button' 
                        className="btn btn-st-2 btn-remove-image" 
                        onClick={ (e) => { setImgData(false);props.setFieldValue("deliveryLogo", '') } }
                        style={{width:'70px'}}
                        >ลบ</button>
                    </div>
                    <div className="tc-7 fs-12">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                </div>
            </div>
        </div>
    )

}