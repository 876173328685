import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactPaginate from 'react-paginate';
import { MultiSelect } from "react-multi-select-component";
import  DownloadExcel from 'utils/DownloadExcel';
import Button from "react-bootstrap/Button";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { appState } from "../../state/app";
import { authState } from "../../state/auth";
import pos2 from "../../img/pos2.png";
import { MoonLoader } from "react-spinners";
import {
  getCurrentDate,
  convertDateFormat,
  getCurrentDateM,
  formatDateToYMD,
  subtractDays,
  getCurrentDateforApi,
} from "utils/dateFormat";
import Select from "react-select";
import { default as ReactSelect, components, InputAction } from "react-select";
import DailyApi from "services/report/DailyApi";


export function ReportCategory() {
  const { token, setToken } = useToken();
  let { businessId } = useParams();
  const navigate = useNavigate();
  const { slug } = useRecoilValue(appState);
  const { user } = useRecoilValue(authState);
  const BASE_URL =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard/total";
  const BASE_URL_POS =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard";

  const [business, setBusiness] = useState(businessId);
  const [reportdata, setreportdata] = useState([]);
  const [searchdata, setSearchdata] = useState({
    branchID: "",
    posID: "",
    startDate: getCurrentDate("/"),
    endDate: getCurrentDate("/"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResetSearch, setShowResetSearch] = useState(false);
  const [subcategory, setSubcategory] = useState([]);
  const [dateApplied, setDateApplied] = useState(false);
  const currentDate = new Date();
  const endDate = getCurrentDateforApi("-", currentDate);
  const startDate = getCurrentDateforApi("-", subtractDays(currentDate, 7));
  const [fetchDate, setCurrentDate] = useState({ startDate, endDate });
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPos, setOptionsPos] = useState([]);
  const keyRef = useRef();


  const columns = [
    {
        name: 'รหัสหมวดหมู่',
        selector: row => row.code,
        width:'150px'
    },
    {
        name: 'ชื่อหมวดหมู่',
        selector: row => row.name,
        cell: (row, index, column, id) => {
            return (
                <div className="ps-2" style={{color:'rgba(86, 93, 255, 1)',fontWeight:700}}>
                    {row.name}
                </div>
            );
        },
    },
    {
        name: "ยอดขาย",
        selector: row => row.sales,
        center: true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.sales.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
            );
        },
    },
    {
        name: "สัดส่วนในหมวดหมู่",
        selector: row => row.proportion_category,
        center: true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.proportion_category}%
                </div>
            );
        },
    },
    {
        name: "สัดส่วนภาพรวม",
        selector: row => row.proportion_overall,
        center: true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.proportion_overall}%
                </div>
            );
        },
    }
]



  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "14px",
        background: " #F5F5F7",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: "500",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background: " #FFF",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    expanderRow: {
      style: {
        minHeight: "52px",
      },
    },
    pagination: {
      style: {
        borderBottomRightRadius: "0.75rem",
        borderBottomLeftRadius: "0.75rem",
      },
    },
  };

  const columnsSubCategory = [
    {
      selector: (row) => "",
      width:'150px'
    },
    {
        name: "",
        selector: (row) => row.name,
        cell: (row, index, column, id) => {
        return (
            <div className="ps-2" style={{fontWeight:700}}>
                - {row.name}
            </div>
        );
        },
    },
    {
        name: "",
        selector: (row) => row.sales,
        center:true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.sales.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </div>
            );
        },
    },
    
    {
        name: "",
        selector: (row) => row.proportion_category,
        center:true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.proportion_category}%
                </div>
            );
        },
    },
    {
        name: "",
        selector: (row) => row.proportion_overall,
        center:true,
        width:'200px',
        cell: (row, index, column, id) => {
            return (
                <div>
                    {row.proportion_overall}%
                </div>
            );
        },
    },
  ]

  const getsubCategoryData = (id) => {
    console.log(id)
    const subcategory = [
        {
            name:"กาแฟ",
            sales:1500.00,
            proportion_category:28.41,
            proportion_overall:10.92

        },
        {
            name:"ชา",
            sales:1500.00,
            proportion_category:28.41,
            proportion_overall:10.92
        },
        {
            name:"นม",
            sales:1500.00,
            proportion_category:28.41,
            proportion_overall:10.92
        }
    ];

    return subcategory;

  }
  const ExpandedComponent = ({ data }) => {

    const subcategory =  getsubCategoryData(data.id)

    return (
      <div className="hideexpandable">
        <DataTable
          columns={columnsSubCategory}
          data={subcategory}
          noTableHead={true}
          expandableRows
          customStyles={customStylesExpander}
        />
      </div>
    );
  };

  const customStylesExpander = {
    rows: {
      style: {
        minHeight: "52px",
        background:'#0000001a',
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background:'rgba(215, 217, 255, 1)',
        fontSize: "14px",
        

      },
    },
  };


  const handleSearch = () => {
    setIsLoading(true);
    let formattedStartDate = searchdata.startDate;
    let formattedEndDate = searchdata.endDate;

    setShowResetSearch(true);
    var a=[];
   

    for(var i=0;i < 25 ;i++){

        var b = {
            "id":i,
            "code":`code00${i+1}`,
            "name":"เครื่องดื่มร้อน ( Hot )",
            "sales":5280.00,
            "proportion_category":100,
            "proportion_overall":4,
        };
        a.push(b); 
        
    }
    setreportdata(a);
    setIsLoading(false);
  };
  const resetFilter = () => {
    setShowResetSearch(false);
    setreportdata([]);

  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const handleApply = (event, picker) => {
    setSearchdata((prev) => ({
      ...prev,
      startDate: picker.startDate.format("YYYY/MM/DD"),
    }));
    setSearchdata((prev) => ({
      ...prev,
      endDate: picker.endDate.format("YYYY/MM/DD"),
    }));
    setDateApplied(true);
  };

  const getBranchData = async () => {
    DailyApi.GetAllBranchByBussiness(config).then((res) => {
      const data = res.data;
      const options = [];


      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsBranch(options);
    });
  };


  useEffect(() => {
    if (user.businessId && token) {
      getBranchData();

    }
  }, [user.businessId, token]);



  const handlerSelectBranchChange = (e) => {
    // check if e is not null
    if (e) {
      if (e.label != "ทั้งหมด") {
        setSearchdata((prev) => ({ ...prev, branchID: e.value }));
      } else {
        setSearchdata((prev) => ({ ...prev, branchID: "", posID: "" }));
      }
    }
  };

  const [filterText, setFilterText] = useState("");

  let filteredItems;
  if (reportdata !== undefined && reportdata.length > 0) {
    filteredItems = reportdata.filter((item) => {
      const matchCode = item.code
        .toLowerCase()
        .includes(filterText.toLowerCase());
        const matchName = item.name
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchCode || matchName;
    });
  }


  const CustomPagination = ({ rowsPerPage, rowCount, currentPage, onChangePage, onChangeRowsPerPage,data }) => {
    const count = rowCount/rowsPerPage;
    var sumall = 0;
    var sumpage = [];
    var sumoverall = 0;
    var sumpageoverall = [];
    var sumcategory = 0;
    var sumpagecategory = [];
    var sumpagen = 0;
    var sumpagenoverall = 0;
    var sumpagencategory = 0;
    var gpage = 1;
    data.map((item,index) => {
  
        sumall += item.sales;
        sumpagen += item.sales;
        sumoverall += item.proportion_overall;
        sumpagenoverall += item.proportion_overall;
        sumcategory += item.proportion_category;
        sumpagencategory += item.proportion_category;

        if((index+1) % rowsPerPage == 0  || (index+1) == rowCount){
            sumpage[gpage] = sumpagen;
            sumpagen = 0;
            sumpageoverall[gpage] = sumpagenoverall;
            sumpagenoverall = 0;
            if((index+1) == rowCount){
                sumpagecategory[gpage] = sumpagencategory/(rowCount%rowsPerPage);
            }else{
                sumpagecategory[gpage] = sumpagencategory/rowsPerPage; 
            }
            sumpagencategory = 0;
            gpage += 1;
        } 
    })



    return (
      <>
      <div className="" style={{borderTop: '1px solid #e0e0e0'}}>
        <div className="row gx-0 fs-14 align-items-center justify-content-end"  style={{height:'52px'}}>
            <div className="col">
                <div className="ps-5">
                    ผลรวม {
                        currentPage*rowsPerPage > rowCount ?  rowCount%rowsPerPage : rowsPerPage
                    }  รายการ
                </div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{sumpage[currentPage].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{sumpagecategory[currentPage]}%</div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{sumpageoverall[currentPage]}%</div>
            </div>
        </div>
      </div>

      <div className="" style={{borderTop: '1px solid #e0e0e0'}}>
        <div className="row gx-0  fs-14 align-items-center justify-content-end" style={{height:'52px'}}>
            <div className="col">
                <div className="ps-5">
                ผลรวมทั้งหมด
                </div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{sumall.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{(sumcategory)/rowCount}%</div>
            </div>
            <div className="col-auto">
                <div className="mx-auto text-center" style={{width:'200px'}}>{sumoverall}%</div>
            </div>
        </div>
      </div>
      
      <div className=' d-flex align-items-center justify-content-end mx-0 pt-3' style={{borderTop: '1px solid #e0e0e0'}}>
  
        <div className='  fs-12 d-flex justify-content-start  me-5'  style={{color:'#4C4E64'}}> 
          <p><span className="me-4">จำนวนรายการต่อหน้า <span className="ms-2">{ rowsPerPage }</span> </span>   
            
            { (currentPage*rowsPerPage + 1 ) - rowsPerPage } 
            <span className="mx-1 fs-18">-</span>
            {
                currentPage*rowsPerPage > rowCount ? rowCount : currentPage*rowsPerPage
            }
             <span className="mx-1">of</span> {rowCount}</p>
        </div>



  
        <div className=' d-flex justify-content-end'>
          <ReactPaginate
            previousLabel={<i class="icon-prev"></i>}
            nextLabel={<i class="icon-next"></i>}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={page => {

                onChangePage(page.selected+1,rowCount)

            }}
            pageCount={count || 1}
            breakLabel={'...'}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            activeClassName={'active'}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName={'pagination react-paginate pagination-sm justify-content-end pr-1 mt-1'}
          />
        </div>
      </div>
      </>
    )
  }

  return (
    <div>

      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายงาน
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            ยอดขายตามหมวดหมู่{" "}
          </li>
        </ol>
      </nav>

      <div className="card card-st-2 mb-4">
        <div className="card-body  py-4">
          <div className="fw-bold mb-3">ค้นหาขั้นสูง</div>
          <div className="row items-center">
            <div className="col-xl-2 col-lg-3 col-2">


              <Select
                as="select"
                name="branch"
                onChange={(e) => handlerSelectBranchChange(e)}
                className={`w-100 select-st-1 pd`}
                options={optionsBranch}
                placeholder="เลือกสาขา"
                isClearable={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-2">
                <DateRangePicker
                    initialSettings={{
                    startDate: searchdata.startDate,
                    endDate: searchdata.endDate,
                    locale: {
                        format: "DD/MM/YYYY",
                    },
                    }}
                    onApply={handleApply}
                >
                    <input
                    type="text"
                    className="form-control iconcalendar-right"
                    />
                </DateRangePicker>
            </div>
            <div className="col-auto">
              <Button
                type="button"
                className="btn-st-3 flex justify-center items-center"
                style={{ width: "120px", height: "40px" }}
                onClick={handleSearch}
              >
                ค้นหา
              </Button>
            </div>
            {showResetSearch && (
              <div className="col-auto">
                <Button
                  type="button"
                  className="bg-orange-400 flex justify-center items-center hover:bg-orange-300"
                  style={{ width: "150px", height: "40px",border:0 }}
                  onClick={resetFilter}
                >
                  ล้างการค้นหา
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-start">
          <MoonLoader
            color="#565DFF"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="mt-40"
          />
        </div>
      )}
      {showResetSearch && (
        <div className="card card-st-2 mb-5">
          <div className="card-body p-0 pb-5 rounded-xl">
            <div className="d-flex align-items-center px-4 mb-4 pt-4 rd-0">
              <DownloadExcel data={filteredItems} filename="ยอดขายตามหมวดหมู่" />
              <div>
                <input
                  type="text"
                  className="form-control form-st-4"
                  placeholder="ค้นหา"
                  onKeyUp={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredItems}
              customStyles={customStyles}
              pagination
              paginationComponent={(props) => (
                  <CustomPagination
                      rowCount={props.rowCount}
                      rowsPerPage={10}
                      currentPage={props.currentPage}
                      onChangePage={(page) => props.onChangePage(page, props.rowCount)}
                      onChangeRowsPerPage={(currentRowsperPage, currentPage) => 
                          props.onChangeRowsPerPage(currentRowsperPage, currentPage)}
                      data={filteredItems}
                  />        
                 )
              }

              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </div>
        </div>
       )} 
    </div>
  );
}
