import React, { useState  } from 'react';
import {useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { Formik } from 'formik';
export default function Otherpaymentsetting(){

    const { branchID } = useParams();

    const [modalShow, setModalShow] = useState(false);



    
    const [paymentsetting, setPaymentsetting] = useState({
        "branchID":branchID,
        "paymentChannelID":"roundoff",
        "status":1,
        "roundoff":1,
        "roundoffText":"ปัดเศษตามหลักสากล"
    });

    
    const handleSubmit = () => {
        console.log(paymentsetting)
    }


    return (
        <div>
            <Form.Check 
                type="switch"
                id="pointpayment"
                label='รับชำระด้วยคะแนน'
                name='pointpayment'
                className='custom-switch-st-1 tc-10  s1 mb-1'
                defaultChecked={paymentsetting.status === 1}
                onChange={(e) => {
                    if(e.target.checked === true){
                        setPaymentsetting((prev) => ({  ...prev,status:1 }) );
                    }else{
                        setPaymentsetting((prev) => ({  ...prev,status:0 }) );
                    }  
                }}
            />

            {
                paymentsetting.status === 1 ? (
            
                    <div className=' d-flex align-items-center ps-2 pe-3 py-2  mb-4 mt-4' style={{width:'283px',borderRadius: '5px',border: '1px solid rgba(76, 78, 100, 0.22)'}}>
                        <div className=' me-2' >
                            <div className='icon-setting'></div>
                        </div>
                        <div className='flex-fill'>
                            <div style={{color:' #2C2C2C'}}>{ paymentsetting.roundoffText }</div>
                        </div>
                        <div className='ms-2'>
                            <button type='button' className='btn-edit-st-1' onClick={() => { setModalShow(true); } } ></button>
                        </div>
                    </div>
                ) : ''
            }


            <div className="text-end  pt-2 pb-3 btn-group-control" >
                <Button type="submit" className='btn-st-3  ' style={{width:'100px'}} onClick={handleSubmit}>  บันทึก</Button>
            </div>

            <RoundoffModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setting={paymentsetting}
            />
        </div>
    )
}

function RoundoffModal(props) {


    const optionsRoundoff= [
        { value: 1, label: 'ปัดเศษขึ้นทุกครั้ง' },
        { value: 2, label: 'ปัดเศษลงทุกศรั้ง' },
        { value: 3, label: 'ปัดเศษตามหลักสากล' }
    ];

    return (
      <Modal
        {...props}
        size="md"
        centered
      >
        <Modal.Body>
            <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                <div className='fs-24 fw-bold me-auto' style={{color:'#565DFF'}}>ตั้งค่าปัดเศษ</div>
                <button onClick={props.onHide} className='btn btn-close-modal'></button>
            </div>
            <div className='px-3'>
                <Formik
                    enableReinitialize
                    initialValues={ props.setting }
                    onSubmit={async (values) => {
                        console.log(values)
                        props.onHide();
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ตัวเลือกการปัดเศษ  <span className='text-danger'>*</span></Form.Label>
                            <Select as="select"
                                name="roundoff"
                                className={ errors.roundoff ? "w-100 invalid select-st-1" : "w-100 cc select-st-1" }
                                options={optionsRoundoff}
                                placeholder="ตัวเลือกการปัดเศษ"
                                onChange={(e) => {
                                    setFieldValue("roundoff", e.value)
                                }}
                                value={
                                    optionsRoundoff.filter(
                                        item => item.value === values.roundoff
                                    )
                                }
                            />
                        </Form.Group>

                        <div className="text-end pt-2 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}> บันทึก </Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            
        </Modal.Body>

      </Modal>
    );
}