import axiosInstance from './axios';
import { jwtDecode } from 'jwt-decode';

export const checkPOSSlug = async (slug) => {
    try {
        const queryParams = {
            slug: slug,
        }
        // console.log('checkPOSSlug', slug);

        const response = await axiosInstance.get('/admin-auth/check-slug', { params: queryParams });
        // console.log("🚀 ~ checkPOSSlug ~ response:", response)

        if (response.status === 200) {
            if(response.data) {
                return {
                    business: response.data, 
                    isValidSlug: true
                }
            } else {
                return {
                    business: null, 
                    isValidSlug: false
                }
            }
        }
        else if (response.status === 404) {
            return false
        }
        else {
            console.error('Unexpected status code:', response.status);
            return false
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}