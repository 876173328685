
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { ModalAllder } from 'layouts/ModalAllder';
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'

export function OptionNote(props){

    const [optionnote, setOptionnote] = useState([])
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    const getoptionData = (props) => {
        fetch(`${process.env.REACT_APP_API_URL}/business-product/remark-options`, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
                'business-id': user.businessId,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setOptionnote(result)
            })
            .catch((error) => console.log('error', error))
    }

    useEffect(() => {
        getoptionData()
    }, [])

    const handleChangeOptionNote = (options) => {
        let selected = []
        props.listoptionnote.map((item, index) => {
            selected.push(item.id)
        })
        let optionselect = []

        options.map((option, index) => {
            if (!selected.includes(option.id)) {
                props.setListOptionnote(listoptionnote => [...listoptionnote, option])
            }
            optionselect.push(option.id)
        })
        props.setListOptionnote(listoptionnote => listoptionnote.filter(item => optionselect.includes(item.id)))

        
    }

    const handleDeleteoptionList = (optionID, listID) => {
        props.setListOptionnote(option => option.map((el) =>
            el.id === optionID
                ? {
                    ...el,
                    product_option_detail: el.product_option_detail.filter(dx => dx.id !== listID),
                } : el,
        ))
    }

    let optionselected = []
    props.listoptionnote.map((item, index) => {
        optionselected.push(item.id)
    })

    return (
        <div className="px-5 py-4">
            <div className="row">
                <div className="col-xl-6 col">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ตัวเลือก <span className="text-danger">*</span></Form.Label>
                        <Select as="select"
                                name="storeH"
                                onChange={(e) => {
                                    handleChangeOptionNote(e)
                                }}
                                className="w-100 select-st-1 select-multi"
                                options={optionnote}
                                placeholder="พิมพ์ชื่อตัวเลือก"
                                getOptionLabel ={(option)=>option.name}
                                getOptionValue ={(option)=>option.id}
                                isMulti
                                value={optionnote.filter(
                                    i => optionselected.includes(i.id),
                                )
                                }
                        />
                    </Form.Group>
                </div>
            </div>
            <div className=" mt-3 ">
                <div className="d-flex flex-wrap">
                    {props.listoptionnote.map((item, index) => (
                        <div key={index}>
                            {item.product_option_detail.map((item2, index2) => (
                                <div className="optionnote-display" key={index2}>
                                    <div>{item2.name}</div>
                                    <button type="button" className="btn btn-remove-st2"
                                            onClick={() => handleDeleteoptionList(item.id, item2.id)}></button>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}
