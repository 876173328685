import React from "react";

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div
        className="form-check form-check-st-3 "
        style={{ backgroundColor: "" }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          style={{ height: "18px", width: "18px" }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
        <label className="form-check-label" id="booty-check" />
      </div>
    </>
  );
});

export default Checkbox;
