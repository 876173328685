import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Collapse from "react-bootstrap/Collapse";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AutoTabProvider } from "react-auto-tab";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";
import ImageUpload from "components/ImageUpload";
import getCurrentDate from "utils/CurrentDate";
import { formatDateToYMD } from "utils/dateFormat";
import ConfirmCancelempModal from "components/BusinessEmp/ConfirmCancelempModal";
import ConfirmCancelModal from "components/BusinessEmp/ConfirmCancelModal";
import SuccessModal from "components/BusinessEmp/SuccessModal";

import BusinessEmpApi from "services/BusinessEmpApi";
import BusinessDeptApi from "services/BusinessDeptApi";
import BusinessPositionApi from "services/BusinessPositionApi";

import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";
import { MoonLoader } from "react-spinners";

export default function EmpForm() {
  const { empID } = useParams();
  // console.log("🚀 ~ EmpForm ~ empID:", empID);

  // get current date format yyyy/mm/dd
  const currentdate = new Date().toISOString().slice(0, 10);

  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const [modalcancel, setModalcancel] = useState(false);
  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState(false);
  const [modalconfirmcancelemp, setModalconfirmcancelemp] = useState(false);

  const [formdisabled, setFormdisabled] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);

  const [isResign, setIsResign] = useState(false);

  const [formdata, setFormdata] = useState();

  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPosition, setOptionsPosition] = useState([]);
  const [optionsDepartment, setOptionsDepartment] = useState([]);

  const [cardOpen1, setCardOpen1] = useState(true);
  const [cardOpen2, setCardOpen2] = useState(true);
  const [cardOpen3, setCardOpen3] = useState(true);
  const [cardOpen4, setCardOpen4] = useState(true);
  const [cardOpen5, setCardOpen5] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [branchselected, setBranchselected] = useState([]);
  const [userCancel, setUserCancel] = useState("");
  const [userCancelID, setUserCancelID] = useState("");
  const [empAll, setEmpAll] = useState([]);


  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": user.businessId,
    },
  };

  const getEmpCode = (userCancel = 0) => {
    BusinessEmpApi.GetAll(config)
      .then((res) => {
        const data = res.data;

        // count total emp for empCode this format EMP00001
        const empCount = data.length + 1;
        const empCode = `EMP${empCount.toString().padStart(5, "0")}`;

        // add empCode to formdata
        setFormdata({ ...formdata, code: empCode });

        // get user name for create emp
        if (userCancel != 0) {
          const user = data.find((item) => item.updated_by == userCancel);
          console.log("🚀 ~ .then ~ user:", user)
          setUserCancel(user.first_name_th);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getempAll = () => {
    BusinessEmpApi.GetAll(config)
      .then((res) => {
        const data = res.data;
        // console.log("🚀 ~ .then ~ data:", data)

        setEmpAll(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getempEditData = (id) => {
    BusinessEmpApi.Get(id, config)
      .then((res) => {
        const data = res.data;
        // console.log("🚀 ~ .then ~ data:", data);

        data.start_date = new Date(data.start_date).toISOString().slice(0, 10);
        data.end_date = data.end_date
          ? new Date(data.end_date).toISOString().slice(0, 10)
          : "";
        data.birthdate = new Date(data.birthdate).toISOString().slice(0, 10);
        data.type_id = data.type_id.toString();
        data.status_id = data.status_id.toString();
        data.department_id = data.department_id.toString();
        data.position_id = data.position_id.toString();
        // console.log("🚀 ~ .then ~ data:", data.updated_by)

        setUserCancelID(data.updated_by);
        // getEmpCode(data.updated_by);
        
        // add empCode to formdata
        setFormdata(data);

        // if emp status is 3 (resign) then set isResign to true
        if (data.status_id == 3) {
          setFormdisabled(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeptAll = () => {
    BusinessDeptApi.GetAll(config)
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res)
        const data = res.data;
        const options = data.map((item) => {
          return { value: item.id, label: item.name_th };
        });

        setDepartments(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPosAll = () => {
    BusinessPositionApi.GetAll(config)
      .then((res) => {
        const data = res.data;
        const options = data.map((item) => {
          return { value: item.id, label: item.name_th };
        });
        setPositions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user.businessId && token) {
      if (!empID) {
        getEmpCode();
      }
      getDeptAll();
      getPosAll();
      getempAll()
    }
  }, [user.businessId, token, empID]);

  useEffect(() => {
    if (empID) {
      getempEditData(empID);
    } else {
      setFormdata(initialData);
    }
  }, [empID]);

  useEffect(() => {
    if (empAll) {
      const userCancel = empAll.find((item) => item.id == userCancelID);

      if (userCancel) {
        setUserCancel(`${userCancel.first_name_th} ${userCancel.last_name_th}`);
      }
      // getempEditData(empID);
    } 
  }, [empAll, userCancelID]);

  const schema = yup.object({
    // code: yup.string().required(),
    first_name_th: yup.string().required(),
    last_name_th: yup.string().required(),
    first_name_en: yup.string().required(),
    last_name_en: yup.string().required(),
    address: yup.string().required(),
    type_id: yup.string().required(),
    status_id: yup.string().required(),
    department_id: yup.string().required(),
    position_id: yup.string().required(),
    user_name: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const schemaEdit = yup.object({
    // code: yup.string().required(),
    first_name_th: yup.string().required(),
    last_name_th: yup.string().required(),
    first_name_en: yup.string().required(),
    last_name_en: yup.string().required(),
    address: yup.string().required(),
    type_id: yup.string().required(),
    status_id: yup.string().required(),
    department_id: yup.string().required(),
    position_id: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const initialData = {
    code: "EMP00001",
    // empImage: "",
    prefix: "",
    first_name_th: "",
    last_name_th: "",
    first_name_en: "",
    last_name_en: "",
    // identitycard: "",
    nickname: "",
    gender: "",
    birthdate: "",
    age: "",
    race: "thai",
    nationality: "thai",
    marital_status: "โสด",
    military_status: "ยังไม่ได้รับการคัดเลือก",

    address: "",
    // subdistrict: "",
    // district: "",
    // province: "",
    // postalcode: "",
    email: "-",
    phone_number: "-",
    mobile_number: "-",
    contactor_number: "-",
    line: "-",
    facebook: "-",
    ig: "-",
    contactor_name: "-",
    contactor_number: "-",
    contactor_relation: "",

    start_date: currentdate,
    end_date: "",
    type_id: "",
    status_id: "",
    department_id: "",
    position_id: "",
    is_registed: false,
    // empBranch: [],

    channel: "2",
    contactor_bank_name: "",
    account_type: "",
    account_name: "",
    account_number: "",
    account_office_name: "-",
    account_office_number: "-",

    user_name: "",
    password: "",
    confirmPassword: "",
    // userCreate: "Admin Pat",
    // dateCreate: "22/01/2023",
  };

  const prefixType = [
    { value: 1, label: "นาย" },
    { value: 2, label: "นาง" },
    { value: 3, label: "นางสาว" },
    { value: 4, label: "ไม่ระบุ" },
  ];

  const optionsMarital = [
    { value: "แยกกันอยู", label: "แยกกันอยู" },
    { value: "หย่าร้าง", label: "หย่าร้าง" },
    { value: "สมรส", label: "สมรส" },
    { value: "โสด", label: "โสด" },
  ];

  const optionsMilitary = [
    { value: "ได้รับการยกเว้น", label: "ได้รับการยกเว้น" },
    { value: "ยังไม่ได้รับการคัดเลือก", label: "ยังไม่ได้รับการคัดเลือก" },
    { value: "ผ่านการเรียน รด", label: "ผ่านการเรียน รด" },
  ];

  const optionsGender = [
    { value: "female", label: "หญิง" },
    { value: "male", label: "ชาย" },
    { value: "other", label: "อื่นๆ" },
  ];

  const optionsRelationship = [
    { value: "บิดา", label: "บิดา" },
    { value: "มารดา", label: "มารดา" },
    { value: "ญาติ", label: "ญาติ" },
    { value: "เพื่อนสนิท", label: "เพื่อนสนิท" },
    { value: "อื่นๆ", label: "อื่นๆ" },
  ];

  const optionsempType = [
    { value: 1, label: "พนักงานชั่วคราว" },
    { value: 2, label: "พนักงานรายวัน" },
    { value: 3, label: "พนักงานสัญญาจ้าง" },
    { value: 4, label: "พนักงานประจำ" },
  ];

  const optionsempStatus = [
    { value: 1, label: "ปกติ" },
    { value: 2, label: "พักงาน" },
    { value: 3, label: "ลาออก" },
  ];

  const optionsaccountType = [
    { value: 1, label: "ออมทรัพย์" },
    { value: 2, label: "ฝากประจำ" },
    { value: 3, label: "กระแสรายวัน" },
  ];

  const optionsBank = [
    { value: 1, label: "กรุงเทพ" },
    { value: 2, label: "กสิกรไทย" },
    { value: 3, label: "กรุงไทย" },
    { value: 4, label: "ทหารไทย" },
    { value: 5, label: "ไทยพาณิชย์" },
    { value: 6, label: "กรุงศรีอยุธยา" },
    { value: 7, label: "เกียรตินาคิน" },
    { value: 8, label: "ซีไอเอ็มบีไทย" },
    { value: 9, label: "ทิสโก้" },
    { value: 10, label: "ธนชาต" },
    { value: 11, label: "ยูโอบี" },
    { value: 12, label: "สแตนดาร์ดชาร์เตอร์ด (ไทย)" },
    { value: 13, label: "ไทยเครดิตเพื่อรายย่อย" },
    { value: 14, label: "ออมสิน" },
    { value: 15, label: "อาคารสงเคราะห์" },
    { value: 16, label: "เพื่อการเกษตรและสหกรณ์การเกษตร" },
    { value: 17, label: "พัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย" },
    { value: 18, label: "ไอซีบีซี (ไทย)" },
    { value: 19, label: "เพื่อการส่งออกและนำเข้าแห่งประเทศไทย" },
    { value: 20, label: "แห่งประเทศจีน" },
    { value: 21, label: "ซูมิโตโม มิตซุย ทรัสต์ (ไทย)" },
    { value: 22, label: "อิสลามแห่งประเทศไทย" },
    { value: 23, label: "ฮ่องกงและเซี้ยงไฮ้แบงกิ้งคอร์ปอเรชั่น จำกัด" },
  ];

  const [identitycard, setIdentitycard] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const handleinputTab = (e, index) => {
    const updateItem = [...identitycard];
    updateItem[index] = e.target.value;
    setIdentitycard(updateItem);
  };

  const handleChangeBranch = (options) => {
    let optionselect = [];
    options.map((option, index) => {
      optionselect.push(option.value);
    });

    setBranchselected(optionselect);
  };

  const [address, setAddress] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  //   useEffect(() => {

  //     setOptionsBranch(getbranchData());
  //     setOptionsPosition(getpositionData());
  //     setOptionsDepartment(getdepartmentData());

  //     if(empID){
  //         getempEditData(empID).then(response => {
  //             setFormdata(response)
  //             setAddress({
  //                 "district":response.district,
  //                 "subdistrict":response.subdistrict,
  //                 "province":response.province,
  //                 "postalCode":response.postalcode,

  //             })
  //             setBranchselected(response.empBranch)
  //             setFormdisabled(true)

  //             setIdentitycard(response.identitycard.split(""))

  //         } )
  //     }

  // }, [empID]);

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-1">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            รายชื่อพนักงาน{" "}
          </li>
        </ol>
      </nav>
      {formdata ? (
        <Formik
          enableReinitialize
          initialValues={formdata}
          validationSchema={empID ? schemaEdit : schema}
          onSubmit={async (values) => {
            console.log("🚀 ~ onSubmit={ ~ values:", values);

            // check if empID is null then create new emp else update emp
            if (!empID) {
              // create new emp

              const response = await BusinessEmpApi.Create(
                values,
                configCreate
              );

              if (response.status === 200) {
                setTextsuccess("สร้างรายการสินค้าสำเร็จ");
                setModalsuccess(true);
                setFormdisabled(true);
                // set timeout for close modal and redirect to list
                setTimeout(() => {
                  setModalsuccess(false);
                  window.location.href = `/${slug}/emps`;
                }, 2000);
              } else if (response.status === 401) {
                // add validation message for username already exist
                setTextsuccess("Username นี้มีอยู่แล้วในระบบ");
                setModalsuccess(true);
                setFormdisabled(false);
              } else {
                setTextsuccess("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                setModalsuccess(true);
                setFormdisabled(false);
              }
            } else {
              // update emp
              const response = await BusinessEmpApi.Update(
                empID,
                values,
                configCreate
              );

              if (response.status === 200) {
                setTextsuccess("แก้ไขรายการสินค้าสำเร็จ");
                setModalsuccess(true);
                setFormdisabled(true);
                // set timeout for close modal and redirect to list
                setTimeout(() => {
                  setModalsuccess(false);
                  window.location.href = `/${slug}/emps`;
                }, 2000);
              } else if (response.status === 401) {
                // add validation message for username already exist
                setTextsuccess("Username นี้มีอยู่แล้วในระบบ");
                setModalsuccess(true);
                setFormdisabled(false);
              } else {
                setTextsuccess("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                setModalsuccess(true);
                setFormdisabled(false);
              }
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
            isInvalid,
            isValid,
          }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                if (!values.prefix) {
                  setFieldValue("prefix", "ไม่ระบุ");
                }
                if (!values.gender) {
                  setFieldValue("gender", "other");
                }
                if (!values.birthdate) {
                  const date = new Date();
                  setFieldValue("birthdate", getCurrentDate("-", date));
                }
                if (!values.age) {
                  setFieldValue("age", "-");
                }
                if (!values.marital_status) {
                  setFieldValue("marital_status", "โสด");
                }
                if (!values.military_status) {
                  setFieldValue("military_status", "ยังไม่ได้รับการคัดเลือก");
                }
                if (!values.nickname) {
                  setFieldValue("nickname", "-");
                }
                if (!values.race) {
                  setFieldValue("race", "thai");
                }
                if (!values.nationality) {
                  setFieldValue("nationality", "thai");
                }
                if (!values.contactor_relation) {
                  setFieldValue("contactor_relation", "อื่นๆ");
                }
                if (values.channel != "1") {
                  setFieldValue("contactor_bank_name", "-");
                  setFieldValue("account_type", "-");
                  setFieldValue("account_name", "-");
                  setFieldValue("account_number", "-");
                }

                setFieldValue("is_registed", !!values.is_registed);
                setFieldValue("updated_by", user.id);

                handleSubmit();
              }}
            >
              <fieldset disabled={formdisabled}>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <button
                      type="button"
                      className="btn w-100 text-start btn-collapse"
                      onClick={() => setCardOpen1(!cardOpen1)}
                      aria-controls="collapse1"
                      aria-expanded={cardOpen1}
                    >
                      <div className="tc-8  fs-18 fw-bold px-3">
                        ข้อมูลทั่วไป
                      </div>
                    </button>
                    <Collapse in={cardOpen1}>
                      <div id="collapse1">
                        {values.status_id == 3 ? (
                          <div className="d-flex flex-wrap align-items-center align-items-center px-3 mt-4">
                            <div className="me-3 alert-st-1">
                              พนักงานลาออกไปไปเมื่อวันที่ {values.end_date} โดย{" "}
                              {userCancel}{" "}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div>
                          {/* <ImageUpload
                            setFieldValue={setFieldValue}
                            values={values}
                          /> */}
                          <div className="pt-3 px-3">
                            <div className="row gx-xl-4">
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    รหัสพนักงาน{" "}
                                    <span className="text-danger ms-1"> *</span>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="code"
                                    className="form-control"
                                    placeholder="รหัสพนักงาน"
                                    value={values.code}
                                    onChange={handleChange}
                                    isInvalid={!!errors.code}
                                    disabled={true}
                                  />
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                  {errors.code}
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    คำนำหน้า
                                  </Form.Label>
                                  <Select
                                    as="select"
                                    name="prefix"
                                    onChange={(e) => {
                                      setFieldValue("prefix", e.label);
                                    }}
                                    className="w-100 select-st-1"
                                    options={prefixType}
                                    placeholder="เลือกคำนำหน้า"
                                    value={prefixType.filter(
                                      (item) => item.label === values.prefix
                                    )}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ชื่อ (TH){" "}
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="first_name_th"
                                    className="form-control"
                                    placeholder="กรอกชื่อภาษาไทย"
                                    value={values.first_name_th}
                                    onChange={handleChange}
                                    isInvalid={!!errors.first_name_th}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    นามสกุล (TH){" "}
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="last_name_th"
                                    className="form-control"
                                    placeholder="กรอกนามสกุลภาษาไทย"
                                    value={values.last_name_th}
                                    onChange={handleChange}
                                    isInvalid={!!errors.last_name_th}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ชื่อ (EN){" "}
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="first_name_en"
                                    className="form-control"
                                    placeholder="กรอกชื่อภาษาอังกฤษ"
                                    value={values.first_name_en}
                                    onChange={handleChange}
                                    isInvalid={!!errors.first_name_en}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    นามสกุล (EN){" "}
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="last_name_en"
                                    className="form-control"
                                    placeholder="กรอกนามสกุลภาษาอังกฤษ"
                                    value={values.last_name_en}
                                    onChange={handleChange}
                                    isInvalid={!!errors.last_name_en}
                                  />
                                </Form.Group>
                              </div>
                              {/* <div className="col-xl-6 col-md-12">
                                <Form.Label className="mb-1 tc-10  fs-12">
                                  หมายเลขบัตรประชาชน (13 หลัก)
                                </Form.Label>
                                <div className="my-0 form-st-3 identitycard ">
                                  <AutoTabProvider>
                                    <span>
                                      <input
                                        type="text"
                                        settings={{ placement: 1 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[0]}
                                        className="form-tab first"
                                        onChange={(e) => handleinputTab(e, 0)}
                                      />
                                    </span>
                                    <span>
                                      <input
                                        type="text"
                                        settings={{ placement: 2 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[1]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 1)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 3 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[2]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 2)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 4 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[3]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 3)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 5 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[4]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 4)}
                                      />
                                    </span>
                                    <span>
                                      <input
                                        type="text"
                                        settings={{ placement: 6 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[5]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 5)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 7 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[6]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 6)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 8 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[7]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 7)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 9 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[8]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 8)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 10 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[9]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 9)}
                                      />
                                    </span>
                                    <span>
                                      <input
                                        type="text"
                                        settings={{ placement: 11 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[10]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 10)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 12 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[11]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 11)}
                                      />
                                      <input
                                        type="text"
                                        settings={{ placement: 13 }}
                                        maxLength={1}
                                        tabbable="true"
                                        defaultValue={identitycard[12]}
                                        className="form-tab"
                                        onChange={(e) => handleinputTab(e, 12)}
                                      />
                                    </span>
                                  </AutoTabProvider>
                                </div>
                              </div> */}
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ชื่อเล่น{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="nickname"
                                    className="form-control"
                                    placeholder="กรอกชื่อเล่น"
                                    value={values.nickname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.nickname}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">เพศ</Form.Label>
                                  <Select
                                    as="select"
                                    name="gender"
                                    onChange={(e) => {
                                      setFieldValue("gender", e.value);
                                    }}
                                    className="w-100 select-st-1"
                                    options={optionsGender}
                                    placeholder="เพศ"
                                    value={optionsGender.filter(
                                      (item) => item.value === values.gender
                                    )}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    วันเกิด{" "}
                                  </Form.Label>
                                  <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    selected={startDate}
                                    onChange={(date) => {
                                      setStartDate(date);
                                      setFieldValue(
                                        "birthdate",
                                        getCurrentDate("-", date)
                                      );
                                    }}
                                    placeholder="DD/MM/YYYY"
                                    className="w-100 datepicker2"
                                    value={values.birthdate}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">อายุ</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="age"
                                    className="form-control"
                                    placeholder="กรอกอายุ"
                                    value={values.age}
                                    onChange={handleChange}
                                    isInvalid={!!errors.age}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    เชื้อชาติ
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="race"
                                    className="form-control"
                                    placeholder="กรอกเชื้อชาติ"
                                    value={values.race}
                                    onChange={handleChange}
                                    isInvalid={!!errors.race}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    สัญชาติ
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="nationality"
                                    className="form-control"
                                    placeholder="กรอกสัญชาติ"
                                    value={values.nationality}
                                    onChange={handleChange}
                                    isInvalid={!!errors.nationality}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    สถานะสมรส
                                  </Form.Label>
                                  <Select
                                    as="select"
                                    name="marital_status"
                                    onChange={(e) => {
                                      setFieldValue("marital_status", e.value);
                                    }}
                                    className="w-100 select-st-1"
                                    options={optionsMarital}
                                    placeholder="ระบุสถานะสมรส"
                                    value={optionsMarital.filter(
                                      (item) =>
                                        item.value === values.marital_status
                                    )}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    สถานะทางทหาร
                                  </Form.Label>
                                  <Select
                                    as="select"
                                    name="military_status"
                                    onChange={(e) => {
                                      setFieldValue("military_status", e.value);
                                    }}
                                    className="w-100 select-st-1"
                                    options={optionsMilitary}
                                    placeholder="ระบุสถานะทางทหาร"
                                    value={optionsMilitary.filter(
                                      (item) =>
                                        item.value === values.military_status
                                    )}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>

                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <button
                      type="button"
                      className="btn w-100 text-start btn-collapse"
                      onClick={() => setCardOpen2(!cardOpen2)}
                      aria-controls="collapse2"
                      aria-expanded={cardOpen2}
                    >
                      <div className="tc-8  fs-18 fw-bold px-3">
                        ข้อมูลการติดต่อ
                      </div>
                    </button>
                    <Collapse in={cardOpen2}>
                      <div id="collapse2">
                        <div>
                          <div className="pt-3 px-3">
                            <div className="row gx-xl-4">
                              <div className="col-xl-12 col-md-12">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ที่อยู่{" "}
                                    <span className="text-danger ms-1"> *</span>{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    placeholder="ที่อยู่"
                                    value={values.address}
                                    onChange={handleChange}
                                    isInvalid={!!errors.address}
                                  />
                                </Form.Group>
                              </div>

                              {/* <ThailandAddressTypeahead
                                value={address}
                                onValueChange={(val) => {
                                  setAddress(val);
  
                                  setFieldValue("subdistrict", val.subdistrict);
                                  setFieldValue("district", val.district);
                                  setFieldValue("province", val.province);
                                  setFieldValue("postalcode", val.postalCode);
                                }}
                              >
                                <div className="row gx-xl-4">
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        แขวง/ตำบล
                                      </Form.Label>
                                      <ThailandAddressTypeahead.SubdistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.subdistrict
                                            ? ""
                                            : !!errors.subdistrict
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก แขวง/ตำบล"
                                        name="subdistrict"
                                        value={values.subdistrict}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        อำเภอ/เขต
                                      </Form.Label>
                                      <ThailandAddressTypeahead.DistrictInput
                                        type="text"
                                        className={`form-control ${
                                          values.district
                                            ? ""
                                            : !!errors.district
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอก อำเภอ/เขต"
                                        name="district"
                                        value={values.district}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        จังหวัด
                                      </Form.Label>
                                      <ThailandAddressTypeahead.ProvinceInput
                                        type="text"
                                        className={`form-control ${
                                          values.province
                                            ? ""
                                            : !!errors.province
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกจังหวัด"
                                        name="province"
                                        value={values.province}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                      <Form.Label className="mb-1">
                                        รหัสไปรษณีย์
                                      </Form.Label>
                                      <ThailandAddressTypeahead.PostalCodeInput
                                        type="text"
                                        className={`form-control ${
                                          values.postalcode
                                            ? ""
                                            : !!errors.postalcode
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="กรอกรหัสไปรษณีย์"
                                        name="postalcode"
                                        value={values.postalcode}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                                <ThailandAddressTypeahead.Suggestion
                                  containerProps={{
                                    className: "suggestion-container",
                                  }}
                                  optionItemProps={{
                                    className: "suggestion-option",
                                  }}
                                />
                              </ThailandAddressTypeahead> */}

                              <div className="col-xxl-2 col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    E-mail
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    placeholder="E-mail"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    เบอร์โทรศัพท์
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mobile_number"
                                    className="form-control"
                                    placeholder="เบอร์โทรศัพท์"
                                    value={values.mobile_number}
                                    onChange={handleChange}
                                    isInvalid={!!errors.mobile_number}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xxl-2 col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">Line</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="line"
                                    className="form-control"
                                    placeholder="Line"
                                    value={values.line}
                                    onChange={handleChange}
                                    isInvalid={!!errors.line}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    Facebook
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="facebook"
                                    className="form-control"
                                    placeholder="Facebook"
                                    value={values.facebook}
                                    onChange={handleChange}
                                    isInvalid={!!errors.facebook}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xxl-2 col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    Instagam
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="ig"
                                    className="form-control"
                                    placeholder="Instagam"
                                    value={values.ig}
                                    onChange={handleChange}
                                    isInvalid={!!errors.ig}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-4 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    บุคคลติดต่อในกรณีฉุกเฉิน{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="contactor_name"
                                    className="form-control"
                                    placeholder="บุคคลติดต่อในกรณีฉุกเฉิน"
                                    value={values.contactor_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contactor_name}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-4 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    เบอร์โทรศัพท์{" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="contactor_number"
                                    className="form-control"
                                    placeholder="เบอร์โทรศัพท์"
                                    value={values.contactor_number}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contactor_number}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-4 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ความสัมพันธ์
                                  </Form.Label>
                                  <Select
                                    as="select"
                                    name="contactor_relation"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "contactor_relation",
                                        e.value
                                      );
                                    }}
                                    className="w-100 select-st-1"
                                    options={optionsRelationship}
                                    placeholder="ระบุความสัมพันธ์"
                                    value={optionsRelationship.filter(
                                      (item) =>
                                        item.value === values.contactor_relation
                                    )}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>

                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <button
                      type="button"
                      className="btn w-100 text-start btn-collapse"
                      onClick={() => setCardOpen3(!cardOpen3)}
                      aria-controls="collapse3"
                      aria-expanded={cardOpen3}
                    >
                      <div className="tc-8  fs-18 fw-bold px-3">
                        ข้อมูลการทำงาน
                      </div>
                    </button>
                    <Collapse in={cardOpen3}>
                      <div id="collapse3">
                        <div>
                          <div className="pt-3 px-3">
                            <div className="row gx-xl-4">
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    วันที่เริ่มทำงาน{" "}
                                  </Form.Label>
                                  <DatePicker
                                    // inputFormat="DD/MM/YYYY"
                                    inputFormat="YYYY/MM/DD"
                                    selected={startDate}
                                    onChange={(date) => {
                                      const localDate = new Date(
                                        date.getTime() -
                                          date.getTimezoneOffset() * 60000
                                      );
                                      const formattedDate = localDate
                                        .toISOString()
                                        .slice(0, 10);
                                      setFieldValue(
                                        "start_date",
                                        formattedDate
                                      );

                                      // setStartDate(date);
                                      // setFieldValue(
                                      //   "start_date",
                                      //   getCurrentDate("-", date)
                                      // );
                                    }}
                                    placeholder="YYYY/MM/DD"
                                    className="w-100 datepicker2"
                                    value={values.start_date}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    วันที่สิ้นสุด{" "}
                                  </Form.Label>
                                  <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    selected={startDate}
                                    onChange={(date) => {
                                      const localDate = new Date(
                                        date.getTime() -
                                          date.getTimezoneOffset() * 60000
                                      );
                                      const formattedDate = localDate
                                        .toISOString()
                                        .slice(0, 10);
                                      setFieldValue("end_date", formattedDate);
                                      // setStartDate(date);
                                      // setFieldValue(
                                      //   "end_date",
                                      //   getCurrentDate("-", date)
                                      // );
                                    }}
                                    placeholder="DD/MM/YYYY"
                                    className="w-100 datepicker2"
                                    disabled={!isResign}
                                    value={values.end_date}
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ประเภทพนักงาน
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Select
                                    name="type_id"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "type_id",
                                        e.target.value
                                      ).toString();
                                    }}
                                    placeholder="ประเภทพนักงาน"
                                    value={values.type_id}
                                    isInvalid={!!errors.type_id}
                                  >
                                    <option value="">เลือกประเภท</option>
                                    {optionsempType.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {errors.type_id ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.type_id}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    สถานะ
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Select
                                    name="status_id"
                                    onChange={(e) => {
                                      if (e.target.value == 3) {
                                        setIsResign(true);
                                      } else {
                                        setFieldValue("end_date", "");
                                        setIsResign(false);
                                      }
                                      setFieldValue(
                                        "status_id",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="สถานะ"
                                    value={values.status_id}
                                    isInvalid={!!errors.status_id}
                                  >
                                    <option value="">เลือกสถานะ</option>
                                    {optionsempStatus.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {errors.status_id ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.status_id}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    แผนก
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Select
                                    name="department_id"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "department_id",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="แผนก"
                                    value={values.department_id}
                                    isInvalid={!!errors.department_id}
                                  >
                                    <option value="">เลือกแผนก</option>
                                    {departments.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {errors.department_id ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.department_id}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-6">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    ตำแหน่ง
                                    <span className="text-danger ms-1"> *</span>
                                  </Form.Label>
                                  <Form.Select
                                    name="position_id"
                                    onChange={(e) => {
                                      // console.log("🚀 ~ EmpForm ~ e:", e.value)
                                      setFieldValue(
                                        "position_id",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="เลือกหมวดหมู่หลัก"
                                    value={values.position_id}
                                    isInvalid={!!errors.position_id}
                                  >
                                    <option value="">เลือกตำแหน่ง</option>
                                    {positions.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {errors.position_id ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.position_id}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-xl-6 col-md-12 align-self-center">
                                <Form.Check
                                  name="is_registed"
                                  type="checkbox"
                                  id="is_registed"
                                  label="ขึ้นทะเบียนประกันสังคม"
                                  className="form-check-st-1"
                                  checked={values.is_registed}
                                  onChange={(e) => {
                                    // if (e.target.checked === true) {
                                    //   setFieldValue("is_registed", 1);
                                    // } else {
                                    //   setFieldValue("is_registed", 0);
                                    // }
                                    setFieldValue(
                                      "is_registed",
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>

                              {/* <div className="col-12">
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                  <Form.Label className="mb-1">
                                    สาขาที่ประจำ
                                  </Form.Label>
                                  <Select
                                    as="select"
                                    isMulti
                                    name="empBranch"
                                    onChange={(e) => {
                                      handleChangeBranch(e);
                                    }}
                                    className="w-100 select-st-1 select-multi"
                                    options={optionsBranch}
                                    placeholder="สาขาที่ประจำ"
                                    value={optionsBranch.filter((i) =>
                                      branchselected.includes(i.value)
                                    )}
                                  />
                                </Form.Group>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>

                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <button
                      type="button"
                      className="btn w-100 text-start btn-collapse"
                      onClick={() => setCardOpen4(!cardOpen4)}
                      aria-controls="collapse4"
                      aria-expanded={cardOpen4}
                    >
                      <div className="tc-8  fs-18 fw-bold px-3">
                        ช่องทางการรับเงินเดือน
                      </div>
                    </button>
                    <Collapse in={cardOpen4}>
                      <div id="collapse4">
                        <div>
                          <div className="pt-3 px-3">
                            <Form.Label className="mb-2  tc-10">
                              ช่องทางรับเงินเดือน
                            </Form.Label>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="บัญชีธนาคาร"
                                  name="channel"
                                  type={type}
                                  id={`mn-${type}-21`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.channel == 1}
                                  onChange={(e) => {
                                    setFieldValue("channel", "1");
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="เงินสด"
                                  name="channel"
                                  type={type}
                                  id={`mn-${type}-22`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.channel == 2}
                                  onChange={(e) => {
                                    setFieldValue("channel", "2");
                                  }}
                                />
                              </div>
                            ))}

                            {values.channel == 1 ? (
                              <div className="row gx-xl-4">
                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      ธนาคาร
                                    </Form.Label>
                                    <Select
                                      as="select"
                                      name="contactor_bank_name"
                                      onChange={(e) => {
                                        console.log("🚀 ~ e:", e.label);
                                        setFieldValue(
                                          "contactor_bank_name",
                                          e.label
                                        );
                                      }}
                                      className="w-100 select-st-1"
                                      options={optionsBank}
                                      placeholder="ธนาคาร"
                                      value={optionsBank.filter(
                                        (item) =>
                                          item.label ===
                                          values.contactor_bank_name
                                      )}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      ประเภทบัญชี
                                    </Form.Label>
                                    <Select
                                      as="select"
                                      name="account_type"
                                      onChange={(e) => {
                                        setFieldValue("account_type", e.label);
                                      }}
                                      className="w-100 select-st-1"
                                      options={optionsaccountType}
                                      placeholder="ประเภทบัญชี"
                                      value={optionsaccountType.filter(
                                        (item) =>
                                          item.label === values.account_type
                                      )}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      ชื่อบัญชี
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="account_name"
                                      className="form-control"
                                      placeholder="ชื่อบัญชี"
                                      value={values.account_name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.account_name}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      เลขบัญชี
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="account_number"
                                      className="form-control"
                                      placeholder="เลขบัญชี"
                                      value={values.account_number}
                                      onChange={handleChange}
                                      isInvalid={!!errors.account_number}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      ชื่อสาขา (ถ้ามี)
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="account_office_name"
                                      className="form-control"
                                      placeholder="กรอกชื่อสาขา"
                                      value={values.account_office_name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.account_office_name}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      เลขที่สาขา (ถ้ามี)
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="account_office_number"
                                      className="form-control"
                                      placeholder="กรอกเลขที่สาขา"
                                      value={values.account_office_number}
                                      onChange={handleChange}
                                      isInvalid={!!errors.account_office_number}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                {!empID && (
                  <div className="card card-st-3 mb-3">
                    <div className="card-body px-xl-3">
                      <button
                        type="button"
                        className="btn w-100 text-start btn-collapse"
                        onClick={() => setCardOpen5(!cardOpen5)}
                        aria-controls="collapse5"
                        aria-expanded={cardOpen5}
                      >
                        <div className="tc-8  fs-18 fw-bold px-3">
                          ข้อมูลการเข้าสู่ระบบ
                        </div>
                      </button>
                      <Collapse in={cardOpen5}>
                        <div id="collapse5">
                          <fieldset>
                            <div className="pt-3 px-3">
                              <div className="row gx-xl-4">
                                <div className="col-xl-3 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      Username{" "}
                                      <span className="text-danger ms-1">
                                        {" "}
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="user_name"
                                      className="form-control"
                                      placeholder="Username"
                                      value={values.user_name}
                                      onChange={handleChange}
                                      isInvalid={!!errors.user_name}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      Password{" "}
                                      <span className="text-danger ms-1">
                                        {" "}
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="password"
                                      name="password"
                                      className="form-control"
                                      placeholder="Password"
                                      value={values.password}
                                      onChange={handleChange}
                                      isInvalid={!!errors.password}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      Confirm Password{" "}
                                      <span className="text-danger ms-1">
                                        {" "}
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="password"
                                      name="confirmPassword"
                                      className="form-control"
                                      placeholder="Confirm Password"
                                      value={values.confirmPassword}
                                      onChange={handleChange}
                                      isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                )}
              </fieldset>

              {(empID && values.status_id != 3) ? (
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3 text-end">
                    <button
                      type="button"
                      className="btn  btn-st-3"
                      onClick={() => setModalconfirmcancelemp(true)}
                      style={{ background: "#F95057", borderColor: "#F95057" }}
                    >
                      พนักงานลาออก
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="text-end pb-4 pe-3">
                {formdisabled === true ? (
                  <Button
                    type="button"
                    className="btn-st-3 mx-3"
                    onClick={(e) => {
                      setFormdisabled(false);
                    }}
                  >
                    แก้ไข
                  </Button>
                ) : (
                  <div className="text-end pt-3 btn-group-control">
                    <Button
                      type="button"
                      className="btn-st-6 me-3 btn"
                      onClick={() => setModalcancel(true)}
                    >
                      ยกเลิก
                    </Button>
                    <Button type="submit" className="btn-st-3 ">
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>

              <ConfirmCancelempModal
                show={modalconfirmcancelemp}
                onHide={() => setModalconfirmcancelemp(false)}
                onSuccess={() => setModalsuccess(true)}
                formdata={formdata}
                empID={empID}
                setTextsuccess={setTextsuccess}
                setFieldValue={setFieldValue}
                token={token}
                businessId={user.businessId}
                updated_by={user.id}
              />
              {/* / div for show error validate / */}
            </Form>
          )}
        </Formik>
      ) : (
        <MoonLoader
          color="#FFFFFF"
          loading={true}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}

      <ConfirmCancelModal
        show={modalcancel}
        onHide={() => setModalcancel(false)}
      />
      <SuccessModal
        show={modalsuccess}
        onHide={() => setModalsuccess(false)}
        textsuccess={textsuccess}
      />
    </div>
  );
}
