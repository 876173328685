import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Form from 'react-bootstrap/Form'
import { utils, writeFileXLSX } from 'xlsx'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import 'react-datepicker/dist/react-datepicker.css'

import { Category } from './Category'
import { useRecoilValue } from 'recoil'
import { authState } from '../../state/auth'
import useToken from '../../utils/UseToken'

export function SubCategoryDataList () {
    const PRODUCT_SUBCATEGORY_URL = `${process.env.REACT_APP_API_URL}/business-product-subcategory`

    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const [subcategorydata, setSubCategorydata] = useState([])
    const [modalcategoryShow, setModalcategoryShow] = useState(false)
    const [modaleditcategoryShow, setModaleditcategoryShow] = useState(false)
    const [modalcategoryDeleteShow, setModalcategoryDeleteShow] = useState(false)
    const [modalsuccessShow, setModalsuccessShow] = useState(false)
    const [categoryeditdata, setCategoryeditdata] = useState({})
    const [onSuccess, setOnSuccess] = useState(false)

    const getcategoryData = () => {

        fetch('/json/subcategory.json')
            .then((response) => response.json())
            .then((result) => setSubCategorydata(result))
            .catch((error) => console.log('error', error))
    }

    const getProductSubcategoryData = async () => {
        try {
            const res = await fetch(PRODUCT_SUBCATEGORY_URL, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                })
            })
            const data = await res.json()
            console.log('sub cat data:', data)
            setSubCategorydata(data)
            setOnSuccess(false)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    useEffect(() => {
        getProductSubcategoryData()
        // getcategoryData()
    }, [onSuccess])

    console.log('subCategoryData:', subcategorydata)

    const columns = [
        {
            name: 'รหัสหมวดหมู่ย่อย',
            selector: row => row.code,
        },
        {
            name: 'ชื่อหมวดหมู่ย่อย',
            selector: row => row.name,
            cell: (row, index, column, id) => <div
                style={{ color: '#565DFF', fontWeight: 500 }}>{row.name}</div>,
        },
        {
            name: 'ชื่อหมวดหมู่หลัก',
            selector: row => row.category_name,
        },
        {
            name: 'จำนวนรายการ',
            selector: row => row.product_count,
            center: 'true',
        },
        {
            name: 'สถานะ',
            selector: row => row.is_active,
            center: 'true',
            cell: (row, index, column, id) => <div className={`category-status status${row.is_active === true ? '1' : '0'}`} />,
        },
        {
            name: 'จัดการ',
            selector: row => row.id,
            center: 'true',
            cell: (row, index, column, id) =>
                <div>
                    <Button
                        type="button"
                        className="btn-edit-st-1 "
                        onClick={() => {
                            setCategoryeditdata(row)
                            setModaleditcategoryShow(true)
                        }}
                    />
                </div>,
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: '52px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                background: ' #F5F5F7',
                paddingTop: '15px',
                paddingBottom: '15px',
            },
        },
        cells: {
            style: {
                paddingLeft: '15px',
                paddingRight: '15px',
                color: 'rgba(76, 78, 100, 0.87)',
                fontFamily: 'Sarabun',
                fontSize: '18px',
                fontWeight: '300',
            },
        },
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'จำนวนรายการต่อหน้า',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const [filterText, setFilterText] = useState('')
    let filteredItems;
    if (subcategorydata !== undefined && Array.isArray(subcategorydata)) {
        filteredItems = subcategorydata.filter((item) => {
            const matchCode = item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
            const matchName = item.code && item.code.toLowerCase().includes(filterText.toLowerCase());

            return matchName || matchCode;
        })
    }

    const downloadExcel = () => {
        const ws = utils.json_to_sheet(filteredItems)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'รายชื่อสาขา')
        writeFileXLSX(wb, 'รายชื่อสาขา.xlsx')
    }

    return (
        <div>
            <Category/>
            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className="d-flex align-items-center px-4 mb-4">
                        <button className="btn btn-export-st-1 me-auto" onClick={downloadExcel}>Export</button>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control form-st-4"
                                placeholder="ค้นหาชื่อหมวดหมู่ย่อย"
                                onKeyUp={e => setFilterText(e.target.value)}
                            />
                        </div>
                        <Button
                            type="button"
                            className="btn btn btn-st-7"
                            onClick={() => setModalcategoryShow(true)}
                        >
                            เพิ่มหมวดหมู่ย่อย
                        </Button>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>
            </div>
            <CategoryModal
                show={modalcategoryShow}
                onHide={() => setModalcategoryShow(false)}
                onSuccess={() => setOnSuccess(true)}
            />
            <CategoryEditModal
                show={modaleditcategoryShow}
                onHide={() => setModaleditcategoryShow(false)}
                onDelete={() => setModalcategoryDeleteShow(true)}
                categoryeditdata={categoryeditdata}
                onSuccess={() => setOnSuccess(true)}
            />
            <ConfirmDeleteModal
                show={modalcategoryDeleteShow}
                onHide={() => setModalcategoryDeleteShow(false)}
                // onConfirm={() => setModalcategoryDeleteShow(false)}
                onSuccess={() => {
                    setModalcategoryDeleteShow(false)
                    setModalsuccessShow(true)
                    setOnSuccess(true)
                }}
                categoryeditdata={categoryeditdata}
            />
            <SuccessDeleteModal
                show={modalsuccessShow}
                onHide={() => setModalsuccessShow(false)}
            />
        </div>
    )
}

function CategoryModal (props) {
    const productSubcategoryURL = `${process.env.REACT_APP_API_URL}/business-product-subcategory`;
    const PRODUCT_CATEGORY_URL = `${process.env.REACT_APP_API_URL}/business-product-category`

    const { user } = useRecoilValue(authState)
    const { token} = useToken()

    const schema = yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
        is_show: yup.boolean().required(),
        is_active: yup.boolean().required(),
        product_category_id: yup.number().required(),
    })

    const [categorydata, setCategorydata] = useState(
        {
            'code': '',
            'product_category_id': '',
            'name': '',
            'is_show': true,
            'is_active': true,
            'business_id': user.businessId,
            'created_by': user.accountId,
            'creator_name': `${user.firstNameEN} ${user.lastNameEN}`,
            'created_at': new Date(),
        },
    )

    const [optionsCategory, setOptionsCategory] = useState([])

    const getProductCategoryData = async () => {
        try {
            const res = await fetch(PRODUCT_CATEGORY_URL, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                })
            })
            const data = await res.json()
            const options = []
            for (let i = 0; i < data.length; i++) {
                let obj = {}
                obj['value'] = data[i].id
                obj['label'] = data[i].name
                options.push(obj)
            }
            setOptionsCategory(options)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const postNewSubcategory = async (values) => {
        console.log('Create submitted!:', values)

        try {
            const res = await fetch(productSubcategoryURL, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return res.text()
        } catch (error) {
            console.log('Failed to post data:', error)
        }
    }

    useEffect(() => {
        getProductCategoryData()
    }, [])

    return (
        <Modal
            {...props}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">จัดการหมวดหมู่ย่อย</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        enableReinitialize
                        initialValues={categorydata}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            postNewSubcategory({
                                ...values,
                                created_at: `${values.created_at.toISOString()}`,
                                updated_at: `${values.created_at.toISOString()}`,
                            })
                                .then(() => props.onHide())
                                .then(() => props.onSuccess())
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                        รหัสหมวดหมู่ย่อย <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        className="form-control"
                                        placeholder="รหัสหมวดหมู่ย่อย"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label
                                        className="mb-1"
                                    >
                                        เลือกหมวดหมู่หลัก (ประเภท)
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Select
                                        as="select"
                                        name="product_category_id"
                                        onChange={(e) => {
                                            setFieldValue('product_category_id', e.value)
                                        }}
                                        value={optionsCategory.filter(item =>
                                            item.value === values.product_category_id,
                                        )}
                                        className="w-100 select-st-1"
                                        options={optionsCategory}
                                        placeholder="เลือกหมวดหมู่"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                        ชื่อหมวดหมู่ย่อย
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="ชื่อหมวดหมู่ย่อย"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                </Form.Group>
                                <div>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="แสดงหน้าจอลูกค้า"
                                        name="displayCustomer"
                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                        defaultChecked={values.is_show}
                                        onChange={(e) => {
                                            if (e.target.checked === true) {
                                                setFieldValue('is_show', true)
                                            } else {
                                                setFieldValue('is_show', false)
                                            }
                                        }}
                                    />
                                </div>
                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type === true ? '1' : '0'}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="เปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-21`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === true}
                                            onChange={(e) => {
                                                setFieldValue('is_active', true)
                                            }}
                                        />
                                        <Form.Check
                                            inline
                                            label="ปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-22`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === false}
                                            onChange={(e) => {
                                                setFieldValue('is_active', false)
                                            }}
                                        />
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">วันที่สร้าง </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="created_at"
                                                className="form-control"
                                                placeholder="วันที่สร้าง"
                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                onChange={handleChange}
                                                isInvalid={!!errors.created_at}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">ชื่อผู้สร้าง </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="creator_name"
                                                className="form-control"
                                                placeholder="ชื่อผู้สร้าง"
                                                value={values.creator_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.creator_name}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="text-end pt-2 pb-3 btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-6 me-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btn-st-3  "
                                        style={{ width: '100px' }}
                                    >
                                        เพิ่ม
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function CategoryEditModal (props) {
    const productSubcategoryURL = `${process.env.REACT_APP_API_URL}/business-product-subcategory`;
    const PRODUCT_CATEGORY_URL = `${process.env.REACT_APP_API_URL}/business-product-category`

    const { user } = useRecoilValue(authState)
    const { token} = useToken()

    const [optionsCategory, setOptionsCategory] = useState([])

    const schema = yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
        is_show: yup.boolean().required(),
        is_active: yup.boolean().required(),
        product_category_id: yup.number().required(),
    })

    const getProductCategoryData = async () => {
        try {
            const res = await fetch(PRODUCT_CATEGORY_URL, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'business-id': user.businessId,
                })
            })
            const data = await res.json()
            const options = []
            for (let i = 0; i < data.length; i++) {
                let obj = {}
                obj['value'] = data[i].id
                obj['label'] = data[i].name
                options.push(obj)
            }
            setOptionsCategory(options)
        } catch (error) {
            console.log('Failed to get data:', error)
        }
    }

    const patchSubCategory = async (values) => {
        console.log('Update submitted!:', values)

        try {
            const res = await fetch(`${productSubcategoryURL}/${props.categoryeditdata.id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            return { data: res.text(), status: res.status }
        } catch (error) {
            console.log('Failed to patch data:', error)
        }
    }

    useEffect(() => {
        getProductCategoryData()
    }, [])

    return (
        <Modal
            {...props}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">จัดการหมวดหมู่หลัก</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        enableReinitialize
                        initialValues={props.categoryeditdata}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            const updateCategory = await patchSubCategory(values);
                            if (updateCategory.status === 200) {
                                props.onHide()
                                props.onSuccess()
                            }
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">รหัสหมวดหมู่ย่อย <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        className="form-control"
                                        placeholder="รหัสหมวดหมู่ย่อย"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                        เลือกหมวดหมู่หลัก (ประเภท)
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Select
                                        as="select"
                                        name="product_category_id"
                                        onChange={(e) => {
                                            setFieldValue('product_category_id', e.value)
                                        }}
                                        value={
                                            optionsCategory.filter(
                                                item => item.value === values.product_category_id,
                                            )
                                        }
                                        className="w-100 select-st-1"
                                        options={optionsCategory}
                                        placeholder="เลือกหมวดหมู่"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                        ชื่อหมวดหมู่ย่อย
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="ชื่อหมวดหมู่ย่อย"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                </Form.Group>
                                <div>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="แสดงหน้าจอลูกค้า"
                                        name="is_show"
                                        className="custom-switch-st-1 tc-10 s2 mb-3"
                                        defaultChecked={values.is_show}
                                        onChange={(e) => {
                                            if (e.target.checked === true) {
                                                setFieldValue('is_show', 1)
                                            } else {
                                                setFieldValue('is_show', 0)
                                            }
                                        }}
                                    />
                                </div>
                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type === true ? '1' : '0'}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="เปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-21`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === true}
                                            onChange={(e) => {
                                                setFieldValue('is_active', true)
                                            }}
                                        />
                                        <Form.Check
                                            inline
                                            label="ปิดใช้งาน"
                                            name="is_active"
                                            type={type}
                                            id={`inline-${type}-22`}
                                            className="form-check-st-2 tc-7"
                                            checked={values.is_active === false}
                                            onChange={(e) => {
                                                setFieldValue('is_active', false)
                                            }}
                                        />
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="created_at"
                                                className="form-control"
                                                placeholder="วันที่สร้าง"
                                                value={getCurrentDate('/', new Date(values.created_at))}
                                                onChange={handleChange}
                                                isInvalid={!!errors.created_at}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-6">
                                        <Form.Group className="mb-3 form-st-3 pb-1 ">
                                            <Form.Label className="mb-1">ชื่อผู้แก้ไข</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="creator_name"
                                                className="form-control"
                                                placeholder="กรอกชื่อผู้แก้ไข"
                                                value={values.creator_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.creator_name}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-5 me-auto "
                                        style={{ width: '80px' }}
                                        onClick={() => {
                                            props.onDelete()
                                            props.onHide()
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn-st-6 me-3"
                                        style={{ width: '100px' }}
                                        nClick={props.onHide}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btn-st-3  "
                                        style={{ width: '100px' }}
                                    >
                                        อัพเดท
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}

function ConfirmDeleteModal (props) {
    const { onSuccess, onHide, ...rest } = props

    const OPTION_EDIT_URL = `${process.env.REACT_APP_API_URL}/delete-product-subcategory`

    const { user } = useRecoilValue(authState)
    const { token } = useToken()

    const deleteCategory = async (values) => {
        console.log('Delete option submitted!:', values)

        try {
            const res = await fetch(`${OPTION_EDIT_URL}/${values.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(values),
            })
            // return { data: res.text(), status: res.status }
            console.log('deleted! status: ', res.status)
            return res.text()
        } catch (error) {
            console.log('Failed to delete data:', error)
        }
    }

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="px-3 py-4">
                    {
                        (props.categoryeditdata && props.categoryeditdata.productQty !== undefined && props.categoryeditdata.productQty > 0) ? (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">ขออภัยไม่สามารถลบได้</div>
                                    <div className="text-center fs-22 tc-7">เนื่องจากมีสินค้าอยู่ในตัวเลือกมาตราฐาน
                                    </div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ตกลง
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
                                    <div className="text-center fs-22 tc-7">ตัวเลือกมาตราฐานนี้ใช่หรือไม่</div>
                                </div>
                                <div className="text-center pt-4 mt-3  btn-group-control">
                                    <Button
                                        type="button"
                                        className="btn-st-3 mx-3"
                                        style={{ width: '100px' }}
                                        onClick={async () => {
                                            // handleDelete(props.optiondata.optionID)
                                            deleteCategory(props.categoryeditdata)
                                            // if (deleteCategoryResponse.status === 200) {
                                            props.onHide()
                                            props.onSuccess()
                                            // }
                                        }}
                                    >
                                        ใช่
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn-st-6 mx-3 "
                                        style={{ width: '100px' }}
                                        onClick={props.onHide}
                                    >
                                        ไม่ใช่
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

function SuccessDeleteModal (props) {
    return (
        <Modal
            {...props}
            size="sm"
            centered
        >
            <Modal.Body>
                <div className="px-3 py-4">
                    <div className="text-center mb-4">
                        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                            <path
                                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                                stroke="white"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="text-center fs-22 tc-7">ลบรายการสินค้าสำเร็จ</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
        <>
            <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '18px', width: '18px' }}
                    ref={ref}
                    onClick={onClick}
                    {...rest}
                />
                <label className="form-check-label" id="booty-check"/>
            </div>
        </>
    )
})

function getCurrentDate (separator = '', dateb = new Date()) {

    let newDate = dateb
    let date = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}