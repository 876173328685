import React from 'react'
import { Outlet } from 'react-router-dom'

import '../styles/sidemenu.css'
import '../styles/style.css'

import { Header } from './header'
import { NavBar } from './Nav'

export function Layout ({ selectbranchID, setSelectBranchID }) {
    return (
        <div className="page">
            <Header selectbranchID={selectbranchID}/>
            <div className="main-content app-content">
                <NavBar setSelectBranchID={setSelectBranchID} selectbranchID={selectbranchID}/>
                <div className="container-fluid">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}