import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAllBranchExcludeKitchenProduct = (branchId, kitchenId, config) =>
  axiosClient.get(
    `/branch-exclude-kitchen-product/${branchId}/${kitchenId}`,
    config
  );

const CreateBranchKitchensProduct = (branchId, kitchenId, data, config) =>
  axiosClient.post(
    `/branch-kitchen-product/${branchId}/${kitchenId}`,
    data,
    config
  );

const DeleteBranchKitchensProduct = (
  branchId,
  kitchenId,
  branchProductId,
  config
) => axiosClient.delete(`/branch-kitchen-product/${branchId}/${kitchenId}/${branchProductId}`, config);

export default {
  GetAllBranchExcludeKitchenProduct,
  CreateBranchKitchensProduct,
  DeleteBranchKitchensProduct
};
