import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetAllProductCategories = (branchId, kitchenId, config) =>
  axiosClient.get(
    `/branch-kitchen-product-category/${branchId}/${kitchenId}`,
    config
  );

const GetAllSubCategories = (config) =>
  axiosClient.get(`/business-product-subcategory`, config);

const GetAllProducts = (branchId, kitchenId, config) =>
  axiosClient.get(`/branch-kitchen-product/${branchId}/${kitchenId}`, config);

export default {
  GetAllProductCategories,
  GetAllSubCategories,
  GetAllProducts,
};
