import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import DataTableLayout from "layouts/DatatableLayout";

export default function KitchenCategoryModal({ handleSubmit, ...props }) {
  const [subcategorySelected, setSubcategorySelected] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [subcategory, setSubcategory] = useState([
  //   {
  //     categoryID: 1,
  //     categoryName: "ราดข้าว",
  //     subcategoryID: 1,
  //     subcategoryName: "กระเพรา",
  //     productQty: 23,
  //   },
  //   {
  //     categoryID: 1,
  //     categoryName: "ราดข้าว",
  //     subcategoryID: 2,
  //     subcategoryName: "กระเพรา",
  //     productQty: 23,
  //   },
  //   {
  //     categoryID: 1,
  //     categoryName: "ราดข้าว",
  //     subcategoryID: 3,
  //     subcategoryName: "กระเพรา",
  //     productQty: 23,
  //   },
  //   {
  //     categoryID: 1,
  //     categoryName: "ราดข้าว",
  //     subcategoryID: 4,
  //     subcategoryName: "กระเพรา",
  //     productQty: 23,
  //   },
  //   {
  //     categoryID: 1,
  //     categoryName: "ราดข้าว",
  //     subcategoryID: 5,
  //     subcategoryName: "กระเพรา",
  //     productQty: 23,
  //   },
  // ]);

  const columns = [
    {
      name: "หมวดหมู่ย่อย",
      selector: (row) => row.name,
    },
    {
      name: "จำนวนรายการ",
      selector: (row) => row.product_count,
    },
  ];

  const [filterText, setFilterText] = useState("");

  // filter subcategory by category and filterText filter together
  const filteredItems = props.subcategory?.filter(
    (item) =>
      item.category_name &&
      item.category_name.toLowerCase().includes(selectedCategory.toLowerCase()) &&
      item.name &&
      item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleRowSelected = React.useCallback((state) => {
    setSubcategorySelected(state.selectedRows);
  });

  const formatOption = (data) => {
    const options = [];
    const initialObj = {
      label: "ทั้งหมด",
      value: "",
    };
    options.push(initialObj);
    // check duplicate product_category_id and remove
    const unique = [...new Map(data.map((item) => [item["product_category_id"], item])).values()];
    unique.map((item) => {
      const obj = {};
      obj["value"] = item.product_category_id;
      obj["label"] = item.category_name;
      options.push(obj);
    });

    return options; 
  };

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Body>
        <div className="d-flex align-items-start  mb-0">
          <div className="fs-20 tc-8 me-auto mt-2">ค้นหารายการ</div>
          <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
            <Button
              type="button"
              className="btn-st-5 me-auto "
              style={{ width: "80px" }}
              onClick={() => {
                props.onHide();
                setSubcategorySelected(null);
                setFilterText("");
                setSelectedCategory("");
              }}
            >
              ยกเลิก
            </Button>
            <div className="bx-count mx-3">
              เลือกไว้ {subcategorySelected ? subcategorySelected.length : 0}{" "}
              รายการ
            </div>

            <Button
              type="submit"
              className="btn-st-3  "
              style={{ width: "130px" }}
              disabled={!subcategorySelected || subcategorySelected.length == 0}
              onClick={() => handleSubmit(subcategorySelected)}
            >
              เพิ่มรายการ
            </Button>
          </div>
        </div>
        <div
          className="fs-18 fw-6  mb-3 d-inline-block text-white"
          style={{
            borderRadius: "8px",
            background: "#565DFF",
            padding: "6.5px 25px",
          }}
        >
          หมวดหมู่
        </div>
        <div className="d-flex align-items-center  ">
          <div className="me-auto" style={{ flex: "0 0 220px" }}>
            {/* <SelectCategory setFilterText={setFilterText} /> */}
            <Select
              as="select"
              name="caregory"
              onChange={(e) => {
                e.label !== "ทั้งหมด"
                  ? setSelectedCategory(e.label)
                  : setSelectedCategory("");
              }}
              className="w-100 select-st-1 pd"
              options={formatOption(props.subcategory)}
              placeholder="เลือกหมวดหมู่"
            />
          </div>
          <div className="" style={{ flex: "0 0 220px" }}>
            <input
              type="text"
              className="form-control form-st-4"
              placeholder="ค้นหาหมวดหมู่"
              // value={filterText}
              onKeyUp={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <DataTableLayout
        item={filteredItems}
        columns={columns}
        isSelectRows={true}
        PerPage={5}
        handleRowSelected={handleRowSelected}
      />
    </Modal>
  );
}
