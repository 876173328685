import axios from "axios";

const axiosClient=axios.create({
    baseURL:process.env.REACT_APP_API_URL,
})

const CreateBranchKitchens=(branchId, data, config)=>axiosClient.post(`/branch-kitchen/${branchId}`, data, config)

const GetBranchKitchens=(branchId, config)=>axiosClient.get(`/branch-kitchen/${branchId}`,config)

const GetBranchKitchenById=(branchId, kitchenId, config)=>axiosClient.get(`/branch-kitchen/${branchId}/${kitchenId}`,config)

const DeleteBranchKitchens=(branchId, kitchenId, config)=>axiosClient.delete(`/branch-kitchen/${branchId}/${kitchenId}`,config)

const UpdateBranchKitchens=(branchId, kitchenId, data, config)=>axiosClient.patch(`/branch-kitchen/${branchId}/${kitchenId}`,data,config)

export default {
    GetBranchKitchens,
    CreateBranchKitchens,
    DeleteBranchKitchens,
    GetBranchKitchenById,
    UpdateBranchKitchens
}