import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  redirect,
  useNavigate,
  useParams,
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import getCurrentDate from "utils/CurrentDate";
import DownloadExcel from "utils/DownloadExcel";
import DataTableLayout from "layouts/DatatableLayout";
import SelectStoreHouse from "fragments/SelectStoreHouse";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { authState } from "../state/auth";
import { appState } from "../state/app";
import {
  ThailandAddressTypeahead,
  ThailandAddressValue,
} from "react-thailand-address-typeahead";
import { formatDateToYMD } from "../utils/dateFormat";
import BranchApi from "services/branch/BranchApi";

export function BranchDataList() {
  const BranchDataURL = `${process.env.REACT_APP_API_URL}/branch-info`;

  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const [branchData, setBranchData] = useState([]);
  const [filterText, setFilterText] = useState("");

  const getBranchData = async (auth, businessID) => {
    try {
      const res = await fetch(BranchDataURL, {
        headers: new Headers({
          Authorization: "Bearer " + auth,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": businessID,
        }),
      });
      const data = await res.json();
      setBranchData(data);
    } catch (error) {
      console.log("Failed to get data!", error);
    }
  };

  useEffect(() => {
    if (user) {
      getBranchData(token, user.businessId);
    }
    // getBranchData(token)
  }, [token, user.businessId]);

  const columns = [
    {
      name: "รหัสสาขา",
      selector: (row) => row.code,
    },
    {
      name: "ชื่อสาขา",
      selector: (row) => row.name,
    },
    // {
    //     name: 'คลังสินค้าที่เชื่อม',
    //     selector: row => row.storeHouse,
    // },
    {
      name: "วันที่สร้่าง",
      selector: (row) => getCurrentDate("/", new Date(row.created_at)),
      // selector: row => row.created_at,
    },
    {
      name: "จำนวน POS",
      selector: (row) => row.pos,
      center: "true",
    },
    {
      name: "จัดการ",
      selector: (row) => row.id,
      center: "true",
      cell: (row, index, column, id) => (
        <div>
          <Link
            className="btn btn-edit-st-1 me-2"
            to={`/${slug}/branch/edit/${row.id}`}
          ></Link>
          <Link
            className="btn btn-shop-st-1"
            to={`/${slug}/branch/setting/general/${row.id}`}
          ></Link>
        </div>
      ),
    },
  ];

  let filteredItems = [];
  if (Array.isArray(branchData) && branchData.length > 0) {
    filteredItems = branchData.filter((item) => {
      const matchName =
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
      const matchCode =
        item.code && item.code.toLowerCase().includes(filterText.toLowerCase());
      return matchName || matchCode;
    });
  }

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            รายชื่อสาขา
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">รายชื่อสาขา</div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <DownloadExcel data={filteredItems} filename="รายชื่อสาขา" />
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหา ชื่อสาขา, คลังสินค้าที่เชื่อม"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Link to={`/${slug}/branch/create`} className="btn btn btn-st-7">
              เพิ่มสาขา
            </Link>
          </div>
          <DataTableLayout
            item={filteredItems}
            columns={columns}
            isSelectRows={true}
            PerPage={5}
          />
        </div>
      </div>
    </div>
  );
}

export function BranchCreate() {
  const BusinessURL = `${process.env.REACT_APP_API_URL}/business-info`;
  const BranchURL = `${process.env.REACT_APP_API_URL}/branch-info`;
  const UploadImageFileURL = `${process.env.REACT_APP_API_URL}/branch-info/branch-image`;

  const { branchID } = useParams();
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const schema = yup.object().shape({
    img_url: yup.string().required(),
    business_name: yup.string().required(),
    name: yup.string().required(),
    code: yup.string().required(),
    address: yup.string().required(),
    created_at: yup.string().required(),
    phone_number: yup.string().required(),
    mobile_number: yup.string().required(),
    subdistrict: yup.string().required(),
    district: yup.string().required(),
    province: yup.string().required(),
    zipcode: yup.string().required(),
    is_active: yup.boolean().required(),
    location: yup.string().required(),
    vat_code: yup.string().required(),
  });

  const [businessInfo, setBusinessInfo] = useState([]);

  const [businessData, setBusinessData] = useState({
    img_url: "",
    business_name: "",
    name: "",
    code: "",
    address: "",
    created_at: getCurrentDate("/"),
    province: "",
    district: "",
    subdistrict: "",
    zipcode: "",
    phone_number: "",
    mobile_number: "",
    is_active: true,
    location: "",
    vat_code: "",
  });

  const [formdisabled, setFormdisabled] = useState(false);

  const [posShow, setPosShow] = useState(false);
  const [modalposShow, setModalposShow] = useState(false);
  const [modalposEditShow, setModalposEditShow] = useState(false);
  const [modalposDeleteShow, setModalposDeleteShow] = useState(false);
  const [branchId, setBranchId] = useState();

  const [posFormData, setPosFormData] = useState({
    created_at: getCurrentDate("/"),
    code: "",
    name: "",
    status_id: 2,
    is_registed: false,
    revenue_number: "",
    allowed_at: "",
    is_active: true,
  });

  const [posData, setPosData] = useState([]);
  const [branchCount, setBranchCount] = useState(0);
  const [address, setAddress] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  const uploadImageFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(UploadImageFileURL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "business-id": user.businessId,
        }),
        body: formData,
      });

      return await response.text();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const getBusinessInfo = async () => {
    try {
      const res = await fetch(BusinessURL, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      setBusinessInfo(data);
      console.log("Business Info:", data);
    } catch (error) {
      console.log("Failed to get business data:", error);
    }
  };

  const createBranch = async (values) => {
    try {
      console.log("Submit create branch data:", values);

      const res = await fetch(BranchURL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(values),
      });
      const data = await res.json();
      setBranchId(data.branch_id);
      return { status: res.status, branchId: data.branch_id };
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const getBranchData = async () => {
    BranchApi.GetAllBranchByBussiness(config).then((res) => {
      setBranchCount(res.data.length);
    });
  };

  console.log("branch id:", branchId);

  useEffect(() => {
    getBusinessInfo();
  }, []);

  useEffect(() => {
    if (businessInfo.length > 0) {
      setBusinessData((prev) => ({
        ...prev,
        business_name: businessInfo[0].name,
      }));
    }
  }, [businessInfo]);

  useEffect(() => {
    if (user.businessId && token) {
      getBranchData();
    }
  }, [user.businessId, token]);

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            สร้างสาขา
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">สร้างสาขา</div>
      <div className="card card-st-1 mb-4">
        <div className="card-body">
          <div className="tc-8 fs-18 fw-bold px-3">ข้อมูลสาขา</div>
          <Formik
            enableReinitialize
            initialValues={businessData}
            validationSchema={schema}
            onSubmit={async (values) => {
              const count = (branchCount + 1).toString().padStart(2, "0");
              const createNewBranch = await createBranch({
                ...values,
                zipcode: parseInt(values.zipcode),
                slug: `B${count}`,
              });
              if (createNewBranch.status === 201) {
                setFormdisabled(true);
                navigate(`/${slug}/branch/edit/${createNewBranch.branchId}`);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <fieldset disabled={formdisabled}>
                  <div className="card-body px-xl-3">
                    <div>
                      <ImageUpload
                        setFieldValue={setFieldValue}
                        values={values}
                        isBusinessEdit={true}
                        uploadImageFile={uploadImageFile}
                      />
                      <div className="pt-3">
                        <div className="row gx-xl-4">
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อกิจการ
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="business_name"
                                className="form-control"
                                placeholder="ชื่อกิจการ"
                                value={values.business_name}
                                onChange={handleChange}
                                isInvalid={!!errors.business_name}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อสาขา{" "}
                                <span className="text-danger ms-1"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="กรอกชื่อสาขา"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-2">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                รหัสสาขา{" "}
                                <span className="text-danger ms-1"> *</span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="code"
                                className="form-control"
                                placeholder="กรอกรหัสสาขา"
                                value={values.code}
                                onChange={handleChange}
                                isInvalid={!!errors.code}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-2">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                VAT CODE
                                <span className="text-danger ms-1">
                                  {" "}
                                  *
                                </span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="vat_code"
                                className="form-control"
                                placeholder="กรอก VAT Code"
                                value={values.vat_code}
                                onChange={handleChange}
                                isInvalid={!!errors.vat_code}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-12">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">ที่อยู่</Form.Label>
                              <Form.Control
                                type="text"
                                name="address"
                                className="form-control"
                                placeholder="กรอกที่อยู่"
                                value={values.address}
                                onChange={handleChange}
                                isInvalid={!!errors.address}
                              />
                            </Form.Group>
                          </div>
                          <ThailandAddressTypeahead
                            value={address}
                            onValueChange={(val) => {
                              setAddress(val);
                              setFieldValue("subdistrict", val.subdistrict);
                              setFieldValue("district", val.district);
                              setFieldValue("province", val.province);
                              setFieldValue("zipcode", val.postalCode);
                            }}
                          >
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  จังหวัด
                                </Form.Label>
                                <ThailandAddressTypeahead.ProvinceInput
                                  type="text"
                                  className={`form-control ${
                                    values.province
                                      ? ""
                                      : !!errors.province
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอกจังหวัด"
                                  name="province"
                                  value={values.province}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  อำเภอ/เขต
                                </Form.Label>
                                <ThailandAddressTypeahead.DistrictInput
                                  type="text"
                                  className={`form-control ${
                                    values.district
                                      ? ""
                                      : !!errors.district
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอก อำเภอ/เขต"
                                  name="district"
                                  value={values.district}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  แขวง/ตำบล
                                </Form.Label>
                                <ThailandAddressTypeahead.SubdistrictInput
                                  type="text"
                                  className={`form-control ${
                                    values.subdistrict
                                      ? ""
                                      : !!errors.subdistrict
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอก แขวง/ตำบล"
                                  name="subdistrict"
                                  value={values.subdistrict}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  รหัสไปรษณีย์
                                </Form.Label>
                                <ThailandAddressTypeahead.PostalCodeInput
                                  type="text"
                                  className={`form-control ${
                                    values.zipcode
                                      ? ""
                                      : !!errors.zipcode
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอกรหัสไปรษณีย์"
                                  name="zipcode"
                                  value={values.zipcode}
                                />
                              </Form.Group>
                            </div>
                            <ThailandAddressTypeahead.Suggestion
                              containerProps={{
                                className: "suggestion-container",
                              }}
                              optionItemProps={{
                                className: "suggestion-option",
                              }}
                            />
                          </ThailandAddressTypeahead>
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                เบอร์โทรศัพท์
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="กรอกเบอร์โทรศัพท์"
                                value={values.phone_number}
                                onChange={handleChange}
                                isInvalid={!!errors.phone_number}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                เบอร์มือถือ
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="mobile_number"
                                className="form-control"
                                placeholder="กรอกเบอร์มือถือ"
                                value={values.mobile_number}
                                onChange={handleChange}
                                isInvalid={!!errors.mobile_number}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-6">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                URL Google Map
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="location"
                                className="form-control"
                                placeholder="URL Google Map"
                                value={values.location}
                                onChange={handleChange}
                                isInvalid={!!errors.location}
                              />
                            </Form.Group>
                          </div>
                          {/*<div className="col-xl-6">*/}
                          {/*    <Form.Group className="mb-2 form-st-3 pb-1">*/}
                          {/*        <Form.Label*/}
                          {/*            className="mb-1">คลังสินค้าที่เชื่อม</Form.Label>*/}
                          {/*        <SelectStoreHouse*/}
                          {/*            handleChange={(e) => {*/}
                          {/*                setFieldValue('storeHouse', e.value)*/}
                          {/*            }}*/}
                          {/*            errors={errors}*/}
                          {/*        />*/}
                          {/*    </Form.Group>*/}
                          {/*</div>*/}
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                วันที่สร้าง
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="created_at"
                                className="form-control"
                                placeholder="วันที่สร้าง"
                                value={values.created_at}
                                onChange={handleChange}
                                isInvalid={!!errors.created_at}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 align-self-center">
                            <Form.Label className="mb-0 mt-2 tc-10">
                              สถานะ
                            </Form.Label>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="เปิดใช้งาน"
                                  name="is_active"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  className="form-check-st-2 tc-7"
                                  // checked={values.is_active === true}
                                  checked={values.is_active === true}
                                  onChange={(e) => {
                                    setFieldValue("is_active", true);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="ปิดใช้งาน"
                                  name="is_active"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.is_active === false}
                                  onChange={(e) => {
                                    setFieldValue("is_active", false);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="text-end pb-4 pe-3">
                  {formdisabled === true ? (
                    <Button
                      type="button"
                      className="btn-st-3 mx-3"
                      onClick={(e) => {
                        setFormdisabled(false);
                      }}
                    >
                      แก้ไข
                    </Button>
                  ) : (
                    <div className="text-end pt-3 btn-group-control">
                      <Link
                        to={`/${slug}/branch`}
                        className="btn-st-5 btn text-decoration-none me-3"
                      >
                        ยกเลิก
                      </Link>
                      <Button type="submit" className="btn-st-3">
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/*{posShow === true && (*/}
      {/*    <div className="card card-st-1 mb-4">*/}
      {/*        <div className="card-body">*/}
      {/*            <div className="tc-8 fs-18 fw-bold px-3 mb-4">POS</div>*/}
      {/*            <div className="px-4 pb-5">*/}
      {/*                {posData.map((item, index) => (*/}
      {/*                    <button className="btn btn-pos" key={index} onClick={() => {*/}
      {/*                        setPosFormData(item)*/}
      {/*                        setModalposEditShow(true)*/}
      {/*                    }}>*/}
      {/*                        <div className="text-center tc-8 mb-1">{item.posName}</div>*/}
      {/*                        <div className="text-center fs-14 tc-11 mb-2">{item.dateCreate}</div>*/}
      {/*                        <div*/}
      {/*                            className={item.posStatus == 'มีการซื้อขายแล้ว' ? 'text-center pos-status tc-8 active' : 'text-center pos-status tc-8'}*/}
      {/*                        >*/}
      {/*                            {item.posStatus}*/}
      {/*                        </div>*/}
      {/*                    </button>*/}
      {/*                ))}*/}
      {/*                <button*/}
      {/*                    type="button"*/}
      {/*                    className="btn  btn-add-pos"*/}
      {/*                    onClick={() => setModalposShow(true)}*/}
      {/*                >*/}
      {/*                    <div>เพิ่ม POS</div>*/}
      {/*                </button>*/}
      {/*            </div>*/}
      {/*            <div className="text-end pt-3 btn-group-control pb-4">*/}
      {/*                <Link to={`/${slug}/branch`} className="btn btn-st-6 me-3">ยกเลิก</Link>*/}
      {/*                <Link to="/branch/list" className="btn btn-st-3 ">บันทึก</Link>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*)}*/}
      <PosModal
        show={modalposShow}
        onHide={() => setModalposShow(false)}
        posFormData={posFormData}
        setPosFormData={setPosFormData}
        posData={posData}
        setPosData={setPosData}
        setModalposShow={setModalposShow}
        branchId={branchId}
      />
      <PosEditModal
        show={modalposEditShow}
        onHide={() => setModalposEditShow(false)}
        posFormData={posFormData}
        setPosFormData={setPosFormData}
      />
      {/*<Outlet />*/}
    </div>
  );
}

export function BranchEdit() {
  const BranchDataURL = `${process.env.REACT_APP_API_URL}/branch-info`;
  const PosDataURL = `${process.env.REACT_APP_API_URL}/pos-setting`;
  const UploadImageFileURL = `${process.env.REACT_APP_API_URL}/branch-info/branch-image`;

  const { branchID } = useParams();
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const [posData, setPosData] = useState([]);
  const [formdisabled, setFormdisabled] = useState(true);
  const [posformdisabled, setPosFormdisabled] = useState(true);

  const [districtoption, setDistrictoption] = useState([]);
  const [subdistrictoption, setSubDistrictoption] = useState([]);
  const [provinceoption, setProvinceoption] = useState([]);

  const [posShow, setPosShow] = useState(false);
  const [modalposShow, setModalposShow] = useState(false);
  const [modalposEditShow, setModalposEditShow] = useState(false);
  const [modalposDeleteShow, setModalposDeleteShow] = useState(false);

  const [deletingPos, setDeletingPos] = useState(null);

  const [createPosSuccess, setCreatePosSuccess] = useState(false);
  const [isEditPos, setIsEditPos] = useState(false);

  const [branchData, setBranchData] = useState({
    img_url: "",
    business_name: "",
    name: "",
    code: "",
    address: "",
    created_at: "",
    phone_number: "",
    mobile_number: "",
    subdistrict: "",
    district: "",
    province: "",
    zipcode: "",
    storeHouse: 0,
    is_active: "",
    location: "",
  });

  const [posFormData, setPosFormData] = useState({
    created_at: getCurrentDate("/"),
    code: "",
    name: "",
    status_id: 2,
    is_registed: false,
    revenue_number: "",
    allowed_at: "",
    is_active: true,
  });

  console.log("posFormData:", posFormData);

  const [address, setAddress] = useState(
    ThailandAddressValue.fromDatasourceItem({
      d: "",
      p: "",
      po: "",
      s: "",
    })
  );

  const getBranchData = async () => {
    try {
      const res = await fetch(`${BranchDataURL}/${branchID}`, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      setBranchData(data);
      console.log("branchData:", data);
    } catch (error) {
      console.log("Failed to get data!", error);
    }
  };

  // const getPosData = (branchID) => {
  //     fetch('/json/pos.json')
  //         .then((response) => response.json())
  //         .then((result) => {
  //                 setPosData(result)
  //                 setPosShow(true)
  //             },
  //         )
  //         .catch((error) => console.log('error', error))
  // }

  const getPosData = async () => {
    try {
      const res = await fetch(`${PosDataURL}/${branchID}`, {
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      setPosData(data);
      setPosShow(true);
      setCreatePosSuccess(false);
      console.log("PosData:", data);
    } catch (error) {
      console.log("Failed to get data!", error);
    }
  };

  const uploadImageFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(UploadImageFileURL, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "business-id": user.businessId,
        }),
        body: formData,
      });

      return await response.text();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const updateBusinessData = async (values) => {
    try {
      // setBusinessData(values);
      console.log("Submit update branch data:", values);

      const res = await fetch(`${BranchDataURL}/${branchID}`, {
        method: "PATCH",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(values),
      });
      return res.text();
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const schema = yup.object().shape({
    img_url: yup.string().required(),
    business_name: yup.string().required(),
    name: yup.string().required(),
    code: yup.string().required(),
    address: yup.string().required(),
    created_at: yup.string().required(),
    phone_number: yup.string().required(),
    mobile_number: yup.string().required(),
    subdistrict: yup.string().required(),
    district: yup.string().required(),
    province: yup.string().required(),
    zipcode: yup.string().required(),
    is_active: yup.boolean().required(),
    location: yup.string().required(),
  });

  const optionsstoreHouse = [
    { value: 1, label: "คลัง 1" },
    { value: 2, label: "คลัง 2" },
  ];

  useEffect(() => {
    getBranchData(branchID);
    getPosData(branchID);
  }, [branchID, createPosSuccess]);

  useEffect(() => {
    if (branchData) {
      setAddress(
        ThailandAddressValue.fromDatasourceItem({
          d:
            branchData.district !== undefined && branchData.district.length > 0
              ? branchData.district
              : "",
          p:
            branchData.province !== undefined && branchData.province.length > 0
              ? branchData.province
              : "",
          po:
            branchData.zipcode !== undefined &&
            branchData.zipcode.toString().length > 0
              ? branchData.zipcode
              : "",
          s:
            branchData.subdistrict !== undefined &&
            branchData.subdistrict.length > 0
              ? branchData.subdistrict
              : "",
        })
      );
    }
  }, [branchData]);

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              จัดการสาขา
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            แก้ไข
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">แก้ไขสาขา</div>
      <div className="card card-st-1 mb-4">
        <div className="card-body">
          <div className="tc-8 fs-18 fw-bold px-3">ข้อมูลสาขา</div>
          <Formik
            enableReinitialize
            initialValues={branchData}
            validationSchema={schema}
            onSubmit={async (values) => {
              updateBusinessData({
                ...values,
                zipcode: parseInt(values.zipcode),
              }).then(() => setFormdisabled(true));
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <fieldset disabled={formdisabled}>
                  <div className="card-body px-xl-3">
                    <div>
                      <ImageUpload
                        setFieldValue={setFieldValue}
                        values={values}
                        isBusinessEdit={!formdisabled}
                        uploadImageFile={uploadImageFile}
                      />
                      <div className="pt-3">
                        <div className="row gx-xl-4">
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อกิจการ
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="business_name"
                                className="form-control"
                                placeholder="ชื่อกิจการ"
                                value={values.business_name}
                                onChange={handleChange}
                                isInvalid={!!errors.business_name}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อสาขา
                                <span className="text-danger ms-1">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="กรอกชื่อสาขา"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                รหัสสาขา{" "}
                                <span className="text-danger ms-1"> *</span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="code"
                                className="form-control"
                                placeholder="กรอกรหัสสาขา"
                                value={values.code}
                                onChange={handleChange}
                                isInvalid={!!errors.code}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-12">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">ที่อยู่</Form.Label>
                              <Form.Control
                                type="text"
                                name="address"
                                className="form-control"
                                placeholder="กรอกที่อยู่"
                                value={values.address}
                                onChange={handleChange}
                                isInvalid={!!errors.address}
                              />
                            </Form.Group>
                          </div>
                          <ThailandAddressTypeahead
                            value={address}
                            onValueChange={(val) => {
                              setAddress(val);
                              setFieldValue("subdistrict", val.subdistrict);
                              setFieldValue("district", val.district);
                              setFieldValue("province", val.province);
                              setFieldValue("zipcode", val.postalCode);
                            }}
                          >
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  จังหวัด
                                </Form.Label>
                                <ThailandAddressTypeahead.ProvinceInput
                                  type="text"
                                  className={`form-control ${
                                    values.province
                                      ? ""
                                      : !!errors.province
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอกจังหวัด"
                                  name="province"
                                  value={values.province}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  อำเภอ/เขต
                                </Form.Label>
                                <ThailandAddressTypeahead.DistrictInput
                                  type="text"
                                  className={`form-control ${
                                    values.district
                                      ? ""
                                      : !!errors.district
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอก อำเภอ/เขต"
                                  name="district"
                                  value={values.district}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  แขวง/ตำบล
                                </Form.Label>
                                <ThailandAddressTypeahead.SubdistrictInput
                                  type="text"
                                  className={`form-control ${
                                    values.subdistrict
                                      ? ""
                                      : !!errors.subdistrict
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอก แขวง/ตำบล"
                                  name="subdistrict"
                                  value={values.subdistrict}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  รหัสไปรษณีย์
                                </Form.Label>
                                <ThailandAddressTypeahead.PostalCodeInput
                                  type="text"
                                  className={`form-control ${
                                    values.zipcode
                                      ? ""
                                      : !!errors.zipcode
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรอกรหัสไปรษณีย์"
                                  name="zipcode"
                                  value={values.zipcode}
                                />
                              </Form.Group>
                            </div>
                            <ThailandAddressTypeahead.Suggestion
                              containerProps={{
                                className: "suggestion-container",
                              }}
                              optionItemProps={{
                                className: "suggestion-option",
                              }}
                            />
                          </ThailandAddressTypeahead>
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                เบอร์โทรศัพท์
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="กรอกเบอร์โทรศัพท์"
                                value={values.phone_number}
                                onChange={handleChange}
                                isInvalid={!!errors.phone_number}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                เบอร์มือถือ
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="mobile_number"
                                className="form-control"
                                placeholder="กรอกเบอร์มือถือ"
                                value={values.mobile_number}
                                onChange={handleChange}
                                isInvalid={!!errors.mobile_number}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-6">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                URL Google Map
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="location"
                                className="form-control"
                                placeholder="URL Google Map"
                                value={values.location}
                                onChange={handleChange}
                                isInvalid={!!errors.location}
                              />
                            </Form.Group>
                          </div>
                          {/*<div className="col-xl-6">*/}
                          {/*    <Form.Group className="mb-2 form-st-3 pb-1">*/}
                          {/*        <Form.Label*/}
                          {/*            className="mb-1">คลังสินค้าที่เชื่อม</Form.Label>*/}
                          {/*        <SelectStoreHouse*/}
                          {/*            handleChange={(e) => {*/}
                          {/*                setFieldValue('storeHouse', e.value)*/}
                          {/*            }}*/}
                          {/*            selectedID={values.storeHouse}*/}
                          {/*            errors={errors}*/}
                          {/*        />*/}
                          {/*    </Form.Group>*/}
                          {/*</div>*/}
                          <div className="col-xl-3">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                วันที่สร้าง
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="created_at"
                                className="form-control"
                                placeholder="วันที่สร้าง"
                                value={getCurrentDate(
                                  "/",
                                  new Date(values.created_at)
                                )}
                                onChange={handleChange}
                                isInvalid={!!errors.created_at}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 align-self-center">
                            <Form.Label className="mb-0 mt-2 tc-10">
                              สถานะ
                            </Form.Label>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="เปิดใช้งาน"
                                  name="is_active"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.is_active === true}
                                  onChange={(e) => {
                                    setFieldValue("is_active", true);
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="ปิดใช้งาน"
                                  name="is_active"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  className="form-check-st-2 tc-7"
                                  checked={values.is_active === false}
                                  onChange={(e) => {
                                    setFieldValue("is_active", false);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="text-end pb-4 pe-3">
                  {formdisabled === true ? (
                    <Button
                      type="button"
                      className="btn-st-3 mx-3"
                      onClick={(e) => {
                        setFormdisabled(false);
                      }}
                    >
                      แก้ไข
                    </Button>
                  ) : (
                    <div className="text-end pt-3 btn-group-control">
                      <Button
                        type="button"
                        className="btn-st-5 me-3"
                        onClick={() => {
                          setFormdisabled(true);
                        }}
                      >
                        ยกเลิก
                      </Button>
                      <Button type="submit" className="btn-st-3 ">
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {posShow === true ? (
        <div className="card card-st-1 mb-4">
          <div className="card-body">
            <div className="tc-8 fs-18 fw-bold px-3 mb-4">POS</div>
            <Formik
              onSubmit={async (values) => {
                console.log(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <fieldset disabled={posformdisabled}>
                    <div className="px-4 pb-5">
                      {posData.length > 0 &&
                        posData.map((item, index) => (
                          <button
                            type="button"
                            className="btn btn-pos"
                            key={index}
                            onClick={() => {
                              setPosFormData({
                                ...item,
                                created_at:
                                  item.created_at.length > 0
                                    ? getCurrentDate(
                                        "/",
                                        new Date(item.created_at)
                                      )
                                    : "",
                                allowed_at:
                                  item.allowed_at.length > 0
                                    ? getCurrentDate(
                                        "/",
                                        new Date(item.allowed_at)
                                      )
                                    : "",
                              });
                              // setModalposEditShow(true)
                              setIsEditPos(true);
                              setModalposShow(true);
                            }}
                          >
                            {/*{item.is_active === true ?*/}
                            {/*    <div className="icon-view"></div>*/}
                            {/*    :*/}
                            {/*    <div className="icon-edit"></div>*/}
                            {/*}*/}
                            <div className="text-center tc-8 mb-1">
                              {item.code}
                            </div>
                            <div className="text-center fs-14 tc-11 mb-2">
                              {getCurrentDate("/", new Date(item.created_at))}
                            </div>
                            <div
                              className={
                                item.posStatus === true
                                  ? "text-center pos-status tc-8 active"
                                  : "text-center pos-status tc-8"
                              }
                            >
                              {item.posStatus === true
                                ? "มีการซื้อขายแล้ว"
                                : "ยังไม่มีการซื้อขาย"}
                            </div>
                          </button>
                        ))}
                      {posformdisabled !== true ? (
                        <button
                          type="button"
                          className="btn  btn-add-pos"
                          onClick={() => {
                            setPosFormData({
                              created_at: getCurrentDate("/"),
                              code: "",
                              name: "",
                              status_id: 2,
                              is_registed: false,
                              revenue_number: "",
                              allowed_at: "",
                              is_active: true,
                            });
                            setIsEditPos(false);
                            setModalposShow(true);
                          }}
                        >
                          <div>เพิ่ม POS</div>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </fieldset>
                  <div className="text-end pt-3 btn-group-control pb-4">
                    {posformdisabled === true ? (
                      <Button
                        type="button"
                        className="btn-st-3 mx-3"
                        onClick={(e) => {
                          setPosFormdisabled(false);
                        }}
                      >
                        {posData.length === 0 ? "เพิ่ม POS" : "แก้ไข"}
                      </Button>
                    ) : (
                      <div className="text-end pt-3 btn-group-control">
                        <Link
                          to={`/${slug}/branch`}
                          className="btn btn-st-6 me-3"
                          onClick={() => setPosFormdisabled(true)}
                        >
                          ยกเลิก
                        </Link>
                        {/*<Link to="/branch/list" className="btn btn-st-3 ">บันทึก</Link>*/}
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        ""
      )}
      <PosModal
        show={modalposShow}
        onHide={() => setModalposShow(false)}
        posFormData={posFormData}
        setPosFormData={setPosFormData}
        posData={posData}
        setPosData={setPosData}
        setModalposShow={setModalposShow}
        branchId={branchID}
        onSuccess={() => setCreatePosSuccess(true)}
        isEdit={isEditPos}
        onDelete={() => setModalposDeleteShow(true)}
        setDeletingPos={setDeletingPos}
      />
      <ConfirmDeleteModal
        show={modalposDeleteShow}
        onHide={() => setModalposDeleteShow(false)}
        onConfirm={() => setModalposDeleteShow(false)}
        deletingPos={deletingPos}
        setPosData={setPosData}
        branchId={branchID}
        onSuccess={() => setCreatePosSuccess(true)}
      />
      <PosEditModal
        show={modalposEditShow}
        onHide={() => setModalposEditShow(false)}
        onDelete={() => setModalposDeleteShow(true)}
        posFormData={posFormData}
        setPosFormData={setPosFormData}
        setPosdeleteID={setDeletingPos}
      />
    </div>
  );
}

function PosModal(props) {
  const {
    posFormData,
    setPosFormData,
    setPosData,
    setModalposShow,
    posData,
    branchId,
    onSuccess,
    isEdit,
    onDelete,
    setDeletingPos,
    ...rest
  } = props;

  const PosURL = `${process.env.REACT_APP_API_URL}/pos-setting`;

  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const schema = yup.object().shape({
    code: yup.string().required(),
    name: yup.string().required(),
    is_registed: yup.boolean(),
    revenue_number: yup.string().when("is_registed", {
      is: (value) => value === true,
      then: (schema) => schema.required("เลขจากสรรพากรต้องไม่ว่างเปล่า"),
      otherwise: (schema) => schema.notRequired(),
    }),
    allowed_at: yup.string().when("is_registed", {
      is: (value) => value === true,
      then: (schema) => schema.required("วันที่ได้รับอนุญาติต้องไม่ว่างเปล่า"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const [startDate, setStartDate] = useState("");

  const getPosData = () => {
    fetch("/json/pos2.json")
      .then((response) => response.json())
      .then((result) => props.setPosData(result))
      .catch((error) => console.log("error", error));
  };

  const createPos = async (values) => {
    try {
      console.log("Submit create POS:", values);

      const res = await fetch(`${PosURL}/${branchId}`, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(values),
      });
      return res.text();
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const editPos = async (values) => {
    try {
      console.log("Submit edit POS:", values);

      const res = await fetch(`${PosURL}/${branchId}/${props.posFormData.id}`, {
        method: "PATCH",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(values),
      });
      return res.text();
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-20 tc-7 me-auto">เพิ่ม POS</div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.posFormData}
            validationSchema={schema}
            onSubmit={async (values) => {
              console.log("submit POS:", values);
              if (isEdit === false) {
                await createPos({
                  ...values,
                  created_at:
                    values.created_at.length > 0
                      ? `${new Date(
                          formatDateToYMD(values.created_at)
                        ).toISOString()}`
                      : "",
                  allowed_at:
                    values.allowed_at.length > 0
                      ? `${new Date(
                          formatDateToYMD(values.allowed_at)
                        ).toISOString()}`
                      : "",
                });
              } else {
                await editPos({
                  ...values,
                  created_at:
                    values.created_at.length > 0
                      ? `${new Date(
                          formatDateToYMD(values.created_at)
                        ).toISOString()}`
                      : "",
                  allowed_at:
                    values.allowed_at.length > 0
                      ? `${new Date(
                          formatDateToYMD(values.allowed_at)
                        ).toISOString()}`
                      : "",
                });
              }
              props.onHide();
              props.onSuccess(true);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                  <Form.Control
                    type="text"
                    name="created_at"
                    className="form-control"
                    placeholder="วันที่สร้าง"
                    value={values.created_at}
                    onChange={handleChange}
                    isInvalid={!!errors.created_at}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">POS ID (รหัสเครื่อง)</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    className="form-control"
                    placeholder="POS ID"
                    value={values.code}
                    onChange={handleChange}
                    isInvalid={!!errors.code}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อ POS <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="ชื่อ POS"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">สถานะ </Form.Label>
                  <Form.Control
                    type="text"
                    name="status_id"
                    className="form-control"
                    placeholder="สถานะ"
                    value={
                      values.status_id === 2
                        ? "ยังไม่มีการซื้อขาย"
                        : values.status_id
                    }
                    onChange={handleChange}
                    isInvalid={!!errors.status_id}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="จดทะเบียนใบกำกับภาษีอย่างย่อ"
                  name="is_registed"
                  className="custom-switch-st-1 mb-4"
                  defaultChecked={values.is_registed}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setFieldValue("is_registed", true);
                    } else {
                      setFieldValue("is_registed", false);
                      // setFieldValue('revenue_number', '')
                      // setFieldValue('allowed_at', '')
                    }
                  }}
                />
                {values.is_registed === true && (
                  <div>
                    <Form.Group className="mb-3 form-st-3 pb-2">
                      <Form.Label className="mb-1">
                        เลขจากสรรพากร <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="revenue_number"
                        className="form-control"
                        placeholder="เลขจากสรรพากร"
                        value={values.revenue_number}
                        onChange={handleChange}
                        isInvalid={!!errors.revenue_number}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        วันที่ได้รับอนุญาติ{" "}
                      </Form.Label>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        selected={startDate}
                        value={values.allowed_at}
                        onChange={(date) => {
                          setStartDate(date);
                          if (date) {
                            setFieldValue(
                              "allowed_at",
                              getCurrentDate("/", date)
                            );
                          }
                        }}
                        placeholder="วันที่ได้รับอนุญาติ"
                        className="w-100 datepicker"
                      />
                    </Form.Group>
                  </div>
                )}
                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="is_active"
                      type={type}
                      id={`inline-${type}-1`}
                      className="form-check-st-2 tc-7"
                      checked={values.is_active === true}
                      onChange={(e) => {
                        setFieldValue("is_active", true);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="is_active"
                      type={type}
                      id={`inline-${type}-2`}
                      className="form-check-st-2 tc-7"
                      checked={values.is_active === false}
                      onChange={(e) => {
                        setFieldValue("is_active", false);
                      }}
                    />
                  </div>
                ))}
                <div className="d-flex align-items-center pt-4 pb-3 btn-group-control">
                  {values.status !== undefined &&
                  values.status.name !== undefined &&
                  values.status.name === "ยังไม่มีการซื้อขาย" ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto "
                      style={{ width: "120px" }}
                      onClick={() => {
                        props.setDeletingPos(values);
                        props.onDelete();
                        props.onHide();
                      }}
                    >
                      ลบข้อมูล
                    </Button>
                  ) : (
                    <div className="me-auto" />
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "120px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "120px" }}
                  >
                    บันทึก
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function PosEditModal(props) {
  const { onDelete, posFormData, setPosdeleteID, setPosFormData, ...rest } =
    props;

  const schema = yup.object().shape({
    posName: yup.string().required(),
  });

  const [startDate, setStartDate] = useState("");

  const getPosData = () => {
    fetch("/json/pos2.json")
      .then((response) => response.json())
      .then((result) => props.setPosData(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-20 tc-7 me-auto">จัดการ POS</div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.posFormData}
            validationSchema={schema}
            onSubmit={async (values) => {
              console.log(values);
              getPosData();
              props.onHide();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">วันที่สร้าง </Form.Label>
                  <Form.Control
                    type="text"
                    name="dateCreate"
                    className="form-control"
                    placeholder="วันที่สร้าง"
                    value={values.dateCreate}
                    onChange={handleChange}
                    isInvalid={!!errors.dateCreate}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">ลำดับที่ POS </Form.Label>
                  <Form.Control
                    type="text"
                    name="posNo"
                    className="form-control"
                    placeholder="ลำดับที่ POS"
                    value={values.posNo}
                    onChange={handleChange}
                    isInvalid={!!errors.posNo}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    POS ID (รหัสเครื่อง){" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="posID"
                    className="form-control"
                    placeholder="ลำดับที่ POS"
                    value={values.posID}
                    onChange={handleChange}
                    isInvalid={!!errors.posID}
                    disabled={values.posStatus === "มีการซื้อขายแล้ว"}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อ POS <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="posName"
                    className="form-control"
                    placeholder="ชื่อ POS"
                    value={values.posName}
                    onChange={handleChange}
                    isInvalid={!!errors.posNo}
                    disabled={values.posStatus === "มีการซื้อขายแล้ว"}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">สถานะ </Form.Label>
                  <Form.Control
                    type="text"
                    name="posStatus"
                    className="form-control"
                    placeholder="สถานะ"
                    value={values.posStatus}
                    onChange={handleChange}
                    isInvalid={!!errors.posStatus}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="จดทะเบียนใบกำกับภาษีอย่างย่อ"
                  name="taxInvoice"
                  className="custom-switch-st-1 mb-4"
                  defaultChecked={values.taxInvoice}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setFieldValue("taxInvoice", 1);
                    } else {
                      setFieldValue("taxInvoice", 0);
                      setFieldValue("revenueNumber", "");
                      setFieldValue("dateAuthorized", "");
                    }
                  }}
                  disabled={values.posStatus === "มีการซื้อขายแล้ว"}
                />

                {values.taxInvoice == 1 ? (
                  <div>
                    <Form.Group className="mb-3 form-st-3 pb-2">
                      <Form.Label className="mb-1">
                        เลขจากสรรพากร <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="revenueNumber"
                        className="form-control"
                        placeholder="เลขจากสรรพากร"
                        value={values.revenueNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.revenueNumber}
                        disabled={values.posStatus === "มีการซื้อขายแล้ว"}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        วันที่ได้รับอนุญาติ{" "}
                      </Form.Label>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setFieldValue(
                            "dateAuthorized",
                            getCurrentDate("-", date)
                          );
                        }}
                        placeholder="วันที่ได้รับอนุญาติ"
                        className="w-100 datepicker"
                        value={values.dateAuthorized}
                        disabled={values.posStatus === "มีการซื้อขายแล้ว"}
                      />
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}

                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="usageStatus"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.usageStatus === 1}
                      onChange={(e) => {
                        setFieldValue("usageStatus", 1);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="usageStatus"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.usageStatus === 0}
                      onChange={(e) => {
                        setFieldValue("usageStatus", 0);
                      }}
                    />
                  </div>
                ))}
                <div className="d-flex align-items-center pt-4 pb-3 btn-group-control">
                  {values.posStatus == "ยังไม่มีการซื้อขาย" ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto "
                      style={{ width: "120px" }}
                      onClick={() => {
                        props.setPosdeleteID(values.id);
                        props.onDelete();
                        props.onHide();
                      }}
                    >
                      ลบข้อมูล
                    </Button>
                  ) : (
                    <div className="me-auto" />
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "120px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "120px" }}
                  >
                    บันทึก
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmDeleteModal(props) {
  const { onConfirm, deletingPos, setPosData, branchId, onSuccess, ...rest } =
    props;

  const PosURL = `${process.env.REACT_APP_API_URL}/pos-setting`;

  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const getPosData = () => {
    fetch("/json/pos2.json")
      .then((response) => response.json())
      .then((result) => props.setPosData(result))
      .catch((error) => console.log("error", error));
  };

  const deletePos = async (PosId) => {
    try {
      const res = await fetch(`${PosURL}/${branchId}/${PosId}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      console.log("deletePOS data:", data);
      console.log("deletePOS status:", res.status);
      return { data: data, status: res.status };
    } catch (error) {
      console.log("Failed to delete POS!", error);
    }
  };

  const handleDelete = async (PosId) => {
    // getPosData()
    const deletePosRes = await deletePos(PosId);
    if (deletePosRes.status === 200) {
      props.onHide();
      props.onSuccess();
    }
  };

  console.log("POS deleting:", deletingPos);

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div className="text-center fs-24 tc-7">
            คุณต้องการลบ {props.deletingPos && props.deletingPos.name}{" "}
            ใช่หรือไม่
          </div>
          <div className="text-center pt-4 mt-3  btn-group-control">
            <Button
              type="button"
              className="btn-st-6 mx-3 "
              style={{ width: "120px" }}
              onClick={props.onHide}
            >
              ยกเลิก
            </Button>
            <Button
              type="button"
              className="btn-st-3 mx-3  "
              style={{ width: "120px" }}
              onClick={() => handleDelete(props.deletingPos.id)}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const ImageUpload = (props) => {
  const [imgData, setImgData] = useState(false);
  const [imageURL, setImageURL] = useState(props.values.img_url);

  const handlePreviewImage = async (e) => {
    if (e.target.files[0]) {
      const res = await props.uploadImageFile(e.target.files[0]);
      setImageURL(res);
      props.setFieldValue("img_url", res);
      // const reader = new FileReader()
      // reader.addEventListener('load', () => {
      //     setImgData(reader.result)
      //     props.setFieldValue('img_url', reader.result)
      // })
      // reader.readAsDataURL(e.target.files[0])
    }
  };

  useEffect(() => {
    setImageURL(props.values.img_url);
  }, [props.values.img_url, props.isBusinessEdit]);

  return (
    <div className="d-flex align-items-center mb-4 mt-3 ">
      <div className="bx-upload">
        {imageURL !== undefined && imageURL.length > 0 ? (
          <img
            src={`${process.env.REACT_APP_API_URL_SOCKET}/${imageURL}`}
            alt={`preview-${imageURL}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/blank.png";
            }}
          />
        ) : (
          <div className="preimg"></div>
        )}
      </div>
      <input
        type="file"
        name=""
        className="fileUpload hide-input"
        id="fileUpload"
        accept="image/*"
        onChange={(e) => {
          handlePreviewImage(e);
        }}
      />
      {props.isBusinessEdit && (
        <div className="flex-fill ms-3">
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-st-4 btn-upload me-3 hover:bg-transparent hover:text-[#565DFF] hover:border-[#565DFF]"
              onClick={() => document.getElementById("fileUpload").click()}
            >
              แก้ไขรูปภาพ
            </button>
            <button
              type="button"
              className="btn btn-st-2 btn-remove-image hover:bg-[#FF4D49] hover:text-white"
              onClick={(e) => {
                setImgData(false);
                props.setFieldValue("img_url", "");
              }}
            >
              ลบ
            </button>
          </div>
          <div className="tc-7 fs-12">
            อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K
          </div>
        </div>
      )}
    </div>
  );
};
