import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { ModalAllder } from 'layouts/ModalAllder';
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'

export function OptionStandard(props){

    const [optionstandard, setOptionstandard] = useState([])
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()
    // const getoptionData = (props) => {
    //     fetch('/json/optionstandard.json')
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setOptionstandard(result)
    //         })
    //         .catch((error) => console.log('error', error))
    // }

    const getoptionData = (props) => {
        fetch(`${process.env.REACT_APP_API_URL}/business-product/standard-options`, {
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
                'business-id': user.businessId,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setOptionstandard(result)
                console.log('option_standard',result)
            })
            .catch((error) => console.log('error', error))
    }

    useEffect(() => {
        getoptionData()
    }, [])

    const handleChangeOptionStandard = (options) => {

        let selected = []
        props.listoptionstandard.map((item, index) => {
            selected.push(item.id)
        })
        let optionselect = []

        options.map((option, index) => {

            console.log(option)
            if (!selected.includes(option.id)) {

                var obj = {}
                obj['id'] = option.id
                obj['name'] = option.name
                obj['selecttype'] = false
                var list = []
                option.product_option_detail.map((item2, index2) => {
                    var obj2 = {}
                    obj2['id'] = item2.id
                    obj2['name'] = item2.name
                    obj2['price'] = item2.price
                    list.push(obj2)
                })
                obj['product_option_detail'] = list
                props.setListOptionstandard(listoptionstandard => [...listoptionstandard, obj])
            }
            optionselect.push(option.id)
        })
        props.setListOptionstandard(listoptionstandard => listoptionstandard.filter(item => optionselect.includes(item.id)))

    }

    const handleDeleteoptionList = (optionID, listID) => {

        props.setListOptionstandard(option => option.map((el) =>
            el.id === optionID
                ? {
                    ...el,
                    product_option_detail: el.product_option_detail.filter(dx => dx.id !== listID),
                } : el,
        ))

    }

    const handleChangeSelecttype = (e, optionID) => {

        let status = e.target.checked === true ? true : false
        props.setListOptionstandard((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        selecttype: el.selecttype = status,
                    }
                    : el,
            ),
        )

    }

    let optionselected = []
    props.listoptionstandard.map((item, index) => {
        optionselected.push(item.id)
    })

    return (
        <div className="px-5 py-4">
            <div className="row">
                <div className="col-xl-6 col">
                    <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ตัวเลือก <span className="text-danger">*</span></Form.Label>
                        <Select as="select"
                                name="storeH"
                                onChange={(e) => {
                                    handleChangeOptionStandard(e)
                                }}
                                className="w-100 select-st-1 select-multi"
                                options={optionstandard}
                                getOptionLabel ={(option)=>option.name}
                                getOptionValue ={(option)=>option.id}
                                placeholder="พิมพ์ชื่อตัวเลือก"
                                isMulti
                                value={optionstandard.filter(
                                    i => optionselected.includes(i.id),
                                )
                                }
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="ms-xl-4 mt-2 ps-2">
                {props.listoptionstandard.map((item, index) => (
                    <div className="row gx-4 align-items-center" key={index}>
                        <div className="col-xl-7 col-6 ">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">ตัวเลือกมาตราฐาน</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="note"
                                    className="form-control"
                                    placeholder="ตัวเลือกมาตราฐาน"
                                    value={item.name}
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-xl-5 col-6">
                            <Form.Check
                                type="switch"
                                id={`custom-switch-stardard${index}`}
                                label="เลือกได้มากกว่า 1 รายการ"
                                name={`selecttype[${index}]`}
                                className="custom-switch-st-1 tc-10  s1 mb-1"
                                defaultChecked={item.selecttype}
                                onChange={(e) => handleChangeSelecttype(e, item.id)}

                            />
                        </div>
                        {item.product_option_detail.map((item2, index2) => (
                            <div className="col-xl-6 row gx-4 align-items-center" key={index2}>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="listName"
                                            className="form-control"
                                            placeholder="รายการ"
                                            value={item2.name}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-auto">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ราคา</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="note"
                                            className="form-control"
                                            placeholder="ราคา"
                                            value={item2.price}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                                {/* <div className="col-auto">
                                    <button type="button" className="btn btn-delete-st-1"
                                            onClick={() => handleDeleteoptionList(item.id, item2.id)}></button>
                                </div> */}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )

}