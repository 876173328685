
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import { nanoid } from 'nanoid'
import { ModalAllder } from 'layouts/ModalAllder';
import { useRecoilValue } from 'recoil'
import { authState } from '../state/auth'
import useToken from '../utils/UseToken'

export function EditOptionProduct(props){

    const [modalStockShow, setModalStockShow] = useState(false)
    const [settingStock, setSettingStock] = useState(null)
    const [stockindex, setStockindex] = useState({})
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()



    const handleDeleteoption = async (id) => {
        // props.setOptionproduct(optionlist => optionlist.filter(item => item.id !== id))
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific/${props.values.id}/${id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            const result = await res.text();
            console.log('Delete successful:', result);
    
            // Update the state to remove the deleted option
            props.setOptionproduct(optionlist => optionlist.filter(item => item.id !== id));
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }




    const handleDeleteoptionList = (optionID, listID) => {
        props.setOptionproduct(optionproduct => optionproduct.map((el) =>
            el.optionID === optionID
                ? {
                    ...el,
                    product_option_detail: el.product_option_detail.filter(dx => dx.listID !== listID),
                } : el,
        ))
    }
    const handleChangeSelecttype = (e, id) => {
        let status = e.target.checked === true ? true : false
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        selecttype: el.selecttype = status,
                    }
                    : el,
            ),
        )
    }

    const handleInputChange = (id, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        name: el.name = value,
                    }
                    : el,
            ),
        )
    }

    const handlelistNameChange = (optionID, listID, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        product_option_detail: el.product_option_detail.map((el2) =>
                            el2.id == listID
                                ? {
                                    ...el2,
                                    name: el2.name = value,
                                } : el2,
                        ),
                    }
                    : el,
            ),
        )
    }

    const handlelistPriceChange = (optionID, listID, value) => {
        props.setOptionproduct((prev) =>
            prev.map((el) =>
                el.id === optionID
                    ? {
                        ...el,
                        product_option_detail: el.product_option_detail.map((el2) =>
                            el2.id == listID
                                ? {
                                    ...el2,
                                    price: el2.price = value,
                                } : el2,
                        ),
                    }
                    : el,
            ),
        )
    }


    const [productOptionDetail, setProductOptionDetail] = useState({
        "id":null,
        "optionDetail":null
    })


    

    const [modaledit, setModaledit] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:380,
        pin:false,
        mode:'normal',
        modalname:"modal_edit_option_product"
    });

    const [modalEditName, setModalEditName] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:380,
        pin:false,
        mode:'normal',
        modalname:"modal_add_option_product"
    });


    const [optionNameEdit, setOptionNameEdit] = useState({
        name:null,
        price:null
    })



    const [modalcreatoptionlist, setModalcreateoptionlist] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:440,
        pin:false,
        mode:'normal',
        modalname:"modal_create_option_product"
    });

    const [modalcreatoption, setModalcreateoption] = useState({
        open:false,
        x:0,
        y:0,
        width:560,
        height:740,
        pin:false,
        mode:'normal',
        modalname:"modal_create_option_product"
    });

    const [productCreateOption, setProductCreateOption] = useState({
        id:null,
        name:null
    })

    return (
        <div className="px-5 py-4">
            <div className=" mt-2 ps-2">
                {props.optionproduct.map((item, index) => (
                    <div key={item.optionID}>
                        <div className="row gx-4 align-items-center">
                            <div className="col-xl-6 col-6 d-flex align-items-center ">
                                <Form.Group className="mb-2 form-st-3 pb-1 flex-fill">
                                    <Form.Label className="mb-1">ตัวเลือกเฉพาะสินค้า</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="optionName"
                                        className="form-control"
                                        placeholder="ตัวเลือกเฉพาะสินค้า"
                                        value={item.name}
                                        onChange={(e) => handleInputChange(item.optionID, e.target.value)}
                                        disabled={!item.isNewOption}
                                    />
                                     
                                </Form.Group>
                                <button
                                        type='button'
                                        className="btn d-block btn-edit-st-1 me-0 ms-3  "
                                        onClick={() => {
                                            setOptionNameEdit(item)
                                            setModalEditName( (prev) => ({...prev,open:true}) )
                                        }}
                                    >
                                </button>
                            </div>
                            <div className="col-xl-4 col-6">
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-stardard${index}`}
                                    label="เลือกได้มากกว่า 1 รายการ"
                                    name={`selecttype[${index}]`}
                                    className="custom-switch-st-1 tc-10  s1 mb-1"
                                    defaultChecked={item.selecttype}
                                    onChange={(e) => handleChangeSelecttype(e, item.id)}
                                    
                                   
                                />
                            </div>
                            <div className="col-xl-2 col-6">
                                <button type="button" className="btn  btn-st-3"
                                        onClick={() => handleDeleteoption(item.id)}
                                        style={{ background: '#F95057', borderColor: '#F95057' }}>ลบตัวเลือกสินค้า
                                </button>

                            </div>
                            {item.product_option_detail.map((item2, index2) => (
                                <div className="col-xxl-6 row gx-4 align-items-center" key={item2.listID}>
                                    <div className="col">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">รายการ</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="listName"
                                                className="form-control"
                                                placeholder="รายการ"
                                                value={item2.name}
                                                onChange={(e) => handlelistNameChange(item.id, item2.id, e.target.value)}
                                                disabled={!item2.isNewOption}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ราคา</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="listPrice"
                                                className="form-control"
                                                placeholder="ราคา"
                                                value={item2.price}
                                                onChange={(e) => handlelistPriceChange(item.id, item2.id, e.target.value)}
                                                disabled={!item2.isNewOption}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-auto ">
                                        <div className='d-flex align-items-center' style={{width:'60px'}}>
                                        {
                                            !item2.isNewOption ? (
                                                <button
                                                    type='button'
                                                    className="btn d-block btn-edit-st-1 me-0  "
                                                    onClick={() => {
                                                        setProductOptionDetail( (prev) => ({...prev,id:item.id,optionDetail:item2}) )
                                                        setModaledit( (prev) => ({...prev,open:true}) )
                                                    }}
                                                >
                                                </button>
                                            )
                                            : (
                                                <button type="button" className="btn btn-delete-st-1 me-0"
                                                    onClick={() => handleDeleteoptionList(item.id, item2.id)}>
                                                </button>
                                            )
                                        }

                                    </div>
                                        
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mb-2 mt-4">
                            <button type="button" className="btn btn-st-3 mb-4"
                                    onClick={() => {
                                        setProductCreateOption( (prev) => ({...prev,id:item.id,name:item.name}) )
                                        setModalcreateoptionlist( (prev) => ({...prev,open:true}) )
                                    }}
                                    style={{ background: '#FF731D', borderColor: '#FF731D' }}> เพิ่มรายการ
                            </button>
                        </div>
                    </div>
                ))}

                <div className="mb-2 mt-4">
                    <button type="button" className="btn btn-st-3 mb-4" 
                        onClick={() => {
                            // setProductCreateOption( (prev) => ({...prev,id:item.id,name:item.name}) )
                            setModalcreateoption( (prev) => ({...prev,open:true}) )
                        }}
                    
                    // onClick={addHandeler}
                    
                    >เพิ่มตัวเลือกสินค้า
                    </button>
                </div>
            </div>

            <StockModal
                show={modalStockShow}
                onHide={() => setModalStockShow(false)}
                settingStock={settingStock}
                deletestock={() => {
                    // values.optionList[stockindex].stock = null
                    setModalStockShow(false)
                }}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
            />

            <ModalEditOptionProduct
                modal={modaledit}
                setModal={setModaledit}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                productId={props.values.id}
            />

            <ModalCreateOptionProductList
                modal={modalcreatoptionlist}
                setModal={setModalcreateoptionlist}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
                productCreateOption={productCreateOption}
                productId={props.values.id}
            />


            <ModalCreateOptionProduct
                modal={modalcreatoption}
                setModal={setModalcreateoption}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
                productCreateOption={productCreateOption}
                productId={props.values.id}
            />



            <ModalEditNameOptionProduct
                modal={modalEditName}
                setModal={setModalEditName}
                optionproduct={props.optionproduct}
                setOptionproduct={props.setOptionproduct}
                setProductOptionDetail={setProductOptionDetail}
                productOptionDetail={productOptionDetail}
                optionNameEdit={optionNameEdit}
                setOptionNameEdit={setOptionNameEdit}
            />
        </div>
    )

}

const ModalEditOptionProduct = ({modal,setModal,optionproduct,setOptionproduct,productOptionDetail,setProductOptionDetail, productId,...props}) => {
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const handleChangeoptiondetail = async (id,option) => {
        const data = {
            price: parseInt(option.price, 10),
            name: option.name,
        }
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific-detail/${id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(data),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }

    const handleDeleteoptiondetail = async (id,product_id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific-detail/${product_id}/${id}`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }
    
    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"แก้ไขรายการตัวเลือกเฉพาะ"} 
                hbtn={true}
            >
                <fieldset >
                    <div className="row ">
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`listName`}
                                    className="form-control"
                                    placeholder="กรอกชื่อรายการ"
                                    defaultValue={ productOptionDetail.optionDetail ? productOptionDetail.optionDetail.name : ""}
                                    onChange={(e) => {
                                        setProductOptionDetail( (el) => (
                                            {
                                                ...el,
                                                optionDetail:{
                                                    ...el.optionDetail,
                                                    name:e.target.value
                                                }
                                            }
                                        ) )
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name={`listPrice`}
                                    className="form-control"
                                    placeholder="กรอกราคา"
                                    defaultValue={ productOptionDetail.optionDetail ? productOptionDetail.optionDetail.price : ''}
                                    onChange={(e) => {
                                        setProductOptionDetail( (el) => (
                                            {
                                                ...el,
                                                optionDetail:{
                                                    ...el.optionDetail,
                                                    price:e.target.value
                                                }
                                            }
                                        ) )
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </fieldset>
                <div className="text-end pb-4 pt-4 pe-3">
                    

                    <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
                        <Button
                            type="button"
                            className="btn-st-5 me-auto px-4 "
                            style={{ width: '90px' }}
                            onClick={() => {

                              

                                setOptionproduct(optionproduct => optionproduct.map((el) =>
                                    el.id === productOptionDetail.id
                                        ? {
                                            ...el,
                                            product_option_detail: el.product_option_detail.filter(dx => dx.id !== productOptionDetail.optionDetail.id),
                                        } : el,
                                ))
                                handleDeleteoptiondetail(productOptionDetail.optionDetail.id, productId)
                                setModal( (prev) => ({...prev,open:false}) )
                            }}
                        >
                            ลบ
                        </Button>
                        <Button
                            
                            className="btn btn-st-6 me-3 bg-transparent"
                            onClick={() => setModal( (prev) => ({...prev,open:false}) )}
                        >
                            ยกเลิก
                        </Button>
                        <Button type="button" className="btn-st-3 "
                            onClick={() => {
                                setOptionproduct((prev) =>
                                    prev.map((el) =>
                                        el.id == productOptionDetail.id
                                            ? {
                                                ...el,
                                                product_option_detail: el.product_option_detail.map((el2) =>
                                                    el2.id == productOptionDetail.optionDetail.id
                                                        ? {
                                                            ...el2,
                                                            price: productOptionDetail.optionDetail.price,
                                                            name: productOptionDetail.optionDetail.name,
                                                        } : el2,
                                                ),
                                            }
                                            : el,
                                    ),
                                )
                                handleChangeoptiondetail(productOptionDetail.optionDetail.id, productOptionDetail.optionDetail)
                                
                                setModal( (prev) => ({...prev,open:false}) )
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
        </ModalAllder>
    )
}


const ModalEditNameOptionProduct = ({modal,setModal,optionproduct,setOptionproduct,productOptionDetail,setProductOptionDetail,optionNameEdit,setOptionNameEdit,...props}) => {

    const [name, setName] = useState()

    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const handleChangeoption = async (id,name) => {
        const data = {
            name: name,
        }
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific/${id}`, {
                method: 'PATCH',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(data),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }


    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"แก้ไขตัวเลือกเฉพาะสินค้า"} 
                hbtn={true}
            >
                <fieldset >
                    <div className="row ">
                        <div className="col-12 pt-5 px-5">
                            <Form.Group className="mb-2 form-st-3 pb-1 mt-5  flex-fill">
                                <Form.Label className="mb-1">ตัวเลือกเฉพาะสินค้า</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="optionName"
                                    className="form-control"
                                    placeholder="ตัวเลือกเฉพาะสินค้า"
                                    defaultValue={optionNameEdit.name}
                                    onChange={(e) =>   setOptionNameEdit( (prev) => ({...prev,name:e.target.value}) ) }
              
                                />
                                
                            </Form.Group>
                        </div>

                    </div>
                </fieldset>
                <div className="text-end pb-4 pt-4 pe-3">
                    

                    <div className="d-flex justify-end align-items-center pt-3 btn-group-control">

                        <Button
                            
                            className="btn btn-st-6 me-3 bg-transparent"
                            onClick={() => setModal( (prev) => ({...prev,open:false}) )}
                        >
                            ยกเลิก
                        </Button>
                        <Button type="button" className="btn-st-3 "
                            onClick={() => {
         
                                setOptionproduct((prev) =>
                                    prev.map((el) =>
                                        el.id == optionproduct[0].id
                                            ? {
                                                ...el,
                                                name:optionNameEdit.name
                                            }
                                            : el,
                                    ),
                                )
                                handleChangeoption(optionproduct[0].id, optionNameEdit.name)

                                setModal( (prev) => ({...prev,open:false}) )
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
        </ModalAllder>
    )
}


const ModalCreateOptionProductList = ({modal,setModal,optionproduct,setOptionproduct, productCreateOption, productId, ...props}) => {
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const [optionDetail, setOptionDetail] = useState({
        "id":nanoid(),
        "name":null,
        "price":null
    })

    const handleChangeoptiondetail = async (optionId,option) => {
        const data = {
            price: parseInt(option.price, 10),
            name: option.name
        }
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific-detail/${productId}/${optionId}`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(data),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }
    
    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"เพิ่มรายการตัวเลือกเฉพาะ"} 
                hbtn={true}
            >
                <fieldset >
                    <div className="row mt-3">
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อตัวเลือก</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`listName`}
                                    className="form-control"
                                    placeholder="กรอกชื่อรายการ"
                                    defaultValue={ productCreateOption.name}
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อรายการ</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`listName`}
                                    className="form-control"
                                    placeholder="กรอกชื่อรายการ"
                                    defaultValue={ optionDetail.name}
                                    onChange={(e) => {
                                        setOptionDetail( (el) => (
                                            {
                                                ...el,
                                                name:e.target.value
                                            }
                                        ) )
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ราคา (ส่วนที่จ่ายเพิ่ม)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name={`listPrice`}
                                    className="form-control"
                                    placeholder="กรอกราคา"
                                    defaultValue={ optionDetail.price}
                                    onChange={(e) => {
                                        setOptionDetail( (el) => (
                                            {
                                                ...el,
                                                price:e.target.value
                                            }
                                        ) )
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </fieldset>
                <div className="text-end pb-4 pt-4 pe-3">
                    

                    <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
       
                        <Button
                            
                            className="btn btn-st-6 me-3 ms-auto bg-transparent"
                            onClick={() => setModal( (prev) => ({...prev,open:false}) )}
                        >
                            ยกเลิก
                        </Button>
                        <Button type="button" className="btn-st-3 "
                            onClick={() => {
                                setOptionproduct((prev) =>
                                    prev.map((el) =>
                                        el.id == productCreateOption.id
                                            ? {
                                                ...el,
                                                product_option_detail:[,...el.product_option_detail,optionDetail] 
                                                
                                            }
                                            : el,
                                    ),
                                )
                                handleChangeoptiondetail(productCreateOption.id, optionDetail)
                                
                                setModal( (prev) => ({...prev,open:false}) )
                            }}

                            disabled={!optionDetail.name || !optionDetail.price}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
        </ModalAllder>
    )
}

const ModalCreateOptionProduct = ({modal,setModal,optionproduct,setOptionproduct, productCreateOption, productId, ...props}) => {
    const { user } = useRecoilValue(authState)
    const { token, setToken } = useToken()

    const [option, setOption] = useState({
        'id': nanoid(),
        'name': '',
        'selecttype': 0,
        'product_option_detail': [],
        
    })

    const addListHandeler = (id) => {
        setOption((el) => (
            {
                ...el,
                product_option_detail:[...el.product_option_detail,{
                    "id":nanoid(),
                    "name":null,
                    "price":null
                }]
            }
        ))
    }

    const handleDeleteoptionList = (listID) => {
        setOption((el) => (
            ({
                ...el,
                product_option_detail: el.product_option_detail.filter(dx => dx.id !== listID),
            })
        ))
    }

    const transformData = (input) => {
        const transformedDetails = input.product_option_detail.map(detail => ({
          name: detail.name,
          price: parseFloat(detail.price)
        }));
      
        return {
          name: input.name,
          option_details: transformedDetails
        };
    };

    const handleSaveoption = async (option) => {
        const data = transformData(option);

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/business-product/product-options-specific/${productId}`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'business-id': user.businessId,
                }),
                body: JSON.stringify(data),
            })
            const result = await res.text();
            console.log('Update successful:', result);
            
            return result;
        } catch (error) {
            console.log('Failed to update data:', error)
        }
    }
    
    return (
        <ModalAllder  
                modal={modal} 
                setModal={setModal}  
                htitle={"เพิ่มตัวเลือกเฉพาะ"} 
                hbtn={true}
            >
                <fieldset >
                    <div className="row mt-3">
                        <div className="col-12">
                            <Form.Group className="mb-4 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อตัวเลือก</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`listName`}
                                    className="form-control"
                                    placeholder="กรอกชื่อตัวเลือก"
                                    onChange={(e) => {
                                        setOption( (el) => (
                                            {
                                                ...el,
                                                name:e.target.value
                                            }
                                        ) )
                                    }}
                                />
                            </Form.Group>
                        </div>
                        {option.product_option_detail.map((item2, index2) => (
                            <div className="col-12 row gx-4 align-items-center" >
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">รายการ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="listName"
                                            className="form-control"
                                            placeholder="รายการ"
                                            value={item2.name}
                                            onChange={(e) => {
                                                setOption((el) =>
                                                    ({
                                                        ...el,
                                                        product_option_detail: el.product_option_detail.map((el2) =>
                                                            el2.id == item2.id
                                                                ? {
                                                                    ...el2,
                                                                    name: el2.name = e.target.value,
                                                                } : el2,
                                                        ),
                                                    }),
                                                )
                                            }}
                  
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">ราคา</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="listPrice"
                                            className="form-control"
                                            placeholder="ราคา"
                                            value={item2.price}
                                            onChange={(e) => {
                                                setOption((el) =>
                                                    ({
                                                        ...el,
                                                        product_option_detail: el.product_option_detail.map((el2) =>
                                                            el2.id == item2.id
                                                                ? {
                                                                    ...el2,
                                                                    price: el2.price = e.target.value,
                                                                } : el2,
                                                        ),
                                                    }),
                                                )
                                            }}

                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-auto ">
                                    <div className='d-flex align-items-center' style={{width:'60px'}}>
                                        <button type="button" className="btn btn-delete-st-1 me-0"
                                            onClick={() => handleDeleteoptionList(item2.id)}>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    <button type="button" className="btn btn-st-3 mb-4 mt-3"
                            onClick={() => addListHandeler(option.id) }
                            style={{ background: '#FF731D', borderColor: '#FF731D' }}> เพิ่มรายการ
                    </button>
                </fieldset>
                <div className="text-end pb-4 pt-4 pe-3">
                    

                    <div className="d-flex justify-end align-items-center pt-3 btn-group-control">
       
                        <Button
                            
                            className="btn btn-st-6 me-3 ms-auto bg-transparent"
                            onClick={() => setModal( (prev) => ({...prev,open:false}) )}
                        >
                            ยกเลิก
                        </Button>
                        <Button type="button" className="btn-st-3 "
                            onClick={() => {
                                setOptionproduct((prev) => [...prev,option]  )
                                handleSaveoption(option)
   
                                setModal( (prev) => ({...prev,open:false}) )
                            }}

                            disabled={false}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
        </ModalAllder>
    )
}

function StockModal (props) {

    const { settingStock, deletestock,productOptionDetail,setProductOptionDetail,optionproduct,setOptionproduct, ...rest } = props


   
    const schema = yup.object().shape({

    })



    const optionsProduct = [
        { value: 1, label: 'ไข่ไก่สด' },
        { value: 2, label: 'ไก่สด' },
    ]

    const optionsStoreHouse = [
        { value: 1, label: 'คลังสินค้าวัตถุดิบ' },
        { value: 2, label: 'คลังสินค้าวัตถุดิบ' },
    ]
    

    return (
        <Modal
            {...rest}
            size="md"
            centered
        >
            <Modal.Body>
                <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
                    <div className="fs-20 tc-7 me-auto">ตั้งค่าเชื่อมต่อสต๊อกสินค้า</div>
                    <button onClick={props.onHide} className="btn btn-close-modal"></button>
                </div>
                <div className="px-3">
                    <Formik
                        initialValues={props.settingStock}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            // props.setFieldValue('stock', values)
                            // props.setFieldValue(`optionList[${props.stockindex}].stock`, values)

                            props.setOptionproduct((prev) =>
                                prev.map((el) =>
                                    el.id === productOptionDetail.id
                                        ? {
                                            ...el,
                                            optionList: el.optionList.map((el2) =>
                                                el2.listID == productOptionDetail.optionDetail.listID
                                                    ? {
                                                        ...el2,
                                                        stock: values,
                                                    } : el2,
                                            ),
                                        }
                                        : el,
                                ),
                            )

                            props.onHide()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid,

                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ชื่อรายการ </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="listName"
                                        className="form-control"
                                        placeholder="ชื่อรายการ"
                                        disabled={true}
                                        value={productOptionDetail.optionDetail ? productOptionDetail.optionDetail.listName : ''}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">ประเภทคลังสินค้าที่ตัดสต๊อก </Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsStoreHouse.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsStoreHouse}
                                            placeholder="ประเภทคลังสินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">สินค้าที่ตัดสต๊อก</Form.Label>
                                    <Select as="select"
                                            name="productID"
                                            onChange={(e) => {
                                                setFieldValue('productID', e.value)
                                            }}
                                            value={
                                                optionsProduct.filter(
                                                    item => item.value === values.productID,
                                                )
                                            }
                                            className="w-100 select-st-1"
                                            options={optionsProduct}
                                            placeholder="สินค้าที่ตัดสต๊อก"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">จำนวน </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="qty"
                                        className="form-control"
                                        placeholder="จำนวน"
                                        value={values.qty}
                                        onChange={handleChange}
                                        isInvalid={!!errors.qty}


                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">หน่วยวัตถุดิบ / UMS </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="unit"
                                        className="form-control"
                                        placeholder="หน่วยวัตถุดิบ / UMS"
                                        value={values.unit}
                                        disabled={true}

                                    />
                                </Form.Group>

                                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                                    {
                                        productOptionDetail.optionDetail.stock.productID ? (
                                            <Button type="button" className="btn-st-5 me-auto "
                                                    style={{ width: '80px' }} onClick={props.deletestock}>ลบ</Button>
                                        ) : (
                                            <div className="me-auto"></div>
                                        )
                                    }

                                    <Button type="button" className="btn-st-6 me-3 " style={{ width: '100px' }}
                                            onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className="btn-st-3  "
                                            style={{ width: '100px' }}>บันทึก</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal.Body>

        </Modal>
    )
}