import React, { useState } from 'react'
import Select from 'react-select'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useAuthLogout } from '../state/auth'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { appState } from '../state/app'

export function Header ({ selectbranchID, setSelectBranchID }) {
    const logout = useAuthLogout();
    const { slug } = useRecoilValue(appState)
    const navigate = useNavigate();

    const [isLogoutOpen, setIsLogoutOpen] = useState(true)
    const toggleDropDown = () => {
        console.log('Logging clicked.')
        console.log('isLogoutOpen', isLogoutOpen)
        setIsLogoutOpen(!isLogoutOpen)
    }

    function handleSidebar (e) {
        var sidebar = document.getElementsByClassName('app')
        for (var i = 0; i < sidebar.length; i++) {
            sidebar[i].classList.toggle('sidenav-toggled')
        }
    }

    const handleLogout = () => {
        console.log('Logging out...')
        logout().then(() => navigate(`/${slug}/login`))
    }

    const optionsBranch = [
        { value: 1, label: 'สาขา สำนักงานใหญ่' },
        // { value: 2, label: 'สาขา ย่อย' },
    ]



    return (
        <nav className="navbar fixed-top navbar-st-1">
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <div className="app-sidebar__toggle ms-0 me-4" data-toggle="sidebar" onClick={handleSidebar}>
                        <a className="open-toggle" href="#"></a>
                        <a className="close-toggle" href="#"></a>
                    </div>
                    <div className="d-lg-block d-none">
                        {/*<Select*/}
                        {/*    as="select"*/}
                        {/*    name="branch"*/}
                        {/*    className="w-100  select-st-2 "*/}
                        {/*    options={optionsBranch}*/}
                        {/*    placeholder="เลือกสาขา"*/}
                        {/*    onChange={(e) => {*/}
                        {/*        setSelectBranchID(e.value)*/}
                        {/*    }}*/}
                        {/*    value={*/}
                        {/*        optionsBranch.filter(*/}
                        {/*            item => item.value === selectbranchID,*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*/>*/}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="dropdown nav-item main-header-message me-xl-3 me-2 hidden">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown">
                            <div className="ih-2 p-4"></div>
                        </a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="dropdown nav-item main-header-notification me-xl-3 me-2 hidden">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown">
                            <div className="ih-1 p-4"></div>
                        </a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="nav-item full-screen fullscreen-button me-xl-3 me-2 hidden">
                        <a className="new nav-link full-screen-link" href="#">
                            <div className="ih-3 p-4"></div>
                        </a>
                    </div>

                    <div className="dropdown gg_menu nav-item main-header-notification me-xl-3 me-2 hidden">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown">
                            <div className="ih-4 p-4"></div>
                        </a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="dropdown me-xl-5">
                            <a
                                className="btn dropdown-toggle-1 drop-left border-none"
                                href="#" role="button" onClick={toggleDropDown} data-bs-toggle="dropdown"
                                aria-expanded={isLogoutOpen ? 'true' : 'false'}
                            >
                                <div className="d-flex align-items-center">
                                    <img src="/images/Avatar.png" alt="avatar-image" className="Avatar"/>
                                </div>
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a
                                    className="dropdown-item bg-white text-color-6 fs-14 py-2 spacing-1 rounded-md"
                                    href="#"
                                    onClick={handleLogout}
                                    >
                                        ออกจากระบบ
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <Dropdown className="d-flex align-items-center">
                        <div className="dropdown me-xl-5">
                            <Dropdown.Toggle id="dropdown-basic" className='logout-toggle'>
                                <div className="d-flex align-items-center">
                                    <img src="/images/Avatar.png" className="Avatar" />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleLogout}>ออกจากระบบ</Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown> */}
                </div>
            </div>
        </nav>
    )
}