import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { utils, writeFileXLSX } from "xlsx";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import PaginatedItems from "layouts/PaginateProduct";
import BranchProductList from "layouts/BranchProductList";
import ProductSelectModal from "layouts/ModalProductSelect";
import SelectCategory from "fragments/SelectCategory";
import DownloadExcel from "utils/DownloadExcel";

import { nanoid } from "nanoid";
import { useRecoilValue } from "recoil";
import { authState } from "../../state/auth";
import useToken from "../../utils/UseToken";
import { appState } from "../../state/app";
import { MoonLoader } from "react-spinners";

import { EditOptionProduct } from "layouts/BranchProductOptionEdit";
import { EditOptionStandard } from "layouts/BranchProductOptionStandardEdit";
import { EditOptionNote } from "layouts/BranchProductOptionNoteEdit";
import { getCurrentDate } from "../../utils/dateFormat";
import UploadFile from "utils/UploadFile";
import Global from "utils/Global";

export function ProductBranch() {
  const branchProductURL = `${process.env.REACT_APP_API_URL}/branch-product`;

  const { branchID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();
  const { slug } = useRecoilValue(appState);

  const [modalproductlistShow, setModalproductlistShow] = useState(false);
  const [productdata, setProductdata] = useState([]);
  const [productCanceldata, setProductCanceldata] = useState([]);
  const [productAlldata, setProductAlldata] = useState([]);
  const [btnproductstatus, setBtnproductstatus] = useState(1);
  const [displayproduct, setDisplayproduct] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const getProductData = async (branchID) => {
    try {
      const res = await fetch(`${branchProductURL}/${branchID}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      setProductdata(data);
      setProductAlldata(data);

      return { data: data, status: res.status };
    } catch (error) {
      console.log("Failed to get data:", error);
      return { data: [], status: 500 };
    }
  };

  const postSelectedProduct = async (values) => {
    setIsAdding(true);
    console.log("Add submitted!:", values);

    try {
      const res = await fetch(`${branchProductURL}/${branchID}`, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify({ branch_product: values }),
      });
      return { data: res.text(), status: res.status };
    } catch (error) {
      console.log("Failed to post data:", error);
    }
  };

  const [filterText, setFilterText] = useState("");
  let filteredItems;
  if (productdata !== undefined && productdata.length > 0) {
    filteredItems = productdata.filter((item) => {
      const matchCode = item.code
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchName = item.name
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const matchCategory = item.product_category
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return matchCode || matchName || matchCategory;
    });
  }

  const deleteProduct = (item) => {
    console.log(item.productID);
  };

  const handleAddProduct = async (product) => {
    const addProduct = await postSelectedProduct(product);
    if (addProduct.status === 201) {
      window.location.reload();
    }
  };

  useEffect(() => {
    // console.log("Effect!!!");
    setIsLoading(true);
    getProductData(branchID).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
      }
    });
  }, [branchID, btnproductstatus, user.businessId]);

  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div>
        <div className="mb-3">
          <Button
            type="button"
            className={
              btnproductstatus === 1
                ? "btn-category px-4 active"
                : "btn-category px-4"
            }
            onClick={() => {
              setBtnproductstatus(1);
              setProductdata(productAlldata);
            }}
          >
            ทั้งหมด (<span>{productAlldata.length}</span>)
          </Button>
          {/*<Button*/}
          {/*    type="button"*/}
          {/*    className={btnproductstatus === 0 ? 'btn-category px-4 active' : 'btn-category px-4'}*/}
          {/*    onClick={() => {*/}
          {/*        setBtnproductstatus(0)*/}
          {/*        setProductdata(productCanceldata)*/}
          {/*    }}*/}
          {/*>*/}
          {/*    สินค้าไม่พร้อมขาย (<span>{productCanceldata.length}</span>)*/}
          {/*</Button>*/}
        </div>
      </div>
      <div className="card card-st-2">
        <div className="card-body px-0 pt-4 pb-0">
          <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
            <button
              className={
                displayproduct == 0
                  ? "btn btn-display-product d1 active mb-2 me-2"
                  : "btn btn-display-product d1 mb-2 me-2"
              }
              data-select
              onClick={() => setDisplayproduct(0)}
            />
            <button
              className={
                displayproduct == 1
                  ? "btn btn-display-product d2 active mb-2 me-2"
                  : "btn btn-display-product d2 mb-2 me-2"
              }
              onClick={() => setDisplayproduct(1)}
            />
            <div className="me-3 mb-2" style={{ flex: "0 0 200px" }}>
              <SelectCategory setFilterText={setFilterText} />
            </div>
            <div className="mb-2 me-auto">
              {/*<DownloadExcel*/}
              {/*    data={filteredItems}*/}
              {/*    filename="รายชื่อสินค้า"*/}
              {/*/>*/}
            </div>
            <div className="me-3 mb-2">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหารหัสสินค้า ชื่อสินค้า"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              className="btn btn btn-st-7 mb-2 px-4"
              onClick={() => setModalproductlistShow(true)}
            >
              นำเข้าสินค้า
            </Button>
          </div>
          {displayproduct === 0 &&
            (isLoading === true ? (
              <div className="w-full h-full min-h-[calc(100vh*0.5)] flex justify-center items-center">
                <MoonLoader
                  color="#565DFF"
                  loading={isLoading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <BranchProductList
                branchID={branchID}
                btnedit={true}
                filteredItems={filteredItems}
                handleDelete={(product) => deleteProduct(product)}
              />
            ))}
        </div>
      </div>
      {displayproduct === 1 && (
        <PaginatedItems items={filteredItems} itemsPerPage={10} />
      )}
      <ProductSelectModal
        show={modalproductlistShow}
        onHide={() => {
          setModalproductlistShow(false);
          setIsAdding(false);
        }}
        handleSubmit={(product) => handleAddProduct(product)}
        isAdding={isAdding}
      />
    </div>
  );
}

export function ProductBranchEdit() {
  return (
    <div style={{ padding: "10px 10px 10px 10px" }}>
      <Tab.Container id="tabs" defaultActiveKey="product">
        <Nav variant="pills" className=" mb-3 pills-st-1">
          <Nav.Item>
            <Nav.Link eventKey="product">ข้อมูลสินค้า</Nav.Link>
          </Nav.Item>
          {/*<Nav.Item>*/}
          {/*    <Nav.Link eventKey="foodrecipes">สูตรอาหาร</Nav.Link>*/}
          {/*</Nav.Item>*/}
          {/*<Nav.Item>*/}
          {/*    <Nav.Link eventKey="setting">ตั้งค่าขั้นสูง</Nav.Link>*/}
          {/*</Nav.Item>*/}
          {/*<Nav.Item>*/}
          {/*    <Nav.Link eventKey="history">ประวัติ</Nav.Link>*/}
          {/*</Nav.Item>*/}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="product">
            <Productdata />
          </Tab.Pane>
          <Tab.Pane eventKey="foodrecipes">
            <FoodrecipesData />
          </Tab.Pane>
          <Tab.Pane eventKey="setting">
            <SettingData />
          </Tab.Pane>
          <Tab.Pane eventKey="history">
            <HistoryData />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

function Productdata() {
  const branchProductURL = `${process.env.REACT_APP_API_URL}/branch-product`;
  const branchProductDetailURL = `${process.env.REACT_APP_API_URL}/branch-product-detail`;

  const navigate = useNavigate();

  const { pset, productID, branchID } = useParams();
  const { user } = useRecoilValue(authState);
  const { token } = useToken();
  const { slug } = useRecoilValue(appState);

  const [modalcancel, setModalcancel] = useState(false);
  const [modalsuccess, setModalsuccess] = useState(false);
  const [textsuccess, setTextsuccess] = useState(false);
  const [modalconfirmcancelsale, setModalconfirmcancelsale] = useState(false);

  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsubCategory, setOptionsubCategory] = useState([]);

  const [listoptionstandard, setListOptionstandard] = useState([]);
  const [listoptionnote, setListOptionnote] = useState([]);
  const [onSuccess, setOnSuccess] = useState(false);

  const [optionproduct, setOptionproduct] = useState([
    {
      optionID: nanoid(),
      optionName: "",
      selecttype: 0,
      optionList: [
        {
          listID: nanoid(),
          listName: "",
          listPrice: "",
        },
      ],
    },
  ]);
  const [productset, setProductset] = useState([
    {
      id: nanoid(),
      productID: "",
      productName: "",
      productNum: 1,
      productPrice: 0,
      productsumPrice: 0,
    },
  ]);

  const [optionsUnit, setOptionsUnit] = useState([]);

  const [formdata, setFormdata] = useState({
    productCode: "",
    productName: "",
    category: "",
    subcategory: "",
    saleperweight: 0,
    productPrice: "",
    saleunit: "",
    barcode: "",
    productVat: 0,
    statusSale: 0,
    displayCustomer: 0,
    promotion: 0,
    recommended: 0,
    buffet: 0,
    optionstandardStatus: 0,
    optionstandard: "",
    optionproductStatus: 0,
    optionproduct: [],
    optionnoteStatus: 0,
    optionnote: [],
    productsetStatus: 0,
    productset: [],
    note: "",
    userCreate: "Admin Pat",
    dateCreate: "22/01/2023",
  });

  const [optionsProduct, setOptionsProduct] = useState([]);

  const [branchProductDetailData, setBranchProductDetailData] =
    useState(undefined);

  const schema = yup.object().shape({
    price: yup.string().required(),
  });

  const handleSaleproduct = (productID) => {
    setFormdata((prev) => ({ ...prev, productStatus: 1 }));
  };

  const getBranchProductDetails = async (productID) => {
    try {
      const res = await fetch(`${branchProductDetailURL}/${productID}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "business-id": user.businessId,
        }),
      });
      const data = await res.json();
      setBranchProductDetailData(data);
    } catch (error) {
      console.log("Failed to get data:", error);
      setBranchProductDetailData(undefined);
      return { data: undefined, status: 500 };
    }
  };

  const patchBranchProduct = async (productID, values) => {
    // console.log(`Patch branch product data to productID ${productID}: ${values}`)
    try {
      const res = await fetch(`${branchProductURL}/${productID}`, {
        method: "PATCH",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
        body: JSON.stringify(values),
      });
      return { status: res.status };
    } catch (error) {
      console.log("Failed to patch:", error);
    }
  };

  const deleteBranchProduct = async (productID) => {
    console.log(`Delete branch productID: ${productID}`);
    try {
      const res = await fetch(`${branchProductURL}/${productID}`, {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "business-id": user.businessId,
        }),
      });
      return { status: res.status };
    } catch (error) {
      console.log("Failed to patch:", error);
    }
  };

  const handleRemoveProduct = async (productID) => {
    const removeBranchProduct = await deleteBranchProduct(productID);
    if (removeBranchProduct.status === 200) {
      setTextsuccess("นำรายการสินค้าออกสำเร็จ");
      setModalsuccess(true);
    }
  };

  useEffect(() => {
    if (token && user.businessId) {
      // console.log("Get branch product detail");
      getBranchProductDetails(productID);
    }

  }, [token, user.businessId]);

  useEffect(() => {
    // console.log("🚀 ~ Productdata ~ onSuccess:", onSuccess)
    if (onSuccess) {
      getBranchProductDetails(productID);
      setOnSuccess(false);
    }

  }, [onSuccess]);

  return (
    <div>
      {branchProductDetailData && (
        <>
          <Formik
            enableReinitialize
            initialValues={branchProductDetailData}
            validationSchema={schema}
            onSubmit={async (values) => {
              const updateBranchProduct = await patchBranchProduct(productID, {
                price: parseInt(values.price),
                is_active: values.is_active,
                is_show: values.is_show,
              });
              if (updateBranchProduct.status === 200) {
                setTextsuccess("สร้างรายการสินค้าสำเร็จ");
                setModalsuccess(true);

                setTimeout(() => {
                  setModalsuccess(false);
                  navigate(`/${slug}/branch/setting/product/${branchID}`);
                  // returnProductPage();
                }, 2000);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="d-flex align-items-center">
                      <div className="tc-8 fs-18 fw-bold px-3 me-auto">
                        ข้อมูลสินค้าและราคา
                      </div>
                      <div className="text-end pe-3">
                        <button
                          type="button"
                          className="btn me-3 btn-st-3 hidden"
                          onClick={() => handleRemoveProduct(productID)}
                          style={{
                            background: "#F95057",
                            borderColor: "#F95057",
                          }}
                        >
                          นำออกจากสาขา
                        </button>
                        <Link
                          to={`/${slug}/branch/setting/product/${branchID}`}
                          className="btn-st-6 me-3 btn"
                        >
                          ยกเลิก
                        </Link>
                        <Button type="submit" className="btn-st-3 ">
                          บันทึก
                        </Button>
                      </div>
                    </div>
                    {values !== undefined && values.productStatus === 2 && (
                      <div className="d-flex flex-wrap align-items-center align-items-center px-3 mt-1">
                        <div className="me-3 alert-st-1">
                          สินค้านี้ถูกยกเลิกขายไปเมื่อวันที่ {values.dateCancel}{" "}
                          โดย {values.userCancel}
                        </div>
                        <button
                          type="button"
                          className="btn  btn-st-3"
                          onClick={() => handleSaleproduct(productID)}
                          style={{
                            background: "#F95057",
                            borderColor: "#F95057",
                          }}
                        >
                          ขายอีกครั้ง
                        </button>
                      </div>
                    )}
                    <fieldset>
                      <div>
                        <ImageUpload
                          setFieldValue={setFieldValue}
                          values={values.product}
                          isBusinessEdit={false}
                          // uploadImageFile={props.uploadImageFile}
                        />
                        <div className="pt-3 px-3">
                          <div className="row gx-xl-4">
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  รหัสสินค้า{" "}
                                  <span className="text-danger ms-1"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="code"
                                  className="form-control"
                                  placeholder="รหัสสินค้า"
                                  value={values?.product?.code}
                                  onChange={handleChange}
                                  // isInvalid={!!errors.product.code}
                                  disabled={true}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  ชื่อสินค้า{" "}
                                  <span className="text-danger ms-1"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder="ชื่อสินค้า"
                                  value={values?.product?.name}
                                  onChange={handleChange}
                                  // isInvalid={!!errors.product.name}
                                  disabled={true}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หมวดหมู่หลัก
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="category"
                                  className="form-control"
                                  placeholder="ชื่อสินค้า"
                                  value={values.category}
                                  onChange={handleChange}
                                  // isInvalid={!!errors.product.name}
                                  disabled={true}
                                  readOnly={true}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <Form.Group className="mb-2 form-st-3 pb-1">
                                <Form.Label className="mb-1">
                                  หมวดหมู่ย่อย
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sub_category"
                                  className="form-control"
                                  placeholder="ชื่อสินค้า"
                                  value={values.sub_category}
                                  onChange={handleChange}
                                  // isInvalid={!!errors.product.name}
                                  disabled={true}
                                  readOnly={true}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-xl-6">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <div style={{ width: "160px" }}>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label="ขายตามน้ำหนัก"
                                      name="is_price_depend_weight"
                                      className="custom-switch-st-1 tc-10 s2 mb-1"
                                      defaultChecked={
                                        values?.product?.is_price_depend_weight
                                      }
                                      checked={
                                        values?.product?.is_price_depend_weight
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      ราคาสินค้า{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="price"
                                      className="form-control"
                                      placeholder="ราคาสินค้า"
                                      value={values.price}
                                      onChange={handleChange}
                                      isInvalid={!!errors.price}
                                      disabled={false}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-12 col-sm">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      หน่วยขาย / หน่วยเล็ก UMS
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="unit"
                                      className="form-control"
                                      placeholder="ชื่อสินค้า"
                                      value={values?.product?.unit.name}
                                      onChange={handleChange}
                                      // isInvalid={!!errors.product.name}
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="row align-items-center">
                                <div className="col-sm-6">
                                  <Form.Group className="mb-2 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                      บาร์โค้ด
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="barcode"
                                      className="form-control icon-barcode"
                                      placeholder="บาร์โค้ด"
                                      value={values?.product?.barcode}
                                      onChange={handleChange}
                                      // isInvalid={!!errors.product.barcode}
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-sm-6">
                                  <Form.Check
                                    name="productVat"
                                    type="checkbox"
                                    id="is_include_vat"
                                    label="สินค้ามีภาษีมูลค่าเพิ่ม"
                                    className="form-check-st-1"
                                    checked={values?.product?.is_include_vat}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-1">
                      ตั้งค่าการขาย
                    </div>
                    <div className="d-flex flex-wrap px-3">
                      <div className="mb-2 me-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch2"
                          label="พร้อมจำหน่าย"
                          name="is_active"
                          className="custom-switch-st-1 tc-10 s2 mb-1"
                          defaultChecked={values.is_active}
                          checked={values.is_active}
                          disabled={false}
                          onChange={(e) => {
                            setFieldValue("is_active", e.target.checked);
                          }}
                        />
                      </div>
                      <div className="mb-2 me-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch3"
                          label="แสดงหน้าจอลูกค้า"
                          name="is_show"
                          className="custom-switch-st-1 tc-10 s2 mb-1"
                          defaultChecked={values.is_show}
                          checked={values.is_show}
                          disabled={false}
                          onChange={(e) => {
                            setFieldValue("is_show", e.target.checked);
                          }}
                        />
                      </div>
                      <div className="mb-2 me-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch4"
                          label="ไม่เข้าร่วมทุกโปรโมชั่น"
                          name="is_join_promotion"
                          className="custom-switch-st-1 tc-10 s2 mb-1"
                          defaultChecked={values.is_join_promotion}
                          checked={values.is_join_promotion}
                          disabled={true}
                          onChange={(e) => {
                            setFieldValue(
                              "is_join_promotion",
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2 me-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch5"
                          label="เมนูแนะนำ"
                          name="is_recommend"
                          className="custom-switch-st-1 tc-10 s2 mb-1"
                          defaultChecked={values.is_recommend}
                          checked={values.is_recommend}
                          disabled={true}
                          onChange={(e) => {
                            setFieldValue("is_recommend", e.target.checked);
                          }}
                        />
                      </div>
                      <div className="mb-2 me-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch6"
                          label="เป็นแพคเกจบุฟเฟต์"
                          name="is_buffet"
                          className="custom-switch-st-1 tc-10 s2 mb-1"
                          defaultChecked={values.is_buffet}
                          checked={values.is_buffet}
                          disabled={true}
                          onChange={(e) => {
                            setFieldValue("is_buffet", e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset disabled={true}>
                  {pset && (
                    <div className="card card-st-3 mb-3">
                      <div className="card-body px-xl-3">
                        <div className="pt-2 pb-1 px-3">
                          <Form.Check
                            type="switch"
                            id="option-set-switch2"
                            label="ตัวเลือกสินค้าจัดชุด"
                            name="productsetStatus"
                            className="custom-switch-st-1 tc-10  s1 mb-1"
                            defaultChecked={values.productsetStatus}
                            checked={values.productsetStatus === 1}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("productsetStatus", 1);
                              } else {
                                setFieldValue("productsetStatus", 0);
                              }
                            }}
                          />
                          <span className="ms-2" style={{ color: "#858796" }}>
                            {" "}
                            (เช่น ชุดอาหารกลางวัน Combo ข้าว+น้ำ){" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </fieldset>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="pt-2 pb-1 px-3">
                      <Form.Check
                        type="switch"
                        id="custom-sd"
                        label="กำหนดตัวเลือกมาตรฐาน"
                        name="have_product_standard"
                        className="custom-switch-st-1 tc-10  s1 mb-1"
                        defaultChecked={values?.product?.have_product_standard}
                        checked={values?.product?.have_product_standard}
                        readOnly={true}
                        disabled={true}
                      />
                      <span className="ms-2" style={{ color: "#858796" }}>
                        {" "}
                        ( ใช้ข้อมูลจากตัวเลือกมาตรฐานที่สร้างไว้ ){" "}
                      </span>
                    </div>
                    {values?.product?.have_product_standard &&
                      values?.standard_products && (
                        <EditOptionStandard
                          listoptionstandard={values.standard_products}
                          setListOptionstandard={setListOptionstandard}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          values={values}
                          onSuccess={() => setOnSuccess(true)}
                        />
                      )}
                  </div>
                </div>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="pt-2 pb-1 px-3">
                      <Form.Check
                        type="switch"
                        id="option-food-switch2"
                        label="ตัวเลือกเฉพาะสินค้า"
                        name="have_product_specific"
                        className="custom-switch-st-1 tc-10  s1 mb-1"
                        defaultChecked={values?.product?.have_product_specific}
                        checked={values?.product?.have_product_specific}
                        readOnly={true}
                        disabled={true}
                      />
                      <span className="ms-2" style={{ color: "#858796" }}>
                        (เพิ่มตัวเลือกเฉพาะสินค้านี้เท่านั้น เช่น เพิ่มไข่ดาว,
                        Size S M L, อื่นๆ)
                      </span>
                    </div>
                    {values?.product?.have_product_specific &&
                      values?.specific_product_options && (
                        <EditOptionProduct
                          optionproduct={values.specific_product_options}
                          setOptionproduct={setOptionproduct}
                          onSuccess={() => setOnSuccess(true)}
                        />
                      )}
                  </div>
                </div>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3">
                    <div className="pt-2 pb-1 px-3">
                      <Form.Check
                        type="switch"
                        id="custom-notef"
                        label="ตัวเลือกหมายเหตุ"
                        name="have_product_remark"
                        className="custom-switch-st-1 tc-10  s1 mb-1"
                        defaultChecked={values.product.have_product_remark}
                        checked={values.product.have_product_remark}
                        readOnly={true}
                        disabled={true}
                      />
                      <span className="ms-2" style={{ color: "#858796" }}>
                        {" "}
                        ( ใช้ข้อมูลจากตัวเลือกหมายเหตุที่สร้างไว้ ){" "}
                      </span>
                    </div>
                    {values?.product?.have_product_remark && (
                      <EditOptionNote
                        listoptionnote={values.remark_products}
                        setListOptionnote={setListOptionnote}
                        onSuccess={() => setOnSuccess(true)}
                      />
                    )}
                  </div>
                </div>
                <div className="card card-st-3 mb-3">
                  <div className="card-body px-xl-3 row">
                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">หมายเหตุ</Form.Label>
                        <Form.Control
                          type="text"
                          name="remark"
                          className="form-control"
                          placeholder="หมายเหตุ"
                          value={
                            values.product.remark === null
                              ? "-"
                              : values.product.remark
                          }
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">วันที่สร้าง</Form.Label>
                        <Form.Control
                          type="text"
                          name="created_at"
                          className="form-control"
                          placeholder="วันที่สร้าง"
                          value={getCurrentDate(
                            "/",
                            new Date(values.product.created_at)
                          )}
                          onChange={handleChange}
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ผู้แก้ไข</Form.Label>
                        <Form.Control
                          type="text"
                          name="user_name"
                          className="form-control"
                          placeholder="ผู้แก้ไข"
                          value={values.product.modified.user_name}
                          onChange={handleChange}
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <ConfirmCancelsaleModal
                  show={modalconfirmcancelsale}
                  onHide={() => setModalconfirmcancelsale(false)}
                  onSuccess={() => setModalsuccess(true)}
                  formdata={formdata}
                  productID={productID}
                  setTextsuccess={setTextsuccess}
                  setFieldValue={setFieldValue}
                />
              </Form>
            )}
          </Formik>
          <ConfirmCancelModal
            show={modalcancel}
            onHide={() => setModalcancel(false)}
          />
          <SuccessModal
            show={modalsuccess}
            onHide={() => {
              setModalsuccess(false);
              navigate(`/${slug}/branch/setting/product/${branchID}`);
            }}
            textsuccess={textsuccess}
          />
        </>
      )}
    </div>
  );
}

const ImageUpload = (props) => {
  const [imgData, setImgData] = useState(false);
  const [imageURL, setImageURL] = useState(props.values.img_url);

  const handlePreviewImage = async (e) => {
    if (e.target.files[0]) {
      const res = await props.uploadImageFile(e.target.files[0]);
      setImageURL(res);
      props.setFieldValue("img_url", res);
      // const reader = new FileReader()
      // reader.addEventListener('load', () => {
      //     setImgData(reader.result)
      //     props.setFieldValue('img_url', reader.result)
      // })
      // reader.readAsDataURL(e.target.files[0])
    }
  };

  useEffect(() => {
    const exists = Global.checkURL(props.values.img_url);
    const img_url = exists ? props.values.img_url : process.env.REACT_APP_API_URL_SOCKET + "/" + props.values.img_url;
    setImageURL(img_url);
  }, [props.values.img_url, props.isBusinessEdit]);

  return (
    <div className="d-flex align-items-center mb-4 mt-3 ">
      <div className="bx-upload">
        {imageURL !== undefined && imageURL.length > 0 ? (
          <img
            src={imageURL}
            alt={`product ${props.values.productName}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/blank.png";
            }}
          />
        ) : (
          <div className="preimg"></div>
        )}
      </div>
      <input
        type="file"
        name=""
        className="fileUpload hide-input"
        id="fileUpload"
        accept="image/*"
        onChange={(e) => {
          handlePreviewImage(e);
        }}
      />
      {props.isBusinessEdit && (
        <div className="flex-fill ms-3">
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-st-4 btn-upload me-3 hover:bg-transparent hover:text-[#565DFF] hover:border-[#565DFF]"
              onClick={() => document.getElementById("fileUpload").click()}
            >
              แก้ไขรูปภาพ
            </button>
            <button
              type="button"
              className="btn btn-st-2 btn-remove-image hover:bg-[#FF4D49] hover:text-white"
              onClick={(e) => {
                setImgData(false);
                props.setFieldValue("img_url", "");
              }}
            >
              ลบ
            </button>
          </div>
          <div className="tc-7 fs-12">
            อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K
          </div>
        </div>
      )}
    </div>
  );
};

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div
        className="form-check form-check-st-3 "
        style={{ backgroundColor: "" }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          style={{ height: "18px", width: "18px" }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
        <label className="form-check-label" id="booty-check" />
      </div>
    </>
  );
});

function FoodrecipesData() {
  return (
    <div>
      <div className="card card-st-2" style={{ height: "400px" }}>
        <div className="card-body px-0 py-4 d-flex align-items-center justify-content-center">
          <div>
            <div className="text-center mb-3">
              <img src="/images/tdesign_system-setting.png" width={100} />
            </div>
            <div className="fs-20 tc-12">กำลังพัฒนาระบบ</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getunitData = () => {
  const options = [];
  fetch("/json/productunit.json")
    .then((response) => response.json())
    .then((result) => {
      for (var i = 0; i < result.length; i++) {
        var obj = {};
        obj["value"] = result[i].unitID;
        obj["label"] = result[i].unitName;
        options.push(obj);
      }
    })
    .catch((error) => console.log("error", error));

  return options;
};

function SettingData() {
  const [optionsUnit, setOptionsUnit] = useState([]);

  useEffect(() => {
    setOptionsUnit(getunitData());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ratio: "",
        umlLabel: "",
        ums: "",
      }}
      validationSchema=""
      onSubmit={async (values) => {}}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        values,
        setFieldValue,
        isInvalid,
        isValid,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset>
            <div className="card card-st-3 mb-3">
              <div className="card-body px-xl-3">
                <div className="tc-8 fs-18 fw-bold px-3 mb-4 pt-2">
                  ตั้งค่าขั้นสูง
                </div>
                <div className="px-3">
                  <div className="pt-2 pb-1 mb-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      label="ตั้งค่าสต๊อก"
                      name="settingStock"
                      className="custom-switch-st-1 tc-10  s1 mb-1"
                      defaultChecked={values.settingStock}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setFieldValue("settingStock", 1);
                        } else {
                          setFieldValue("settingStock", 0);
                        }
                      }}
                    />
                    <span className="ms-2" style={{ color: "#858796" }}>
                      {" "}
                      ( สินค้ามีการตัดสต๊อก ){" "}
                    </span>
                  </div>
                  {values.settingStock === 1 ? (
                    <div>
                      <div className="pb-2">
                        <Form.Label className="mb-4 pt-2 tc-10">
                          ตั้งค่าประเภทการตัดสต๊อก
                        </Form.Label>
                        <div className="mb-3">
                          <Form.Check
                            inline
                            label="ตัดสต๊อกสินค้าสำเร็จรูป"
                            name="stockType"
                            type="radio"
                            id={`stockType-1`}
                            className="form-check-st-2 tc-7"
                            checked={values.stockType === 1}
                            onChange={(e) => {
                              setFieldValue("stockType", 1);
                            }}
                          />
                          <Form.Check
                            inline
                            label="ตัดสต๊อกตามสตรอาหาร"
                            name="stockType"
                            type="radio"
                            id={`stockType-2`}
                            className="form-check-st-2 tc-7"
                            checked={values.stockType === 2}
                            onChange={(e) => {
                              setFieldValue("stockType", 2);
                            }}
                          />
                        </div>
                      </div>

                      {values.stockType == 1 ? (
                        <div className="row">
                          <div className="col-md-2">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                หน่วยเล็ก (UMS){" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="ums"
                                className="form-control"
                                placeholder="หน่วยเล็ก"
                                value={values.ums}
                                onChange={handleChange}
                                isInvalid={!!errors.ums}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group className="mb-2 mt-3 form-st-3  ">
                              <Form.Label className="mb-1">
                                หน่วยใหญ่ (UML){" "}
                              </Form.Label>
                              <Select
                                as="select"
                                name="uml"
                                onChange={(e) => {
                                  setFieldValue("uml", e.value);
                                  setFieldValue("umlLabel", e.label);
                                }}
                                className="w-100 select-st-1"
                                options={optionsUnit}
                                placeholder="หน่วยใหญ่ (UML) "
                                value={optionsUnit.filter(
                                  (item) => item.value === values.uml
                                )}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-3">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                อัตราส่วนต่อหน่วยย่อย (Ratio){" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="ratio"
                                className="form-control"
                                placeholder="อัตราส่วนต่อหน่วยย่อย"
                                value={values.ratio}
                                onChange={handleChange}
                                isInvalid={!!errors.ratio}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">ผลลัพธ์ </Form.Label>
                              <Form.Control
                                type="text"
                                name="result"
                                className="form-control"
                                placeholder="ผลลัพธ์"
                                value={`${values.ratio} X ${values.ums} เท่ากับ 1 ${values.umlLabel}`}
                                onChange={handleChange}
                                isInvalid={!!errors.result}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      ) : values.stockType == 2 ? (
                        <div style={{ color: "#F00" }}>
                          กำหนดรายการที่ต้องการตัดสต๊อกตามเมนูสตรอาหาร
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="text-end pt-2 pb-3 btn-group-control ">
                        <Button
                          type="button"
                          className="btn-st-6 me-3 "
                          style={{ width: "100px" }}
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          type="submit"
                          className="btn-st-3  "
                          style={{ width: "100px" }}
                        >
                          บันทึก
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
}

function HistoryData() {
  const [logdata, setLogdata] = useState([]);

  const getlogData = () => {
    fetch("/json/productLog.json")
      .then((response) => response.json())
      .then((result) => {
        setLogdata(result);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getlogData();
  }, []);

  const columns = [
    {
      name: "วันที่",
      selector: (row) => row.logDate,
      width: "200px",
    },
    {
      name: "LOG",
      selector: (row) => row.logName,
      width: "300px",
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.logName}</div>
      ),
    },
    {
      name: "รายการ",
      selector: (row) => row.logDetail,
    },
    {
      name: "สมาชิก",
      selector: (row) => row.userUpdate,
      width: "250px",
      cell: (row, index, column, id) => (
        <div>
          <div>{row.userUpdate}</div>
          <div className="fs-12 mt-1">{row.logDate}</div>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        background: " #F5F5F7",
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "16px",
        fontWeight: "300",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const [filterText, setFilterText] = useState("");
  const filteredItems = logdata.filter(
    (item) =>
      item.logName &&
      item.logName.toLowerCase().includes(filterText.toLowerCase())
  );

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Log");
    writeFileXLSX(wb, "Log.xlsx");
  };

  const optionsLog = [
    { value: "ทั้งหมด", label: "ทั้งหมด" },
    { value: "ยกเลิกการขาย", label: "ยกเลิกการขาย" },
    { value: "สร้าง", label: "สร้าง" },
    { value: "แก้ไข", label: "แก้ไข" },
  ];

  return (
    <div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
            <div className="me-3 mb-2" style={{ flex: "0 0 200px" }}>
              <Select
                as="select"
                name="logname"
                onChange={(e) => {
                  e.label != "ทั้งหมด"
                    ? setFilterText(e.label)
                    : setFilterText("");
                }}
                className="w-100 select-st-1 pd"
                options={optionsLog}
                placeholder="ทั้งหมด"
              />
            </div>
            <button
              className="btn btn-export-st-1 mb-2 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
          </div>
          <DataTable
            columns={columns}
            data={filteredItems}
            customStyles={customStyles}
            selectableRows
            selectableRowsComponent={Checkbox}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </div>
  );
}

function ConfirmCancelsaleModal(props) {
  const { setTextsuccess, productID, onSuccess, setFieldValue, ...rest } =
    props;

  const handleCancel = (productID) => {
    props.setFieldValue("productStatus", 2);
    props.setTextsuccess("ยกเลิกการขายสำเร็จ");
    props.onHide();
    props.onSuccess();
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องยืนยันยกเลิกขายสินค้า
              </div>
              <div className="text-center fs-22 tc-7">
                <span style={{ color: "#565DFF" }}>
                  {props.formdata.productName}
                </span>{" "}
                ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "150px" }}
                onClick={() => handleCancel(props.productID)}
              >
                ยกเลิกการขาย
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "150px" }}
                onClick={props.onHide}
              >
                ไม่ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmCancelModal(props) {
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                คุณต้องการยกเลิก
              </div>
              <div className="text-center fs-22 tc-7">
                การสร้างรายการนี้ใช่หรือไม่
              </div>
            </div>
            <div className="text-center pt-4 mt-3  btn-group-control">
              <Link
                to="/products"
                className="btn btn-st-3 mx-3 text-decoration-none"
                style={{ width: "100px" }}
              >
                ใช่
              </Link>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SuccessModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
        <div className="px-3 py-4 mx-auto">
          <div className="text-center mb-4 flex justify-center">
            <svg
              width="84"
              height="83"
              viewBox="0 0 84 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
              <path
                d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text-center fs-18 tc-7">{props.textsuccess}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
