import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import getCurrentDate from "utils/CurrentDate";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as yup from "yup";
import BusinessEmpApi from "services/BusinessEmpApi";

const ConfirmCancelempModal = (props) => {
  const {
    token,
    businessId,
    updated_by,
    setTextsuccess,
    empID,
    onSuccess,
    setFieldValue,
    ...rest
  } = props;

  const [startDate, setStartDate] = useState("");
  const schema = yup.object().shape({
    end_date: yup.string(),
  });

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": businessId,
    },
  };

  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <Formik
            enableReinitialize
            initialValues={{
              end_date: "",
            }}
            validationSchema={schema}
            onSubmit={async (values) => {
              console.log("🚀 ~ onSubmit={ ~ values:", startDate);

              const localDate = new Date(
                startDate.getTime() - startDate.getTimezoneOffset() * 60000
              );
              const formattedDate = localDate.toISOString().slice(0, 10);
              console.log("🚀 ~ formattedDate:", formattedDate)

              const data = {
                end_date: formattedDate,
                status_id: "3",
                updated_by: updated_by,
              };

              const response = await BusinessEmpApi.Update(
                empID,
                data,
                configCreate
              );

              if (response.status === 200) {
                props.setTextsuccess("ทำรายการสำเร็จ");
                props.onHide();
                props.onSuccess();

                setTimeout(() => {
                  // props.setModalsuccess(false);
                  // current location
                  window.location.reload();
                }, 2000);
              } else {
                console.log(response);
              }

    
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div>
                  <div>
                    <div className="text-center fs-22 tc-7 mb-4">
                      คุณยืนยันการลาออกใช่หรือไม่
                    </div>
                  </div>

                  <Form.Group className="mb-3 form-st-3 pb-1">
                    <Form.Label className="mb-1">วันที่ลาออก </Form.Label>
                    <DatePicker
                      inputFormat="YYYY/MM/DD"
                      selected={startDate}
                      onChange={(date) => {
                        console.log("🚀 ~ onSubmit={ ~ date:", date);
                        // const localDate = new Date(
                        //   date.getTime() - date.getTimezoneOffset() * 60000
                        // );
                        // const formattedDate = localDate
                        //   .toISOString()
                        //   .slice(0, 10);
                        // setFieldValue("end_date", formattedDate);
                        setStartDate(date);
                      }}
                      placeholder="YYYY/MM/DD"
                      className="w-100 datepicker2"
                      isInvalid={!!errors.end_date}
                      values={startDate}
                    />
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    {errors.end_date}
                  </Form.Control.Feedback>

                  <div className="text-center pt-4 mt-3  btn-group-control">
                    <Button
                      type="button"
                      className="btn-st-6 mx-3 "
                      style={{ width: "150px" }}
                      onClick={props.onHide}
                    >
                      ไม่ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-st-3 mx-3 text-decoration-none"
                      style={{ width: "150px" }}
                    >
                      ยืนยันการลาออก
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCancelempModal;
