import * as yup from 'yup';

export const BranchEmployeeSchema = yup.object().shape({
    empCode: yup.string().required(),
    empName: yup.string().required(),
    empEmail: yup.string().required(),
    pin: yup.string()
    .when("usePin", (usePin, schema) => {
        if(usePin == 1) 
          return schema.required()
        return schema
    }),
    pinConfirm: yup.string()
    .when("usePin", (usePin, schema) => {
        if(usePin == 1) 
          return schema.oneOf([yup.ref('pin'), undefined], 'Passwords must match').required()
        return schema
    }),
})